@charset "UTF-8";
/*------------------------------------
  Components
------------------------------------*/
/*------------------------------------
  Alerts
------------------------------------*/
/* Alert Close Button */
.u-alert-close--light {
  font-weight: 300;
  color: #000;
  opacity: .7;
  text-shadow: none;
  transition: color .3s;
  font-size: 18px;
  cursor: pointer;
}

.u-alert-close--light:hover {
  opacity: 1;
}

.u-alert-close--light:focus, .u-alert-close--light:active:focus {
  outline: 0 none;
  box-shadow: none;
}

/* Alert Lists */
.u-alert-list {
  margin-left: -20px;
}

/* Alert Lists with Font Awesome Icons */
.alert__icon {
  margin-left: 20px;
}

.alert__icon-list {
  font-size: 12px;
  line-height: 1.5;
}

/*------------------------------------
  Arrows
------------------------------------*/
.u-arrow-v1::before {
  display: inline-block;
  position: relative;
  top: 50%;
  left: 50%;
  vertical-align: top;
  transform: translateX(-50%) translateY(-50%);
}

.u-arrow-v1[class*="abs"]::before {
  position: absolute;
}

.u-arrow-custom-v1 .u-arrow-icon {
  display: block;
}

.u-arrow-custom-v1 .u-arrow-text {
  position: absolute;
  top: 50%;
  transition: transform .3s;
}

.u-arrow-custom-v1.js-prev .u-arrow-text {
  transform: translateY(-50%) translateX(-100%);
}

.u-arrow-custom-v1.js-next .u-arrow-text {
  transform: translateY(-50%) translateX(100%);
}

.u-arrow-custom-v1:hover .u-arrow-text {
  transform: translateY(-50%) translateX(0);
}

.u-arrow-custom-v2 {
  overflow: hidden;
  transition: background-color 0.3s;
}

.u-arrow-custom-v2 .u-arrow-icon {
  transition: transform 0.3s;
}

.u-arrow-custom-v2 .u-arrow-img {
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s;
}

.u-arrow-custom-v2.js-prev .u-arrow-icon {
  transform: translateY(-50%) translateX(10px);
}

.u-arrow-custom-v2.js-prev .u-arrow-img {
  transform: translateX(100%);
}

.u-arrow-custom-v2.js-next .u-arrow-icon {
  transform: translateY(-50%) translateX(-10px);
}

.u-arrow-custom-v2.js-next .u-arrow-img {
  transform: translateX(-100%);
}

.u-arrow-custom-v2:hover .u-arrow-icon {
  transform: translateY(-50%) translateX(0);
}

.u-arrow-custom-v2:hover .u-arrow-img {
  opacity: .6;
  transform: translateX(0);
}

.u-arrow-custom-v3 .u-arrow-helper {
  content: "";
  opacity: 0;
  transition: transform 0.3s, opacity 0.3s;
  transform: scale(0.9);
}

.u-arrow-custom-v3 .u-arrow-icon {
  display: block;
}

.u-arrow-custom-v3 .u-arrow-icon-before, .u-arrow-custom-v3 .u-arrow-icon-after {
  position: absolute;
  left: 25%;
  transition: transform 0.3s, background-color 0.3s;
  backface-visibility: hidden;
}

.u-arrow-custom-v3 .u-arrow-icon-before {
  transform: translateX(-50%) rotate(30deg);
  transform-origin: 0 100%;
}

.u-arrow-custom-v3 .u-arrow-icon-after {
  top: 50%;
  transform: translateX(-50%) rotate(-30deg);
  transform-origin: 0 0;
}

.u-arrow-custom-v3.js-next .u-arrow-icon {
  transform: rotate(180deg);
}

.u-arrow-custom-v3:hover .u-arrow-helper {
  background-color: #fff;
  opacity: 1;
  transform: scale(1);
}

.u-arrow-custom-v3:hover .u-arrow-icon-before, .u-arrow-custom-v3:hover .u-arrow-icon-after {
  background-color: #2cb8f3;
}

.u-arrow-custom-v3:hover .u-arrow-icon-before {
  transform: translateX(-50%) rotate(45deg);
}

.u-arrow-custom-v3:hover .u-arrow-icon-after {
  transform: translateX(-50%) rotate(-45deg);
}

.u-arrow-custom-v4 {
  width: 62px;
  height: 62px;
  transition: width 0.3s, background-color 0.3s;
}

.u-arrow-custom-v4 h4 {
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s;
}

.u-arrow-custom-v4.js-prev h4 {
  transform: translateX(100%);
}

.u-arrow-custom-v4.js-next h4 {
  transform: translateX(-100%);
}

.u-arrow-custom-v4:hover {
  width: 200px;
}

.u-arrow-custom-v4:hover .u-arrow-icon {
  color: #2cb8f3;
}

.u-arrow-custom-v4:hover h4 {
  opacity: 1;
  transition-delay: 0.1s;
  transform: translateX(0);
}

.u-arrow-custom-v5 .u-arrow-icon::before {
  transition: transform .3s .3s;
}

.u-arrow-custom-v5 .u-arrow-text {
  transition: transform .3s .3s;
  perspective: 1000px;
}

.u-arrow-custom-v5 .u-arrow-title {
  position: absolute;
  top: 100%;
  width: 100%;
  height: 30%;
  transition: transform 0.3s;
  backface-visibility: hidden;
  transform-origin: 50% 0;
  transform: rotateX(-90deg);
  margin: 0;
}

.u-arrow-custom-v5 .u-arrow-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.u-arrow-custom-v5.js-prev .u-arrow-text {
  transform: translateX(-100%);
}

.u-arrow-custom-v5.js-next .u-arrow-text {
  transform: translateX(100%);
}

.u-arrow-custom-v5:hover .u-arrow-text {
  transform: translateX(0);
}

.u-arrow-custom-v5:hover .u-arrow-title {
  transition-delay: .6s;
  transform: rotateX(0deg);
}

.u-arrow-custom-v5:hover .u-arrow-icon::before {
  transition-delay: 0s;
}

.u-arrow-custom-v5:hover.js-prev .u-arrow-icon::before {
  transform: translateX(-100%);
}

.u-arrow-custom-v5:hover.js-next .u-arrow-icon::before {
  transform: translateX(100%);
}

.u-arrow-custom-v6 {
  overflow: hidden;
  width: 40px;
  transition: width .4s, background-color .4s, z-index .4s;
  transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
}

.u-arrow-custom-v6 .u-arrow-text {
  width: 400px;
}

.u-arrow-custom-v6:hover {
  width: 400px;
  z-index: 11;
}

.u-arrow-custom-v7 {
  perspective: 1000px;
}

.u-arrow-custom-v7 .u-arrow-icon::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.u-arrow-custom-v7 .u-arrow-img {
  backface-visibility: hidden;
  transition: transform 0.3s;
}

.u-arrow-custom-v7.js-prev {
  perspective-origin: 100% 50%;
}

.u-arrow-custom-v7.js-prev .u-arrow-img {
  transform-origin: 0 50%;
  transform: rotateY(90deg);
}

.u-arrow-custom-v7.js-next {
  perspective-origin: 0 50%;
}

.u-arrow-custom-v7.js-next .u-arrow-img {
  transform-origin: 100% 50%;
  transform: rotateY(-90deg);
}

.u-arrow-custom-v7:hover .u-arrow-icon {
  color: #2cb8f3;
}

.u-arrow-custom-v7:hover .u-arrow-img {
  transform: rotateY(0deg);
}

.u-arrow-custom-v8 {
  perspective: 1000px;
}

.u-arrow-custom-v8 .u-arrow-icon::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.u-arrow-custom-v8 .u-arrow-title,
.u-arrow-custom-v8 .u-arrow-img {
  backface-visibility: hidden;
}

.u-arrow-custom-v8 .u-arrow-title {
  transition: transform 0.3s;
}

.u-arrow-custom-v8 .u-arrow-text {
  transition: transform 0.3s 0.3s;
  transform-style: preserve-3d;
}

.u-arrow-custom-v8.js-prev {
  perspective-origin: 100% 50%;
}

.u-arrow-custom-v8.js-prev .u-arrow-text,
.u-arrow-custom-v8.js-prev .u-arrow-title {
  transform-origin: 0 50%;
  transform: rotateY(90deg);
}

.u-arrow-custom-v8.js-next {
  perspective-origin: 0 50%;
}

.u-arrow-custom-v8.js-next .u-arrow-text,
.u-arrow-custom-v8.js-next .u-arrow-title {
  transform-origin: 100% 50%;
  transform: rotateY(-90deg);
}

.u-arrow-custom-v8:hover .u-arrow-icon {
  color: #2cb8f3;
  transition-delay: 0s;
}

.u-arrow-custom-v8:hover .u-arrow-icon::before {
  transition-delay: 0s;
}

.u-arrow-custom-v8:hover .u-arrow-text,
.u-arrow-custom-v8:hover .u-arrow-title {
  transform: rotateY(0deg);
}

.u-arrow-custom-v8:hover .u-arrow-text {
  transition-delay: 0s;
}

.u-arrow-custom-v8:hover .u-arrow-title {
  transition-delay: .3s;
}

.u-arrow-custom-v9 {
  width: 60px;
}

.u-arrow-custom-v9 .u-arrow-icon::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 20px;
  height: 1px;
  background: #fff;
  transition: width .3s .2s;
  backface-visibility: hidden;
}

.u-arrow-custom-v9 .u-arrow-text::after {
  content: attr(data-title);
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  color: #fff;
  text-transform: uppercase;
  opacity: 0;
  transition: transform 0.3s, opacity 0.3s;
  margin: 20px 5px 0;
}

.u-arrow-custom-v9 .u-arrow-img {
  opacity: 0;
  box-shadow: 0 1px 0 #fff, 0 -1px 0 #fff;
  transition: transform .3s, opacity .3s;
  transform: scale(0.3);
}

.u-arrow-custom-v9.js-prev .u-arrow-icon::after {
  left: calc(100% - 25px);
}

.u-arrow-custom-v9.js-next .u-arrow-icon::after {
  right: calc(100% - 25px);
}

.u-arrow-custom-v9.js-next .u-arrow-text::after {
  text-align: right;
}

.u-arrow-custom-v9:hover {
  width: 200px;
}

.u-arrow-custom-v9:hover .u-arrow-icon::after {
  width: 200px;
  transition-delay: 0s;
}

.u-arrow-custom-v9:hover .u-arrow-text {
  pointer-events: auto;
}

.u-arrow-custom-v9:hover .u-arrow-text::after {
  opacity: 1;
  transition-delay: .2s;
  transform: translateY(0);
}

.u-arrow-custom-v9:hover .u-arrow-img {
  opacity: 1;
  transform: scale(1);
}

.u-arrow-custom-v9:hover .u-arrow-img:first-child {
  transition-delay: 0.3s;
}

.u-arrow-custom-v9:hover .u-arrow-img:nth-child(2) {
  transition-delay: 0.35s;
}

.u-arrow-custom-v9:hover .u-arrow-img:nth-child(3) {
  transition-delay: 0.4s;
}

.u-arrow-custom-v10 .u-arrow-text {
  opacity: 0;
  overflow: hidden;
  transform: scale(0.7);
  transition: transform .3s, opacity .3s, background-color .1s 0s;
}

.u-arrow-custom-v10 .u-arrow-img {
  transition: transform .3s;
}

.u-arrow-custom-v10.js-prev .u-arrow-img {
  transform: translateX(-100%);
}

.u-arrow-custom-v10.js-next .u-arrow-img {
  transform: translateX(100%);
}

.u-arrow-custom-v10:hover .u-arrow-text {
  opacity: 1;
  background-color: transparent;
  transform: scale(1);
  transition: transform .3s, opacity .3s, background-color .1s .2s;
}

.u-arrow-custom-v10:hover .u-arrow-img {
  transform: translateX(0);
}

.u-arrow-custom-v11 .u-arrow-icon {
  border-width: 0;
  transition: border-width .3s .15s;
}

.u-arrow-custom-v11 .u-arrow-text {
  opacity: 0;
  transition: transform .3s, opacity .3s;
}

.u-arrow-custom-v11.js-prev .u-arrow-text {
  transform: translateY(-50%) translateX(-100%) scale(0.75);
}

.u-arrow-custom-v11.js-next .u-arrow-text {
  transform: translateY(-50%) translateX(100%) scale(0.75);
}

.u-arrow-custom-v11:hover .u-arrow-icon {
  border-top-width: 40px;
  border-bottom-width: 40px;
  transition-delay: 0s;
}

.u-arrow-custom-v11:hover .u-arrow-text {
  opacity: 1;
  transition-delay: .3s;
  transform: translateY(-50%) translateX(0) scale(1);
}

.u-arrow-custom-v12 .u-arrow-icon {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.u-arrow-custom-v12 .u-arrow-icon::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.u-arrow-custom-v12 .u-arrow-text {
  overflow: hidden;
  transition: transform .3s;
  backface-visibility: hidden;
  transform: scale(0.6);
}

.u-arrow-custom-v12 .u-arrow-img {
  opacity: 0;
  backface-visibility: hidden;
  transition: opacity .3s, transform .3s;
}

.u-arrow-custom-v12:hover .u-arrow-img {
  opacity: .8;
}

.u-arrow-custom-v12.js-prev .u-arrow-img {
  transform: translateX(-50%) translateY(-50%) rotate(45deg) scale(2);
}

.u-arrow-custom-v12.js-prev:hover .u-arrow-text {
  transform: scale(1) rotate(-45deg);
}

.u-arrow-custom-v12.js-prev:hover .u-arrow-img {
  transform: translateX(-50%) translateY(-50%) rotate(45deg) scale(1);
}

.u-arrow-custom-v12.js-next .u-arrow-img {
  transform: translateX(-50%) translateY(-50%) rotate(-45deg) scale(2);
}

.u-arrow-custom-v12.js-next:hover .u-arrow-text {
  transform: scale(1) rotate(45deg);
}

.u-arrow-custom-v12.js-next:hover .u-arrow-img {
  transform: translateX(-50%) translateY(-50%) rotate(-45deg) scale(1);
}

.u-arrow-custom-v13 .u-arrow-icon {
  overflow: hidden;
  backface-visibility: hidden;
}

.u-arrow-custom-v13 .u-arrow-icon::before {
  position: relative;
  z-index: 2;
}

.u-arrow-custom-v13 .u-arrow-icon::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 110%;
  background: #fff;
  backface-visibility: hidden;
  transition: transform .3s .3s;
}

.u-arrow-custom-v13 .u-arrow-text {
  transition: transform .3s;
}

.u-arrow-custom-v13.js-prev .u-arrow-icon::after {
  transform: translateX(-100%);
}

.u-arrow-custom-v13.js-prev .u-arrow-text {
  transform: translateX(-100%);
}

.u-arrow-custom-v13.js-next .u-arrow-icon::after {
  transform: translateX(100%);
}

.u-arrow-custom-v13.js-next .u-arrow-text {
  transform: translateX(100%);
}

.u-arrow-custom-v13:hover .u-arrow-icon::after {
  transform: translateX(0);
  transition-delay: 0s;
}

.u-arrow-custom-v13:hover .u-arrow-text {
  transition-delay: .3s;
  transform: translateX(0);
}

.u-arrow-custom-v14::before, .u-arrow-custom-v14::after,
.u-arrow-custom-v14 .u-arrow-icon::before,
.u-arrow-custom-v14 .u-arrow-icon::after {
  content: "";
  position: absolute;
  left: 50%;
  width: 3px;
  height: 50%;
  background: #2cb8f3;
  transition: transform .3s;
  backface-visibility: hidden;
}

.u-arrow-custom-v14::before,
.u-arrow-custom-v14 .u-arrow-icon::before {
  top: 50%;
  transform: translateX(-50%) rotate(-135deg);
  transform-origin: 50% 0;
}

.u-arrow-custom-v14::after,
.u-arrow-custom-v14 .u-arrow-icon::after {
  top: 50%;
  transform: translateX(-50%) rotate(-45deg);
  transform-origin: 0 0;
}

.u-arrow-custom-v14 .u-arrow-icon::before, .u-arrow-custom-v14 .u-arrow-icon::after {
  z-index: 2;
  height: 0;
  background: #fff;
  transition: height .3s, transform .3s;
}

.u-arrow-custom-v14 .u-arrow-title {
  position: absolute;
  top: 50%;
  opacity: 0;
  transition: transform .3s, opacity .3s;
  margin: 0;
}

.u-arrow-custom-v14.js-prev .u-arrow-title {
  left: 100%;
  transform: translateY(-50%) translateX(-50%);
}

.u-arrow-custom-v14.js-next::before,
.u-arrow-custom-v14.js-next .u-arrow-icon::before {
  transform: translateX(-50%) rotate(135deg);
  transform-origin: 50% 0;
}

.u-arrow-custom-v14.js-next::after,
.u-arrow-custom-v14.js-next .u-arrow-icon::after {
  transform: translateX(-50%) rotate(45deg);
  transform-origin: 100% 0;
}

.u-arrow-custom-v14.js-next .u-arrow-title {
  right: 100%;
  text-align: right;
  transform: translateY(-50%) translateX(50%);
}

.u-arrow-custom-v14:hover::before,
.u-arrow-custom-v14:hover .u-arrow-icon::before {
  transform: translateX(-50%) rotate(-125deg);
}

.u-arrow-custom-v14:hover::after,
.u-arrow-custom-v14:hover .u-arrow-icon::after {
  transform: translateX(-50%) rotate(-55deg);
}

.u-arrow-custom-v14:hover .u-arrow-icon::before, .u-arrow-custom-v14:hover .u-arrow-icon::after {
  height: 50%;
}

.u-arrow-custom-v14:hover .u-arrow-title {
  opacity: 1;
  transform: translateY(-50%) translateX(0);
}

.u-arrow-custom-v14:hover.js-next::before,
.u-arrow-custom-v14:hover.js-next .u-arrow-icon::before {
  transform: translateX(-50%) rotate(125deg);
}

.u-arrow-custom-v14:hover.js-next::after,
.u-arrow-custom-v14:hover.js-next .u-arrow-icon::after {
  transform: translateX(-50%) rotate(55deg);
}

/*------------------------------------
  Badges
------------------------------------*/
[class*="u-badge"] {
  position: absolute;
  display: inline-block;
  text-align: center;
  font-size: 0.92857rem;
  color: #555;
  z-index: 3;
}

[class*="u-badge"]:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
}

.u-badge--top-left {
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
}

.u-badge--bottom-left {
  bottom: 0;
  left: 0;
  transform: translate(-50%, 50%);
}

.u-badge--bottom-right {
  bottom: 0;
  right: 0;
  transform: translate(50%, 50%);
}

/*------------------------------------
  Badges v1
------------------------------------*/
[class*="u-badge-v1"] {
  background-color: #ddd;
  box-sizing: content-box;
}

.u-badge-v1 {
  min-width: 1.28571rem;
  min-height: 1.28571rem;
  line-height: 1.28571rem;
  padding: 0.21429rem;
}

.u-badge-v1.g-brd-around {
  line-height: 1.14286rem;
}

.u-badge-v1--xs {
  min-width: 1rem;
  min-height: 1rem;
  line-height: 1rem;
  font-size: 0.71429rem;
  padding: 0.14286rem;
}

.u-badge-v1--xs.g-brd-around {
  line-height: 0.85714rem;
}

.u-badge-v1--sm {
  min-width: 1.14286rem;
  min-height: 1.14286rem;
  line-height: 1.14286rem;
  font-size: 0.78571rem;
  padding: 0.14286rem;
}

.u-badge-v1--sm.g-brd-around {
  line-height: 1rem;
}

.u-badge-v1--md {
  min-width: 1.28571rem;
  min-height: 1.28571rem;
  line-height: 1.28571rem;
  font-size: 0.92857rem;
  padding: 0.21429rem;
}

.u-badge-v1--md.g-brd-around {
  line-height: 1.14286rem;
}

.u-badge-v1--lg {
  min-width: 1.42857rem;
  min-height: 1.42857rem;
  line-height: 1.42857rem;
  font-size: 1.07143rem;
  padding: 0.21429rem;
}

.u-badge-v1--lg.g-brd-around {
  line-height: 1.28571rem;
}

.u-badge-v1--xl {
  min-width: 1.85714rem;
  min-height: 1.85714rem;
  line-height: 1.85714rem;
  font-size: 1.21429rem;
  padding: 0.21429rem;
}

.u-badge-v1--xl.g-brd-around {
  line-height: 1.71429rem;
}

/*------------------------------------
  Badges v2
------------------------------------*/
[class*="u-badge-v2"] {
  background-color: #2cb8f3;
  border-radius: 50%;
}

.u-badge-v2 {
  width: 14px;
  height: 14px;
}

.u-badge-v2--xs {
  width: 8px;
  height: 8px;
}

.u-badge-v2--sm {
  width: 12px;
  height: 12px;
}

.u-badge-v2--md {
  width: 14px;
  height: 14px;
}

.u-badge-v2--lg {
  width: 16px;
  height: 16px;
}

.u-badge-v2--xl {
  width: 18px;
  height: 18px;
}

/*------------------------------------
  Badges v3
------------------------------------*/
[class*="u-badge-v3"] {
  line-height: 1;
  color: #fff;
  text-shadow: 0 1px 1px #555, 0 -1px 1px #555, 1px 0 1px #555, -1px 0 1px #555;
}

.u-badge-v3 {
  font-size: 1rem;
}

.u-badge-v3--xs {
  font-size: 0.71429rem;
}

.u-badge-v3--sm {
  font-size: 0.85714rem;
}

.u-badge-v3--md {
  font-size: 1rem;
}

.u-badge-v3--lg {
  font-size: 1.14286rem;
}

.u-badge-v3--xl {
  font-size: 1.14286rem;
}

hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

/* Solid Divider */
.u-divider-solid {
  border-top-style: solid;
}

/* Dotted Divider */
.u-divider-dotted {
  border-top-style: dotted;
}

/* Dashed Divider */
.u-divider-dashed {
  border-top-style: dashed;
}

/* Double Solid Divider */
.u-divider-db-solid {
  height: 5px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}

/* Double Dashed Divider */
.u-divider-db-dashed {
  height: 5px;
  border-top: 1px dashed transparent;
  border-bottom: 1px dashed transparent;
}

/* Double Dotted Divider */
.u-divider-db-dotted {
  height: 5px;
  border-top: 1px dotted transparent;
  border-bottom: 1px dotted transparent;
}

/* Linear Gradient Divider */
.u-divider-linear-gradient {
  height: 1px;
  border: none;
}

.u-divider-linear-gradient--gray-light-v2 {
  background-image: linear-gradient(to right, transparent, #ccc, transparent);
}

.u-divider-linear-gradient--gray-light-v3 {
  background-image: linear-gradient(to right, transparent, #ddd, transparent);
}

.u-divider {
  position: relative;
  border-top-width: 1px;
}

.u-divider__icon {
  position: absolute;
  top: -1.42857rem;
  width: 2.85714rem;
  height: 2.85714rem;
  font-size: 1.28571rem;
  line-height: 2.85714rem;
  text-align: center;
  font-style: normal;
}

.u-divider__icon--indented {
  box-shadow: 0 0 0 15px #fff;
}

.u-divider-center {
  text-align: center;
}

.u-divider-right {
  text-align: right;
}

.u-divider-center .u-divider__icon {
  left: auto;
  right: auto;
  margin-left: -1.42857rem;
}

.u-divider-right .u-divider__icon {
  left: auto;
  right: 0;
}

/*------------------------------------
  Go To v1
------------------------------------*/
[class*="u-go-to"] {
  display: none;
}

.u-go-to-v1 {
  width: 3.57143rem;
  height: 3.57143rem;
  display: block;
  background-color: rgba(255, 255, 255, 0.7);
  color: #555;
  border-radius: 50%;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  transition: .3s ease-out;
  z-index: 11;
}

.u-go-to-v1 i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.u-go-to-v1:hover, .u-go-to-v1:focus:hover {
  text-decoration: none;
  color: #fff;
  background-color: #2cb8f3;
}

.u-go-to-v1:focus {
  text-decoration: none;
  color: #555;
  background-color: rgba(255, 255, 255, 0.7);
}

@media (max-width: 576px - 1) {
  .u-go-to-v1 {
    transform: scale(0.8, 0.8);
  }
}

/*------------------------------------
  Go To v2
------------------------------------*/
[class*="u-go-to"] {
  display: none;
}

.u-go-to-v2 {
  display: block;
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  border-radius: 3px;
  transition: .3s ease-out;
  z-index: 11;
}

.u-go-to-v2 i {
  width: 2.85714rem;
  height: 2.85714rem;
  display: block;
  text-align: center;
  line-height: 2.85714rem;
}

.u-go-to-v2:hover, .u-go-to-v2:focus:hover {
  text-decoration: none;
  color: #fff;
  background-color: #2cb8f3;
}

.u-go-to-v2:focus {
  text-decoration: none;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.3);
}

@media (max-width: 576px - 1) {
  .u-go-to-v2 {
    transform: scale(0.8, 0.8);
  }
}

/*------------------------------------
  Go To v3
------------------------------------*/
[class*="u-go-to"] {
  display: none;
}

.u-go-to-v3 {
  padding: 5px;
  display: block;
  background-color: rgba(255, 255, 255, 0.7);
  color: #555;
  border-radius: 50%;
  transition: .3s ease-out;
  z-index: 11;
}

.u-go-to-v3 i {
  width: 3.57143rem;
  height: 3.57143rem;
  display: block;
  text-align: center;
  border-radius: 50%;
  font-size: 1.14286rem;
  line-height: 3.28571rem;
  border: solid 1px #555;
  transition: .3s ease-out;
}

.u-go-to-v3:hover, .u-go-to-v3:focus:hover {
  text-decoration: none;
  color: #2cb8f3;
  background-color: white;
}

.u-go-to-v3:hover i, .u-go-to-v3:focus:hover i {
  border-color: #2cb8f3;
}

.u-go-to-v3:focus {
  text-decoration: none;
  color: #555;
  background-color: rgba(255, 255, 255, 0.7);
}

.u-go-to-v3:focus i {
  border-color: #555;
}

@media (max-width: 576px - 1) {
  .u-go-to-v3 {
    transform: scale(0.8, 0.8);
  }
}

/*------------------------------------
  Go To v4
------------------------------------*/
.u-go-to-v4 {
  position: relative;
  width: 22px;
  height: 35px;
  border: 2px solid #2cb8f3;
  border-radius: 15px;
}

.u-go-to-v4::before {
  width: 7px;
  height: 7px;
  background-color: #fff;
  border-radius: 50%;
  content: " ";
  position: absolute !important;
  left: 50% !important;
  transform: translateX(-50%);
  -webkit-backface-visibility: hidden;
  animation-duration: 2s;
  animation-name: u-go-to-v4;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes u-go-to-v4 {
  0% {
    top: 20%;
    bottom: 0;
  }
  50% {
    top: 50%;
    bottom: 0;
  }
  100% {
    top: 20%;
    bottom: 100%;
  }
}

/*------------------------------------
  Headers
------------------------------------*/
.u-header {
  position: relative;
  left: 0;
  right: 0;
  width: 100%;
  font-size: 0.92857rem;
  z-index: 501;
}

.u-header [aria-labelledby] {
  opacity: 0;
}

.u-header [aria-labelledby][role="tabpanel"] {
  opacity: 1;
}

.u-header [aria-labelledby].u-dropdown--css-animation,
.u-header [aria-labelledby].u-dropdown--jquery-slide {
  opacity: 1;
}

.u-header--abs-top, .u-header--abs-bottom, .u-header--abs-top-2nd-screen {
  position: absolute;
}

.u-header--abs-top-2nd-screen {
  top: 100%;
  bottom: auto;
}

.u-header--abs-top {
  top: 0;
  bottom: auto;
}

.u-header--abs-bottom {
  top: auto;
  bottom: 0;
}

.u-header--abs-bottom .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: .125rem;
}

.u-header--sticky-top, .u-header--sticky-bottom {
  position: fixed;
}

.u-header--sticky-top {
  bottom: auto;
  top: 0;
}

.u-header--sticky-top.u-negative-top {
  bottom: 100%;
  top: auto;
}

.u-header--sticky-bottom {
  top: auto;
  bottom: 0;
}

.u-header--sticky-bottom .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: .125rem;
}

.u-header--invisible {
  display: none;
}

.u-header--moved-up {
  transform: translate3d(0, -100%, 0);
}

.u-header--moved-up.u-negative-top {
  transform: translate3d(0, 100%, 0);
}

.u-header--faded {
  opacity: 0;
  visibility: hidden;
}

.u-header--untransitioned {
  transition: none !important;
}

.u-header__section--hidden {
  position: relative;
}

.u-header--floating {
  position: absolute;
  left: 0;
  right: 0;
}

.u-header--floating.js-header-fix-moment {
  margin-top: 0 !important;
}

.u-header--floating.js-header-fix-moment .navbar {
  padding-left: 0;
  padding-right: 0;
}

.u-header[data-header-fix-effect] {
  transition: .3s ease;
}

.u-header-reduced--shift.js-header-change-moment {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.u-header.js-header-fix-moment {
  position: fixed;
  top: 0;
  bottom: auto;
}

.u-header__section {
  position: relative;
  z-index: 1;
}

.u-header__section:nth-child(1) {
  z-index: 5;
}

.u-header__section:nth-child(2) {
  z-index: 4;
}

.u-header__section:nth-child(3) {
  z-index: 3;
}

.u-header__section:nth-child(4) {
  z-index: 2;
}

.u-header__section:nth-child(5) {
  z-index: 1;
}

.u-header__sections-container {
  position: relative;
  z-index: 2;
}

.u-header__logo {
  position: relative;
  z-index: 1;
}

.u-header__logo-img {
  top: 0;
  left: 0;
  /*transition: .3s ease;*/
}

.u-header__logo-img:not(.u-header__logo-img--main) {
  position: absolute;
  opacity: 0;
}

.dropdown-menu.u-dropdown--reverse-position {
  left: auto;
  right: 0;
}

.js-header-change-moment.g-bg-white--shift {
  background-color: #fff;
}

.js-header-change-moment.g-bg-light-semi-transparent--shift {
  background-color: rgba(255, 255, 255, 0.9);
}

.js-header-change-moment.g-bg-black--shift {
  background-color: #000;
}

.js-header-change-moment.g-bg-dark-semi-transparent--shift {
  background-color: rgba(0, 0, 0, 0.9);
}

.js-header-change-moment.g-bg-primary--shift {
  background-color: #2cb8f3;
}

.js-header-change-moment.g-bg-primary-semi-transparent--shift {
  background-color: rgba(44, 184, 243, 0.9);
}

.u-header__section--light .navbar-brand,
.u-header__section--light .navbar-toggler,
.u-header__section--light--shift.js-header-change-moment .navbar-brand,
.u-header__section--light--shift.js-header-change-moment .navbar-toggler {
  color: #555555;
}

.u-header__section--light .navbar-brand:focus,
.u-header__section--light .navbar-brand:hover,
.u-header__section--light .navbar-toggler:focus,
.u-header__section--light .navbar-toggler:hover,
.u-header__section--light--shift.js-header-change-moment .navbar-brand:focus,
.u-header__section--light--shift.js-header-change-moment .navbar-brand:hover,
.u-header__section--light--shift.js-header-change-moment .navbar-toggler:focus,
.u-header__section--light--shift.js-header-change-moment .navbar-toggler:hover {
  color: #555555;
}

.u-header__section--light .navbar-nav:not([class*="u-main-nav-v"]) .nav-link,
.u-header__section--light--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .nav-link {
  color: #555555;
}

.u-header__section--light .navbar-nav:not([class*="u-main-nav-v"]) .nav-link:focus,
.u-header__section--light .navbar-nav:not([class*="u-main-nav-v"]) .nav-link:hover,
.u-header__section--light--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .nav-link:focus,
.u-header__section--light--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .nav-link:hover {
  color: #555555;
}

.u-header__section--light .navbar-nav:not([class*="u-main-nav-v"]) .nav-link.disabled,
.u-header__section--light--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .nav-link.disabled {
  color: rgba(85, 85, 85, 0.5);
}

.u-header__section--light .navbar-nav:not([class*="u-main-nav-v"]) .open > .nav-link,
.u-header__section--light .navbar-nav:not([class*="u-main-nav-v"]) .active > .nav-link,
.u-header__section--light .navbar-nav:not([class*="u-main-nav-v"]) .nav-link.open,
.u-header__section--light .navbar-nav:not([class*="u-main-nav-v"]) .nav-link.active,
.u-header__section--light--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .open > .nav-link,
.u-header__section--light--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .active > .nav-link,
.u-header__section--light--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .nav-link.open,
.u-header__section--light--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .nav-link.active {
  color: #2cb8f3;
}

.u-header__section--light .navbar-toggler,
.u-header__section--light--shift.js-header-change-moment .navbar-toggler {
  border-color: rgba(85, 85, 85, 0.3);
}

.u-header__section--light .navbar-toggler-icon,
.u-header__section--light--shift.js-header-change-moment .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba($g-color-main, .5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.u-header__section--light .navbar-text,
.u-header__section--light--shift.js-header-change-moment .navbar-text {
  color: rgba(85, 85, 85, 0.7);
}

.u-header__section--dark .navbar-brand,
.u-header__section--dark .navbar-toggler,
.u-header__section--dark--shift.js-header-change-moment .navbar-brand,
.u-header__section--dark--shift.js-header-change-moment .navbar-toggler,
.u-header__section--primary .navbar-brand,
.u-header__section--primary .navbar-toggler,
.u-header__section--primary--shift.js-header-change-moment .navbar-brand,
.u-header__section--primary--shift.js-header-change-moment .navbar-toggler {
  color: #fff;
}

.u-header__section--dark .navbar-brand:focus,
.u-header__section--dark .navbar-brand:hover,
.u-header__section--dark .navbar-toggler:focus,
.u-header__section--dark .navbar-toggler:hover,
.u-header__section--dark--shift.js-header-change-moment .navbar-brand:focus,
.u-header__section--dark--shift.js-header-change-moment .navbar-brand:hover,
.u-header__section--dark--shift.js-header-change-moment .navbar-toggler:focus,
.u-header__section--dark--shift.js-header-change-moment .navbar-toggler:hover,
.u-header__section--primary .navbar-brand:focus,
.u-header__section--primary .navbar-brand:hover,
.u-header__section--primary .navbar-toggler:focus,
.u-header__section--primary .navbar-toggler:hover,
.u-header__section--primary--shift.js-header-change-moment .navbar-brand:focus,
.u-header__section--primary--shift.js-header-change-moment .navbar-brand:hover,
.u-header__section--primary--shift.js-header-change-moment .navbar-toggler:focus,
.u-header__section--primary--shift.js-header-change-moment .navbar-toggler:hover {
  color: #fff;
}

.u-header__section--dark .navbar-nav:not([class*="u-main-nav-v"]) .nav-link,
.u-header__section--dark--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .nav-link,
.u-header__section--primary .navbar-nav:not([class*="u-main-nav-v"]) .nav-link,
.u-header__section--primary--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .nav-link {
  color: rgba(255, 255, 255, 0.8);
}

.u-header__section--dark .navbar-nav:not([class*="u-main-nav-v"]) .nav-link:focus,
.u-header__section--dark .navbar-nav:not([class*="u-main-nav-v"]) .nav-link:hover,
.u-header__section--dark--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .nav-link:focus,
.u-header__section--dark--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .nav-link:hover,
.u-header__section--primary .navbar-nav:not([class*="u-main-nav-v"]) .nav-link:focus,
.u-header__section--primary .navbar-nav:not([class*="u-main-nav-v"]) .nav-link:hover,
.u-header__section--primary--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .nav-link:focus,
.u-header__section--primary--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .nav-link:hover {
  color: #fff;
}

.u-header__section--dark .navbar-nav:not([class*="u-main-nav-v"]) .nav-link.disabled,
.u-header__section--dark--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .nav-link.disabled,
.u-header__section--primary .navbar-nav:not([class*="u-main-nav-v"]) .nav-link.disabled,
.u-header__section--primary--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .nav-link.disabled {
  color: rgba(255, 255, 255, 0.5);
}

.u-header__section--dark .navbar-nav:not([class*="u-main-nav-v"]) .open > .nav-link,
.u-header__section--dark .navbar-nav:not([class*="u-main-nav-v"]) .active > .nav-link,
.u-header__section--dark .navbar-nav:not([class*="u-main-nav-v"]) .nav-link.open,
.u-header__section--dark .navbar-nav:not([class*="u-main-nav-v"]) .nav-link.active,
.u-header__section--dark--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .open > .nav-link,
.u-header__section--dark--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .active > .nav-link,
.u-header__section--dark--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .nav-link.open,
.u-header__section--dark--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .nav-link.active,
.u-header__section--primary .navbar-nav:not([class*="u-main-nav-v"]) .open > .nav-link,
.u-header__section--primary .navbar-nav:not([class*="u-main-nav-v"]) .active > .nav-link,
.u-header__section--primary .navbar-nav:not([class*="u-main-nav-v"]) .nav-link.open,
.u-header__section--primary .navbar-nav:not([class*="u-main-nav-v"]) .nav-link.active,
.u-header__section--primary--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .open > .nav-link,
.u-header__section--primary--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .active > .nav-link,
.u-header__section--primary--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .nav-link.open,
.u-header__section--primary--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .nav-link.active {
  color: #fff;
}

.u-header__section--dark .navbar-toggler,
.u-header__section--dark--shift.js-header-change-moment .navbar-toggler,
.u-header__section--primary .navbar-toggler,
.u-header__section--primary--shift.js-header-change-moment .navbar-toggler {
  border-color: rgba(255, 255, 255, 0.3);
}

.u-header__section--dark .navbar-toggler-icon,
.u-header__section--dark--shift.js-header-change-moment .navbar-toggler-icon,
.u-header__section--primary .navbar-toggler-icon,
.u-header__section--primary--shift.js-header-change-moment .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba($g-color-white, .5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.u-header__section--dark .navbar-text,
.u-header__section--dark--shift.js-header-change-moment .navbar-text,
.u-header__section--primary .navbar-text,
.u-header__section--primary--shift.js-header-change-moment .navbar-text {
  color: rgba(255, 255, 255, 0.7);
}

.navbar > .container {
  position: relative;
}

@media all and (min-width: 576px) {
  .u-header--abs-top--sm, .u-header--abs-bottom--sm, .u-header--abs-top-2nd-screen--sm {
    position: absolute;
  }
  .u-header--abs-top-2nd-screen--sm {
    top: 100%;
  }
  .u-header--abs-top--sm {
    bottom: auto;
    top: 0;
  }
  .u-header--abs-bottom--sm {
    top: auto;
    bottom: 0;
  }
  .u-header--sticky-top--sm, .u-header--sticky-bottom--sm {
    position: fixed;
  }
  .u-header--sticky-top--sm {
    bottom: auto;
    top: 0;
  }
  .u-header--sticky-bottom--sm {
    top: auto;
    bottom: 0;
  }
  .u-header--floating--sm {
    position: absolute;
    left: 0;
    right: 0;
  }
  .u-header--floating--sm.js-header-fix-moment {
    margin-top: 0 !important;
  }
  .u-header--floating--sm.js-header-fix-moment .navbar {
    padding-left: 0;
    padding-right: 0;
  }
}

@media all and (min-width: 768px) {
  .u-header--abs-top--md, .u-header--abs-bottom--md, .u-header--abs-top-2nd-screen--md {
    position: absolute;
  }
  .u-header--abs-top-2nd-screen--md {
    top: 100%;
  }
  .u-header--abs-top--md {
    bottom: auto;
    top: 0;
  }
  .u-header--abs-bottom--md {
    top: auto;
    bottom: 0;
  }
  .u-header--sticky-top--md, .u-header--sticky-bottom--md {
    position: fixed;
  }
  .u-header--sticky-top--md {
    bottom: auto;
    top: 0;
  }
  .u-header--sticky-bottom--md {
    top: auto;
    bottom: 0;
  }
  .u-header--floating--md {
    position: absolute;
    left: 0;
    right: 0;
  }
  .u-header--floating--md.js-header-fix-moment {
    margin-top: 0 !important;
  }
  .u-header--floating--md.js-header-fix-moment .navbar {
    padding-left: 0;
    padding-right: 0;
  }
}

@media all and (min-width: 992px) {
  .u-header--abs-top--lg, .u-header--abs-bottom--lg, .u-header--abs-top-2nd-screen--lg {
    position: absolute;
  }
  .u-header--abs-top-2nd-screen--lg {
    top: 100%;
  }
  .u-header--abs-top--lg {
    bottom: auto;
    top: 0;
  }
  .u-header--abs-bottom--lg {
    top: auto;
    bottom: 0;
  }
  .u-header--sticky-top--lg, .u-header--sticky-bottom--lg {
    position: fixed;
  }
  .u-header--sticky-top--lg {
    bottom: auto;
    top: 0;
  }
  .u-header--sticky-bottom--lg {
    top: auto;
    bottom: 0;
  }
  .u-header--floating--lg {
    position: absolute;
    left: 0;
    right: 0;
  }
  .u-header--floating--lg.js-header-fix-moment {
    margin-top: 0 !important;
  }
  .u-header--floating--lg.js-header-fix-moment .navbar {
    padding-left: 0;
    padding-right: 0;
  }
}

@media all and (min-width: 1200px) {
  .u-header--abs-top--xl, .u-header--abs-bottom--xl, .u-header--abs-top-2nd-screen--xl {
    position: absolute;
  }
  .u-header--abs-top-2nd-screen--xl {
    top: 100%;
  }
  .u-header--abs-top--xl {
    bottom: auto;
    top: 0;
  }
  .u-header--abs-bottom--xl {
    top: auto;
    bottom: 0;
  }
  .u-header--sticky-top--xl, .u-header--sticky-bottom--xl {
    position: fixed;
  }
  .u-header--sticky-top--xl {
    bottom: auto;
    top: 0;
  }
  .u-header--sticky-bottom--xl {
    top: auto;
    bottom: 0;
  }
  .u-header--floating--xl {
    position: absolute;
    left: 0;
    right: 0;
  }
  .u-header--floating--xl.js-header-fix-moment {
    margin-top: 0 !important;
  }
  .u-header--floating--xl.js-header-fix-moment .navbar {
    padding-left: 0;
    padding-right: 0;
  }
}

@media all and (max-width: 1200px - 1) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-collapse {
    width: 100%;
    order: 10;
  }
}

@media all and (max-width: 992px - 1) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-collapse {
    width: 100%;
    order: 10;
  }
}

@media all and (max-width: 768px - 1) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-collapse {
    width: 100%;
    order: 10;
  }
}

@media all and (max-width: 576px - 1) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-collapse {
    width: 100%;
    order: 10;
  }
}

/*------------------------------------
	Header Togglers
------------------------------------*/
.u-header-toggler {
  display: block;
  width: 5rem;
  height: 3.57143rem;
  z-index: 502;
  /* Hamburgers */
}

.u-header-toggler .hamburger {
  line-height: 1;
}

/* Toggler Positioning */
@media all and (min-width: 0) {
  .u-header-toggler--top-right, .u-header-toggler--top-right.btn, .u-header-toggler--top-left, .u-header-toggler--top-left.btn, .u-header-toggler--bottom-right, .u-header-toggler--bottom-right.btn, .u-header-toggler--bottom-left, .u-header-toggler--bottom-left.btn {
    position: fixed;
  }
  .u-header-toggler--top-right, .u-header-toggler--top-right.btn, .u-header-toggler--top-left, .u-header-toggler--top-left.btn {
    top: 1.42857rem;
  }
  .u-header-toggler--bottom-right, .u-header-toggler--bottom-right.btn, .u-header-toggler--bottom-left, .u-header-toggler--bottom-left.btn {
    bottom: 1.42857rem;
  }
  .u-header-toggler--top-left, .u-header-toggler--bottom-left {
    left: 1.42857rem;
  }
  .u-header-toggler--top-right, .u-header-toggler--bottom-right {
    right: 1.42857rem;
  }
}

/* Toggler Positioning (sm) */
@media all and (min-width: 576px) {
  .u-header-toggler--top-right--sm, .u-header-toggler--top-right--sm.btn, .u-header-toggler--top-left--sm, .u-header-toggler--top-left--sm.btn, .u-header-toggler--bottom-right--sm, .u-header-toggler--bottom-right--sm.btn, .u-header-toggler--bottom-left--sm, .u-header-toggler--bottom-left--sm.btn {
    position: fixed;
  }
  .u-header-toggler--top-right--sm, .u-header-toggler--top-right--sm.btn, .u-header-toggler--top-left--sm, .u-header-toggler--top-left--sm.btn {
    top: 1.42857rem;
  }
  .u-header-toggler--bottom-right--sm, .u-header-toggler--bottom-right--sm.btn, .u-header-toggler--bottom-left--sm, .u-header-toggler--bottom-left--sm.btn {
    bottom: 1.42857rem;
  }
  .u-header-toggler--top-left--sm, .u-header-toggler--bottom-left--sm {
    left: 1.42857rem;
  }
  .u-header-toggler--top-right--sm, .u-header-toggler--bottom-right--sm {
    right: 1.42857rem;
  }
}

/* Toggler Positioning (md) */
@media all and (min-width: 768px) {
  .u-header-toggler--top-right--md, .u-header-toggler--top-right--md.btn, .u-header-toggler--top-left--md, .u-header-toggler--top-left--md.btn, .u-header-toggler--bottom-right--md, .u-header-toggler--bottom-right--md.btn, .u-header-toggler--bottom-left--md, .u-header-toggler--bottom-left--md.btn {
    position: fixed;
  }
  .u-header-toggler--top-right--md, .u-header-toggler--top-right--md.btn, .u-header-toggler--top-left--md, .u-header-toggler--top-left--md.btn {
    top: 1.42857rem;
  }
  .u-header-toggler--bottom-right--md, .u-header-toggler--bottom-right--md.btn, .u-header-toggler--bottom-left--md, .u-header-toggler--bottom-left--md.btn {
    bottom: 1.42857rem;
  }
  .u-header-toggler--top-left--md, .u-header-toggler--bottom-left--md {
    left: 1.42857rem;
  }
  .u-header-toggler--top-right--md, .u-header-toggler--bottom-right--md {
    right: 1.42857rem;
  }
}

/* Toggler Positioning (lg) */
@media all and (min-width: 992px) {
  .u-header-toggler--top-right--lg, .u-header-toggler--top-right--lg.btn, .u-header-toggler--top-left--lg, .u-header-toggler--top-left--lg.btn, .u-header-toggler--bottom-right--lg, .u-header-toggler--bottom-right--lg.btn, .u-header-toggler--bottom-left--lg, .u-header-toggler--bottom-left--lg.btn {
    position: fixed;
  }
  .u-header-toggler--top-right--lg, .u-header-toggler--top-right--lg.btn, .u-header-toggler--top-left--lg, .u-header-toggler--top-left--lg.btn {
    top: 1.42857rem;
  }
  .u-header-toggler--bottom-right--lg, .u-header-toggler--bottom-right--lg.btn, .u-header-toggler--bottom-left--lg, .u-header-toggler--bottom-left--lg.btn {
    bottom: 1.42857rem;
  }
  .u-header-toggler--top-left--lg, .u-header-toggler--bottom-left--lg {
    left: 1.42857rem;
  }
  .u-header-toggler--top-right--lg, .u-header-toggler--bottom-right--lg {
    right: 1.42857rem;
  }
}

/* Toggler Positioning (xl) */
@media all and (min-width: 1200px) {
  .u-header-toggler--top-right--xl, .u-header-toggler--top-right--xl.btn, .u-header-toggler--top-left--xl, .u-header-toggler--top-left--xl.btn, .u-header-toggler--bottom-right--xl, .u-header-toggler--bottom-right--xl.btn, .u-header-toggler--bottom-left--xl, .u-header-toggler--bottom-left--xl.btn {
    position: fixed;
  }
  .u-header-toggler--top-right--xl, .u-header-toggler--top-right--xl.btn, .u-header-toggler--top-left--xl, .u-header-toggler--top-left--xl.btn {
    top: 1.42857rem;
  }
  .u-header-toggler--bottom-right--xl, .u-header-toggler--bottom-right--xl.btn, .u-header-toggler--bottom-left--xl, .u-header-toggler--bottom-left--xl.btn {
    bottom: 1.42857rem;
  }
  .u-header-toggler--top-left--xl, .u-header-toggler--bottom-left--xl {
    left: 1.42857rem;
  }
  .u-header-toggler--top-right--xl, .u-header-toggler--bottom-right--xl {
    right: 1.42857rem;
  }
}

/*------------------------------------
  Fullscreen Header
------------------------------------*/
[class*="u-header--fullscreen"] {
  text-align: center;
  visibility: hidden;
}

[class*="u-header--fullscreen"] .u-header__sections-container {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity .5s ease, visibility .5s ease;
}

[class*="u-header--fullscreen"] .mCustomScrollBox {
  width: 100%;
  height: auto;
}

[class*="u-header--fullscreen"] .u-header__section {
  width: 100%;
}

[class*="u-header--fullscreen"] .navbar-nav[class*="u-main-nav-v"] > li > a {
  display: inline-block;
}

[class*="u-header--fullscreen"] .u-header__overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  opacity: 0;
  visibility: hidden;
  border-radius: 50%;
  transition: transform .5s ease, opacity .5s ease, visibility .5s ease;
}

.u-header.u-header--fullscreen--top-left, .u-header.u-header--fullscreen--top-right, .u-header.u-header--fullscreen--bottom-left, .u-header.u-header--fullscreen--bottom-right {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.u-header.u-header--fullscreen--top-left .u-header__overlay, .u-header.u-header--fullscreen--top-right .u-header__overlay, .u-header.u-header--fullscreen--bottom-left .u-header__overlay, .u-header.u-header--fullscreen--bottom-right .u-header__overlay {
  display: block;
}

.u-header.u-header--fullscreen--top-left.u-header--fullscreen-showed, .u-header.u-header--fullscreen--top-right.u-header--fullscreen-showed, .u-header.u-header--fullscreen--bottom-left.u-header--fullscreen-showed, .u-header.u-header--fullscreen--bottom-right.u-header--fullscreen-showed {
  visibility: visible;
}

.u-header.u-header--fullscreen--top-left.u-header--fullscreen-showed .u-header__overlay,
.u-header.u-header--fullscreen--top-left.u-header--fullscreen-showed .u-header__sections-container, .u-header.u-header--fullscreen--top-right.u-header--fullscreen-showed .u-header__overlay,
.u-header.u-header--fullscreen--top-right.u-header--fullscreen-showed .u-header__sections-container, .u-header.u-header--fullscreen--bottom-left.u-header--fullscreen-showed .u-header__overlay,
.u-header.u-header--fullscreen--bottom-left.u-header--fullscreen-showed .u-header__sections-container, .u-header.u-header--fullscreen--bottom-right.u-header--fullscreen-showed .u-header__overlay,
.u-header.u-header--fullscreen--bottom-right.u-header--fullscreen-showed .u-header__sections-container {
  opacity: 1;
  visibility: visible;
}

.u-header.u-header--fullscreen--top-left.u-header--fullscreen-showed .u-header__sections-container, .u-header.u-header--fullscreen--top-right.u-header--fullscreen-showed .u-header__sections-container, .u-header.u-header--fullscreen--bottom-left.u-header--fullscreen-showed .u-header__sections-container, .u-header.u-header--fullscreen--bottom-right.u-header--fullscreen-showed .u-header__sections-container {
  transition-delay: .5s;
}

.u-header.u-header--fullscreen--top-left .container, .u-header.u-header--fullscreen--top-right .container, .u-header.u-header--fullscreen--bottom-left .container, .u-header.u-header--fullscreen--bottom-right .container {
  width: 100%;
}

.u-header.u-header--fullscreen--top-left .navbar, .u-header.u-header--fullscreen--top-right .navbar, .u-header.u-header--fullscreen--bottom-left .navbar, .u-header.u-header--fullscreen--bottom-right .navbar {
  min-width: 22.85714rem;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.u-header.u-header--fullscreen--top-left .dropdown-menu, .u-header.u-header--fullscreen--top-right .dropdown-menu, .u-header.u-header--fullscreen--bottom-left .dropdown-menu, .u-header.u-header--fullscreen--bottom-right .dropdown-menu {
  text-align: inherit;
  position: static;
  width: 100%;
}

.u-header.u-header--fullscreen--top-left .u-header__overlay {
  transform-origin: 0% 0%;
  transform: scale3d(0.1, 0.1, 0.1) translate3d(-20%, -20%, 0);
}

.u-header.u-header--fullscreen--top-left.u-header--fullscreen-showed .u-header__overlay {
  transform: scale3d(1, 1, 1) translate3d(-20%, -20%, 0);
}

.u-header.u-header--fullscreen--top-right .u-header__overlay {
  left: auto;
  transform-origin: 100% 0%;
  transform: scale3d(0.1, 0.1, 0.1) translate3d(20%, -20%, 0);
}

.u-header.u-header--fullscreen--top-right.u-header--fullscreen-showed .u-header__overlay {
  transform: scale3d(1, 1, 1) translate3d(20%, -20%, 0);
}

.u-header.u-header--fullscreen--bottom-left .u-header__overlay {
  top: auto;
  transform-origin: 0% 100%;
  transform: scale3d(0.1, 0.1, 0.1) translate3d(-20%, 20%, 0);
}

.u-header.u-header--fullscreen--bottom-left.u-header--fullscreen-showed .u-header__overlay {
  transform: scale3d(1, 1, 1) translate3d(-20%, 20%, 0);
}

.u-header.u-header--fullscreen--bottom-right .u-header__overlay {
  top: auto;
  left: auto;
  transform-origin: 100% 100%;
  transform: scale3d(0.1, 0.1, 0.1) translate3d(20%, 20%, 0);
}

.u-header.u-header--fullscreen--bottom-right.u-header--fullscreen-showed .u-header__overlay {
  transform: scale3d(1, 1, 1) translate3d(20%, 20%, 0);
}

/*------------------------------------
  Side Header
------------------------------------*/
/* Common styles of Side Header */
body[class*="u-body--header-side"] .u-header.u-header--side {
  position: fixed;
  top: 0;
  height: 100%;
  width: 21.42857rem;
}

body[class*="u-body--header-side"] .u-header.u-header--side .navbar {
  flex-direction: column;
}

body[class*="u-body--header-side"] .u-header.u-header--side .navbar .container {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  flex-direction: column;
}

body[class*="u-body--header-side"] .u-header.u-header--side .navbar .navbar-collapse {
  flex-direction: column;
}

body[class*="u-body--header-side"] .u-header.u-header--side .navbar .navbar-nav {
  width: 100%;
  flex-direction: column;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

body[class*="u-body--header-side"] .u-header.u-header--side .u-header__sections-container {
  height: 100%;
}

body[class*="u-body--header-side"] .u-header.u-header--side .dropdown-menu {
  top: 0;
}

body[class*="u-body--header-side"] .u-header.u-header--side .mCSB_container,
body[class*="u-body--header-side"] .u-header.u-header--side .mCustomScrollBox {
  overflow: visible;
}

/* Overlay */
.u-header--side .u-header__overlay {
  position: absolute;
  top: 0;
  height: 100%;
  width: 2000%;
  opacity: 0;
  visibility: hidden;
}

body[class*="u-body--header-side"].u-body--header-side-opened .u-header__overlay {
  opacity: 1;
  visibility: visible;
}

.u-body--header-side-static-left .u-header--side .dropdown-menu, .u-body--header-side-overlay-left .u-header--side .dropdown-menu, .u-body--header-side-push-left .u-header--side .dropdown-menu {
  left: 100%;
  margin-left: 1.07143rem;
}

.u-body--header-side-static-left .u-header--side .hs-menu-vertical .hs-mega-menu,
.u-body--header-side-static-left .u-header--side .hs-menu-vertical .hs-sub-menu, .u-body--header-side-overlay-left .u-header--side .hs-menu-vertical .hs-mega-menu,
.u-body--header-side-overlay-left .u-header--side .hs-menu-vertical .hs-sub-menu, .u-body--header-side-push-left .u-header--side .hs-menu-vertical .hs-mega-menu,
.u-body--header-side-push-left .u-header--side .hs-menu-vertical .hs-sub-menu {
  margin-left: 1.07143rem;
}

.u-body--header-side-static-left .u-header--side .hs-menu-vertical .hs-mega-menu .hs-mega-menu,
.u-body--header-side-static-left .u-header--side .hs-menu-vertical .hs-mega-menu .hs-sub-menu,
.u-body--header-side-static-left .u-header--side .hs-menu-vertical .hs-sub-menu .hs-mega-menu,
.u-body--header-side-static-left .u-header--side .hs-menu-vertical .hs-sub-menu .hs-sub-menu, .u-body--header-side-overlay-left .u-header--side .hs-menu-vertical .hs-mega-menu .hs-mega-menu,
.u-body--header-side-overlay-left .u-header--side .hs-menu-vertical .hs-mega-menu .hs-sub-menu,
.u-body--header-side-overlay-left .u-header--side .hs-menu-vertical .hs-sub-menu .hs-mega-menu,
.u-body--header-side-overlay-left .u-header--side .hs-menu-vertical .hs-sub-menu .hs-sub-menu, .u-body--header-side-push-left .u-header--side .hs-menu-vertical .hs-mega-menu .hs-mega-menu,
.u-body--header-side-push-left .u-header--side .hs-menu-vertical .hs-mega-menu .hs-sub-menu,
.u-body--header-side-push-left .u-header--side .hs-menu-vertical .hs-sub-menu .hs-mega-menu,
.u-body--header-side-push-left .u-header--side .hs-menu-vertical .hs-sub-menu .hs-sub-menu {
  margin-left: 0;
}

.u-body--header-side-static-left .u-header--side .hs-menu-vertical.hs-rtl .hs-mega-menu,
.u-body--header-side-static-left .u-header--side .hs-menu-vertical.hs-rtl .hs-sub-menu, .u-body--header-side-overlay-left .u-header--side .hs-menu-vertical.hs-rtl .hs-mega-menu,
.u-body--header-side-overlay-left .u-header--side .hs-menu-vertical.hs-rtl .hs-sub-menu, .u-body--header-side-push-left .u-header--side .hs-menu-vertical.hs-rtl .hs-mega-menu,
.u-body--header-side-push-left .u-header--side .hs-menu-vertical.hs-rtl .hs-sub-menu {
  margin-left: 0;
  margin-right: 1.07143rem;
}

.u-body--header-side-static-left .u-header--side .hs-menu-vertical.hs-rtl .hs-mega-menu .hs-mega-menu,
.u-body--header-side-static-left .u-header--side .hs-menu-vertical.hs-rtl .hs-mega-menu .hs-sub-menu,
.u-body--header-side-static-left .u-header--side .hs-menu-vertical.hs-rtl .hs-sub-menu .hs-mega-menu,
.u-body--header-side-static-left .u-header--side .hs-menu-vertical.hs-rtl .hs-sub-menu .hs-sub-menu, .u-body--header-side-overlay-left .u-header--side .hs-menu-vertical.hs-rtl .hs-mega-menu .hs-mega-menu,
.u-body--header-side-overlay-left .u-header--side .hs-menu-vertical.hs-rtl .hs-mega-menu .hs-sub-menu,
.u-body--header-side-overlay-left .u-header--side .hs-menu-vertical.hs-rtl .hs-sub-menu .hs-mega-menu,
.u-body--header-side-overlay-left .u-header--side .hs-menu-vertical.hs-rtl .hs-sub-menu .hs-sub-menu, .u-body--header-side-push-left .u-header--side .hs-menu-vertical.hs-rtl .hs-mega-menu .hs-mega-menu,
.u-body--header-side-push-left .u-header--side .hs-menu-vertical.hs-rtl .hs-mega-menu .hs-sub-menu,
.u-body--header-side-push-left .u-header--side .hs-menu-vertical.hs-rtl .hs-sub-menu .hs-mega-menu,
.u-body--header-side-push-left .u-header--side .hs-menu-vertical.hs-rtl .hs-sub-menu .hs-sub-menu {
  margin-left: 0;
  margin-right: 0;
}

.u-body--header-side-static-left {
  margin-left: 21.42857rem;
}

.u-body--header-side-static-left .u-header--side {
  right: auto;
  left: 0;
}

.u-body--header-side-static-right {
  margin-right: 21.42857rem;
}

.u-body--header-side-static-right .u-header--side {
  left: auto;
  right: 0;
}

.u-body--header-side-overlay-left .u-header--side, .u-body--header-side-push-left .u-header--side {
  right: auto;
  left: -21.42857rem;
}

.u-body--header-side-overlay-left .u-header--side .u-header__overlay, .u-body--header-side-push-left .u-header--side .u-header__overlay {
  left: 100%;
}

.u-body--header-side-overlay-left.u-body--header-side-opened .u-header--side, .u-body--header-side-push-left.u-body--header-side-opened .u-header--side {
  left: 0;
}

.u-body--header-side-push-left.u-body--header-side-opened {
  margin-left: 21.42857rem;
}

.u-body--header-side-overlay-right .u-header--side, .u-body--header-side-push-right .u-header--side {
  left: auto;
  right: -21.42857rem;
}

.u-body--header-side-overlay-right .u-header--side .u-header__overlay, .u-body--header-side-push-right .u-header--side .u-header__overlay {
  right: 100%;
}

.u-body--header-side-overlay-right.u-body--header-side-opened .u-header--side, .u-body--header-side-push-right.u-body--header-side-opened .u-header--side {
  right: 0;
}

.u-body--header-side-push-right.u-body--header-side-opened {
  margin-right: 21.42857rem;
}

.u-body--header-side-static-right .u-header--side .dropdown-menu, .u-body--header-side-overlay-right .u-header--side .dropdown-menu, .u-body--header-side-push-right .u-header--side .dropdown-menu {
  left: auto;
  right: 100%;
  margin-right: 1.07143rem;
}

.u-body--header-side-static-right .u-header--side .hs-menu-vertical .hs-mega-menu,
.u-body--header-side-static-right .u-header--side .hs-menu-vertical .hs-sub-menu, .u-body--header-side-overlay-right .u-header--side .hs-menu-vertical .hs-mega-menu,
.u-body--header-side-overlay-right .u-header--side .hs-menu-vertical .hs-sub-menu, .u-body--header-side-push-right .u-header--side .hs-menu-vertical .hs-mega-menu,
.u-body--header-side-push-right .u-header--side .hs-menu-vertical .hs-sub-menu {
  left: auto;
  right: 100%;
  margin-right: 1.07143rem;
}

.u-body--header-side-static-right .u-header--side .hs-menu-vertical .hs-mega-menu .hs-mega-menu,
.u-body--header-side-static-right .u-header--side .hs-menu-vertical .hs-mega-menu .hs-sub-menu,
.u-body--header-side-static-right .u-header--side .hs-menu-vertical .hs-sub-menu .hs-mega-menu,
.u-body--header-side-static-right .u-header--side .hs-menu-vertical .hs-sub-menu .hs-sub-menu, .u-body--header-side-overlay-right .u-header--side .hs-menu-vertical .hs-mega-menu .hs-mega-menu,
.u-body--header-side-overlay-right .u-header--side .hs-menu-vertical .hs-mega-menu .hs-sub-menu,
.u-body--header-side-overlay-right .u-header--side .hs-menu-vertical .hs-sub-menu .hs-mega-menu,
.u-body--header-side-overlay-right .u-header--side .hs-menu-vertical .hs-sub-menu .hs-sub-menu, .u-body--header-side-push-right .u-header--side .hs-menu-vertical .hs-mega-menu .hs-mega-menu,
.u-body--header-side-push-right .u-header--side .hs-menu-vertical .hs-mega-menu .hs-sub-menu,
.u-body--header-side-push-right .u-header--side .hs-menu-vertical .hs-sub-menu .hs-mega-menu,
.u-body--header-side-push-right .u-header--side .hs-menu-vertical .hs-sub-menu .hs-sub-menu {
  margin-right: 0;
}

.u-body--header-side-static-right .u-header--side .hs-menu-vertical.hs-rtl .hs-mega-menu,
.u-body--header-side-static-right .u-header--side .hs-menu-vertical.hs-rtl .hs-sub-menu, .u-body--header-side-overlay-right .u-header--side .hs-menu-vertical.hs-rtl .hs-mega-menu,
.u-body--header-side-overlay-right .u-header--side .hs-menu-vertical.hs-rtl .hs-sub-menu, .u-body--header-side-push-right .u-header--side .hs-menu-vertical.hs-rtl .hs-mega-menu,
.u-body--header-side-push-right .u-header--side .hs-menu-vertical.hs-rtl .hs-sub-menu {
  margin-right: 0;
  margin-left: 1.07143rem;
}

.u-body--header-side-static-right .u-header--side .hs-menu-vertical.hs-rtl .hs-mega-menu .hs-mega-menu,
.u-body--header-side-static-right .u-header--side .hs-menu-vertical.hs-rtl .hs-mega-menu .hs-sub-menu,
.u-body--header-side-static-right .u-header--side .hs-menu-vertical.hs-rtl .hs-sub-menu .hs-mega-menu,
.u-body--header-side-static-right .u-header--side .hs-menu-vertical.hs-rtl .hs-sub-menu .hs-sub-menu, .u-body--header-side-overlay-right .u-header--side .hs-menu-vertical.hs-rtl .hs-mega-menu .hs-mega-menu,
.u-body--header-side-overlay-right .u-header--side .hs-menu-vertical.hs-rtl .hs-mega-menu .hs-sub-menu,
.u-body--header-side-overlay-right .u-header--side .hs-menu-vertical.hs-rtl .hs-sub-menu .hs-mega-menu,
.u-body--header-side-overlay-right .u-header--side .hs-menu-vertical.hs-rtl .hs-sub-menu .hs-sub-menu, .u-body--header-side-push-right .u-header--side .hs-menu-vertical.hs-rtl .hs-mega-menu .hs-mega-menu,
.u-body--header-side-push-right .u-header--side .hs-menu-vertical.hs-rtl .hs-mega-menu .hs-sub-menu,
.u-body--header-side-push-right .u-header--side .hs-menu-vertical.hs-rtl .hs-sub-menu .hs-mega-menu,
.u-body--header-side-push-right .u-header--side .hs-menu-vertical.hs-rtl .hs-sub-menu .hs-sub-menu {
  margin-left: 0;
  margin-right: 0;
}

/* Button Styles
------------------------------------*/
/* General Button Styles */
.btn {
  position: relative;
  transition: .2s ease;
  cursor: pointer;
}

.btn:focus, .btn:active:focus, .btn.active:focus {
  outline: 0 none;
  box-shadow: none;
}

/* Button Content
------------------------------------*/
.u-btn-content {
  white-space: normal;
}

/* Buttons Only Icon (O)
------------------------------------*/
.u-btn-only-icon {
  position: relative;
}

.u-btn-only-icon i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

/* Button Sizes
------------------------------------*/
/* Extra Small */
.btn-xs {
  line-height: 1.4;
  padding: 0.14286rem 0.5rem;
  font-size: 0.78571rem;
}

/* Medium */
.btn-md {
  line-height: 1.4;
  padding: 0.57143rem 1.42857rem;
  font-size: 1.07143rem;
}

/* Extra Large */
.btn-xl {
  line-height: 1.4;
  padding: 0.92857rem 1.85714rem;
  font-size: 1.28571rem;
}

/* Extramly Large */
.btn-xxl {
  line-height: 1.4;
  padding: 1.07143rem 2.14286rem;
  font-size: 1.5rem;
}

/* Button Types
------------------------------------*/
/* Inset Buttons */
.u-btn-inset {
  position: relative;
}

.u-btn-inset::before {
  position: absolute;
  top: 0.14286rem;
  right: 0.14286rem;
  bottom: 0.14286rem;
  left: 0.14286rem;
  content: "";
  border: solid 1px #fff;
  border-radius: 1px;
}

.u-btn-inset--rounded::before {
  border-radius: 50px;
}

/* 3d Buttons */
.u-btn-3d {
  border-bottom: solid 3px rgba(0, 0, 0, 0.2);
}

.u-btn-3d:hover {
  border-bottom-color: rgba(0, 0, 0, 0.3);
}

/* Skew Button */
.u-btn-skew {
  transform: skewX(-20deg);
}

.u-btn-skew__inner {
  transform: skewX(20deg);
  display: block;
}

/* Button Hovers
------------------------------------*/
[class*="u-btn-hover"] {
  z-index: 1;
}

[class*="u-btn-hover"]:hover {
  text-decoration: none;
}

[class*="u-btn-hover"]:focus {
  text-decoration: none;
}

[class*="u-btn-hover"]::after {
  position: absolute;
  content: "";
  z-index: -1;
  transition: all .3s;
}

/* Hover v1-1 */
.u-btn-hover-v1-1::after {
  top: 0;
  left: 0;
  height: 0;
  width: 100%;
}

.u-btn-hover-v1-1:hover::after {
  height: 100%;
}

/* Hover v1-2 */
.u-btn-hover-v1-2::after {
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
}

.u-btn-hover-v1-2:hover::after {
  width: 100%;
}

/* Hover v1-3 */
.u-btn-hover-v1-3::after {
  bottom: 0;
  left: 0;
  height: 0;
  width: 100%;
}

.u-btn-hover-v1-3:hover::after {
  height: 100%;
}

/* Hover v1-4 */
.u-btn-hover-v1-4::after {
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
}

.u-btn-hover-v1-4:hover::after {
  width: 100%;
}

/* Hover v2-1 */
.u-btn-hover-v2-1::after {
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
}

.u-btn-hover-v2-1:hover::after {
  left: 0;
  width: 100%;
}

/* Hover v2-2 */
.u-btn-hover-v2-2::after {
  left: 0;
  bottom: 0;
  height: 0;
  width: 100%;
}

.u-btn-hover-v2-2:hover::after {
  top: 0;
  height: 100%;
}

/* Button Primary */
.u-btn-primary {
  color: #fff;
  background-color: #2cb8f3;
}

.u-btn-primary:hover, .u-btn-primary.active {
  border-color: #5cc8f6;
  background-color: #5cc8f6;
}

.u-btn-primary:hover, .u-btn-primary:focus, .u-btn-primary.active {
  color: #fff;
}

.u-btn-primary.g-btn-hover-reset:hover, .u-btn-primary.g-btn-hover-reset.active {
  background-color: #2cb8f3;
  border-color: #2cb8f3;
}

.u-btn-primary.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-primary.u-btn-hover-v1-1::after, .u-btn-primary.u-btn-hover-v1-1:hover::after {
  background-color: #44c0f4;
}

.u-btn-primary.u-btn-hover-v1-1:hover {
  background-color: #2cb8f3;
  overflow: hidden;
}

.u-btn-primary.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-primary.u-btn-hover-v1-2::after, .u-btn-primary.u-btn-hover-v1-2:hover::after {
  background-color: #44c0f4;
}

.u-btn-primary.u-btn-hover-v1-2:hover {
  background-color: #2cb8f3;
  overflow: hidden;
}

.u-btn-primary.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-primary.u-btn-hover-v1-3::after, .u-btn-primary.u-btn-hover-v1-3:hover::after {
  background-color: #44c0f4;
}

.u-btn-primary.u-btn-hover-v1-3:hover {
  background-color: #2cb8f3;
  overflow: hidden;
}

.u-btn-primary.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-primary.u-btn-hover-v1-4::after, .u-btn-primary.u-btn-hover-v1-4:hover::after {
  background-color: #44c0f4;
}

.u-btn-primary.u-btn-hover-v1-4:hover {
  background-color: #2cb8f3;
  overflow: hidden;
}

.u-btn-primary.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-primary.u-btn-hover-v2-1::after, .u-btn-primary.u-btn-hover-v2-1:hover::after {
  background-color: #44c0f4;
}

.u-btn-primary.u-btn-hover-v2-1:hover {
  background-color: #2cb8f3;
  overflow: hidden;
}

.u-btn-primary.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-primary.u-btn-hover-v2-2::after, .u-btn-primary.u-btn-hover-v2-2:hover::after {
  background-color: #44c0f4;
}

.u-btn-primary.u-btn-hover-v2-2:hover {
  background-color: #2cb8f3;
  overflow: hidden;
}

/* Button White */
.u-btn-white {
  color: #555;
  background-color: #fff;
}

.u-btn-white:hover, .u-btn-white.active {
  border-color: white;
  background-color: white;
}

.u-btn-white:hover, .u-btn-white:focus, .u-btn-white.active {
  color: #555;
}

.u-btn-white.g-btn-hover-reset:hover, .u-btn-white.g-btn-hover-reset.active {
  background-color: #fff;
  border-color: #fff;
}

.u-btn-white.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-white.u-btn-hover-v1-1::after, .u-btn-white.u-btn-hover-v1-1:hover::after {
  background-color: white;
}

.u-btn-white.u-btn-hover-v1-1:hover {
  background-color: #fff;
  overflow: hidden;
}

.u-btn-white.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-white.u-btn-hover-v1-2::after, .u-btn-white.u-btn-hover-v1-2:hover::after {
  background-color: white;
}

.u-btn-white.u-btn-hover-v1-2:hover {
  background-color: #fff;
  overflow: hidden;
}

.u-btn-white.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-white.u-btn-hover-v1-3::after, .u-btn-white.u-btn-hover-v1-3:hover::after {
  background-color: white;
}

.u-btn-white.u-btn-hover-v1-3:hover {
  background-color: #fff;
  overflow: hidden;
}

.u-btn-white.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-white.u-btn-hover-v1-4::after, .u-btn-white.u-btn-hover-v1-4:hover::after {
  background-color: white;
}

.u-btn-white.u-btn-hover-v1-4:hover {
  background-color: #fff;
  overflow: hidden;
}

.u-btn-white.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-white.u-btn-hover-v2-1::after, .u-btn-white.u-btn-hover-v2-1:hover::after {
  background-color: white;
}

.u-btn-white.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-white.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-white.u-btn-hover-v2-2::after, .u-btn-white.u-btn-hover-v2-2:hover::after {
  background-color: white;
}

.u-btn-white.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Button Black */
.u-btn-black {
  color: #fff;
  background-color: #000;
}

.u-btn-black:hover, .u-btn-black.active {
  border-color: #1a1a1a;
  background-color: #1a1a1a;
}

.u-btn-black:hover, .u-btn-black:focus, .u-btn-black.active {
  color: #fff;
}

.u-btn-black.g-btn-hover-reset:hover, .u-btn-black.g-btn-hover-reset.active {
  background-color: #000;
  border-color: #000;
}

.u-btn-black.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-black.u-btn-hover-v1-1::after, .u-btn-black.u-btn-hover-v1-1:hover::after {
  background-color: #0d0d0d;
}

.u-btn-black.u-btn-hover-v1-1:hover {
  background-color: #000;
  overflow: hidden;
}

.u-btn-black.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-black.u-btn-hover-v1-2::after, .u-btn-black.u-btn-hover-v1-2:hover::after {
  background-color: #0d0d0d;
}

.u-btn-black.u-btn-hover-v1-2:hover {
  background-color: #000;
  overflow: hidden;
}

.u-btn-black.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-black.u-btn-hover-v1-3::after, .u-btn-black.u-btn-hover-v1-3:hover::after {
  background-color: #0d0d0d;
}

.u-btn-black.u-btn-hover-v1-3:hover {
  background-color: #000;
  overflow: hidden;
}

.u-btn-black.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-black.u-btn-hover-v1-4::after, .u-btn-black.u-btn-hover-v1-4:hover::after {
  background-color: #0d0d0d;
}

.u-btn-black.u-btn-hover-v1-4:hover {
  background-color: #000;
  overflow: hidden;
}

.u-btn-black.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-black.u-btn-hover-v2-1::after, .u-btn-black.u-btn-hover-v2-1:hover::after {
  background-color: #0d0d0d;
}

.u-btn-black.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-black.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-black.u-btn-hover-v2-2::after, .u-btn-black.u-btn-hover-v2-2:hover::after {
  background-color: #0d0d0d;
}

.u-btn-black.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Button Dark Gray */
.u-btn-darkgray {
  color: #fff;
  background-color: #333;
}

.u-btn-darkgray:hover, .u-btn-darkgray.active {
  border-color: #4d4d4d;
  background-color: #4d4d4d;
}

.u-btn-darkgray:hover, .u-btn-darkgray:focus, .u-btn-darkgray.active {
  color: #fff;
}

.u-btn-darkgray.g-btn-hover-reset:hover, .u-btn-darkgray.g-btn-hover-reset.active {
  background-color: #333;
  border-color: #333;
}

.u-btn-darkgray.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-darkgray.u-btn-hover-v1-1::after, .u-btn-darkgray.u-btn-hover-v1-1:hover::after {
  background-color: #404040;
}

.u-btn-darkgray.u-btn-hover-v1-1:hover {
  background-color: #333;
  overflow: hidden;
}

.u-btn-darkgray.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-darkgray.u-btn-hover-v1-2::after, .u-btn-darkgray.u-btn-hover-v1-2:hover::after {
  background-color: #404040;
}

.u-btn-darkgray.u-btn-hover-v1-2:hover {
  background-color: #333;
  overflow: hidden;
}

.u-btn-darkgray.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-darkgray.u-btn-hover-v1-3::after, .u-btn-darkgray.u-btn-hover-v1-3:hover::after {
  background-color: #404040;
}

.u-btn-darkgray.u-btn-hover-v1-3:hover {
  background-color: #333;
  overflow: hidden;
}

.u-btn-darkgray.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-darkgray.u-btn-hover-v1-4::after, .u-btn-darkgray.u-btn-hover-v1-4:hover::after {
  background-color: #404040;
}

.u-btn-darkgray.u-btn-hover-v1-4:hover {
  background-color: #333;
  overflow: hidden;
}

.u-btn-darkgray.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-darkgray.u-btn-hover-v2-1::after, .u-btn-darkgray.u-btn-hover-v2-1:hover::after {
  background-color: #404040;
}

.u-btn-darkgray.u-btn-hover-v2-1:hover {
  background-color: #333;
  overflow: hidden;
}

.u-btn-darkgray.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-darkgray.u-btn-hover-v2-2::after, .u-btn-darkgray.u-btn-hover-v2-2:hover::after {
  background-color: #404040;
}

.u-btn-darkgray.u-btn-hover-v2-2:hover {
  background-color: #333;
  overflow: hidden;
}

/* Button Red */
.u-btn-red {
  color: #fff;
  background-color: #f00;
}

.u-btn-red:hover, .u-btn-red.active {
  border-color: #ff3333;
  background-color: #ff3333;
}

.u-btn-red:hover, .u-btn-red:focus, .u-btn-red.active {
  color: #fff;
}

.u-btn-red.g-btn-hover-reset:hover, .u-btn-red.g-btn-hover-reset.active {
  background-color: #f00;
  border-color: #f00;
}

.u-btn-red.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-red.u-btn-hover-v1-1::after, .u-btn-red.u-btn-hover-v1-1:hover::after {
  background-color: #ff1a1a;
}

.u-btn-red.u-btn-hover-v1-1:hover {
  background-color: #f00;
  overflow: hidden;
}

.u-btn-red.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-red.u-btn-hover-v1-2::after, .u-btn-red.u-btn-hover-v1-2:hover::after {
  background-color: #ff1a1a;
}

.u-btn-red.u-btn-hover-v1-2:hover {
  background-color: #f00;
  overflow: hidden;
}

.u-btn-red.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-red.u-btn-hover-v1-3::after, .u-btn-red.u-btn-hover-v1-3:hover::after {
  background-color: #ff1a1a;
}

.u-btn-red.u-btn-hover-v1-3:hover {
  background-color: #f00;
  overflow: hidden;
}

.u-btn-red.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-red.u-btn-hover-v1-4::after, .u-btn-red.u-btn-hover-v1-4:hover::after {
  background-color: #ff1a1a;
}

.u-btn-red.u-btn-hover-v1-4:hover {
  background-color: #f00;
  overflow: hidden;
}

.u-btn-red.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-red.u-btn-hover-v2-1::after, .u-btn-red.u-btn-hover-v2-1:hover::after {
  background-color: #ff1a1a;
}

.u-btn-red.u-btn-hover-v2-1:hover {
  background-color: #f00;
  overflow: hidden;
}

.u-btn-red.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-red.u-btn-hover-v2-2::after, .u-btn-red.u-btn-hover-v2-2:hover::after {
  background-color: #ff1a1a;
}

.u-btn-red.u-btn-hover-v2-2:hover {
  background-color: #f00;
  overflow: hidden;
}

/* Button Red Tomato */
.u-btn-lightred {
  color: #fff;
  background-color: #e64b3b;
}

.u-btn-lightred:hover, .u-btn-lightred.active {
  border-color: #ec7568;
  background-color: #ec7568;
}

.u-btn-lightred:hover, .u-btn-lightred:focus, .u-btn-lightred.active {
  color: #fff;
}

.u-btn-lightred.g-btn-hover-reset:hover, .u-btn-lightred.g-btn-hover-reset.active {
  background-color: #e64b3b;
  border-color: #e64b3b;
}

.u-btn-lightred.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-lightred.u-btn-hover-v1-1::after, .u-btn-lightred.u-btn-hover-v1-1:hover::after {
  background-color: #e96052;
}

.u-btn-lightred.u-btn-hover-v1-1:hover {
  background-color: #e64b3b;
  overflow: hidden;
}

.u-btn-lightred.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-lightred.u-btn-hover-v1-2::after, .u-btn-lightred.u-btn-hover-v1-2:hover::after {
  background-color: #e96052;
}

.u-btn-lightred.u-btn-hover-v1-2:hover {
  background-color: #e64b3b;
  overflow: hidden;
}

.u-btn-lightred.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-lightred.u-btn-hover-v1-3::after, .u-btn-lightred.u-btn-hover-v1-3:hover::after {
  background-color: #e96052;
}

.u-btn-lightred.u-btn-hover-v1-3:hover {
  background-color: #e64b3b;
  overflow: hidden;
}

.u-btn-lightred.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-lightred.u-btn-hover-v1-4::after, .u-btn-lightred.u-btn-hover-v1-4:hover::after {
  background-color: #e96052;
}

.u-btn-lightred.u-btn-hover-v1-4:hover {
  background-color: #e64b3b;
  overflow: hidden;
}

.u-btn-lightred.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-lightred.u-btn-hover-v2-1::after, .u-btn-lightred.u-btn-hover-v2-1:hover::after {
  background-color: #e96052;
}

.u-btn-lightred.u-btn-hover-v2-1:hover {
  background-color: #e64b3b;
  overflow: hidden;
}

.u-btn-lightred.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-lightred.u-btn-hover-v2-2::after, .u-btn-lightred.u-btn-hover-v2-2:hover::after {
  background-color: #e96052;
}

.u-btn-lightred.u-btn-hover-v2-2:hover {
  background-color: #e64b3b;
  overflow: hidden;
}

/* Button Dark Red */
.u-btn-darkred {
  color: #fff;
  background-color: #a10f2b;
}

.u-btn-darkred:hover, .u-btn-darkred.active {
  border-color: #d01337;
  background-color: #d01337;
}

.u-btn-darkred:hover, .u-btn-darkred:focus, .u-btn-darkred.active {
  color: #fff;
}

.u-btn-darkred.g-btn-hover-reset:hover, .u-btn-darkred.g-btn-hover-reset.active {
  background-color: #a10f2b;
  border-color: #a10f2b;
}

.u-btn-darkred.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-darkred.u-btn-hover-v1-1::after, .u-btn-darkred.u-btn-hover-v1-1:hover::after {
  background-color: #b81131;
}

.u-btn-darkred.u-btn-hover-v1-1:hover {
  background-color: #a10f2b;
  overflow: hidden;
}

.u-btn-darkred.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-darkred.u-btn-hover-v1-2::after, .u-btn-darkred.u-btn-hover-v1-2:hover::after {
  background-color: #b81131;
}

.u-btn-darkred.u-btn-hover-v1-2:hover {
  background-color: #a10f2b;
  overflow: hidden;
}

.u-btn-darkred.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-darkred.u-btn-hover-v1-3::after, .u-btn-darkred.u-btn-hover-v1-3:hover::after {
  background-color: #b81131;
}

.u-btn-darkred.u-btn-hover-v1-3:hover {
  background-color: #a10f2b;
  overflow: hidden;
}

.u-btn-darkred.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-darkred.u-btn-hover-v1-4::after, .u-btn-darkred.u-btn-hover-v1-4:hover::after {
  background-color: #b81131;
}

.u-btn-darkred.u-btn-hover-v1-4:hover {
  background-color: #a10f2b;
  overflow: hidden;
}

.u-btn-darkred.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-darkred.u-btn-hover-v2-1::after, .u-btn-darkred.u-btn-hover-v2-1:hover::after {
  background-color: #b81131;
}

.u-btn-darkred.u-btn-hover-v2-1:hover {
  background-color: #a10f2b;
  overflow: hidden;
}

.u-btn-darkred.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-darkred.u-btn-hover-v2-2::after, .u-btn-darkred.u-btn-hover-v2-2:hover::after {
  background-color: #b81131;
}

.u-btn-darkred.u-btn-hover-v2-2:hover {
  background-color: #a10f2b;
  overflow: hidden;
}

/* Button Blue */
.u-btn-blue {
  color: #fff;
  background-color: #3398dc;
}

.u-btn-blue:hover, .u-btn-blue.active {
  border-color: #5faee3;
  background-color: #5faee3;
}

.u-btn-blue:hover, .u-btn-blue:focus, .u-btn-blue.active {
  color: #fff;
}

.u-btn-blue.g-btn-hover-reset:hover, .u-btn-blue.g-btn-hover-reset.active {
  background-color: #3398dc;
  border-color: #3398dc;
}

.u-btn-blue.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-blue.u-btn-hover-v1-1::after, .u-btn-blue.u-btn-hover-v1-1:hover::after {
  background-color: #49a3e0;
}

.u-btn-blue.u-btn-hover-v1-1:hover {
  background-color: #3398dc;
  overflow: hidden;
}

.u-btn-blue.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-blue.u-btn-hover-v1-2::after, .u-btn-blue.u-btn-hover-v1-2:hover::after {
  background-color: #49a3e0;
}

.u-btn-blue.u-btn-hover-v1-2:hover {
  background-color: #3398dc;
  overflow: hidden;
}

.u-btn-blue.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-blue.u-btn-hover-v1-3::after, .u-btn-blue.u-btn-hover-v1-3:hover::after {
  background-color: #49a3e0;
}

.u-btn-blue.u-btn-hover-v1-3:hover {
  background-color: #3398dc;
  overflow: hidden;
}

.u-btn-blue.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-blue.u-btn-hover-v1-4::after, .u-btn-blue.u-btn-hover-v1-4:hover::after {
  background-color: #49a3e0;
}

.u-btn-blue.u-btn-hover-v1-4:hover {
  background-color: #3398dc;
  overflow: hidden;
}

.u-btn-blue.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-blue.u-btn-hover-v2-1::after, .u-btn-blue.u-btn-hover-v2-1:hover::after {
  background-color: #49a3e0;
}

.u-btn-blue.u-btn-hover-v2-1:hover {
  background-color: #3398dc;
  overflow: hidden;
}

.u-btn-blue.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-blue.u-btn-hover-v2-2::after, .u-btn-blue.u-btn-hover-v2-2:hover::after {
  background-color: #49a3e0;
}

.u-btn-blue.u-btn-hover-v2-2:hover {
  background-color: #3398dc;
  overflow: hidden;
}

/* Button Indigo */
.u-btn-indigo {
  color: #fff;
  background-color: #4263a3;
}

.u-btn-indigo:hover, .u-btn-indigo.active {
  border-color: #5b7cbd;
  background-color: #5b7cbd;
}

.u-btn-indigo:hover, .u-btn-indigo:focus, .u-btn-indigo.active {
  color: #fff;
}

.u-btn-indigo.g-btn-hover-reset:hover, .u-btn-indigo.g-btn-hover-reset.active {
  background-color: #4263a3;
  border-color: #4263a3;
}

.u-btn-indigo.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-indigo.u-btn-hover-v1-1::after, .u-btn-indigo.u-btn-hover-v1-1:hover::after {
  background-color: #496eb5;
}

.u-btn-indigo.u-btn-hover-v1-1:hover {
  background-color: #4263a3;
  overflow: hidden;
}

.u-btn-indigo.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-indigo.u-btn-hover-v1-2::after, .u-btn-indigo.u-btn-hover-v1-2:hover::after {
  background-color: #496eb5;
}

.u-btn-indigo.u-btn-hover-v1-2:hover {
  background-color: #4263a3;
  overflow: hidden;
}

.u-btn-indigo.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-indigo.u-btn-hover-v1-3::after, .u-btn-indigo.u-btn-hover-v1-3:hover::after {
  background-color: #496eb5;
}

.u-btn-indigo.u-btn-hover-v1-3:hover {
  background-color: #4263a3;
  overflow: hidden;
}

.u-btn-indigo.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-indigo.u-btn-hover-v1-4::after, .u-btn-indigo.u-btn-hover-v1-4:hover::after {
  background-color: #496eb5;
}

.u-btn-indigo.u-btn-hover-v1-4:hover {
  background-color: #4263a3;
  overflow: hidden;
}

.u-btn-indigo.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-indigo.u-btn-hover-v2-1::after, .u-btn-indigo.u-btn-hover-v2-1:hover::after {
  background-color: #496eb5;
}

.u-btn-indigo.u-btn-hover-v2-1:hover {
  background-color: #4263a3;
  overflow: hidden;
}

.u-btn-indigo.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-indigo.u-btn-hover-v2-2::after, .u-btn-indigo.u-btn-hover-v2-2:hover::after {
  background-color: #496eb5;
}

.u-btn-indigo.u-btn-hover-v2-2:hover {
  background-color: #4263a3;
  overflow: hidden;
}

/* Button Purple */
.u-btn-purple {
  color: #fff;
  background-color: #9a69cb;
}

.u-btn-purple:hover, .u-btn-purple.active {
  border-color: #b48fd8;
  background-color: #b48fd8;
}

.u-btn-purple:hover, .u-btn-purple:focus, .u-btn-purple.active {
  color: #fff;
}

.u-btn-purple.g-btn-hover-reset:hover, .u-btn-purple.g-btn-hover-reset.active {
  background-color: #9a69cb;
  border-color: #9a69cb;
}

.u-btn-purple.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-purple.u-btn-hover-v1-1::after, .u-btn-purple.u-btn-hover-v1-1:hover::after {
  background-color: #a77cd2;
}

.u-btn-purple.u-btn-hover-v1-1:hover {
  background-color: #9a69cb;
  overflow: hidden;
}

.u-btn-purple.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-purple.u-btn-hover-v1-2::after, .u-btn-purple.u-btn-hover-v1-2:hover::after {
  background-color: #a77cd2;
}

.u-btn-purple.u-btn-hover-v1-2:hover {
  background-color: #9a69cb;
  overflow: hidden;
}

.u-btn-purple.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-purple.u-btn-hover-v1-3::after, .u-btn-purple.u-btn-hover-v1-3:hover::after {
  background-color: #a77cd2;
}

.u-btn-purple.u-btn-hover-v1-3:hover {
  background-color: #9a69cb;
  overflow: hidden;
}

.u-btn-purple.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-purple.u-btn-hover-v1-4::after, .u-btn-purple.u-btn-hover-v1-4:hover::after {
  background-color: #a77cd2;
}

.u-btn-purple.u-btn-hover-v1-4:hover {
  background-color: #9a69cb;
  overflow: hidden;
}

.u-btn-purple.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-purple.u-btn-hover-v2-1::after, .u-btn-purple.u-btn-hover-v2-1:hover::after {
  background-color: #a77cd2;
}

.u-btn-purple.u-btn-hover-v2-1:hover {
  background-color: #9a69cb;
  overflow: hidden;
}

.u-btn-purple.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-purple.u-btn-hover-v2-2::after, .u-btn-purple.u-btn-hover-v2-2:hover::after {
  background-color: #a77cd2;
}

.u-btn-purple.u-btn-hover-v2-2:hover {
  background-color: #9a69cb;
  overflow: hidden;
}

/* Button Dark Purple */
.u-btn-darkpurple {
  color: #fff;
  background-color: #6639b6;
}

.u-btn-darkpurple:hover, .u-btn-darkpurple.active {
  border-color: #8157cb;
  background-color: #8157cb;
}

.u-btn-darkpurple:hover, .u-btn-darkpurple:focus, .u-btn-darkpurple.active {
  color: #fff;
}

.u-btn-darkpurple.g-btn-hover-reset:hover, .u-btn-darkpurple.g-btn-hover-reset.active {
  background-color: #6639b6;
  border-color: #6639b6;
}

.u-btn-darkpurple.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-darkpurple.u-btn-hover-v1-1::after, .u-btn-darkpurple.u-btn-hover-v1-1:hover::after {
  background-color: #7244c4;
}

.u-btn-darkpurple.u-btn-hover-v1-1:hover {
  background-color: #6639b6;
  overflow: hidden;
}

.u-btn-darkpurple.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-darkpurple.u-btn-hover-v1-2::after, .u-btn-darkpurple.u-btn-hover-v1-2:hover::after {
  background-color: #7244c4;
}

.u-btn-darkpurple.u-btn-hover-v1-2:hover {
  background-color: #6639b6;
  overflow: hidden;
}

.u-btn-darkpurple.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-darkpurple.u-btn-hover-v1-3::after, .u-btn-darkpurple.u-btn-hover-v1-3:hover::after {
  background-color: #7244c4;
}

.u-btn-darkpurple.u-btn-hover-v1-3:hover {
  background-color: #6639b6;
  overflow: hidden;
}

.u-btn-darkpurple.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-darkpurple.u-btn-hover-v1-4::after, .u-btn-darkpurple.u-btn-hover-v1-4:hover::after {
  background-color: #7244c4;
}

.u-btn-darkpurple.u-btn-hover-v1-4:hover {
  background-color: #6639b6;
  overflow: hidden;
}

.u-btn-darkpurple.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-darkpurple.u-btn-hover-v2-1::after, .u-btn-darkpurple.u-btn-hover-v2-1:hover::after {
  background-color: #7244c4;
}

.u-btn-darkpurple.u-btn-hover-v2-1:hover {
  background-color: #6639b6;
  overflow: hidden;
}

.u-btn-darkpurple.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-darkpurple.u-btn-hover-v2-2::after, .u-btn-darkpurple.u-btn-hover-v2-2:hover::after {
  background-color: #7244c4;
}

.u-btn-darkpurple.u-btn-hover-v2-2:hover {
  background-color: #6639b6;
  overflow: hidden;
}

/* Button Pink */
.u-btn-pink {
  color: #fff;
  background-color: #e81c62;
}

.u-btn-pink:hover, .u-btn-pink.active {
  border-color: #ed4a82;
  background-color: #ed4a82;
}

.u-btn-pink:hover, .u-btn-pink:focus, .u-btn-pink.active {
  color: #fff;
}

.u-btn-pink.g-btn-hover-reset:hover, .u-btn-pink.g-btn-hover-reset.active {
  background-color: #e81c62;
  border-color: #e81c62;
}

.u-btn-pink.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-pink.u-btn-hover-v1-1::after, .u-btn-pink.u-btn-hover-v1-1:hover::after {
  background-color: #ea3372;
}

.u-btn-pink.u-btn-hover-v1-1:hover {
  background-color: #e81c62;
  overflow: hidden;
}

.u-btn-pink.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-pink.u-btn-hover-v1-2::after, .u-btn-pink.u-btn-hover-v1-2:hover::after {
  background-color: #ea3372;
}

.u-btn-pink.u-btn-hover-v1-2:hover {
  background-color: #e81c62;
  overflow: hidden;
}

.u-btn-pink.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-pink.u-btn-hover-v1-3::after, .u-btn-pink.u-btn-hover-v1-3:hover::after {
  background-color: #ea3372;
}

.u-btn-pink.u-btn-hover-v1-3:hover {
  background-color: #e81c62;
  overflow: hidden;
}

.u-btn-pink.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-pink.u-btn-hover-v1-4::after, .u-btn-pink.u-btn-hover-v1-4:hover::after {
  background-color: #ea3372;
}

.u-btn-pink.u-btn-hover-v1-4:hover {
  background-color: #e81c62;
  overflow: hidden;
}

.u-btn-pink.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-pink.u-btn-hover-v2-1::after, .u-btn-pink.u-btn-hover-v2-1:hover::after {
  background-color: #ea3372;
}

.u-btn-pink.u-btn-hover-v2-1:hover {
  background-color: #e81c62;
  overflow: hidden;
}

.u-btn-pink.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-pink.u-btn-hover-v2-2::after, .u-btn-pink.u-btn-hover-v2-2:hover::after {
  background-color: #ea3372;
}

.u-btn-pink.u-btn-hover-v2-2:hover {
  background-color: #e81c62;
  overflow: hidden;
}

/* Button Orange */
.u-btn-orange {
  color: #fff;
  background-color: #e57d20;
}

.u-btn-orange:hover, .u-btn-orange.active {
  border-color: #ea984e;
  background-color: #ea984e;
}

.u-btn-orange:hover, .u-btn-orange:focus, .u-btn-orange.active {
  color: #fff;
}

.u-btn-orange.g-btn-hover-reset:hover, .u-btn-orange.g-btn-hover-reset.active {
  background-color: #e57d20;
  border-color: #e57d20;
}

.u-btn-orange.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-orange.u-btn-hover-v1-1::after, .u-btn-orange.u-btn-hover-v1-1:hover::after {
  background-color: #e88a37;
}

.u-btn-orange.u-btn-hover-v1-1:hover {
  background-color: #e57d20;
  overflow: hidden;
}

.u-btn-orange.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-orange.u-btn-hover-v1-2::after, .u-btn-orange.u-btn-hover-v1-2:hover::after {
  background-color: #e88a37;
}

.u-btn-orange.u-btn-hover-v1-2:hover {
  background-color: #e57d20;
  overflow: hidden;
}

.u-btn-orange.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-orange.u-btn-hover-v1-3::after, .u-btn-orange.u-btn-hover-v1-3:hover::after {
  background-color: #e88a37;
}

.u-btn-orange.u-btn-hover-v1-3:hover {
  background-color: #e57d20;
  overflow: hidden;
}

.u-btn-orange.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-orange.u-btn-hover-v1-4::after, .u-btn-orange.u-btn-hover-v1-4:hover::after {
  background-color: #e88a37;
}

.u-btn-orange.u-btn-hover-v1-4:hover {
  background-color: #e57d20;
  overflow: hidden;
}

.u-btn-orange.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-orange.u-btn-hover-v2-1::after, .u-btn-orange.u-btn-hover-v2-1:hover::after {
  background-color: #e88a37;
}

.u-btn-orange.u-btn-hover-v2-1:hover {
  background-color: #e57d20;
  overflow: hidden;
}

.u-btn-orange.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-orange.u-btn-hover-v2-2::after, .u-btn-orange.u-btn-hover-v2-2:hover::after {
  background-color: #e88a37;
}

.u-btn-orange.u-btn-hover-v2-2:hover {
  background-color: #e57d20;
  overflow: hidden;
}

/* Button Deep Orange */
.u-btn-deeporange {
  color: #fff;
  background-color: #fe541e;
}

.u-btn-deeporange:hover, .u-btn-deeporange.active {
  border-color: #fe7b51;
  background-color: #fe7b51;
}

.u-btn-deeporange:hover, .u-btn-deeporange:focus, .u-btn-deeporange.active {
  color: #fff;
}

.u-btn-deeporange.g-btn-hover-reset:hover, .u-btn-deeporange.g-btn-hover-reset.active {
  background-color: #fe541e;
  border-color: #fe541e;
}

.u-btn-deeporange.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-deeporange.u-btn-hover-v1-1::after, .u-btn-deeporange.u-btn-hover-v1-1:hover::after {
  background-color: #fe6737;
}

.u-btn-deeporange.u-btn-hover-v1-1:hover {
  background-color: #fe541e;
  overflow: hidden;
}

.u-btn-deeporange.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-deeporange.u-btn-hover-v1-2::after, .u-btn-deeporange.u-btn-hover-v1-2:hover::after {
  background-color: #fe6737;
}

.u-btn-deeporange.u-btn-hover-v1-2:hover {
  background-color: #fe541e;
  overflow: hidden;
}

.u-btn-deeporange.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-deeporange.u-btn-hover-v1-3::after, .u-btn-deeporange.u-btn-hover-v1-3:hover::after {
  background-color: #fe6737;
}

.u-btn-deeporange.u-btn-hover-v1-3:hover {
  background-color: #fe541e;
  overflow: hidden;
}

.u-btn-deeporange.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-deeporange.u-btn-hover-v1-4::after, .u-btn-deeporange.u-btn-hover-v1-4:hover::after {
  background-color: #fe6737;
}

.u-btn-deeporange.u-btn-hover-v1-4:hover {
  background-color: #fe541e;
  overflow: hidden;
}

.u-btn-deeporange.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-deeporange.u-btn-hover-v2-1::after, .u-btn-deeporange.u-btn-hover-v2-1:hover::after {
  background-color: #fe6737;
}

.u-btn-deeporange.u-btn-hover-v2-1:hover {
  background-color: #fe541e;
  overflow: hidden;
}

.u-btn-deeporange.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-deeporange.u-btn-hover-v2-2::after, .u-btn-deeporange.u-btn-hover-v2-2:hover::after {
  background-color: #fe6737;
}

.u-btn-deeporange.u-btn-hover-v2-2:hover {
  background-color: #fe541e;
  overflow: hidden;
}

/* Button Yellow */
.u-btn-yellow {
  color: #fff;
  background-color: #ebc71d;
}

.u-btn-yellow:hover, .u-btn-yellow.active {
  border-color: #efd34c;
  background-color: #efd34c;
}

.u-btn-yellow:hover, .u-btn-yellow:focus, .u-btn-yellow.active {
  color: #fff;
}

.u-btn-yellow.g-btn-hover-reset:hover, .u-btn-yellow.g-btn-hover-reset.active {
  background-color: #ebc71d;
  border-color: #ebc71d;
}

.u-btn-yellow.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-yellow.u-btn-hover-v1-1::after, .u-btn-yellow.u-btn-hover-v1-1:hover::after {
  background-color: #edcd34;
}

.u-btn-yellow.u-btn-hover-v1-1:hover {
  background-color: #ebc71d;
  overflow: hidden;
}

.u-btn-yellow.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-yellow.u-btn-hover-v1-2::after, .u-btn-yellow.u-btn-hover-v1-2:hover::after {
  background-color: #edcd34;
}

.u-btn-yellow.u-btn-hover-v1-2:hover {
  background-color: #ebc71d;
  overflow: hidden;
}

.u-btn-yellow.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-yellow.u-btn-hover-v1-3::after, .u-btn-yellow.u-btn-hover-v1-3:hover::after {
  background-color: #edcd34;
}

.u-btn-yellow.u-btn-hover-v1-3:hover {
  background-color: #ebc71d;
  overflow: hidden;
}

.u-btn-yellow.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-yellow.u-btn-hover-v1-4::after, .u-btn-yellow.u-btn-hover-v1-4:hover::after {
  background-color: #edcd34;
}

.u-btn-yellow.u-btn-hover-v1-4:hover {
  background-color: #ebc71d;
  overflow: hidden;
}

.u-btn-yellow.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-yellow.u-btn-hover-v2-1::after, .u-btn-yellow.u-btn-hover-v2-1:hover::after {
  background-color: #edcd34;
}

.u-btn-yellow.u-btn-hover-v2-1:hover {
  background-color: #ebc71d;
  overflow: hidden;
}

.u-btn-yellow.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-yellow.u-btn-hover-v2-2::after, .u-btn-yellow.u-btn-hover-v2-2:hover::after {
  background-color: #edcd34;
}

.u-btn-yellow.u-btn-hover-v2-2:hover {
  background-color: #ebc71d;
  overflow: hidden;
}

/* Button Aqua */
.u-btn-aqua {
  color: #fff;
  background-color: #29d6e6;
}

.u-btn-aqua:hover, .u-btn-aqua.active {
  border-color: #57dfeb;
  background-color: #57dfeb;
}

.u-btn-aqua:hover, .u-btn-aqua:focus, .u-btn-aqua.active {
  color: #fff;
}

.u-btn-aqua.g-btn-hover-reset:hover, .u-btn-aqua.g-btn-hover-reset.active {
  background-color: #29d6e6;
  border-color: #29d6e6;
}

.u-btn-aqua.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-aqua.u-btn-hover-v1-1::after, .u-btn-aqua.u-btn-hover-v1-1:hover::after {
  background-color: #40dae9;
}

.u-btn-aqua.u-btn-hover-v1-1:hover {
  background-color: #29d6e6;
  overflow: hidden;
}

.u-btn-aqua.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-aqua.u-btn-hover-v1-2::after, .u-btn-aqua.u-btn-hover-v1-2:hover::after {
  background-color: #40dae9;
}

.u-btn-aqua.u-btn-hover-v1-2:hover {
  background-color: #29d6e6;
  overflow: hidden;
}

.u-btn-aqua.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-aqua.u-btn-hover-v1-3::after, .u-btn-aqua.u-btn-hover-v1-3:hover::after {
  background-color: #40dae9;
}

.u-btn-aqua.u-btn-hover-v1-3:hover {
  background-color: #29d6e6;
  overflow: hidden;
}

.u-btn-aqua.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-aqua.u-btn-hover-v1-4::after, .u-btn-aqua.u-btn-hover-v1-4:hover::after {
  background-color: #40dae9;
}

.u-btn-aqua.u-btn-hover-v1-4:hover {
  background-color: #29d6e6;
  overflow: hidden;
}

.u-btn-aqua.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-aqua.u-btn-hover-v2-1::after, .u-btn-aqua.u-btn-hover-v2-1:hover::after {
  background-color: #40dae9;
}

.u-btn-aqua.u-btn-hover-v2-1:hover {
  background-color: #29d6e6;
  overflow: hidden;
}

.u-btn-aqua.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-aqua.u-btn-hover-v2-2::after, .u-btn-aqua.u-btn-hover-v2-2:hover::after {
  background-color: #40dae9;
}

.u-btn-aqua.u-btn-hover-v2-2:hover {
  background-color: #29d6e6;
  overflow: hidden;
}

/* Button Cyan */
.u-btn-cyan {
  color: #fff;
  background-color: #00bed6;
}

.u-btn-cyan:hover, .u-btn-cyan.active {
  border-color: #0ae4ff;
  background-color: #0ae4ff;
}

.u-btn-cyan:hover, .u-btn-cyan:focus, .u-btn-cyan.active {
  color: #fff;
}

.u-btn-cyan.g-btn-hover-reset:hover, .u-btn-cyan.g-btn-hover-reset.active {
  background-color: #00bed6;
  border-color: #00bed6;
}

.u-btn-cyan.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-cyan.u-btn-hover-v1-1::after, .u-btn-cyan.u-btn-hover-v1-1:hover::after {
  background-color: #00d5f0;
}

.u-btn-cyan.u-btn-hover-v1-1:hover {
  background-color: #00bed6;
  overflow: hidden;
}

.u-btn-cyan.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-cyan.u-btn-hover-v1-2::after, .u-btn-cyan.u-btn-hover-v1-2:hover::after {
  background-color: #00d5f0;
}

.u-btn-cyan.u-btn-hover-v1-2:hover {
  background-color: #00bed6;
  overflow: hidden;
}

.u-btn-cyan.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-cyan.u-btn-hover-v1-3::after, .u-btn-cyan.u-btn-hover-v1-3:hover::after {
  background-color: #00d5f0;
}

.u-btn-cyan.u-btn-hover-v1-3:hover {
  background-color: #00bed6;
  overflow: hidden;
}

.u-btn-cyan.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-cyan.u-btn-hover-v1-4::after, .u-btn-cyan.u-btn-hover-v1-4:hover::after {
  background-color: #00d5f0;
}

.u-btn-cyan.u-btn-hover-v1-4:hover {
  background-color: #00bed6;
  overflow: hidden;
}

.u-btn-cyan.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-cyan.u-btn-hover-v2-1::after, .u-btn-cyan.u-btn-hover-v2-1:hover::after {
  background-color: #00d5f0;
}

.u-btn-cyan.u-btn-hover-v2-1:hover {
  background-color: #00bed6;
  overflow: hidden;
}

.u-btn-cyan.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-cyan.u-btn-hover-v2-2::after, .u-btn-cyan.u-btn-hover-v2-2:hover::after {
  background-color: #00d5f0;
}

.u-btn-cyan.u-btn-hover-v2-2:hover {
  background-color: #00bed6;
  overflow: hidden;
}

/* Button Teal */
.u-btn-teal {
  color: #fff;
  background-color: #18ba9b;
}

.u-btn-teal:hover, .u-btn-teal.active {
  border-color: #22e3be;
  background-color: #22e3be;
}

.u-btn-teal:hover, .u-btn-teal:focus, .u-btn-teal.active {
  color: #fff;
}

.u-btn-teal.g-btn-hover-reset:hover, .u-btn-teal.g-btn-hover-reset.active {
  background-color: #18ba9b;
  border-color: #18ba9b;
}

.u-btn-teal.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-teal.u-btn-hover-v1-1::after, .u-btn-teal.u-btn-hover-v1-1:hover::after {
  background-color: #1bd1ae;
}

.u-btn-teal.u-btn-hover-v1-1:hover {
  background-color: #18ba9b;
  overflow: hidden;
}

.u-btn-teal.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-teal.u-btn-hover-v1-2::after, .u-btn-teal.u-btn-hover-v1-2:hover::after {
  background-color: #1bd1ae;
}

.u-btn-teal.u-btn-hover-v1-2:hover {
  background-color: #18ba9b;
  overflow: hidden;
}

.u-btn-teal.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-teal.u-btn-hover-v1-3::after, .u-btn-teal.u-btn-hover-v1-3:hover::after {
  background-color: #1bd1ae;
}

.u-btn-teal.u-btn-hover-v1-3:hover {
  background-color: #18ba9b;
  overflow: hidden;
}

.u-btn-teal.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-teal.u-btn-hover-v1-4::after, .u-btn-teal.u-btn-hover-v1-4:hover::after {
  background-color: #1bd1ae;
}

.u-btn-teal.u-btn-hover-v1-4:hover {
  background-color: #18ba9b;
  overflow: hidden;
}

.u-btn-teal.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-teal.u-btn-hover-v2-1::after, .u-btn-teal.u-btn-hover-v2-1:hover::after {
  background-color: #1bd1ae;
}

.u-btn-teal.u-btn-hover-v2-1:hover {
  background-color: #18ba9b;
  overflow: hidden;
}

.u-btn-teal.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-teal.u-btn-hover-v2-2::after, .u-btn-teal.u-btn-hover-v2-2:hover::after {
  background-color: #1bd1ae;
}

.u-btn-teal.u-btn-hover-v2-2:hover {
  background-color: #18ba9b;
  overflow: hidden;
}

/* Button Brown */
.u-btn-brown {
  color: #fff;
  background-color: #9c8061;
}

.u-btn-brown:hover, .u-btn-brown.active {
  border-color: #b09980;
  background-color: #b09980;
}

.u-btn-brown:hover, .u-btn-brown:focus, .u-btn-brown.active {
  color: #fff;
}

.u-btn-brown.g-btn-hover-reset:hover, .u-btn-brown.g-btn-hover-reset.active {
  background-color: #9c8061;
  border-color: #9c8061;
}

.u-btn-brown.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-brown.u-btn-hover-v1-1::after, .u-btn-brown.u-btn-hover-v1-1:hover::after {
  background-color: #a68d70;
}

.u-btn-brown.u-btn-hover-v1-1:hover {
  background-color: #9c8061;
  overflow: hidden;
}

.u-btn-brown.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-brown.u-btn-hover-v1-2::after, .u-btn-brown.u-btn-hover-v1-2:hover::after {
  background-color: #a68d70;
}

.u-btn-brown.u-btn-hover-v1-2:hover {
  background-color: #9c8061;
  overflow: hidden;
}

.u-btn-brown.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-brown.u-btn-hover-v1-3::after, .u-btn-brown.u-btn-hover-v1-3:hover::after {
  background-color: #a68d70;
}

.u-btn-brown.u-btn-hover-v1-3:hover {
  background-color: #9c8061;
  overflow: hidden;
}

.u-btn-brown.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-brown.u-btn-hover-v1-4::after, .u-btn-brown.u-btn-hover-v1-4:hover::after {
  background-color: #a68d70;
}

.u-btn-brown.u-btn-hover-v1-4:hover {
  background-color: #9c8061;
  overflow: hidden;
}

.u-btn-brown.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-brown.u-btn-hover-v2-1::after, .u-btn-brown.u-btn-hover-v2-1:hover::after {
  background-color: #a68d70;
}

.u-btn-brown.u-btn-hover-v2-1:hover {
  background-color: #9c8061;
  overflow: hidden;
}

.u-btn-brown.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-brown.u-btn-hover-v2-2::after, .u-btn-brown.u-btn-hover-v2-2:hover::after {
  background-color: #a68d70;
}

.u-btn-brown.u-btn-hover-v2-2:hover {
  background-color: #9c8061;
  overflow: hidden;
}

/* Button Bluegrey */
.u-btn-bluegray {
  color: #fff;
  background-color: #585f69;
}

.u-btn-bluegray:hover, .u-btn-bluegray.active {
  border-color: #6f7885;
  background-color: #6f7885;
}

.u-btn-bluegray:hover, .u-btn-bluegray:focus, .u-btn-bluegray.active {
  color: #fff;
}

.u-btn-bluegray.g-btn-hover-reset:hover, .u-btn-bluegray.g-btn-hover-reset.active {
  background-color: #585f69;
  border-color: #585f69;
}

.u-btn-bluegray.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-bluegray.u-btn-hover-v1-1::after, .u-btn-bluegray.u-btn-hover-v1-1:hover::after {
  background-color: #646c77;
}

.u-btn-bluegray.u-btn-hover-v1-1:hover {
  background-color: #585f69;
  overflow: hidden;
}

.u-btn-bluegray.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-bluegray.u-btn-hover-v1-2::after, .u-btn-bluegray.u-btn-hover-v1-2:hover::after {
  background-color: #646c77;
}

.u-btn-bluegray.u-btn-hover-v1-2:hover {
  background-color: #585f69;
  overflow: hidden;
}

.u-btn-bluegray.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-bluegray.u-btn-hover-v1-3::after, .u-btn-bluegray.u-btn-hover-v1-3:hover::after {
  background-color: #646c77;
}

.u-btn-bluegray.u-btn-hover-v1-3:hover {
  background-color: #585f69;
  overflow: hidden;
}

.u-btn-bluegray.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-bluegray.u-btn-hover-v1-4::after, .u-btn-bluegray.u-btn-hover-v1-4:hover::after {
  background-color: #646c77;
}

.u-btn-bluegray.u-btn-hover-v1-4:hover {
  background-color: #585f69;
  overflow: hidden;
}

.u-btn-bluegray.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-bluegray.u-btn-hover-v2-1::after, .u-btn-bluegray.u-btn-hover-v2-1:hover::after {
  background-color: #646c77;
}

.u-btn-bluegray.u-btn-hover-v2-1:hover {
  background-color: #585f69;
  overflow: hidden;
}

.u-btn-bluegray.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-bluegray.u-btn-hover-v2-2::after, .u-btn-bluegray.u-btn-hover-v2-2:hover::after {
  background-color: #646c77;
}

.u-btn-bluegray.u-btn-hover-v2-2:hover {
  background-color: #585f69;
  overflow: hidden;
}

/* Button Facebook */
.u-btn-facebook {
  color: #fff;
  background-color: #3b5998;
}

.u-btn-facebook:hover, .u-btn-facebook.active {
  border-color: #4c70ba;
  background-color: #4c70ba;
}

.u-btn-facebook:hover, .u-btn-facebook:focus, .u-btn-facebook.active {
  color: #fff;
}

.u-btn-facebook.g-btn-hover-reset:hover, .u-btn-facebook.g-btn-hover-reset.active {
  background-color: #3b5998;
  border-color: #3b5998;
}

/* Button Twitter */
.u-btn-twitter {
  color: #fff;
  background-color: #00acee;
}

.u-btn-twitter:hover, .u-btn-twitter.active {
  border-color: #22c2ff;
  background-color: #22c2ff;
}

.u-btn-twitter:hover, .u-btn-twitter:focus, .u-btn-twitter.active {
  color: #fff;
}

.u-btn-twitter.g-btn-hover-reset:hover, .u-btn-twitter.g-btn-hover-reset.active {
  background-color: #00acee;
  border-color: #00acee;
}

/* Button Instagram */
.u-btn-instagram {
  color: #fff;
  background-color: #3f729b;
}

.u-btn-instagram:hover, .u-btn-instagram.active {
  border-color: #548cb9;
  background-color: #548cb9;
}

.u-btn-instagram:hover, .u-btn-instagram:focus, .u-btn-instagram.active {
  color: #fff;
}

.u-btn-instagram.g-btn-hover-reset:hover, .u-btn-instagram.g-btn-hover-reset.active {
  background-color: #3f729b;
  border-color: #3f729b;
}

/* Button VK */
.u-btn-vk {
  color: #fff;
  background-color: #2b587a;
}

.u-btn-vk:hover, .u-btn-vk.active {
  border-color: #3873a0;
  background-color: #3873a0;
}

.u-btn-vk:hover, .u-btn-vk:focus, .u-btn-vk.active {
  color: #fff;
}

.u-btn-vk.g-btn-hover-reset:hover, .u-btn-vk.g-btn-hover-reset.active {
  background-color: #2b587a;
  border-color: #2b587a;
}

/* Button Google Plus */
.u-btn-google-plus {
  color: #fff;
  background-color: #dd4b39;
}

.u-btn-google-plus:hover, .u-btn-google-plus.active {
  border-color: #e47365;
  background-color: #e47365;
}

.u-btn-google-plus:hover, .u-btn-google-plus:focus, .u-btn-google-plus.active {
  color: #fff;
}

.u-btn-google-plus.g-btn-hover-reset:hover, .u-btn-google-plus.g-btn-hover-reset.active {
  background-color: #dd4b39;
  border-color: #dd4b39;
}

/* Outline Button Primary */
.u-btn-outline-primary {
  color: #2cb8f3;
  border-color: #2cb8f3;
  background-color: transparent;
}

.u-btn-outline-primary:focus, .u-btn-outline-primary.active {
  color: #fff;
  background-color: #2cb8f3;
}

.u-btn-outline-primary:hover {
  color: #fff;
  background-color: #2cb8f3;
}

.u-btn-outline-primary.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-primary.u-btn-hover-v1-1::after, .u-btn-outline-primary.u-btn-hover-v1-1:hover::after {
  background-color: #44c0f4;
}

.u-btn-outline-primary.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-primary.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-primary.u-btn-hover-v1-2::after, .u-btn-outline-primary.u-btn-hover-v1-2:hover::after {
  background-color: #44c0f4;
}

.u-btn-outline-primary.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-primary.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-primary.u-btn-hover-v1-3::after, .u-btn-outline-primary.u-btn-hover-v1-3:hover::after {
  background-color: #44c0f4;
}

.u-btn-outline-primary.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-primary.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-primary.u-btn-hover-v1-4::after, .u-btn-outline-primary.u-btn-hover-v1-4:hover::after {
  background-color: #44c0f4;
}

.u-btn-outline-primary.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-primary.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-primary.u-btn-hover-v2-1::after, .u-btn-outline-primary.u-btn-hover-v2-1:hover::after {
  background-color: #44c0f4;
}

.u-btn-outline-primary.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-primary.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-primary.u-btn-hover-v2-2::after, .u-btn-outline-primary.u-btn-hover-v2-2:hover::after {
  background-color: #44c0f4;
}

.u-btn-outline-primary.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-primary.u-btn-hover-v3-1 {
  overflow: hidden;
}

.u-btn-outline-primary.u-btn-hover-v3-1::after, .u-btn-outline-primary.u-btn-hover-v3-1:hover::after {
  background-color: #44c0f4;
}

.u-btn-outline-primary.u-btn-hover-v3-1:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button White */
.u-btn-outline-white {
  color: #fff;
  border-color: #fff;
  background-color: transparent;
}

.u-btn-outline-white:focus, .u-btn-outline-white.active {
  color: #555;
  background-color: #fff;
}

.u-btn-outline-white:hover {
  color: #555;
  background-color: #fff;
}

.u-btn-outline-white.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-white.u-btn-hover-v1-1::after, .u-btn-outline-white.u-btn-hover-v1-1:hover::after {
  background-color: white;
}

.u-btn-outline-white.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-white.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-white.u-btn-hover-v1-2::after, .u-btn-outline-white.u-btn-hover-v1-2:hover::after {
  background-color: white;
}

.u-btn-outline-white.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-white.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-white.u-btn-hover-v1-3::after, .u-btn-outline-white.u-btn-hover-v1-3:hover::after {
  background-color: white;
}

.u-btn-outline-white.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-white.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-white.u-btn-hover-v1-4::after, .u-btn-outline-white.u-btn-hover-v1-4:hover::after {
  background-color: white;
}

.u-btn-outline-white.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-white.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-white.u-btn-hover-v2-1::after, .u-btn-outline-white.u-btn-hover-v2-1:hover::after {
  background-color: white;
}

.u-btn-outline-white.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-white.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-white.u-btn-hover-v2-2::after, .u-btn-outline-white.u-btn-hover-v2-2:hover::after {
  background-color: white;
}

.u-btn-outline-white.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Black */
.u-btn-outline-black {
  color: #000;
  border-color: #000;
  background-color: transparent;
}

.u-btn-outline-black:focus, .u-btn-outline-black.active {
  color: #fff;
  background-color: #000;
}

.u-btn-outline-black:hover {
  color: #fff;
  background-color: #000;
}

.u-btn-outline-black.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-black.u-btn-hover-v1-1::after, .u-btn-outline-black.u-btn-hover-v1-1:hover::after {
  background-color: #0d0d0d;
}

.u-btn-outline-black.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-black.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-black.u-btn-hover-v1-2::after, .u-btn-outline-black.u-btn-hover-v1-2:hover::after {
  background-color: #0d0d0d;
}

.u-btn-outline-black.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-black.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-black.u-btn-hover-v1-3::after, .u-btn-outline-black.u-btn-hover-v1-3:hover::after {
  background-color: #0d0d0d;
}

.u-btn-outline-black.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-black.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-black.u-btn-hover-v1-4::after, .u-btn-outline-black.u-btn-hover-v1-4:hover::after {
  background-color: #0d0d0d;
}

.u-btn-outline-black.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-black.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-black.u-btn-hover-v2-1::after, .u-btn-outline-black.u-btn-hover-v2-1:hover::after {
  background-color: #0d0d0d;
}

.u-btn-outline-black.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-black.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-black.u-btn-hover-v2-2::after, .u-btn-outline-black.u-btn-hover-v2-2:hover::after {
  background-color: #0d0d0d;
}

.u-btn-outline-black.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Dark Gray */
.u-btn-outline-darkgray {
  color: #333;
  border-color: #333;
  background-color: transparent;
}

.u-btn-outline-darkgray:focus, .u-btn-outline-darkgray.active {
  color: #fff;
  background-color: #333;
}

.u-btn-outline-darkgray:hover {
  color: #fff;
  background-color: #333;
}

.u-btn-outline-darkgray.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-darkgray.u-btn-hover-v1-1::after, .u-btn-outline-darkgray.u-btn-hover-v1-1:hover::after {
  background-color: #404040;
}

.u-btn-outline-darkgray.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-darkgray.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-darkgray.u-btn-hover-v1-2::after, .u-btn-outline-darkgray.u-btn-hover-v1-2:hover::after {
  background-color: #404040;
}

.u-btn-outline-darkgray.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-darkgray.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-darkgray.u-btn-hover-v1-3::after, .u-btn-outline-darkgray.u-btn-hover-v1-3:hover::after {
  background-color: #404040;
}

.u-btn-outline-darkgray.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-darkgray.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-darkgray.u-btn-hover-v1-4::after, .u-btn-outline-darkgray.u-btn-hover-v1-4:hover::after {
  background-color: #404040;
}

.u-btn-outline-darkgray.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-darkgray.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-darkgray.u-btn-hover-v2-1::after, .u-btn-outline-darkgray.u-btn-hover-v2-1:hover::after {
  background-color: #404040;
}

.u-btn-outline-darkgray.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-darkgray.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-darkgray.u-btn-hover-v2-2::after, .u-btn-outline-darkgray.u-btn-hover-v2-2:hover::after {
  background-color: #404040;
}

.u-btn-outline-darkgray.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Light Gray */
.u-btn-outline-lightgray {
  color: #555;
  border-color: #eee;
  background-color: transparent;
}

.u-btn-outline-lightgray:focus, .u-btn-outline-lightgray.active {
  color: #555;
  background-color: #eee;
}

.u-btn-outline-lightgray:hover {
  color: #555;
  background-color: #eee;
}

.u-btn-outline-lightgray.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-lightgray.u-btn-hover-v1-1::after, .u-btn-outline-lightgray.u-btn-hover-v1-1:hover::after {
  background-color: #fbfbfb;
}

.u-btn-outline-lightgray.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-lightgray.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-lightgray.u-btn-hover-v1-2::after, .u-btn-outline-lightgray.u-btn-hover-v1-2:hover::after {
  background-color: #fbfbfb;
}

.u-btn-outline-lightgray.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-lightgray.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-lightgray.u-btn-hover-v1-3::after, .u-btn-outline-lightgray.u-btn-hover-v1-3:hover::after {
  background-color: #fbfbfb;
}

.u-btn-outline-lightgray.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-lightgray.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-lightgray.u-btn-hover-v1-4::after, .u-btn-outline-lightgray.u-btn-hover-v1-4:hover::after {
  background-color: #fbfbfb;
}

.u-btn-outline-lightgray.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-lightgray.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-lightgray.u-btn-hover-v2-1::after, .u-btn-outline-lightgray.u-btn-hover-v2-1:hover::after {
  background-color: #fbfbfb;
}

.u-btn-outline-lightgray.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-lightgray.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-lightgray.u-btn-hover-v2-2::after, .u-btn-outline-lightgray.u-btn-hover-v2-2:hover::after {
  background-color: #fbfbfb;
}

.u-btn-outline-lightgray.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Button Outline Red */
.u-btn-outline-red {
  color: #f00;
  border-color: #f00;
  background-color: transparent;
}

.u-btn-outline-red:focus, .u-btn-outline-red.active {
  color: #fff;
  background-color: #f00;
}

.u-btn-outline-red:hover {
  color: #fff;
  background-color: #f00;
}

.u-btn-outline-red.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-red.u-btn-hover-v1-1::after, .u-btn-outline-red.u-btn-hover-v1-1:hover::after {
  background-color: #ff1a1a;
}

.u-btn-outline-red.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-red.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-red.u-btn-hover-v1-2::after, .u-btn-outline-red.u-btn-hover-v1-2:hover::after {
  background-color: #ff1a1a;
}

.u-btn-outline-red.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-red.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-red.u-btn-hover-v1-3::after, .u-btn-outline-red.u-btn-hover-v1-3:hover::after {
  background-color: #ff1a1a;
}

.u-btn-outline-red.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-red.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-red.u-btn-hover-v1-4::after, .u-btn-outline-red.u-btn-hover-v1-4:hover::after {
  background-color: #ff1a1a;
}

.u-btn-outline-red.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-red.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-red.u-btn-hover-v2-1::after, .u-btn-outline-red.u-btn-hover-v2-1:hover::after {
  background-color: #ff1a1a;
}

.u-btn-outline-red.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-red.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-red.u-btn-hover-v2-2::after, .u-btn-outline-red.u-btn-hover-v2-2:hover::after {
  background-color: #ff1a1a;
}

.u-btn-outline-red.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Button Outline Red Tomato */
.u-btn-outline-lightred {
  color: #e64b3b;
  border-color: #e64b3b;
  background-color: transparent;
}

.u-btn-outline-lightred:focus, .u-btn-outline-lightred.active {
  color: #fff;
  background-color: #e64b3b;
}

.u-btn-outline-lightred:hover {
  color: #fff;
  background-color: #e64b3b;
}

.u-btn-outline-lightred.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-lightred.u-btn-hover-v1-1::after, .u-btn-outline-lightred.u-btn-hover-v1-1:hover::after {
  background-color: #e96052;
}

.u-btn-outline-lightred.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-lightred.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-lightred.u-btn-hover-v1-2::after, .u-btn-outline-lightred.u-btn-hover-v1-2:hover::after {
  background-color: #e96052;
}

.u-btn-outline-lightred.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-lightred.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-lightred.u-btn-hover-v1-3::after, .u-btn-outline-lightred.u-btn-hover-v1-3:hover::after {
  background-color: #e96052;
}

.u-btn-outline-lightred.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-lightred.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-lightred.u-btn-hover-v1-4::after, .u-btn-outline-lightred.u-btn-hover-v1-4:hover::after {
  background-color: #e96052;
}

.u-btn-outline-lightred.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-lightred.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-lightred.u-btn-hover-v2-1::after, .u-btn-outline-lightred.u-btn-hover-v2-1:hover::after {
  background-color: #e96052;
}

.u-btn-outline-lightred.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-lightred.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-lightred.u-btn-hover-v2-2::after, .u-btn-outline-lightred.u-btn-hover-v2-2:hover::after {
  background-color: #e96052;
}

.u-btn-outline-lightred.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Dark Red */
.u-btn-outline-darkred {
  color: #a10f2b;
  border-color: #a10f2b;
  background-color: transparent;
}

.u-btn-outline-darkred:focus, .u-btn-outline-darkred.active {
  color: #fff;
  background-color: #a10f2b;
}

.u-btn-outline-darkred:hover {
  color: #fff;
  background-color: #a10f2b;
}

.u-btn-outline-darkred.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-darkred.u-btn-hover-v1-1::after, .u-btn-outline-darkred.u-btn-hover-v1-1:hover::after {
  background-color: #b81131;
}

.u-btn-outline-darkred.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-darkred.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-darkred.u-btn-hover-v1-2::after, .u-btn-outline-darkred.u-btn-hover-v1-2:hover::after {
  background-color: #b81131;
}

.u-btn-outline-darkred.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-darkred.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-darkred.u-btn-hover-v1-3::after, .u-btn-outline-darkred.u-btn-hover-v1-3:hover::after {
  background-color: #b81131;
}

.u-btn-outline-darkred.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-darkred.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-darkred.u-btn-hover-v1-4::after, .u-btn-outline-darkred.u-btn-hover-v1-4:hover::after {
  background-color: #b81131;
}

.u-btn-outline-darkred.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-darkred.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-darkred.u-btn-hover-v2-1::after, .u-btn-outline-darkred.u-btn-hover-v2-1:hover::after {
  background-color: #b81131;
}

.u-btn-outline-darkred.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-darkred.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-darkred.u-btn-hover-v2-2::after, .u-btn-outline-darkred.u-btn-hover-v2-2:hover::after {
  background-color: #b81131;
}

.u-btn-outline-darkred.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Blue */
.u-btn-outline-blue {
  color: #3398dc;
  border-color: #3398dc;
  background-color: transparent;
}

.u-btn-outline-blue:focus, .u-btn-outline-blue.active {
  color: #fff;
  background-color: #3398dc;
}

.u-btn-outline-blue:hover {
  color: #fff;
  background-color: #3398dc;
}

.u-btn-outline-blue.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-blue.u-btn-hover-v1-1::after, .u-btn-outline-blue.u-btn-hover-v1-1:hover::after {
  background-color: #49a3e0;
}

.u-btn-outline-blue.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-blue.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-blue.u-btn-hover-v1-2::after, .u-btn-outline-blue.u-btn-hover-v1-2:hover::after {
  background-color: #49a3e0;
}

.u-btn-outline-blue.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-blue.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-blue.u-btn-hover-v1-3::after, .u-btn-outline-blue.u-btn-hover-v1-3:hover::after {
  background-color: #49a3e0;
}

.u-btn-outline-blue.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-blue.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-blue.u-btn-hover-v1-4::after, .u-btn-outline-blue.u-btn-hover-v1-4:hover::after {
  background-color: #49a3e0;
}

.u-btn-outline-blue.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-blue.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-blue.u-btn-hover-v2-1::after, .u-btn-outline-blue.u-btn-hover-v2-1:hover::after {
  background-color: #49a3e0;
}

.u-btn-outline-blue.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-blue.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-blue.u-btn-hover-v2-2::after, .u-btn-outline-blue.u-btn-hover-v2-2:hover::after {
  background-color: #49a3e0;
}

.u-btn-outline-blue.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Indigo */
.u-btn-outline-indigo {
  color: #4263a3;
  border-color: #4263a3;
  background-color: transparent;
}

.u-btn-outline-indigo:focus, .u-btn-outline-indigo.active {
  color: #fff;
  background-color: #4263a3;
}

.u-btn-outline-indigo:hover {
  color: #fff;
  background-color: #4263a3;
}

.u-btn-outline-indigo.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-indigo.u-btn-hover-v1-1::after, .u-btn-outline-indigo.u-btn-hover-v1-1:hover::after {
  background-color: #496eb5;
}

.u-btn-outline-indigo.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-indigo.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-indigo.u-btn-hover-v1-2::after, .u-btn-outline-indigo.u-btn-hover-v1-2:hover::after {
  background-color: #496eb5;
}

.u-btn-outline-indigo.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-indigo.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-indigo.u-btn-hover-v1-3::after, .u-btn-outline-indigo.u-btn-hover-v1-3:hover::after {
  background-color: #496eb5;
}

.u-btn-outline-indigo.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-indigo.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-indigo.u-btn-hover-v1-4::after, .u-btn-outline-indigo.u-btn-hover-v1-4:hover::after {
  background-color: #496eb5;
}

.u-btn-outline-indigo.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-indigo.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-indigo.u-btn-hover-v2-1::after, .u-btn-outline-indigo.u-btn-hover-v2-1:hover::after {
  background-color: #496eb5;
}

.u-btn-outline-indigo.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-indigo.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-indigo.u-btn-hover-v2-2::after, .u-btn-outline-indigo.u-btn-hover-v2-2:hover::after {
  background-color: #496eb5;
}

.u-btn-outline-indigo.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Purple */
.u-btn-outline-purple {
  color: #9a69cb;
  border-color: #9a69cb;
  background-color: transparent;
}

.u-btn-outline-purple:focus, .u-btn-outline-purple.active {
  color: #fff;
  background-color: #9a69cb;
}

.u-btn-outline-purple:hover {
  color: #fff;
  background-color: #9a69cb;
}

.u-btn-outline-purple.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-purple.u-btn-hover-v1-1::after, .u-btn-outline-purple.u-btn-hover-v1-1:hover::after {
  background-color: #a77cd2;
}

.u-btn-outline-purple.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-purple.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-purple.u-btn-hover-v1-2::after, .u-btn-outline-purple.u-btn-hover-v1-2:hover::after {
  background-color: #a77cd2;
}

.u-btn-outline-purple.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-purple.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-purple.u-btn-hover-v1-3::after, .u-btn-outline-purple.u-btn-hover-v1-3:hover::after {
  background-color: #a77cd2;
}

.u-btn-outline-purple.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-purple.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-purple.u-btn-hover-v1-4::after, .u-btn-outline-purple.u-btn-hover-v1-4:hover::after {
  background-color: #a77cd2;
}

.u-btn-outline-purple.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-purple.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-purple.u-btn-hover-v2-1::after, .u-btn-outline-purple.u-btn-hover-v2-1:hover::after {
  background-color: #a77cd2;
}

.u-btn-outline-purple.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-purple.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-purple.u-btn-hover-v2-2::after, .u-btn-outline-purple.u-btn-hover-v2-2:hover::after {
  background-color: #a77cd2;
}

.u-btn-outline-purple.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Dark Purple */
.u-btn-outline-darkpurple {
  color: #6639b6;
  border-color: #6639b6;
  background-color: transparent;
}

.u-btn-outline-darkpurple:focus, .u-btn-outline-darkpurple.active {
  color: #fff;
  background-color: #6639b6;
}

.u-btn-outline-darkpurple:hover {
  color: #fff;
  background-color: #6639b6;
}

.u-btn-outline-darkpurple.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-darkpurple.u-btn-hover-v1-1::after, .u-btn-outline-darkpurple.u-btn-hover-v1-1:hover::after {
  background-color: #7244c4;
}

.u-btn-outline-darkpurple.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-darkpurple.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-darkpurple.u-btn-hover-v1-2::after, .u-btn-outline-darkpurple.u-btn-hover-v1-2:hover::after {
  background-color: #7244c4;
}

.u-btn-outline-darkpurple.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-darkpurple.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-darkpurple.u-btn-hover-v1-3::after, .u-btn-outline-darkpurple.u-btn-hover-v1-3:hover::after {
  background-color: #7244c4;
}

.u-btn-outline-darkpurple.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-darkpurple.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-darkpurple.u-btn-hover-v1-4::after, .u-btn-outline-darkpurple.u-btn-hover-v1-4:hover::after {
  background-color: #7244c4;
}

.u-btn-outline-darkpurple.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-darkpurple.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-darkpurple.u-btn-hover-v2-1::after, .u-btn-outline-darkpurple.u-btn-hover-v2-1:hover::after {
  background-color: #7244c4;
}

.u-btn-outline-darkpurple.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-darkpurple.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-darkpurple.u-btn-hover-v2-2::after, .u-btn-outline-darkpurple.u-btn-hover-v2-2:hover::after {
  background-color: #7244c4;
}

.u-btn-outline-darkpurple.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Pink */
.u-btn-outline-pink {
  color: #e81c62;
  border-color: #e81c62;
  background-color: transparent;
}

.u-btn-outline-pink:focus, .u-btn-outline-pink.active {
  color: #fff;
  background-color: #e81c62;
}

.u-btn-outline-pink:hover {
  color: #fff;
  background-color: #e81c62;
}

.u-btn-outline-pink.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-pink.u-btn-hover-v1-1::after, .u-btn-outline-pink.u-btn-hover-v1-1:hover::after {
  background-color: #ea3372;
}

.u-btn-outline-pink.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-pink.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-pink.u-btn-hover-v1-2::after, .u-btn-outline-pink.u-btn-hover-v1-2:hover::after {
  background-color: #ea3372;
}

.u-btn-outline-pink.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-pink.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-pink.u-btn-hover-v1-3::after, .u-btn-outline-pink.u-btn-hover-v1-3:hover::after {
  background-color: #ea3372;
}

.u-btn-outline-pink.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-pink.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-pink.u-btn-hover-v1-4::after, .u-btn-outline-pink.u-btn-hover-v1-4:hover::after {
  background-color: #ea3372;
}

.u-btn-outline-pink.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-pink.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-pink.u-btn-hover-v2-1::after, .u-btn-outline-pink.u-btn-hover-v2-1:hover::after {
  background-color: #ea3372;
}

.u-btn-outline-pink.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-pink.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-pink.u-btn-hover-v2-2::after, .u-btn-outline-pink.u-btn-hover-v2-2:hover::after {
  background-color: #ea3372;
}

.u-btn-outline-pink.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Orange */
.u-btn-outline-orange {
  color: #e57d20;
  border-color: #e57d20;
  background-color: transparent;
}

.u-btn-outline-orange:focus, .u-btn-outline-orange.active {
  color: #fff;
  background-color: #e57d20;
}

.u-btn-outline-orange:hover {
  color: #fff;
  background-color: #e57d20;
}

.u-btn-outline-orange.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-orange.u-btn-hover-v1-1::after, .u-btn-outline-orange.u-btn-hover-v1-1:hover::after {
  background-color: #e88a37;
}

.u-btn-outline-orange.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-orange.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-orange.u-btn-hover-v1-2::after, .u-btn-outline-orange.u-btn-hover-v1-2:hover::after {
  background-color: #e88a37;
}

.u-btn-outline-orange.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-orange.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-orange.u-btn-hover-v1-3::after, .u-btn-outline-orange.u-btn-hover-v1-3:hover::after {
  background-color: #e88a37;
}

.u-btn-outline-orange.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-orange.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-orange.u-btn-hover-v1-4::after, .u-btn-outline-orange.u-btn-hover-v1-4:hover::after {
  background-color: #e88a37;
}

.u-btn-outline-orange.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-orange.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-orange.u-btn-hover-v2-1::after, .u-btn-outline-orange.u-btn-hover-v2-1:hover::after {
  background-color: #e88a37;
}

.u-btn-outline-orange.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-orange.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-orange.u-btn-hover-v2-2::after, .u-btn-outline-orange.u-btn-hover-v2-2:hover::after {
  background-color: #e88a37;
}

.u-btn-outline-orange.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Deep Orange */
.u-btn-outline-deeporange {
  color: #fe541e;
  border-color: #fe541e;
  background-color: transparent;
}

.u-btn-outline-deeporange:focus, .u-btn-outline-deeporange.active {
  color: #fff;
  background-color: #fe541e;
}

.u-btn-outline-deeporange:hover {
  color: #fff;
  background-color: #fe541e;
}

.u-btn-outline-deeporange.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-deeporange.u-btn-hover-v1-1::after, .u-btn-outline-deeporange.u-btn-hover-v1-1:hover::after {
  background-color: #fe6737;
}

.u-btn-outline-deeporange.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-deeporange.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-deeporange.u-btn-hover-v1-2::after, .u-btn-outline-deeporange.u-btn-hover-v1-2:hover::after {
  background-color: #fe6737;
}

.u-btn-outline-deeporange.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-deeporange.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-deeporange.u-btn-hover-v1-3::after, .u-btn-outline-deeporange.u-btn-hover-v1-3:hover::after {
  background-color: #fe6737;
}

.u-btn-outline-deeporange.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-deeporange.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-deeporange.u-btn-hover-v1-4::after, .u-btn-outline-deeporange.u-btn-hover-v1-4:hover::after {
  background-color: #fe6737;
}

.u-btn-outline-deeporange.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-deeporange.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-deeporange.u-btn-hover-v2-1::after, .u-btn-outline-deeporange.u-btn-hover-v2-1:hover::after {
  background-color: #fe6737;
}

.u-btn-outline-deeporange.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-deeporange.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-deeporange.u-btn-hover-v2-2::after, .u-btn-outline-deeporange.u-btn-hover-v2-2:hover::after {
  background-color: #fe6737;
}

.u-btn-outline-deeporange.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Yellow */
.u-btn-outline-yellow {
  color: #555;
  border-color: #ebc71d;
  background-color: transparent;
}

.u-btn-outline-yellow:focus, .u-btn-outline-yellow.active {
  color: #555;
  background-color: #ebc71d;
}

.u-btn-outline-yellow:hover {
  color: #555;
  background-color: #ebc71d;
}

.u-btn-outline-yellow.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-yellow.u-btn-hover-v1-1::after, .u-btn-outline-yellow.u-btn-hover-v1-1:hover::after {
  background-color: #edcd34;
}

.u-btn-outline-yellow.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-yellow.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-yellow.u-btn-hover-v1-2::after, .u-btn-outline-yellow.u-btn-hover-v1-2:hover::after {
  background-color: #edcd34;
}

.u-btn-outline-yellow.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-yellow.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-yellow.u-btn-hover-v1-3::after, .u-btn-outline-yellow.u-btn-hover-v1-3:hover::after {
  background-color: #edcd34;
}

.u-btn-outline-yellow.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-yellow.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-yellow.u-btn-hover-v1-4::after, .u-btn-outline-yellow.u-btn-hover-v1-4:hover::after {
  background-color: #edcd34;
}

.u-btn-outline-yellow.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-yellow.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-yellow.u-btn-hover-v2-1::after, .u-btn-outline-yellow.u-btn-hover-v2-1:hover::after {
  background-color: #edcd34;
}

.u-btn-outline-yellow.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-yellow.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-yellow.u-btn-hover-v2-2::after, .u-btn-outline-yellow.u-btn-hover-v2-2:hover::after {
  background-color: #edcd34;
}

.u-btn-outline-yellow.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Aqua */
.u-btn-outline-aqua {
  color: #29d6e6;
  border-color: #29d6e6;
  background-color: transparent;
}

.u-btn-outline-aqua:focus, .u-btn-outline-aqua.active {
  color: #fff;
  background-color: #29d6e6;
}

.u-btn-outline-aqua:hover {
  color: #fff;
  background-color: #29d6e6;
}

.u-btn-outline-aqua.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-aqua.u-btn-hover-v1-1::after, .u-btn-outline-aqua.u-btn-hover-v1-1:hover::after {
  background-color: #40dae9;
}

.u-btn-outline-aqua.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-aqua.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-aqua.u-btn-hover-v1-2::after, .u-btn-outline-aqua.u-btn-hover-v1-2:hover::after {
  background-color: #40dae9;
}

.u-btn-outline-aqua.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-aqua.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-aqua.u-btn-hover-v1-3::after, .u-btn-outline-aqua.u-btn-hover-v1-3:hover::after {
  background-color: #40dae9;
}

.u-btn-outline-aqua.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-aqua.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-aqua.u-btn-hover-v1-4::after, .u-btn-outline-aqua.u-btn-hover-v1-4:hover::after {
  background-color: #40dae9;
}

.u-btn-outline-aqua.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-aqua.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-aqua.u-btn-hover-v2-1::after, .u-btn-outline-aqua.u-btn-hover-v2-1:hover::after {
  background-color: #40dae9;
}

.u-btn-outline-aqua.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-aqua.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-aqua.u-btn-hover-v2-2::after, .u-btn-outline-aqua.u-btn-hover-v2-2:hover::after {
  background-color: #40dae9;
}

.u-btn-outline-aqua.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Cyan */
.u-btn-outline-cyan {
  color: #00bed6;
  border-color: #00bed6;
  background-color: transparent;
}

.u-btn-outline-cyan:focus, .u-btn-outline-cyan.active {
  color: #fff;
  background-color: #00bed6;
}

.u-btn-outline-cyan:hover {
  color: #fff;
  background-color: #00bed6;
}

.u-btn-outline-cyan.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-cyan.u-btn-hover-v1-1::after, .u-btn-outline-cyan.u-btn-hover-v1-1:hover::after {
  background-color: #00d5f0;
}

.u-btn-outline-cyan.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-cyan.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-cyan.u-btn-hover-v1-2::after, .u-btn-outline-cyan.u-btn-hover-v1-2:hover::after {
  background-color: #00d5f0;
}

.u-btn-outline-cyan.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-cyan.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-cyan.u-btn-hover-v1-3::after, .u-btn-outline-cyan.u-btn-hover-v1-3:hover::after {
  background-color: #00d5f0;
}

.u-btn-outline-cyan.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-cyan.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-cyan.u-btn-hover-v1-4::after, .u-btn-outline-cyan.u-btn-hover-v1-4:hover::after {
  background-color: #00d5f0;
}

.u-btn-outline-cyan.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-cyan.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-cyan.u-btn-hover-v2-1::after, .u-btn-outline-cyan.u-btn-hover-v2-1:hover::after {
  background-color: #00d5f0;
}

.u-btn-outline-cyan.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-cyan.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-cyan.u-btn-hover-v2-2::after, .u-btn-outline-cyan.u-btn-hover-v2-2:hover::after {
  background-color: #00d5f0;
}

.u-btn-outline-cyan.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Teal */
.u-btn-outline-teal {
  color: #18ba9b;
  border-color: #18ba9b;
  background-color: transparent;
}

.u-btn-outline-teal:focus, .u-btn-outline-teal.active {
  color: #fff;
  background-color: #18ba9b;
}

.u-btn-outline-teal:hover {
  color: #fff;
  background-color: #18ba9b;
}

.u-btn-outline-teal.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-teal.u-btn-hover-v1-1::after, .u-btn-outline-teal.u-btn-hover-v1-1:hover::after {
  background-color: #1bd1ae;
}

.u-btn-outline-teal.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-teal.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-teal.u-btn-hover-v1-2::after, .u-btn-outline-teal.u-btn-hover-v1-2:hover::after {
  background-color: #1bd1ae;
}

.u-btn-outline-teal.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-teal.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-teal.u-btn-hover-v1-3::after, .u-btn-outline-teal.u-btn-hover-v1-3:hover::after {
  background-color: #1bd1ae;
}

.u-btn-outline-teal.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-teal.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-teal.u-btn-hover-v1-4::after, .u-btn-outline-teal.u-btn-hover-v1-4:hover::after {
  background-color: #1bd1ae;
}

.u-btn-outline-teal.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-teal.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-teal.u-btn-hover-v2-1::after, .u-btn-outline-teal.u-btn-hover-v2-1:hover::after {
  background-color: #1bd1ae;
}

.u-btn-outline-teal.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-teal.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-teal.u-btn-hover-v2-2::after, .u-btn-outline-teal.u-btn-hover-v2-2:hover::after {
  background-color: #1bd1ae;
}

.u-btn-outline-teal.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Brown */
.u-btn-outline-brown {
  color: #9c8061;
  border-color: #9c8061;
  background-color: transparent;
}

.u-btn-outline-brown:focus, .u-btn-outline-brown.active {
  color: #fff;
  background-color: #9c8061;
}

.u-btn-outline-brown:hover {
  color: #fff;
  background-color: #9c8061;
}

.u-btn-outline-brown.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-brown.u-btn-hover-v1-1::after, .u-btn-outline-brown.u-btn-hover-v1-1:hover::after {
  background-color: #a68d70;
}

.u-btn-outline-brown.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-brown.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-brown.u-btn-hover-v1-2::after, .u-btn-outline-brown.u-btn-hover-v1-2:hover::after {
  background-color: #a68d70;
}

.u-btn-outline-brown.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-brown.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-brown.u-btn-hover-v1-3::after, .u-btn-outline-brown.u-btn-hover-v1-3:hover::after {
  background-color: #a68d70;
}

.u-btn-outline-brown.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-brown.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-brown.u-btn-hover-v1-4::after, .u-btn-outline-brown.u-btn-hover-v1-4:hover::after {
  background-color: #a68d70;
}

.u-btn-outline-brown.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-brown.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-brown.u-btn-hover-v2-1::after, .u-btn-outline-brown.u-btn-hover-v2-1:hover::after {
  background-color: #a68d70;
}

.u-btn-outline-brown.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-brown.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-brown.u-btn-hover-v2-2::after, .u-btn-outline-brown.u-btn-hover-v2-2:hover::after {
  background-color: #a68d70;
}

.u-btn-outline-brown.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Bluegrey */
.u-btn-outline-bluegray {
  color: #585f69;
  border-color: #585f69;
  background-color: transparent;
}

.u-btn-outline-bluegray:focus, .u-btn-outline-bluegray.active {
  color: #fff;
  background-color: #585f69;
}

.u-btn-outline-bluegray:hover {
  color: #fff;
  background-color: #585f69;
}

.u-btn-outline-bluegray.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-bluegray.u-btn-hover-v1-1::after, .u-btn-outline-bluegray.u-btn-hover-v1-1:hover::after {
  background-color: #646c77;
}

.u-btn-outline-bluegray.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-bluegray.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-bluegray.u-btn-hover-v1-2::after, .u-btn-outline-bluegray.u-btn-hover-v1-2:hover::after {
  background-color: #646c77;
}

.u-btn-outline-bluegray.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-bluegray.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-bluegray.u-btn-hover-v1-3::after, .u-btn-outline-bluegray.u-btn-hover-v1-3:hover::after {
  background-color: #646c77;
}

.u-btn-outline-bluegray.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-bluegray.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-bluegray.u-btn-hover-v1-4::after, .u-btn-outline-bluegray.u-btn-hover-v1-4:hover::after {
  background-color: #646c77;
}

.u-btn-outline-bluegray.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-bluegray.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-bluegray.u-btn-hover-v2-1::after, .u-btn-outline-bluegray.u-btn-hover-v2-1:hover::after {
  background-color: #646c77;
}

.u-btn-outline-bluegray.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-bluegray.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-bluegray.u-btn-hover-v2-2::after, .u-btn-outline-bluegray.u-btn-hover-v2-2:hover::after {
  background-color: #646c77;
}

.u-btn-outline-bluegray.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Button Facebook */
.u-btn-outline-facebook {
  color: #3b5998;
  border-color: #3b5998;
  background-color: transparent;
}

.u-btn-outline-facebook:focus, .u-btn-outline-facebook.active {
  color: #fff;
  background-color: #3b5998;
}

.u-btn-outline-facebook:hover {
  color: #fff;
  background-color: #3b5998;
}

/* Button Twitter */
.u-btn-outline-twitter {
  color: #00acee;
  border-color: #00acee;
  background-color: transparent;
}

.u-btn-outline-twitter:focus, .u-btn-outline-twitter.active {
  color: #fff;
  background-color: #00acee;
}

.u-btn-outline-twitter:hover {
  color: #fff;
  background-color: #00acee;
}

/* Button Dribbble */
.u-btn-outline-dribbble {
  color: #ea4c89;
  border-color: #ea4c89;
  background-color: transparent;
}

.u-btn-outline-dribbble:focus, .u-btn-outline-dribbble.active {
  color: #fff;
  background-color: #ea4c89;
}

.u-btn-outline-dribbble:hover {
  color: #fff;
  background-color: #ea4c89;
}

/* Gradient Style v1 */
.u-btn-gradient-v1 {
  color: #fff;
  background: linear-gradient(to right, #4776E6 0%, #8E54E9 100%) !important;
  border-image: linear-gradient(to right, #4776E6 0%, #8E54E9 100%);
  border-image-slice: 1;
}

.u-btn-gradient-v1:hover, .u-btn-gradient-v1:focus, .u-btn-gradient-v1.active {
  opacity: .9;
  color: #fff;
}

/* Gradient Style v2 */
.u-btn-gradient-v2 {
  color: #fff;
  background: linear-gradient(to right, #E040FB 0%, #00BCD4 100%) !important;
  border-image: linear-gradient(to right, #E040FB 0%, #00BCD4 100%);
  border-image-slice: 1;
}

.u-btn-gradient-v2:hover, .u-btn-gradient-v2:focus, .u-btn-gradient-v2.active {
  opacity: .9;
  color: #fff;
}

/* Gradient Style v1 */
.u-btn-outline-gradient-v1 {
  color: #555;
  background: transparent;
  border-image: linear-gradient(to right, #4776E6 0%, #8E54E9 100%);
  border-image-slice: 1;
}

.u-btn-outline-gradient-v1:hover, .u-btn-outline-gradient-v1:focus, .u-btn-outline-gradient-v1.active {
  background: linear-gradient(to right, #4776E6 0%, #8E54E9 100%);
  color: #fff;
}

/* Gradient Style v2 */
.u-btn-outline-gradient-v2 {
  color: #555;
  background: transparent;
  border-image: linear-gradient(to right, #E040FB 0%, #00BCD4 100%);
  border-image-slice: 1;
}

.u-btn-outline-gradient-v2:hover, .u-btn-outline-gradient-v2:focus, .u-btn-outline-gradient-v2.active {
  background: linear-gradient(to right, #E040FB 0%, #00BCD4 100%);
  color: #fff;
}

/* Material Styles
------------------------------------*/
.u-btn-raised,
.u-btn-raised:active:focus,
.u-btn-raised:focus {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

/*------------------------------------
  Material: Floating
------------------------------------*/
.u-btn-floating {
  width: 2.85714rem;
  height: 2.85714rem;
  padding: 0;
  border-radius: 50%;
  vertical-align: middle;
  overflow: hidden;
  line-height: 2.85714rem;
}

.u-btn-floating i {
  font-size: 1.6rem;
  line-height: 2.71429rem;
}

.u-btn-floating.u-halfway-fab {
  position: absolute;
  right: 1.71429rem;
  bottom: -1.42857rem;
}

.u-btn-floating.u-halfway-fab--left {
  right: auto;
  left: 1.71429rem;
}

.u-btn-floating--large {
  width: 4rem;
  height: 4rem;
  line-height: 4rem;
}

.u-btn-floating--large i {
  font-size: 1.6rem;
  line-height: 3.85714rem;
}

.u-btn-floating--large.u-halfway-fab {
  bottom: -2rem;
}

button.u-btn-floating {
  border: none;
}

/*------------------------------------
  Material: Flat
------------------------------------*/
.u-btn-flat {
  color: #555;
  background-color: transparent;
}

.u-btn-flat:hover {
  background-color: rgba(221, 221, 221, 0.4);
}

.u-btn-flat:hover, .u-btn-flat:focus {
  color: #555;
}

/*------------------------------------
  Icons Styles
------------------------------------*/
.u-icon-v1,
.u-icon-v2,
.u-icon-v3,
.u-icon-v4 {
  position: relative;
  display: inline-block;
  text-align: center;
  transition: all .2s ease-in-out;
}

.u-icon-v1:hover,
.u-icon-v2:hover,
.u-icon-v3:hover,
.u-icon-v4:hover {
  text-decoration: none;
}

.u-icon-v1::before,
.u-icon-v2::before,
.u-icon-v3::before {
  display: block;
}

.u-icon-v1 > i,
.u-icon-v2 > i,
.u-icon-v3 > i,
.u-icon-v4 > span > i {
  position: relative;
  top: 50%;
  display: block;
  transform: translateY(-50%);
  z-index: 2;
}

[class*="u-icon-v"] .u-line-icon-pro {
  transform: translateY(-45%);
}

.u-icon-rotation {
  transform: rotate(45deg);
}

.u-icon-rotation .u-icon__elem {
  transform: rotate(-45deg) translate(15%, -30%);
}

.u-icon-rotation .u-line-icon-pro {
  transform: rotate(-45deg) translate(25%, -30%);
}

/*------------------------------------
  Icon-v1
------------------------------------*/
.u-icon-v1,
.u-icon-v1 .u-icon__elem-regular,
.u-icon-v1 .u-icon__elem-hover {
  width: 2.57143rem;
  height: 2.57143rem;
  font-size: 1.42857rem;
}

.u-icon-v1.u-icon-size--xs,
.u-icon-v1.u-icon-size--xs .u-icon__elem-regular,
.u-icon-v1.u-icon-size--xs .u-icon__elem-hover {
  width: 1.64286rem;
  height: 1.64286rem;
  font-size: 0.92857rem;
}

.u-icon-v1.u-icon-size--sm,
.u-icon-v1.u-icon-size--sm .u-icon__elem-regular,
.u-icon-v1.u-icon-size--sm .u-icon__elem-hover {
  width: 2.28571rem;
  height: 2.28571rem;
  font-size: 1.28571rem;
}

.u-icon-v1.u-icon-size--lg,
.u-icon-v1.u-icon-size--lg .u-icon__elem-regular,
.u-icon-v1.u-icon-size--lg .u-icon__elem-hover {
  width: 4.5rem;
  height: 4.5rem;
  font-size: 2.5rem;
}

.u-icon-v1.u-icon-size--xl,
.u-icon-v1.u-icon-size--xl .u-icon__elem-regular,
.u-icon-v1.u-icon-size--xl .u-icon__elem-hover {
  width: 5.14286rem;
  height: 5.14286rem;
  font-size: 2.85714rem;
}

.u-icon-v1.u-icon-size--2xl,
.u-icon-v1.u-icon-size--2xl .u-icon__elem-regular,
.u-icon-v1.u-icon-size--2xl .u-icon__elem-hover {
  width: 5.85714rem;
  height: 5.85714rem;
  font-size: 3.57143rem;
}

.u-icon-v1.u-icon-size--3xl,
.u-icon-v1.u-icon-size--3xl .u-icon__elem-regular,
.u-icon-v1.u-icon-size--3xl .u-icon__elem-hover {
  width: 8rem;
  height: 8rem;
  font-size: 5.71429rem;
}

/*------------------------------------
  Icon-v2
------------------------------------*/
.u-icon-v2, .u-icon-v2 .u-icon__elem-regular,
.u-icon-v2 .u-icon__elem-hover, .u-icon-v3, .u-icon-v3 .u-icon__elem-regular,
.u-icon-v3 .u-icon__elem-hover, .u-icon-v4 .u-icon-v4-inner, .u-icon-v4 .u-icon__elem-regular,
.u-icon-v4 .u-icon__elem-hover, .u-icon-v5 {
  width: 3.92857rem;
  height: 3.92857rem;
  font-size: 1.57143rem;
}

.u-icon-v2.u-icon-size--xs,
.u-icon-v2.u-icon-size--xs .u-icon__elem-regular,
.u-icon-v2.u-icon-size--xs .u-icon__elem-hover, .u-icon-v3.u-icon-size--xs,
.u-icon-v3.u-icon-size--xs .u-icon__elem-regular,
.u-icon-v3.u-icon-size--xs .u-icon__elem-hover, .u-icon-v4.u-icon-size--xs .u-icon-v4-inner,
.u-icon-v4.u-icon-size--xs .u-icon__elem-regular,
.u-icon-v4.u-icon-size--xs .u-icon__elem-hover, .u-icon-v5.u-icon-size--xs {
  width: 2.14286rem;
  height: 2.14286rem;
  font-size: 0.92857rem;
}

.u-icon-v2.u-icon-size--sm,
.u-icon-v2.u-icon-size--sm .u-icon__elem-regular,
.u-icon-v2.u-icon-size--sm .u-icon__elem-hover, .u-icon-v3.u-icon-size--sm,
.u-icon-v3.u-icon-size--sm .u-icon__elem-regular,
.u-icon-v3.u-icon-size--sm .u-icon__elem-hover, .u-icon-v4.u-icon-size--sm .u-icon-v4-inner,
.u-icon-v4.u-icon-size--sm .u-icon__elem-regular,
.u-icon-v4.u-icon-size--sm .u-icon__elem-hover, .u-icon-v5.u-icon-size--sm {
  width: 2.85714rem;
  height: 2.85714rem;
  font-size: 1.28571rem;
}

.u-icon-v2.u-icon-size--lg,
.u-icon-v2.u-icon-size--lg .u-icon__elem-regular,
.u-icon-v2.u-icon-size--lg .u-icon__elem-hover, .u-icon-v3.u-icon-size--lg,
.u-icon-v3.u-icon-size--lg .u-icon__elem-regular,
.u-icon-v3.u-icon-size--lg .u-icon__elem-hover, .u-icon-v4.u-icon-size--lg .u-icon-v4-inner,
.u-icon-v4.u-icon-size--lg .u-icon__elem-regular,
.u-icon-v4.u-icon-size--lg .u-icon__elem-hover, .u-icon-v5.u-icon-size--lg {
  width: 5rem;
  height: 5rem;
  font-size: 2.14286rem;
}

.u-icon-v2.u-icon-size--xl,
.u-icon-v2.u-icon-size--xl .u-icon__elem-regular,
.u-icon-v2.u-icon-size--xl .u-icon__elem-hover, .u-icon-v3.u-icon-size--xl,
.u-icon-v3.u-icon-size--xl .u-icon__elem-regular,
.u-icon-v3.u-icon-size--xl .u-icon__elem-hover, .u-icon-v4.u-icon-size--xl .u-icon-v4-inner,
.u-icon-v4.u-icon-size--xl .u-icon__elem-regular,
.u-icon-v4.u-icon-size--xl .u-icon__elem-hover, .u-icon-v5.u-icon-size--xl {
  width: 6.42857rem;
  height: 6.42857rem;
  font-size: 2.85714rem;
}

.u-icon-v2.u-icon-size--2xl,
.u-icon-v2.u-icon-size--2xl .u-icon__elem-regular,
.u-icon-v2.u-icon-size--2xl .u-icon__elem-hover, .u-icon-v3.u-icon-size--2xl,
.u-icon-v3.u-icon-size--2xl .u-icon__elem-regular,
.u-icon-v3.u-icon-size--2xl .u-icon__elem-hover, .u-icon-v4.u-icon-size--2xl .u-icon-v4-inner,
.u-icon-v4.u-icon-size--2xl .u-icon__elem-regular,
.u-icon-v4.u-icon-size--2xl .u-icon__elem-hover, .u-icon-v5.u-icon-size--2xl {
  width: 7.14286rem;
  height: 7.14286rem;
  font-size: 3.21429rem;
}

.u-icon-v2.u-icon-size--3xl,
.u-icon-v2.u-icon-size--3xl .u-icon__elem-regular,
.u-icon-v2.u-icon-size--3xl .u-icon__elem-hover, .u-icon-v3.u-icon-size--3xl,
.u-icon-v3.u-icon-size--3xl .u-icon__elem-regular,
.u-icon-v3.u-icon-size--3xl .u-icon__elem-hover, .u-icon-v4.u-icon-size--3xl .u-icon-v4-inner,
.u-icon-v4.u-icon-size--3xl .u-icon__elem-regular,
.u-icon-v4.u-icon-size--3xl .u-icon__elem-hover, .u-icon-v5.u-icon-size--3xl {
  width: 9.28571rem;
  height: 9.28571rem;
  font-size: 3.57143rem;
}

.u-icon-v2 {
  border-width: 1px;
  border-style: solid;
}

.u-icon-v2.u-icon-size--2xl,
.u-icon-v2.u-icon-size--2xl .u-icon__elem-regular,
.u-icon-v2.u-icon-size--2xl .u-icon__elem-hover {
  border-width: 1.5px;
}

.u-icon-v2.u-icon-size--3xl,
.u-icon-v2.u-icon-size--3xl .u-icon__elem-regular,
.u-icon-v2.u-icon-size--3xl .u-icon__elem-hover {
  border-width: 1.5px;
}

/*------------------------------------
  Icon-v3
------------------------------------*/
.u-icon-v2, .u-icon-v2 .u-icon__elem-regular,
.u-icon-v2 .u-icon__elem-hover, .u-icon-v3, .u-icon-v3 .u-icon__elem-regular,
.u-icon-v3 .u-icon__elem-hover, .u-icon-v4 .u-icon-v4-inner, .u-icon-v4 .u-icon__elem-regular,
.u-icon-v4 .u-icon__elem-hover, .u-icon-v5 {
  width: 3.92857rem;
  height: 3.92857rem;
  font-size: 1.57143rem;
}

.u-icon-v2.u-icon-size--xs,
.u-icon-v2.u-icon-size--xs .u-icon__elem-regular,
.u-icon-v2.u-icon-size--xs .u-icon__elem-hover, .u-icon-v3.u-icon-size--xs,
.u-icon-v3.u-icon-size--xs .u-icon__elem-regular,
.u-icon-v3.u-icon-size--xs .u-icon__elem-hover, .u-icon-v4.u-icon-size--xs .u-icon-v4-inner,
.u-icon-v4.u-icon-size--xs .u-icon__elem-regular,
.u-icon-v4.u-icon-size--xs .u-icon__elem-hover, .u-icon-v5.u-icon-size--xs {
  width: 2.14286rem;
  height: 2.14286rem;
  font-size: 0.92857rem;
}

.u-icon-v2.u-icon-size--sm,
.u-icon-v2.u-icon-size--sm .u-icon__elem-regular,
.u-icon-v2.u-icon-size--sm .u-icon__elem-hover, .u-icon-v3.u-icon-size--sm,
.u-icon-v3.u-icon-size--sm .u-icon__elem-regular,
.u-icon-v3.u-icon-size--sm .u-icon__elem-hover, .u-icon-v4.u-icon-size--sm .u-icon-v4-inner,
.u-icon-v4.u-icon-size--sm .u-icon__elem-regular,
.u-icon-v4.u-icon-size--sm .u-icon__elem-hover, .u-icon-v5.u-icon-size--sm {
  width: 2.85714rem;
  height: 2.85714rem;
  font-size: 1.28571rem;
}

.u-icon-v2.u-icon-size--lg,
.u-icon-v2.u-icon-size--lg .u-icon__elem-regular,
.u-icon-v2.u-icon-size--lg .u-icon__elem-hover, .u-icon-v3.u-icon-size--lg,
.u-icon-v3.u-icon-size--lg .u-icon__elem-regular,
.u-icon-v3.u-icon-size--lg .u-icon__elem-hover, .u-icon-v4.u-icon-size--lg .u-icon-v4-inner,
.u-icon-v4.u-icon-size--lg .u-icon__elem-regular,
.u-icon-v4.u-icon-size--lg .u-icon__elem-hover, .u-icon-v5.u-icon-size--lg {
  width: 5rem;
  height: 5rem;
  font-size: 2.14286rem;
}

.u-icon-v2.u-icon-size--xl,
.u-icon-v2.u-icon-size--xl .u-icon__elem-regular,
.u-icon-v2.u-icon-size--xl .u-icon__elem-hover, .u-icon-v3.u-icon-size--xl,
.u-icon-v3.u-icon-size--xl .u-icon__elem-regular,
.u-icon-v3.u-icon-size--xl .u-icon__elem-hover, .u-icon-v4.u-icon-size--xl .u-icon-v4-inner,
.u-icon-v4.u-icon-size--xl .u-icon__elem-regular,
.u-icon-v4.u-icon-size--xl .u-icon__elem-hover, .u-icon-v5.u-icon-size--xl {
  width: 6.42857rem;
  height: 6.42857rem;
  font-size: 2.85714rem;
}

.u-icon-v2.u-icon-size--2xl,
.u-icon-v2.u-icon-size--2xl .u-icon__elem-regular,
.u-icon-v2.u-icon-size--2xl .u-icon__elem-hover, .u-icon-v3.u-icon-size--2xl,
.u-icon-v3.u-icon-size--2xl .u-icon__elem-regular,
.u-icon-v3.u-icon-size--2xl .u-icon__elem-hover, .u-icon-v4.u-icon-size--2xl .u-icon-v4-inner,
.u-icon-v4.u-icon-size--2xl .u-icon__elem-regular,
.u-icon-v4.u-icon-size--2xl .u-icon__elem-hover, .u-icon-v5.u-icon-size--2xl {
  width: 7.14286rem;
  height: 7.14286rem;
  font-size: 3.21429rem;
}

.u-icon-v2.u-icon-size--3xl,
.u-icon-v2.u-icon-size--3xl .u-icon__elem-regular,
.u-icon-v2.u-icon-size--3xl .u-icon__elem-hover, .u-icon-v3.u-icon-size--3xl,
.u-icon-v3.u-icon-size--3xl .u-icon__elem-regular,
.u-icon-v3.u-icon-size--3xl .u-icon__elem-hover, .u-icon-v4.u-icon-size--3xl .u-icon-v4-inner,
.u-icon-v4.u-icon-size--3xl .u-icon__elem-regular,
.u-icon-v4.u-icon-size--3xl .u-icon__elem-hover, .u-icon-v5.u-icon-size--3xl {
  width: 9.28571rem;
  height: 9.28571rem;
  font-size: 3.57143rem;
}

.u-icon-v3 {
  background-color: #eee;
}

/*------------------------------------
  Icon-v4
------------------------------------*/
.u-icon-v2, .u-icon-v2 .u-icon__elem-regular,
.u-icon-v2 .u-icon__elem-hover, .u-icon-v3, .u-icon-v3 .u-icon__elem-regular,
.u-icon-v3 .u-icon__elem-hover, .u-icon-v4 .u-icon-v4-inner, .u-icon-v4 .u-icon__elem-regular,
.u-icon-v4 .u-icon__elem-hover, .u-icon-v5 {
  width: 3.92857rem;
  height: 3.92857rem;
  font-size: 1.57143rem;
}

.u-icon-v2.u-icon-size--xs,
.u-icon-v2.u-icon-size--xs .u-icon__elem-regular,
.u-icon-v2.u-icon-size--xs .u-icon__elem-hover, .u-icon-v3.u-icon-size--xs,
.u-icon-v3.u-icon-size--xs .u-icon__elem-regular,
.u-icon-v3.u-icon-size--xs .u-icon__elem-hover, .u-icon-v4.u-icon-size--xs .u-icon-v4-inner,
.u-icon-v4.u-icon-size--xs .u-icon__elem-regular,
.u-icon-v4.u-icon-size--xs .u-icon__elem-hover, .u-icon-v5.u-icon-size--xs {
  width: 2.14286rem;
  height: 2.14286rem;
  font-size: 0.92857rem;
}

.u-icon-v2.u-icon-size--sm,
.u-icon-v2.u-icon-size--sm .u-icon__elem-regular,
.u-icon-v2.u-icon-size--sm .u-icon__elem-hover, .u-icon-v3.u-icon-size--sm,
.u-icon-v3.u-icon-size--sm .u-icon__elem-regular,
.u-icon-v3.u-icon-size--sm .u-icon__elem-hover, .u-icon-v4.u-icon-size--sm .u-icon-v4-inner,
.u-icon-v4.u-icon-size--sm .u-icon__elem-regular,
.u-icon-v4.u-icon-size--sm .u-icon__elem-hover, .u-icon-v5.u-icon-size--sm {
  width: 2.85714rem;
  height: 2.85714rem;
  font-size: 1.28571rem;
}

.u-icon-v2.u-icon-size--lg,
.u-icon-v2.u-icon-size--lg .u-icon__elem-regular,
.u-icon-v2.u-icon-size--lg .u-icon__elem-hover, .u-icon-v3.u-icon-size--lg,
.u-icon-v3.u-icon-size--lg .u-icon__elem-regular,
.u-icon-v3.u-icon-size--lg .u-icon__elem-hover, .u-icon-v4.u-icon-size--lg .u-icon-v4-inner,
.u-icon-v4.u-icon-size--lg .u-icon__elem-regular,
.u-icon-v4.u-icon-size--lg .u-icon__elem-hover, .u-icon-v5.u-icon-size--lg {
  width: 5rem;
  height: 5rem;
  font-size: 2.14286rem;
}

.u-icon-v2.u-icon-size--xl,
.u-icon-v2.u-icon-size--xl .u-icon__elem-regular,
.u-icon-v2.u-icon-size--xl .u-icon__elem-hover, .u-icon-v3.u-icon-size--xl,
.u-icon-v3.u-icon-size--xl .u-icon__elem-regular,
.u-icon-v3.u-icon-size--xl .u-icon__elem-hover, .u-icon-v4.u-icon-size--xl .u-icon-v4-inner,
.u-icon-v4.u-icon-size--xl .u-icon__elem-regular,
.u-icon-v4.u-icon-size--xl .u-icon__elem-hover, .u-icon-v5.u-icon-size--xl {
  width: 6.42857rem;
  height: 6.42857rem;
  font-size: 2.85714rem;
}

.u-icon-v2.u-icon-size--2xl,
.u-icon-v2.u-icon-size--2xl .u-icon__elem-regular,
.u-icon-v2.u-icon-size--2xl .u-icon__elem-hover, .u-icon-v3.u-icon-size--2xl,
.u-icon-v3.u-icon-size--2xl .u-icon__elem-regular,
.u-icon-v3.u-icon-size--2xl .u-icon__elem-hover, .u-icon-v4.u-icon-size--2xl .u-icon-v4-inner,
.u-icon-v4.u-icon-size--2xl .u-icon__elem-regular,
.u-icon-v4.u-icon-size--2xl .u-icon__elem-hover, .u-icon-v5.u-icon-size--2xl {
  width: 7.14286rem;
  height: 7.14286rem;
  font-size: 3.21429rem;
}

.u-icon-v2.u-icon-size--3xl,
.u-icon-v2.u-icon-size--3xl .u-icon__elem-regular,
.u-icon-v2.u-icon-size--3xl .u-icon__elem-hover, .u-icon-v3.u-icon-size--3xl,
.u-icon-v3.u-icon-size--3xl .u-icon__elem-regular,
.u-icon-v3.u-icon-size--3xl .u-icon__elem-hover, .u-icon-v4.u-icon-size--3xl .u-icon-v4-inner,
.u-icon-v4.u-icon-size--3xl .u-icon__elem-regular,
.u-icon-v4.u-icon-size--3xl .u-icon__elem-hover, .u-icon-v5.u-icon-size--3xl {
  width: 9.28571rem;
  height: 9.28571rem;
  font-size: 3.57143rem;
}

.u-icon-v4 {
  padding: 0.57143rem;
  background: transparent !important;
  border: solid 1px #eee;
  transition: all .1s ease-in-out;
}

.u-icon-v4-inner {
  position: relative;
  display: block;
  overflow: hidden;
  background-color: #eee;
}

.u-icon-v4 .u-icon-v4-inner {
  z-index: 1;
  transition: all .2s ease-in-out;
}

.u-icon-v4-rounded-3,
.u-icon-v4-rounded-3 .u-icon-v4-inner {
  border-radius: 3px;
}

.u-icon-v4-rounded-7,
.u-icon-v4-rounded-7 .u-icon-v4-inner {
  border-radius: 7px;
}

.u-icon-v4-rounded-10,
.u-icon-v4-rounded-10 .u-icon-v4-inner {
  border-radius: 10px;
}

.u-icon-v4-rounded-50x,
.u-icon-v4-rounded-50x .u-icon-v4-inner {
  border-radius: 50%;
}

.u-icon-v4-bg-primary {
  border-color: #2cb8f3;
}

.u-icon-v4-bg-primary .u-icon-v4-inner {
  background: #2cb8f3;
}

.u-icon-v4-bg-white {
  border-color: #fff;
}

.u-icon-v4-bg-white .u-icon-v4-inner {
  background: #fff;
}

.u-icon-v4-bg-gray-light-v3 {
  border-color: #ddd;
}

.u-icon-v4-bg-gray-light-v3 .u-icon-v4-inner {
  background: #ddd;
}

.u-icon-v4-bg-gray-dark-v3 {
  border-color: #555;
}

.u-icon-v4-bg-gray-dark-v3 .u-icon-v4-inner {
  background: #555;
}

.u-icon-v4-bg-black {
  border-color: #000;
}

.u-icon-v4-bg-black .u-icon-v4-inner {
  background: #000;
}

.u-icon-v4-bg-primary--hover:hover,
.u-icon-block--hover:hover .u-icon-v4-bg-primary--hover {
  border-color: #2cb8f3;
}

.u-icon-v4-bg-primary--hover:hover .u-icon-v4-inner,
.u-icon-block--hover:hover .u-icon-v4-bg-primary--hover .u-icon-v4-inner {
  background: #2cb8f3;
}

.u-icon-v4-bg-white--hover:hover,
.u-icon-block--hover:hover .u-icon-v4-bg-white--hover {
  border-color: #fff;
}

.u-icon-v4-bg-white--hover:hover .u-icon-v4-inner,
.u-icon-block--hover:hover .u-icon-v4-bg-white--hover .u-icon-v4-inner {
  background: #fff;
}

.u-icon-v4-bg-gray-light-v3--hover:hover,
.u-icon-block--hover:hover .u-icon-v4-bg-gray-light-v3--hover {
  border-color: #ddd;
}

.u-icon-v4-bg-gray-light-v3--hover:hover .u-icon-v4-inner,
.u-icon-block--hover:hover .u-icon-v4-bg-gray-light-v3--hover .u-icon-v4-inner {
  background: #ddd;
}

.u-icon-v4-bg-gray-dark-v3--hover:hover,
.u-icon-block--hover:hover .u-icon-v4-bg-gray-dark-v3--hover {
  border-color: #555;
}

.u-icon-v4-bg-gray-dark-v3--hover:hover .u-icon-v4-inner,
.u-icon-block--hover:hover .u-icon-v4-bg-gray-dark-v3--hover .u-icon-v4-inner {
  background: #555;
}

.u-icon-v4-bg-black--hover:hover,
.u-icon-block--hover:hover .u-icon-v4-bg-black--hover {
  border-color: #000;
}

.u-icon-v4-bg-black--hover:hover .u-icon-v4-inner,
.u-icon-block--hover:hover .u-icon-v4-bg-black--hover .u-icon-v4-inner {
  background: #000;
}

/*------------------------------------
  Icon-v4 Additional BG colors
------------------------------------*/
.u-icon-v4-bg-red {
  border-color: #f00;
}

.u-icon-v4-bg-red .u-icon-v4-inner {
  background: #f00;
}

.u-icon-v4-bg-lightred {
  border-color: #e64b3b;
}

.u-icon-v4-bg-lightred .u-icon-v4-inner {
  background: #e64b3b;
}

.u-icon-v4-bg-darkred {
  border-color: #a10f2b;
}

.u-icon-v4-bg-darkred .u-icon-v4-inner {
  background: #a10f2b;
}

.u-icon-v4-bg-blue {
  border-color: #3398dc;
}

.u-icon-v4-bg-blue .u-icon-v4-inner {
  background: #3398dc;
}

.u-icon-v4-bg-indigo {
  border-color: #4263a3;
}

.u-icon-v4-bg-indigo .u-icon-v4-inner {
  background: #4263a3;
}

.u-icon-v4-bg-purple {
  border-color: #9a69cb;
}

.u-icon-v4-bg-purple .u-icon-v4-inner {
  background: #9a69cb;
}

.u-icon-v4-bg-darkpurple {
  border-color: #6639b6;
}

.u-icon-v4-bg-darkpurple .u-icon-v4-inner {
  background: #6639b6;
}

.u-icon-v4-bg-pink {
  border-color: #e81c62;
}

.u-icon-v4-bg-pink .u-icon-v4-inner {
  background: #e81c62;
}

.u-icon-v4-bg-orange {
  border-color: #e57d20;
}

.u-icon-v4-bg-orange .u-icon-v4-inner {
  background: #e57d20;
}

.u-icon-v4-bg-deeporange {
  border-color: #fe541e;
}

.u-icon-v4-bg-deeporange .u-icon-v4-inner {
  background: #fe541e;
}

.u-icon-v4-bg-aqua {
  border-color: #29d6e6;
}

.u-icon-v4-bg-aqua .u-icon-v4-inner {
  background: #29d6e6;
}

.u-icon-v4-bg-yellow {
  border-color: #ebc71d;
}

.u-icon-v4-bg-yellow .u-icon-v4-inner {
  background: #ebc71d;
}

.u-icon-v4-bg-cyan {
  border-color: #00bed6;
}

.u-icon-v4-bg-cyan .u-icon-v4-inner {
  background: #00bed6;
}

.u-icon-v4-bg-teal {
  border-color: #18ba9b;
}

.u-icon-v4-bg-teal .u-icon-v4-inner {
  background: #18ba9b;
}

.u-icon-v4-bg-brown {
  border-color: #9c8061;
}

.u-icon-v4-bg-brown .u-icon-v4-inner {
  background: #9c8061;
}

.u-icon-v4-bg-bluegray {
  border-color: #585f69;
}

.u-icon-v4-bg-bluegray .u-icon-v4-inner {
  background: #585f69;
}

.u-icon-v4-bg-red--hover:hover,
.u-icon-block--hover:hover .u-icon-v4-bg-red--hover {
  border-color: #f00;
}

.u-icon-v4-bg-red--hover:hover .u-icon-v4-inner,
.u-icon-block--hover:hover .u-icon-v4-bg-red--hover .u-icon-v4-inner {
  background: #f00;
}

.u-icon-v4-bg-lightred--hover:hover,
.u-icon-block--hover:hover .u-icon-v4-bg-lightred--hover {
  border-color: #e64b3b;
}

.u-icon-v4-bg-lightred--hover:hover .u-icon-v4-inner,
.u-icon-block--hover:hover .u-icon-v4-bg-lightred--hover .u-icon-v4-inner {
  background: #e64b3b;
}

.u-icon-v4-bg-darkred--hover:hover,
.u-icon-block--hover:hover .u-icon-v4-bg-darkred--hover {
  border-color: #a10f2b;
}

.u-icon-v4-bg-darkred--hover:hover .u-icon-v4-inner,
.u-icon-block--hover:hover .u-icon-v4-bg-darkred--hover .u-icon-v4-inner {
  background: #a10f2b;
}

.u-icon-v4-bg-blue--hover:hover,
.u-icon-block--hover:hover .u-icon-v4-bg-blue--hover {
  border-color: #3398dc;
}

.u-icon-v4-bg-blue--hover:hover .u-icon-v4-inner,
.u-icon-block--hover:hover .u-icon-v4-bg-blue--hover .u-icon-v4-inner {
  background: #3398dc;
}

.u-icon-v4-bg-indigo--hover:hover,
.u-icon-block--hover:hover .u-icon-v4-bg-indigo--hover {
  border-color: #4263a3;
}

.u-icon-v4-bg-indigo--hover:hover .u-icon-v4-inner,
.u-icon-block--hover:hover .u-icon-v4-bg-indigo--hover .u-icon-v4-inner {
  background: #4263a3;
}

.u-icon-v4-bg-purple--hover:hover,
.u-icon-block--hover:hover .u-icon-v4-bg-purple--hover {
  border-color: #9a69cb;
}

.u-icon-v4-bg-purple--hover:hover .u-icon-v4-inner,
.u-icon-block--hover:hover .u-icon-v4-bg-purple--hover .u-icon-v4-inner {
  background: #9a69cb;
}

.u-icon-v4-bg-darkpurple--hover:hover,
.u-icon-block--hover:hover .u-icon-v4-bg-darkpurple--hover {
  border-color: #6639b6;
}

.u-icon-v4-bg-darkpurple--hover:hover .u-icon-v4-inner,
.u-icon-block--hover:hover .u-icon-v4-bg-darkpurple--hover .u-icon-v4-inner {
  background: #6639b6;
}

.u-icon-v4-bg-pink--hover:hover,
.u-icon-block--hover:hover .u-icon-v4-bg-pink--hover {
  border-color: #e81c62;
}

.u-icon-v4-bg-pink--hover:hover .u-icon-v4-inner,
.u-icon-block--hover:hover .u-icon-v4-bg-pink--hover .u-icon-v4-inner {
  background: #e81c62;
}

.u-icon-v4-bg-orange--hover:hover,
.u-icon-block--hover:hover .u-icon-v4-bg-orange--hover {
  border-color: #e57d20;
}

.u-icon-v4-bg-orange--hover:hover .u-icon-v4-inner,
.u-icon-block--hover:hover .u-icon-v4-bg-orange--hover .u-icon-v4-inner {
  background: #e57d20;
}

.u-icon-v4-bg-deeporange--hover:hover,
.u-icon-block--hover:hover .u-icon-v4-bg-deeporange--hover {
  border-color: #fe541e;
}

.u-icon-v4-bg-deeporange--hover:hover .u-icon-v4-inner,
.u-icon-block--hover:hover .u-icon-v4-bg-deeporange--hover .u-icon-v4-inner {
  background: #fe541e;
}

.u-icon-v4-bg-aqua--hover:hover,
.u-icon-block--hover:hover .u-icon-v4-bg-aqua--hover {
  border-color: #29d6e6;
}

.u-icon-v4-bg-aqua--hover:hover .u-icon-v4-inner,
.u-icon-block--hover:hover .u-icon-v4-bg-aqua--hover .u-icon-v4-inner {
  background: #29d6e6;
}

.u-icon-v4-bg-yellow--hover:hover,
.u-icon-block--hover:hover .u-icon-v4-bg-yellow--hover {
  border-color: #ebc71d;
}

.u-icon-v4-bg-yellow--hover:hover .u-icon-v4-inner,
.u-icon-block--hover:hover .u-icon-v4-bg-yellow--hover .u-icon-v4-inner {
  background: #ebc71d;
}

.u-icon-v4-bg-cyan--hover:hover,
.u-icon-block--hover:hover .u-icon-v4-bg-cyan--hover {
  border-color: #00bed6;
}

.u-icon-v4-bg-cyan--hover:hover .u-icon-v4-inner,
.u-icon-block--hover:hover .u-icon-v4-bg-cyan--hover .u-icon-v4-inner {
  background: #00bed6;
}

.u-icon-v4-bg-teal--hover:hover,
.u-icon-block--hover:hover .u-icon-v4-bg-teal--hover {
  border-color: #18ba9b;
}

.u-icon-v4-bg-teal--hover:hover .u-icon-v4-inner,
.u-icon-block--hover:hover .u-icon-v4-bg-teal--hover .u-icon-v4-inner {
  background: #18ba9b;
}

.u-icon-v4-bg-brown--hover:hover,
.u-icon-block--hover:hover .u-icon-v4-bg-brown--hover {
  border-color: #9c8061;
}

.u-icon-v4-bg-brown--hover:hover .u-icon-v4-inner,
.u-icon-block--hover:hover .u-icon-v4-bg-brown--hover .u-icon-v4-inner {
  background: #9c8061;
}

.u-icon-v4-bg-bluegray--hover:hover,
.u-icon-block--hover:hover .u-icon-v4-bg-bluegray--hover {
  border-color: #585f69;
}

.u-icon-v4-bg-bluegray--hover:hover .u-icon-v4-inner,
.u-icon-block--hover:hover .u-icon-v4-bg-bluegray--hover .u-icon-v4-inner {
  background: #585f69;
}

/*------------------------------------
  Icon-v4 Social BG colors
------------------------------------*/
.u-icon-v4-bg-facebook {
  border-color: #3b5998;
}

.u-icon-v4-bg-facebook .u-icon-v4-inner {
  background-color: #3b5998;
}

.u-icon-v4-bg-twitter {
  border-color: #00acee;
}

.u-icon-v4-bg-twitter .u-icon-v4-inner {
  background-color: #00acee;
}

.u-icon-v4-bg-google-plus {
  border-color: #dd4b39;
}

.u-icon-v4-bg-google-plus .u-icon-v4-inner {
  background-color: #dd4b39;
}

.u-icon-v4-bg-vk {
  border-color: #2b587a;
}

.u-icon-v4-bg-vk .u-icon-v4-inner {
  background-color: #2b587a;
}

.u-icon-v4-bg-linkedin {
  border-color: #0e76a8;
}

.u-icon-v4-bg-linkedin .u-icon-v4-inner {
  background-color: #0e76a8;
}

.u-icon-v4-bg-instagram {
  border-color: #3f729b;
}

.u-icon-v4-bg-instagram .u-icon-v4-inner {
  background-color: #3f729b;
}

.u-icon-v4-bg-pinterest {
  border-color: #c8232c;
}

.u-icon-v4-bg-pinterest .u-icon-v4-inner {
  background-color: #c8232c;
}

.u-icon-v4-bg-vine {
  border-color: #00bf8f;
}

.u-icon-v4-bg-vine .u-icon-v4-inner {
  background-color: #00bf8f;
}

.u-icon-v4-bg-youtube {
  border-color: #c4302b;
}

.u-icon-v4-bg-youtube .u-icon-v4-inner {
  background-color: #c4302b;
}

.u-icon-v4-bg-skype {
  border-color: #00aff0;
}

.u-icon-v4-bg-skype .u-icon-v4-inner {
  background-color: #00aff0;
}

.u-icon-v4-bg-dribbble {
  border-color: #ea4c89;
}

.u-icon-v4-bg-dribbble .u-icon-v4-inner {
  background-color: #ea4c89;
}

.u-icon-v4-bg-facebook--hover:hover,
.u-icon-block--hover:hover .u-icon-v4-bg-facebook--hover {
  border-color: #3b5998;
}

.u-icon-v4-bg-facebook--hover:hover .u-icon-v4-inner,
.u-icon-block--hover:hover .u-icon-v4-bg-facebook--hover .u-icon-v4-inner {
  background: #3b5998;
}

.u-icon-v4-bg-twitter--hover:hover,
.u-icon-block--hover:hover .u-icon-v4-bg-twitter--hover {
  border-color: #00acee;
}

.u-icon-v4-bg-twitter--hover:hover .u-icon-v4-inner,
.u-icon-block--hover:hover .u-icon-v4-bg-twitter--hover .u-icon-v4-inner {
  background: #00acee;
}

.u-icon-v4-bg-google-plus--hover:hover,
.u-icon-block--hover:hover .u-icon-v4-bg-google-plus--hover {
  border-color: #dd4b39;
}

.u-icon-v4-bg-google-plus--hover:hover .u-icon-v4-inner,
.u-icon-block--hover:hover .u-icon-v4-bg-google-plus--hover .u-icon-v4-inner {
  background: #dd4b39;
}

.u-icon-v4-bg-vk--hover:hover,
.u-icon-block--hover:hover .u-icon-v4-bg-vk--hover {
  border-color: #2b587a;
}

.u-icon-v4-bg-vk--hover:hover .u-icon-v4-inner,
.u-icon-block--hover:hover .u-icon-v4-bg-vk--hover .u-icon-v4-inner {
  background: #2b587a;
}

.u-icon-v4-bg-linkedin--hover:hover,
.u-icon-block--hover:hover .u-icon-v4-bg-linkedin--hover {
  border-color: #0e76a8;
}

.u-icon-v4-bg-linkedin--hover:hover .u-icon-v4-inner,
.u-icon-block--hover:hover .u-icon-v4-bg-linkedin--hover .u-icon-v4-inner {
  background: #0e76a8;
}

.u-icon-v4-bg-instagram--hover:hover,
.u-icon-block--hover:hover .u-icon-v4-bg-instagram--hover {
  border-color: #3f729b;
}

.u-icon-v4-bg-instagram--hover:hover .u-icon-v4-inner,
.u-icon-block--hover:hover .u-icon-v4-bg-instagram--hover .u-icon-v4-inner {
  background: #3f729b;
}

.u-icon-v4-bg-pinterest--hover:hover,
.u-icon-block--hover:hover .u-icon-v4-bg-pinterest--hover {
  border-color: #c8232c;
}

.u-icon-v4-bg-pinterest--hover:hover .u-icon-v4-inner,
.u-icon-block--hover:hover .u-icon-v4-bg-pinterest--hover .u-icon-v4-inner {
  background: #c8232c;
}

/*------------------------------------
  Icon-v4 Gradient BG colors
------------------------------------*/
.u-icon-v4-bg-lightblue-radialgradient-ellipse {
  border-color: #d6e2ee;
}

.u-icon-v4-bg-lightblue-radialgradient-ellipse .u-icon-v4-inner {
  background: radial-gradient(ellipse at center center, #fff 22%, #d6e2ee 100%) repeat;
}

.u-icon-v4-bg-gray-radialgradient-ellipse {
  border-color: #bbb;
}

.u-icon-v4-bg-gray-radialgradient-ellipse .u-icon-v4-inner {
  background: radial-gradient(ellipse at center center, #ddd 22%, #bbb 100%) repeat;
}

.u-icon-v4-bg-blue-radialgradient-circle {
  border-color: #275296;
}

.u-icon-v4-bg-blue-radialgradient-circle .u-icon-v4-inner {
  background: radial-gradient(circle farthest-side at 100% 0, #2294d7, #275296) no-repeat;
}

.u-icon-v4-bg-darkgray-radialgradient-circle {
  border-color: #31353e;
}

.u-icon-v4-bg-darkgray-radialgradient-circle .u-icon-v4-inner {
  background: radial-gradient(circle farthest-side at 110% 0, #596070, #31353e) no-repeat;
}

/*------------------------------------
  Icon-v5
------------------------------------*/
.u-icon-v2, .u-icon-v2 .u-icon__elem-regular,
.u-icon-v2 .u-icon__elem-hover, .u-icon-v3, .u-icon-v3 .u-icon__elem-regular,
.u-icon-v3 .u-icon__elem-hover, .u-icon-v4 .u-icon-v4-inner, .u-icon-v4 .u-icon__elem-regular,
.u-icon-v4 .u-icon__elem-hover, .u-icon-v5 {
  width: 3.92857rem;
  height: 3.92857rem;
  font-size: 1.57143rem;
}

.u-icon-v2.u-icon-size--xs,
.u-icon-v2.u-icon-size--xs .u-icon__elem-regular,
.u-icon-v2.u-icon-size--xs .u-icon__elem-hover, .u-icon-v3.u-icon-size--xs,
.u-icon-v3.u-icon-size--xs .u-icon__elem-regular,
.u-icon-v3.u-icon-size--xs .u-icon__elem-hover, .u-icon-v4.u-icon-size--xs .u-icon-v4-inner,
.u-icon-v4.u-icon-size--xs .u-icon__elem-regular,
.u-icon-v4.u-icon-size--xs .u-icon__elem-hover, .u-icon-v5.u-icon-size--xs {
  width: 2.14286rem;
  height: 2.14286rem;
  font-size: 0.92857rem;
}

.u-icon-v2.u-icon-size--sm,
.u-icon-v2.u-icon-size--sm .u-icon__elem-regular,
.u-icon-v2.u-icon-size--sm .u-icon__elem-hover, .u-icon-v3.u-icon-size--sm,
.u-icon-v3.u-icon-size--sm .u-icon__elem-regular,
.u-icon-v3.u-icon-size--sm .u-icon__elem-hover, .u-icon-v4.u-icon-size--sm .u-icon-v4-inner,
.u-icon-v4.u-icon-size--sm .u-icon__elem-regular,
.u-icon-v4.u-icon-size--sm .u-icon__elem-hover, .u-icon-v5.u-icon-size--sm {
  width: 2.85714rem;
  height: 2.85714rem;
  font-size: 1.28571rem;
}

.u-icon-v2.u-icon-size--lg,
.u-icon-v2.u-icon-size--lg .u-icon__elem-regular,
.u-icon-v2.u-icon-size--lg .u-icon__elem-hover, .u-icon-v3.u-icon-size--lg,
.u-icon-v3.u-icon-size--lg .u-icon__elem-regular,
.u-icon-v3.u-icon-size--lg .u-icon__elem-hover, .u-icon-v4.u-icon-size--lg .u-icon-v4-inner,
.u-icon-v4.u-icon-size--lg .u-icon__elem-regular,
.u-icon-v4.u-icon-size--lg .u-icon__elem-hover, .u-icon-v5.u-icon-size--lg {
  width: 5rem;
  height: 5rem;
  font-size: 2.14286rem;
}

.u-icon-v2.u-icon-size--xl,
.u-icon-v2.u-icon-size--xl .u-icon__elem-regular,
.u-icon-v2.u-icon-size--xl .u-icon__elem-hover, .u-icon-v3.u-icon-size--xl,
.u-icon-v3.u-icon-size--xl .u-icon__elem-regular,
.u-icon-v3.u-icon-size--xl .u-icon__elem-hover, .u-icon-v4.u-icon-size--xl .u-icon-v4-inner,
.u-icon-v4.u-icon-size--xl .u-icon__elem-regular,
.u-icon-v4.u-icon-size--xl .u-icon__elem-hover, .u-icon-v5.u-icon-size--xl {
  width: 6.42857rem;
  height: 6.42857rem;
  font-size: 2.85714rem;
}

.u-icon-v2.u-icon-size--2xl,
.u-icon-v2.u-icon-size--2xl .u-icon__elem-regular,
.u-icon-v2.u-icon-size--2xl .u-icon__elem-hover, .u-icon-v3.u-icon-size--2xl,
.u-icon-v3.u-icon-size--2xl .u-icon__elem-regular,
.u-icon-v3.u-icon-size--2xl .u-icon__elem-hover, .u-icon-v4.u-icon-size--2xl .u-icon-v4-inner,
.u-icon-v4.u-icon-size--2xl .u-icon__elem-regular,
.u-icon-v4.u-icon-size--2xl .u-icon__elem-hover, .u-icon-v5.u-icon-size--2xl {
  width: 7.14286rem;
  height: 7.14286rem;
  font-size: 3.21429rem;
}

.u-icon-v2.u-icon-size--3xl,
.u-icon-v2.u-icon-size--3xl .u-icon__elem-regular,
.u-icon-v2.u-icon-size--3xl .u-icon__elem-hover, .u-icon-v3.u-icon-size--3xl,
.u-icon-v3.u-icon-size--3xl .u-icon__elem-regular,
.u-icon-v3.u-icon-size--3xl .u-icon__elem-hover, .u-icon-v4.u-icon-size--3xl .u-icon-v4-inner,
.u-icon-v4.u-icon-size--3xl .u-icon__elem-regular,
.u-icon-v4.u-icon-size--3xl .u-icon__elem-hover, .u-icon-v5.u-icon-size--3xl {
  width: 9.28571rem;
  height: 9.28571rem;
  font-size: 3.57143rem;
}

.u-icon-v5 {
  position: relative;
}

body .u-icon-v5 {
  background: transparent !important;
}

.u-icon-v5::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -.45em 0 0 -.4em;
  width: .8em;
  height: .8em;
  background-color: #eee;
  border-radius: 50%;
  opacity: .7;
}

.u-icon-v5 i {
  position: relative;
  z-index: 2;
}

.u-icon-v5.g-bg-main::after {
  background-color: #555;
}

.u-icon-v5.g-bg-primary::after {
  background-color: #2cb8f3;
}

.u-icon-v5.g-bg-black::after {
  background-color: #000;
}

.u-icon-v5.g-bg-white::after {
  background-color: #fff;
}

.u-icon-v5.g-bg-gray-light-v1::after {
  background-color: #bbb;
}

.u-icon-v5.g-bg-gray-light-v2::after {
  background-color: #ccc;
}

.u-icon-v5.g-bg-gray-light-v3::after {
  background-color: #ddd;
}

.u-icon-v5.g-bg-gray-light-v4::after {
  background-color: #eee;
}

.u-icon-v5.g-bg-gray-light-v5::after {
  background-color: #f7f7f7;
}

.u-icon-v5.g-bg-gray-dark-v1::after {
  background-color: #111;
}

.u-icon-v5.g-bg-gray-dark-v2::after {
  background-color: #333;
}

.u-icon-v5.g-bg-gray-dark-v3::after {
  background-color: #555;
}

.u-icon-v5.g-bg-gray-dark-v4::after {
  background-color: #777;
}

.u-icon-v5.g-bg-gray-dark-v5::after {
  background-color: #999;
}

.u-icon-v5.g-bg-green::after {
  background-color: #72c02c;
}

.u-icon-v5.g-bg-blue::after {
  background-color: #3398dc;
}

.u-icon-v5.g-bg-lightblue::after {
  background-color: #edf2f8;
}

.u-icon-v5.g-bg-lightblue-v1::after {
  background-color: #d6e2ee;
}

.u-icon-v5.g-bg-darkblue::after {
  background-color: #009;
}

.u-icon-v5.g-bg-indigo::after {
  background-color: #4263a3;
}

.u-icon-v5.g-bg-red::after {
  background-color: #f00;
}

.u-icon-v5.g-bg-lightred::after {
  background-color: #e64b3b;
}

.u-icon-v5.g-bg-darkred::after {
  background-color: #a10f2b;
}

.u-icon-v5.g-bg-purple::after {
  background-color: #9a69cb;
}

.u-icon-v5.g-bg-darkpurple::after {
  background-color: #6639b6;
}

.u-icon-v5.g-bg-pink::after {
  background-color: #e81c62;
}

.u-icon-v5.g-bg-orange::after {
  background-color: #e57d20;
}

.u-icon-v5.g-bg-deeporange::after {
  background-color: #fe541e;
}

.u-icon-v5.g-bg-yellow::after {
  background-color: #ebc71d;
}

.u-icon-v5.g-bg-aqua::after {
  background-color: #29d6e6;
}

.u-icon-v5.g-bg-cyan::after {
  background-color: #00bed6;
}

.u-icon-v5.g-bg-teal::after {
  background-color: #18ba9b;
}

.u-icon-v5.g-bg-brown::after {
  background-color: #9c8061;
}

.u-icon-v5.g-bg-bluegray::after {
  background-color: #585f69;
}

/*------------------------------------
  Image Icons
------------------------------------*/
.u-image-icon-size-xs,
.u-image-icon-size-sm,
.u-image-icon-size-md,
.u-image-icon-size-lg,
.u-image-icon-size-xl {
  height: auto;
}

.u-image-icon-size-xs {
  width: 2.14286rem;
}

.u-image-icon-size-sm {
  width: 2.85714rem;
}

.u-image-icon-size-md {
  width: 3.92857rem;
}

.u-image-icon-size-lg {
  width: 5rem;
}

.u-image-icon-size-xl {
  width: 6.42857rem;
}

.u-image-icon-size-2xl {
  width: 7.14286rem;
}

/*------------------------------------
  Hover Effect of Icon on Block Hover
------------------------------------*/
.u-icon-block--hover:hover .g-opacity-0_5--hover {
  opacity: .5;
}

.u-icon-block--hover:hover .g-color-primary--hover {
  color: #2cb8f3;
}

.u-icon-block--hover:hover .g-brd-primary--hover {
  border-color: #2cb8f3;
}

.u-icon-block--hover:hover .g-bg-primary--hover {
  background: #2cb8f3;
}

.u-icon-block--hover:hover .g-color-white--hover {
  color: #fff;
}

.u-icon-block--hover:hover .g-brd-white--hover {
  border-color: #fff;
}

.u-icon-block--hover:hover .g-bg-white--hover {
  background: #fff;
}

.u-icon-block--hover:hover .g-color-black--hover {
  color: #000;
}

.u-icon-block--hover:hover .g-brd-black--hover {
  border-color: #000;
}

.u-icon-block--hover:hover .g-bg-black--hover {
  background: #000;
}

.u-icon-block--hover:hover .g-color-gray-dark-v4--hover {
  color: #777;
}

.u-icon-block--hover:hover .g-brd-gray-dark-v4--hover {
  border-color: #777;
}

.u-icon-block--hover:hover .g-bg-gray-dark-v4--hover {
  background: #777;
}

.u-icon-block-hover:hover .g-color-facebook--hover {
  color: #3b5998;
}

.u-icon-block-hover:hover .g-brd-facebook--hover {
  border-color: #3b5998;
}

.u-icon-block-hover:hover .g-bg-facebook--hover {
  background: #3b5998;
}

.u-icon-block-hover:hover .g-color-twitter--hover {
  color: #00acee;
}

.u-icon-block-hover:hover .g-brd-twitter--hover {
  border-color: #00acee;
}

.u-icon-block-hover:hover .g-bg-twitter--hover {
  background: #00acee;
}

.u-icon-block-hover:hover .g-color-google-plus--hover {
  color: #dd4b39;
}

.u-icon-block-hover:hover .g-brd-google-plus--hover {
  border-color: #dd4b39;
}

.u-icon-block-hover:hover .g-bg-google-plus--hover {
  background: #dd4b39;
}

.u-icon-block-hover:hover .g-color-vk--hover {
  color: #2b587a;
}

.u-icon-block-hover:hover .g-brd-vk--hover {
  border-color: #2b587a;
}

.u-icon-block-hover:hover .g-bg-vk--hover {
  background: #2b587a;
}

.u-icon-block-hover:hover .g-color-linkedin--hover {
  color: #0e76a8;
}

.u-icon-block-hover:hover .g-brd-linkedin--hover {
  border-color: #0e76a8;
}

.u-icon-block-hover:hover .g-bg-linkedin--hover {
  background: #0e76a8;
}

.u-icon-block-hover:hover .g-color-instagram--hover {
  color: #3f729b;
}

.u-icon-block-hover:hover .g-brd-instagram--hover {
  border-color: #3f729b;
}

.u-icon-block-hover:hover .g-bg-instagram--hover {
  background: #3f729b;
}

.u-icon-block-hover:hover .g-color-pinterest--hover {
  color: #c8232c;
}

.u-icon-block-hover:hover .g-brd-pinterest--hover {
  border-color: #c8232c;
}

.u-icon-block-hover:hover .g-bg-pinterest--hover {
  background: #c8232c;
}

/*------------------------------------
  Scale Effect
------------------------------------*/
.u-icon-scale-1_2--hover:hover {
  transform: scale(1.2);
}

/*------------------------------------
  Shadow Effect
------------------------------------*/
.u-icon-shadow--hover:hover,
.u-icon-block--hover:hover .u-icon-shadow--hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

/*------------------------------------
  Slide Effect v1
------------------------------------*/
.u-icon-sliding--hover i::before {
  display: block;
}

.u-icon-sliding--hover:hover i::before,
.u-icon-block--hover:hover .u-icon-sliding--hover i::before {
  animation: toTopFromBottom .3s forwards;
}

@keyframes toTopFromBottom {
  49% {
    transform: translateY(-100%);
  }
  50% {
    opacity: 0;
    transform: translateY(100%);
  }
  51% {
    opacity: 1;
  }
}

/*------------------------------------
  Slide Effect v2
------------------------------------*/
.u-icon__elem-regular,
.u-icon__elem-hover {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  transition: all .3s ease-in-out;
}

.u-icon__elem-hover {
  position: absolute !important;
}

.u-icon-slide-up--hover,
.u-icon-slide-down--hover,
.u-icon-slide-left--hover,
.u-icon-slide-right--hover {
  overflow: hidden;
}

.u-icon-slide-up--hover .u-icon__elem-regular {
  transform: translateY(-20%);
}

.u-icon-slide-up--hover .u-icon__elem-regular.u-line-icon-pro {
  transform: translateY(-30%);
}

.u-icon-slide-up--hover .u-icon__elem-hover {
  transform: translateY(110%);
}

.u-icon-slide-up--hover .u-icon__elem-hover.u-line-icon-pro {
  transform: translateY(120%);
}

.u-icon-slide-up--hover:hover .u-icon__elem-regular,
.u-icon-block--hover:hover .u-icon-slide-up--hover .u-icon__elem-regular {
  transform: translateY(-110%);
}

.u-icon-slide-up--hover:hover .u-icon__elem-regular.u-line-icon-pro,
.u-icon-block--hover:hover .u-icon-slide-up--hover .u-icon__elem-regular.u-line-icon-pro {
  transform: translateY(-120%);
}

.u-icon-slide-up--hover:hover .u-icon__elem-hover,
.u-icon-block--hover:hover .u-icon-slide-up--hover .u-icon__elem-hover {
  transform: translateY(-20%);
}

.u-icon-slide-up--hover:hover .u-icon__elem-hover.u-line-icon-pro,
.u-icon-block--hover:hover .u-icon-slide-up--hover .u-icon__elem-hover.u-line-icon-pro {
  transform: translateY(-30%);
}

.u-icon-slide-down--hover .u-icon__elem-regular {
  transform: translateY(-110%);
}

.u-icon-slide-down--hover .u-icon__elem-regular.u-line-icon-pro {
  transform: translateY(-120%);
}

.u-icon-slide-down--hover .u-icon__elem-hover {
  transform: translateY(-20%);
}

.u-icon-slide-down--hover .u-icon__elem-hover.u-line-icon-pro {
  transform: translateY(-30%);
}

.u-icon-slide-down--hover:hover .u-icon__elem-regular,
.u-icon-block--hover:hover .u-icon-slide-down--hover .u-icon__elem-regular {
  transform: translateY(-20%);
}

.u-icon-slide-down--hover:hover .u-icon__elem-regular.u-line-icon-pro,
.u-icon-block--hover:hover .u-icon-slide-down--hover .u-icon__elem-regular.u-line-icon-pro {
  transform: translateY(-30%);
}

.u-icon-slide-down--hover:hover .u-icon__elem-hover,
.u-icon-block--hover:hover .u-icon-slide-down--hover .u-icon__elem-hover {
  transform: translateY(80%);
}

.u-icon-slide-down--hover:hover .u-icon__elem-hover.u-line-icon-pro,
.u-icon-block--hover:hover .u-icon-slide-down--hover .u-icon__elem-hover.u-line-icon-pro {
  transform: translateY(90%);
}

.u-icon-slide-left--hover .u-icon__elem-regular {
  transform: translate(0, -20%);
}

.u-icon-slide-left--hover .u-icon__elem-regular.u-line-icon-pro {
  transform: translate(0, -30%);
}

.u-icon-slide-left--hover .u-icon__elem-hover {
  transform: translate(110%, -20%);
}

.u-icon-slide-left--hover .u-icon__elem-hover.u-line-icon-pro {
  transform: translate(110%, -30%);
}

.u-icon-slide-left--hover:hover .u-icon__elem-regular,
.u-icon-block--hover:hover .u-icon-slide-left--hover .u-icon__elem-regular {
  transform: translate(-110%, -20%);
}

.u-icon-slide-left--hover:hover .u-icon__elem-regular.u-line-icon-pro,
.u-icon-block--hover:hover .u-icon-slide-left--hover .u-icon__elem-regular.u-line-icon-pro {
  transform: translate(-110%, -30%);
}

.u-icon-slide-left--hover:hover .u-icon__elem-hover,
.u-icon-block--hover:hover .u-icon-slide-left--hover .u-icon__elem-hover {
  transform: translate(0, -20%);
}

.u-icon-slide-left--hover:hover .u-icon__elem-hover.u-line-icon-pro,
.u-icon-block--hover:hover .u-icon-slide-left--hover .u-icon__elem-hover.u-line-icon-pro {
  transform: translate(0, -30%);
}

.u-icon-slide-right--hover .u-icon__elem-regular {
  transform: translate(-110%, -20%);
}

.u-icon-slide-right--hover .u-icon__elem-regular.u-line-icon-pro {
  transform: translate(-110%, -30%);
}

.u-icon-slide-right--hover .u-icon__elem-hover {
  transform: translate(0, -20%);
}

.u-icon-slide-right--hover .u-icon__elem-hover.u-line-icon-pro {
  transform: translate(0, -30%);
}

.u-icon-slide-right--hover:hover .u-icon__elem-regular,
.u-icon-block--hover:hover .u-icon-slide-right--hover .u-icon__elem-regular {
  transform: translate(0, -20%);
}

.u-icon-slide-right--hover:hover .u-icon__elem-regular.u-line-icon-pro,
.u-icon-block--hover:hover .u-icon-slide-right--hover .u-icon__elem-regular.u-line-icon-pro {
  transform: translate(0, -30%);
}

.u-icon-slide-right--hover:hover .u-icon__elem-hover,
.u-icon-block--hover:hover .u-icon-slide-right--hover .u-icon__elem-hover {
  transform: translate(110%, -20%);
}

.u-icon-slide-right--hover:hover .u-icon__elem-hover.u-line-icon-pro,
.u-icon-block--hover:hover .u-icon-slide-right--hover .u-icon__elem-hover.u-line-icon-pro {
  transform: translate(110%, -30%);
}

/*------------------------------------
  Zoom Effect v1
------------------------------------*/
.u-icon-block--hover:hover .u-icon-scale-1_2--hover {
  transform: scale(1.2);
}

.u-icon-rotation.u-icon-scale-1_2--hover:hover,
.u-icon-block--hover:hover .u-icon-rotation.u-icon-scale-1_2--hover {
  transform: scale(1.2) rotate(45deg);
}

/*------------------------------------
  Changing a shape Effect
------------------------------------*/
.u-icon-square--hover:hover,
.u-icon-v4.u-icon-square--hover:hover .u-icon-v4-inner,
.u-icon-block--hover:hover .u-icon-square--hover,
.u-icon-block--hover:hover .u-icon-v4.u-icon-square--hover .u-icon-v4-inner {
  border-radius: 0;
}

.u-icon-rounded-3--hover:hover,
.u-icon-v4.u-icon-rounded-3--hover:hover .u-icon-v4-inner,
.u-icon-block--hover:hover .u-icon-rounded-3--hover,
.u-icon-block--hover:hover .u-icon-v4.u-icon-rounded-3--hover .u-icon-v4-inner {
  border-radius: 3px;
}

.u-icon-rounded-10--hover:hover,
.u-icon-v4.u-icon-rounded-10--hover:hover .u-icon-v4-inner,
.u-icon-block--hover:hover .u-icon-rounded-10--hover,
.u-icon-block--hover:hover .u-icon-v4.u-icon-rounded-10--hover .u-icon-v4-inner {
  border-radius: 10px;
}

.u-icon-rounded-50x--hover:hover,
.u-icon-v4.u-icon-rounded-50x--hover:hover .u-icon-v4-inner,
.u-icon-block--hover:hover .u-icon-rounded-50x--hover,
.u-icon-block--hover:hover .u-icon-v4.u-icon-rounded-50x--hover .u-icon-v4-inner {
  border-radius: 50%;
}

/*------------------------------------
  Effect v1
------------------------------------*/
.u-icon-effect-v1-1--hover::after,
.u-icon-effect-v1-2--hover::after, .u-icon-effect-v2--hover::after, .u-icon-effect-v4--hover::after, .u-icon-effect-v5--hover:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  box-sizing: content-box;
  pointer-events: none;
}

.u-icon-effect-v1-1--hover,
.u-icon-effect-v1-2--hover {
  position: relative;
  overflow: inherit;
}

.u-icon-effect-v1-1--hover::after,
.u-icon-effect-v1-2--hover::after {
  top: -0.5rem;
  left: -0.5rem;
  padding: 0.5rem;
  box-shadow: 0 0 0 1px #2cb8f3;
  transition: all .2s ease-in-out;
  opacity: 0;
}

.u-icon-effect-v1-1--hover:hover,
.u-icon-effect-v1-2--hover:hover,
.u-icon-v4.u-icon-effect-v1-1--hover:hover .u-icon-v4-inner,
.u-icon-v4.u-icon-effect-v1-2--hover:hover .u-icon-v4-inner,
.u-icon-block--hover:hover .u-icon-effect-v1-1--hover,
.u-icon-block--hover:hover .u-icon-effect-v1-2--hover,
.u-icon-block--hover:hover .u-icon-v4.u-icon-effect-v1-1--hover .u-icon-v4-inner,
.u-icon-block--hover:hover .u-icon-v4.u-icon-effect-v1-2--hover .u-icon-v4-inner {
  color: #fff;
  border-color: #2cb8f3;
  background: #2cb8f3 !important;
}

.u-icon-v4.u-icon-effect-v1-1--hover:hover,
.u-icon-v4.u-icon-effect-v1-2--hover:hover,
.u-icon-block--hover:hover .u-icon-v4.u-icon-effect-v1-1--hover,
.u-icon-block--hover:hover .u-icon-v4.u-icon-effect-v1-2--hover {
  border-color: transparent;
}

.u-icon-effect-v1-1--hover::after {
  transform: scale(0.8);
}

.u-icon-effect-v1-1--hover:hover::after,
.u-icon-block--hover:hover .u-icon-effect-v1-1--hover::after {
  transform: scale(1);
  opacity: 1;
}

.u-icon-effect-v1-2--hover::after {
  transform: scale(1.2);
}

.u-icon-effect-v1-2--hover:hover::after,
.u-icon-block--hover:hover .u-icon-effect-v1-2--hover::after {
  transform: scale(1);
  opacity: 1;
}

.u-icon-v4.u-icon-effect-v1-2--hover:hover::after,
.u-icon-block--hover:hover .u-icon-v4.u-icon-effect-v1-2--hover::after {
  transform: scale(0.8);
}

/*------------------------------------
  Effect v2
------------------------------------*/
.u-icon-effect-v1-1--hover::after,
.u-icon-effect-v1-2--hover::after, .u-icon-effect-v2--hover::after, .u-icon-effect-v4--hover::after, .u-icon-effect-v5--hover:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  box-sizing: content-box;
  pointer-events: none;
}

.u-icon-effect-v2--hover {
  transition: all .5s;
  overflow: inherit;
}

.u-icon-effect-v2--hover::after {
  top: -4px;
  left: -4px;
  padding: 4px;
  z-index: -1;
  transition: all .5s;
}

.u-icon-effect-v2--hover:hover,
.u-icon-effect-v2--hover:hover::after {
  background: #2cb8f3;
}

.u-icon-effect-v2--hover:hover::after,
.u-icon-block--hover:hover .u-icon-effect-v2--hover::after {
  opacity: 0;
  transform: scale(1.5);
}

/*------------------------------------
  Effect v3
------------------------------------*/
@keyframes spinAround {
  from {
    transform: translateY(-50%) rotate(0deg);
  }
  to {
    transform: translateY(-50%) rotate(360deg);
  }
}

.u-icon-effect-v3--hover:hover i,
.u-icon-block--hover:hover .u-icon-effect-v3--hover i {
  animation: spinAround 1s linear infinite;
}

/*------------------------------------
  Effect v4
------------------------------------*/
.u-icon-effect-v1-1--hover::after,
.u-icon-effect-v1-2--hover::after, .u-icon-effect-v2--hover::after, .u-icon-effect-v4--hover::after, .u-icon-effect-v5--hover:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  box-sizing: content-box;
  pointer-events: none;
}

.u-icon-effect-v4--hover {
  transition: transform ease-out .1s, background .2s;
  overflow: inherit;
}

.u-icon-effect-v4--hover::after {
  top: 0;
  left: 0;
  padding: 0;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  opacity: 0;
  z-index: -1;
  transform: scale(0.9);
}

.u-icon-effect-v4--hover:hover,
.u-icon-block--hover:hover .u-icon-effect-v4--hover {
  transform: scale(1.1);
}

.u-icon-rotation.u-icon-effect-v4--hover:hover,
.u-icon-block--hover:hover .u-icon-rotation.u-icon-effect-v4--hover {
  transform: scale(1.1) rotate(45deg);
}

.u-icon-effect-v4--hover:hover::after {
  animation: sonarEffect-default 1.3s ease-out 75ms;
}

.u-icon-effect-v4--hover.g-bg-primary:hover::after,
.u-icon-v2.u-icon-effect-v4--hover.g-color-primary:hover::after,
.u-icon-block--hover:hover .u-icon-effect-v4--hover.g-bg-primary::after,
.u-icon-block--hover:hover .u-icon-v2.u-icon-effect-v4--hover.g-color-primary::after {
  animation: sonarEffect-primary 1.3s ease-out 75ms;
}

@keyframes sonarEffect-default {
  0% {
    opacity: .3;
  }
  40% {
    opacity: .5;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #ddd, 0 0 0 10px rgba(255, 255, 255, 0.5);
  }
  100% {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #ddd, 0 0 0 10px rgba(255, 255, 255, 0.5);
    transform: scale(1.5);
    opacity: 0;
  }
}

@keyframes sonarEffect-primary {
  0% {
    opacity: .3;
  }
  40% {
    opacity: .5;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #2cb8f3, 0 0 0 10px rgba(255, 255, 255, 0.5);
  }
  100% {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #2cb8f3, 0 0 0 10px rgba(255, 255, 255, 0.5);
    transform: scale(1.5);
    opacity: 0;
  }
}

/*------------------------------------
  Effect v5
------------------------------------*/
.u-icon-effect-v1-1--hover::after,
.u-icon-effect-v1-2--hover::after, .u-icon-effect-v2--hover::after, .u-icon-effect-v4--hover::after, .u-icon-effect-v5--hover:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  box-sizing: content-box;
  pointer-events: none;
}

.u-icon-effect-v5--hover {
  transition: box-shadow .2s;
  overflow: inherit;
}

.u-icon-effect-v5--hover:after {
  top: 0;
  left: 0;
  padding: 0;
  transition: transform .2s, opacity .2s;
}

.u-icon-v3.u-icon-effect-v5--hover:after {
  box-shadow: 0 0 0 3px #fff;
}

.u-icon-effect-v5--hover:hover:after,
.u-icon-block--hover:hover .u-icon-effect-v5--hover:after {
  transform: scale(0.85);
  opacity: .5;
}

.u-icon-effect-v5--hover:hover,
.u-icon-block--hover:hover .u-icon-effect-v5--hover {
  box-shadow: 0 0 10px 10px #ddd;
}

.u-icon-effect-v5--hover.g-bg-primary:hover,
.u-icon-block--hover:hover .u-icon-effect-v5--hover.g-bg-primary,
.u-icon-effect-v5--hover.g-color-primary:hover,
.u-icon-block--hover:hover .u-icon-effect-v5--hover.g-color-primary {
  box-shadow: 0 0 10px 10px #2cb8f3;
}

/*------------------------------------
  Box-shadows Styles
------------------------------------*/
.u-shadow-none {
  box-shadow: none !important;
}

.u-shadow-none--focus:focus {
  box-shadow: none !important;
}

.g-parent:hover .u-shadow-none--parent-hover {
  box-shadow: none !important;
}

/*------------------------------------
  Box-shadows-v1
------------------------------------*/
.u-shadow-v1-1 {
  box-shadow: 0 0 5px #999;
}

.u-shadow-v1-2 {
  box-shadow: 0 0 10px #999;
}

.u-shadow-v1-2--hover:hover {
  box-shadow: 0 0 10px #999;
}

.g-parent:hover .u-shadow-v1-2--hover-parent {
  box-shadow: 0 0 10px #999;
}

.u-shadow-v1-3 {
  box-shadow: 0 0 5px #ddd;
}

.u-shadow-v1-4 {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.u-shadow-v1-5 {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.u-shadow-v1-5--hover:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

*:hover > .u-shadow-v1-5--hover-parent {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.u-shadow-v1-6 {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.u-shadow-v1-6--hover:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

*:hover > .u-shadow-v1-6--hover-parent {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.u-shadow-v1-7 {
  box-shadow: 0 0 8px #eee;
}

.u-shadow-v1-7--hover:hover {
  box-shadow: 0 0 8px #eee;
}

*:hover > .u-shadow-v1-7--hover-parent {
  box-shadow: 0 0 8px #eee;
}

/*------------------------------------
  Box-shadows-v2
------------------------------------*/
.u-shadow-v2 {
  box-shadow: 0 10px 6px -6px rgba(0, 0, 0, 0.2);
}

/*------------------------------------
  Box-shadows-v3-v4-v5
------------------------------------*/
.u-shadow-v3,
.u-shadow-v4,
.u-shadow-v5 {
  position: relative;
}

.u-shadow-v3::after,
.u-shadow-v3::before,
.u-shadow-v4::before,
.u-shadow-v5::after {
  content: "";
  position: absolute;
  top: 80%;
  left: 0.35714rem;
  bottom: 1.07143rem;
  width: 50%;
  max-width: 21.42857rem;
  background: rgba(0, 0, 0, 0.2);
  z-index: -1;
}

.u-shadow-v3::after,
.u-shadow-v3::before,
.u-shadow-v4::before,
.u-shadow-v5::after {
  box-shadow: 0 15px 10px rgba(0, 0, 0, 0.2);
  transform: rotate(-3deg);
}

.u-shadow-v3::after,
.u-shadow-v5::after {
  left: auto;
  right: 0.35714rem;
  transform: rotate(3deg);
}

/*------------------------------------
  Box-shadows-v6
------------------------------------*/
.u-shadow-v6 {
  box-shadow: 0 8px 6px -6px #555;
}

/*------------------------------------
  Box-shadows-v7
------------------------------------*/
.u-shadow-v7 {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}

/*------------------------------------
  Box-shadows-v8
------------------------------------*/
.u-shadow-v8 {
  box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.05);
}

.u-shadow-v8--hover:hover {
  box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.05);
}

.u-shadow-v8-1 {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
}

/*------------------------------------
  Box-shadows-v9
------------------------------------*/
.u-shadow-v9 {
  box-shadow: 1px 1px 1px #eee;
}

/*------------------------------------
  Box-shadows-v10
------------------------------------*/
.u-shadow-v10 {
  box-shadow: 2px 2px 2px #eee;
}

/*------------------------------------
  Box-shadows-v11
------------------------------------*/
.u-shadow-v11 {
  box-shadow: 0 0 2px #ccc;
}

/*------------------------------------
  Box-shadows-v12
------------------------------------*/
.u-shadow-v12 {
  box-shadow: inset 2px 2px 2px 0 #ccc;
}

/*------------------------------------
  Box-shadows-v13
------------------------------------*/
.u-shadow-v13 {
  box-shadow: inset 2px 2px 2px 0 #2cb8f3;
}

/*------------------------------------
  Box-shadows-v14
------------------------------------*/
.u-shadow-v14 {
  box-shadow: 2px 2px 2px 0 #eee;
}

/*------------------------------------
  Box-shadows-v15
------------------------------------*/
.u-shadow-v15 {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.3);
}

/*------------------------------------
  Box-shadows-v16
------------------------------------*/
.u-shadow-v16 {
  box-shadow: inset 5px 5px 5px 0 rgba(17, 17, 17, 0.5);
}

/*------------------------------------
  Box-shadows-v17
------------------------------------*/
.u-shadow-v17 {
  box-shadow: 3px 2px 8px 2px rgba(0, 0, 0, 0.17);
}

/*------------------------------------
  Box-shadows-v18
------------------------------------*/
.u-shadow-v18 {
  box-shadow: 0 5px 10px -6px rgba(0, 0, 0, 0.15);
}

/*------------------------------------
  Box-shadows-v19
------------------------------------*/
.u-shadow-v19 {
  box-shadow: 0 5px 10px -6px rgba(0, 0, 0, 0.1);
}

.u-shadow-v19--lightblue {
  box-shadow: 0 5px 10px -6px rgba(51, 152, 220, 0.15);
}

/*------------------------------------
  Box-shadows-v20
------------------------------------*/
.u-shadow-v20 {
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
}

.u-shadow-v20--hover:hover {
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
}

/*------------------------------------
  Box-shadows-v21
------------------------------------*/
.u-shadow-v21 {
  box-shadow: 0 20px 25px -12px rgba(0, 0, 0, 0.09);
  transition-property: all;
  transition-timing-function: ease;
  transition-delay: 0s;
  transition-duration: .3s;
}

.u-shadow-v21--hover:hover {
  box-shadow: 0 20px 25px -12px rgba(0, 0, 0, 0.15);
}

/*------------------------------------
  Box-shadows-v22
------------------------------------*/
.u-shadow-v22 {
  box-shadow: 0 2px 5px #eee;
}

/*------------------------------------
  Box-shadows-v23
------------------------------------*/
.u-shadow-v23 {
  box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.2);
}

/*------------------------------------
  Box-shadows-v24
------------------------------------*/
.u-shadow-v24 {
  box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.06);
}

.g-parent.active .u-shadow-v24--active {
  box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.06);
}

/*------------------------------------
  Box-shadows-v25
------------------------------------*/
.u-shadow-v25 {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.07);
}

/*------------------------------------
  Box-shadows-v26
------------------------------------*/
.u-shadow-v26 {
  box-shadow: inset 5px 0 10px rgba(0, 0, 0, 0.2);
}

/*------------------------------------
  Box-shadows-v27
------------------------------------*/
.u-shadow-v27 {
  box-shadow: 0 0 3px #b5b5b5;
}

/*------------------------------------
  Box-shadows-v28
------------------------------------*/
.u-shadow-v28 {
  box-shadow: 0 10px 45px -5px rgba(0, 0, 0, 0.04);
}

/*------------------------------------
  Box-shadows-v29
------------------------------------*/
.u-shadow-v29 {
  box-shadow: 0 15px 50px 0 rgba(0, 0, 0, 0.1);
}

.u-shadow-v29--lightblue {
  box-shadow: 0 10px 30px 0 rgba(51, 152, 220, 0.075);
}

/*------------------------------------
  Box-shadows-v30
------------------------------------*/
.u-shadow-v30 {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

/*------------------------------------
  Box-shadows-v31
------------------------------------*/
.u-shadow-v31 {
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.075);
}

/*------------------------------------
  Box-shadows-v32
------------------------------------*/
.u-shadow-v32 {
  box-shadow: 0 6px 15px -6px rgba(0, 0, 0, 0.1);
}

/*------------------------------------
  Box-shadows-v33
------------------------------------*/
.u-shadow-v33 {
  box-shadow: 0 6px 15px -6px rgba(44, 184, 243, 0.3);
}

/*------------------------------------
  Box-shadows-v34
------------------------------------*/
.u-shadow-v34 {
  box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.1);
}

/*------------------------------------
  Box-shadows-v35
------------------------------------*/
.u-shadow-v35 {
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.045);
}

.u-shadow-v35--active.active {
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.045);
  transition-duration: .2s;
}

.u-shadow-v35.g-bg-teal-opacity-0_1 {
  box-shadow: 0 4px 7px 0 rgba(24, 186, 155, 0.175);
}

.u-shadow-v35.g-bg-purple-opacity-0_1 {
  box-shadow: 0 4px 7px 0 rgba(154, 105, 203, 0.175);
}

.u-shadow-v35.g-bg-blue-opacity-0_1 {
  box-shadow: 0 4px 7px 0 rgba(51, 152, 220, 0.175);
}

/*------------------------------------
  Box-shadows-v36
------------------------------------*/
.u-shadow-v36 {
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.14);
}

/*------------------------------------
  Box-shadows-v37
------------------------------------*/
.u-shadow-v37 {
  box-shadow: 0 13px 25px 0 rgba(235, 237, 242, 0.7);
}

.u-shadow-v37--hover:hover {
  box-shadow: 0 13px 25px 0 rgba(235, 237, 242, 0.7);
  transition-duration: .2s;
}

/*------------------------------------
  Box-shadows-v38
------------------------------------*/
.u-shadow-v38 {
  box-shadow: 0 2px 35px 0 rgba(235, 237, 242, 0.7);
}

/*------------------------------------
  Box-shadows-v39
------------------------------------*/
.u-shadow-v39 {
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
}

/*------------------------------------
  Box-shadows-v40
------------------------------------*/
.u-shadow-v40 {
  box-shadow: -9px -9px 20px -9px rgba(0, 0, 0, 0.1);
}

/*------------------------------------
  Box-shadows-v41
------------------------------------*/
.u-shadow-v41 {
  box-shadow: 10px 40px 120px rgba(0, 0, 0, 0.1);
}

/*------------------------------------
  Form Elements
------------------------------------*/
.u-form-control {
  padding: .8rem 1rem .6rem;
}

.u-form-control::placeholder {
  color: inherit;
  opacity: .3;
}

.u-form-control::-moz-placeholder {
  color: inherit;
  opacity: .3;
}

.u-form-control::-webkit-input-placeholder {
  color: inherit;
  opacity: .3;
}

.u-form-control-sm {
  padding: .4rem .5rem .1rem;
  font-size: .875rem;
}

.u-form-control-lg {
  padding: .75rem 1.5rem .55rem;
  font-size: 1.25rem;
}

.u-form-control-shadow--focus:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.u-textarea-expandable {
  max-height: 42px;
  transition-property: max-height;
  transition-duration: .2s;
  transition-timing-function: ease-in;
}

.u-textarea-expandable:focus {
  max-height: 90px;
}

.u-input-group-addon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
}

.form-control + .u-input-group-addon:not(:first-child) {
  border-left: 0;
}

/*------------------------------------
  States
------------------------------------*/
/*------------------------------------
  Success states
------------------------------------*/
/*------------------------------------
  Success state v1-1
------------------------------------*/
.u-has-success-v1-1 .form-control,
.u-has-success-v1-1 [class*="input-group-"],
.u-has-success-v1-1 [class*="u-select"] {
  background-color: #f0fff0;
  border-color: #2cb8f3;
}

.u-has-success-v1-1 .form-control[readonly],
.u-has-success-v1-1 [class*="input-group-"][readonly],
.u-has-success-v1-1 [class*="u-select"][readonly] {
  background-color: #f0fff0;
}

.u-has-success-v1-1 .form-control-feedback {
  color: #2cb8f3;
}

.u-has-success-v1-1 [class*="u-check-icon"] {
  color: #f0fff0;
  background-color: #f0fff0;
}

.u-has-success-v1-1 [class*="u-check-icon"] i {
  color: rgba(44, 184, 243, 0.2);
}

.u-has-success-v1-1 [class*="input-group-"],
.u-has-success-v1-1 [class*="input-group-"]:last-child,
.u-has-success-v1-1 .chosen-single div b {
  color: #2cb8f3;
}

/*------------------------------------
  Success state v1-2
------------------------------------*/
.u-has-success-v1-2 {
  position: relative;
}

.u-has-success-v1-2 .form-control,
.u-has-success-v1-2 [class*="input-group-"],
.u-has-success-v1-2 [class*="u-select"] {
  background-color: #f0fff0;
  border-color: #2cb8f3;
}

.u-has-success-v1-2 .form-control[readonly],
.u-has-success-v1-2 [class*="input-group-"][readonly],
.u-has-success-v1-2 [class*="u-select"][readonly] {
  background-color: #f0fff0;
}

.u-has-success-v1-2 .form-control-feedback {
  color: #2cb8f3;
}

.u-has-success-v1-2 [class*="u-check-icon"] {
  color: #f0fff0;
  background-color: #f0fff0;
  border-radius: 50%;
}

.u-has-success-v1-2 [class*="u-check-icon"] i {
  color: rgba(44, 184, 243, 0.2);
}

.u-has-success-v1-2 [class*="input-group-"],
.u-has-success-v1-2 [class*="input-group-"]:last-child,
.u-has-success-v1-2 .chosen-single div b {
  color: #2cb8f3;
}

.u-has-success-v1-2 [class*="input-group-"],
.u-has-success-v1-2 [class*="input-group-"]:last-child {
  color: #fff;
  background-color: #2cb8f3;
}

.u-has-success-v1-2 .form-control-feedback::before {
  content: "";
  position: absolute;
  bottom: -3px;
  right: 18px;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 3px 3px 0 3px;
  border-color: #2cb8f3 transparent transparent transparent;
}

/*------------------------------------
  Error states
------------------------------------*/
/*------------------------------------
  Error state v1
------------------------------------*/
.u-has-error-v1 .form-control,
.u-has-error-v1 [class*="input-group-"],
.u-has-error-v1 [class*="u-select"] {
  background-color: #fff0f0;
}

.u-has-error-v1 .form-control[readonly],
.u-has-error-v1 [class*="input-group-"][readonly],
.u-has-error-v1 [class*="u-select"][readonly] {
  background-color: #fff0f0;
}

.u-has-error-v1 .form-control-feedback {
  color: #f00;
}

.u-has-error-v1 [class*="u-check-icon"] {
  background-color: #fff0f0;
}

/*------------------------------------
  Error state v1-2
------------------------------------*/
.u-has-error-v1-2 .form-control,
.u-has-error-v1-2 [class*="input-group-"],
.u-has-error-v1-2 [class*="u-select"] {
  background-color: #fff0f0;
  border-color: #f00;
}

.u-has-error-v1-2 .form-control[readonly],
.u-has-error-v1-2 [class*="input-group-"][readonly],
.u-has-error-v1-2 [class*="u-select"][readonly] {
  background-color: #fff0f0;
}

.u-has-error-v1-2 .form-control-feedback {
  color: #f00;
}

.u-has-error-v1-2 [class*="u-check-icon"] {
  background-color: #fff0f0;
}

.u-has-error-v1-2 [class*="input-group-"]:last-child,
.u-has-error-v1-2 .chosen-single div b {
  color: #f00;
}

.u-has-error-v1-2 .error {
  display: block;
}

/*------------------------------------
  Error state v1-3
------------------------------------*/
.u-has-error-v1-3 {
  position: relative;
}

.u-has-error-v1-3 .form-control,
.u-has-error-v1-3 [class*="input-group-"],
.u-has-error-v1-3 [class*="u-select"] {
  background-color: #fff0f0;
  border-color: #f00;
}

.u-has-error-v1-3 .form-control[readonly],
.u-has-error-v1-3 [class*="input-group-"][readonly],
.u-has-error-v1-3 [class*="u-select"][readonly] {
  background-color: #fff0f0;
}

.u-has-error-v1-3 .form-control-feedback {
  color: #f00;
}

.u-has-error-v1-3 .form-control-feedback::before {
  content: "";
  position: absolute;
  bottom: -3px;
  right: 18px;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 3px 3px 0 3px;
  border-color: #f00 transparent transparent transparent;
}

.u-has-error-v1-3 [class*="u-check-icon"] {
  background-color: #fff0f0;
}

.u-has-error-v1-3 [class*="input-group-"]:last-child,
.u-has-error-v1-3 .chosen-single div b {
  color: #f00;
}

.u-has-error-v1-3 .error {
  display: block;
}

.u-has-error-v1-3 [class*="input-group-"],
.u-has-error-v1-3 [class*="input-group-"]:last-child {
  color: #fff;
  background-color: #f00;
}

/*------------------------------------
  Error state v2
------------------------------------*/
.u-has-error-v2:after {
  content: '\f00d';
  position: absolute;
  top: 50%;
  right: 15px;
  font-family: 'FontAwesome', sans-serif;
  color: #f00;
  transform: translateY(-50%);
}

.u-has-error-v2 input ,
.u-has-error-v2 textarea {
  border-color: #f00;
}

/*------------------------------------
  Disabled states
------------------------------------*/
/*------------------------------------
  Disabled state v1
------------------------------------*/
.u-has-disabled-v1 label,
.u-has-disabled-v1 .form-control,
.u-has-disabled-v1 [class*="input-group-"],
.u-has-disabled-v1 [class*="u-select"] {
  background-color: #fff;
  opacity: .5;
}

.u-has-disabled-v1 label:disabled,
.u-has-disabled-v1 .form-control:disabled,
.u-has-disabled-v1 [class*="input-group-"]:disabled,
.u-has-disabled-v1 [class*="u-select"]:disabled {
  background-color: #fff;
  opacity: .5;
}

/*------------------------------------
  Checked state colors
------------------------------------*/
/* White Colors */
input[type="checkbox"]:checked + .g-color-white--checked,
input[type="radio"]:checked + .g-color-white--checked,
input[type="checkbox"]:checked + * .g-color-white--checked,
input[type="radio"]:checked + * .g-color-white--checked {
  color: #fff !important;
}

.g-color-white--checked.g-checked,
.g-checked .g-color-white--checked {
  color: #fff !important;
}

/*------------------------------------
  Focus state color
------------------------------------*/
/* Primary Colors */
.g-color-primary:focus {
  color: #2cb8f3;
}

/* Black Colors */
.g-color-black--focus:focus {
  color: #000;
}

/* White Colors */
.g-color-white--focus:focus {
  color: #fff !important;
}

/* Gray Colors */
.g-color-gray-light-v4:focus {
  color: #eee !important;
}

/*------------------------------------
  Checked state background
------------------------------------*/
/* Primary Colors */
input[type="checkbox"]:checked + .g-bg-primary--checked,
input[type="radio"]:checked + .g-bg-primary--checked,
input[type="checkbox"]:checked + * .g-bg-primary--checked,
input[type="radio"]:checked + * .g-bg-primary--checked {
  background-color: #2cb8f3 !important;
}

.g-bg-primary--checked.g-checked,
.g-checked .g-bg-primary--checked {
  background-color: #2cb8f3 !important;
}

/* Black Colors */
input[type="checkbox"]:checked + .g-bg-black--checked,
input[type="radio"]:checked + .g-bg-black--checked,
input[type="checkbox"]:checked + * .g-bg-black--checked,
input[type="radio"]:checked + * .g-bg-black--checked {
  background-color: #000 !important;
}

.g-bg-black--checked.g-checked,
.g-checked .g-bg-black--checked {
  background-color: #000 !important;
}

/* White Colors */
input[type="checkbox"]:checked + .g-bg-white--checked,
input[type="radio"]:checked + .g-bg-white--checked,
input[type="checkbox"]:checked + * .g-bg-white--checked,
input[type="radio"]:checked + * .g-bg-white--checked {
  background-color: #fff !important;
}

.g-bg-white--checked.g-checked,
.g-checked .g-bg-white--checked {
  background-color: #fff !important;
}

/* Gray Colors */
input[type="checkbox"]:checked + .g-bg-dark-dark-v1--checked,
input[type="radio"]:checked + .g-bg-dark-dark-v1--checked,
input[type="checkbox"]:checked + * .g-bg-dark-dark-v1--checked,
input[type="radio"]:checked + * .g-bg-dark-dark-v1--checked {
  background-color: #111 !important;
}

.g-bg-dark-dark-v1--checked.g-checked,
.g-checked .g-bg-dark-dark-v1--checked {
  background-color: #111 !important;
}

input[type="checkbox"]:checked + .g-bg-dark-dark-v2--checked,
input[type="radio"]:checked + .g-bg-dark-dark-v2--checked,
input[type="checkbox"]:checked + * .g-bg-dark-dark-v2--checked,
input[type="radio"]:checked + * .g-bg-dark-dark-v2--checked {
  background-color: #333 !important;
}

.g-bg-dark-dark-v2--checked.g-checked,
.g-checked .g-bg-dark-dark-v2--checked {
  background-color: #333 !important;
}

input[type="checkbox"]:checked + .g-bg-dark-dark-v3--checked,
input[type="radio"]:checked + .g-bg-dark-dark-v3--checked,
input[type="checkbox"]:checked + * .g-bg-dark-dark-v3--checked,
input[type="radio"]:checked + * .g-bg-dark-dark-v3--checked {
  background-color: #555 !important;
}

.g-bg-dark-dark-v3--checked.g-checked,
.g-checked .g-bg-dark-dark-v3--checked {
  background-color: #555 !important;
}

input[type="checkbox"]:checked + .g-bg-dark-dark-v4--checked,
input[type="radio"]:checked + .g-bg-dark-dark-v4--checked,
input[type="checkbox"]:checked + * .g-bg-dark-dark-v4--checked,
input[type="radio"]:checked + * .g-bg-dark-dark-v4--checked {
  background-color: #777 !important;
}

.g-bg-dark-dark-v4--checked.g-checked,
.g-checked .g-bg-dark-dark-v4--checked {
  background-color: #777 !important;
}

input[type="checkbox"]:checked + .g-bg-dark-dark-v5--checked,
input[type="radio"]:checked + .g-bg-dark-dark-v5--checked,
input[type="checkbox"]:checked + * .g-bg-dark-dark-v5--checked,
input[type="radio"]:checked + * .g-bg-dark-dark-v5--checked {
  background-color: #999 !important;
}

.g-bg-dark-dark-v5--checked.g-checked,
.g-checked .g-bg-dark-dark-v5--checked {
  background-color: #999 !important;
}

input[type="checkbox"]:checked + .g-bg-dark-light-v1--checked,
input[type="radio"]:checked + .g-bg-dark-light-v1--checked,
input[type="checkbox"]:checked + * .g-bg-dark-light-v1--checked,
input[type="radio"]:checked + * .g-bg-dark-light-v1--checked {
  background-color: #bbb !important;
}

.g-bg-dark-light-v1--checked.g-checked,
.g-checked .g-bg-dark-light-v1--checked {
  background-color: #bbb !important;
}

input[type="checkbox"]:checked + .g-bg-dark-light-v2--checked,
input[type="radio"]:checked + .g-bg-dark-light-v2--checked,
input[type="checkbox"]:checked + * .g-bg-dark-light-v2--checked,
input[type="radio"]:checked + * .g-bg-dark-light-v2--checked {
  background-color: #ccc !important;
}

.g-bg-dark-light-v2--checked.g-checked,
.g-checked .g-bg-dark-light-v2--checked {
  background-color: #ccc !important;
}

input[type="checkbox"]:checked + .g-bg-dark-light-v3--checked,
input[type="radio"]:checked + .g-bg-dark-light-v3--checked,
input[type="checkbox"]:checked + * .g-bg-dark-light-v3--checked,
input[type="radio"]:checked + * .g-bg-dark-light-v3--checked {
  background-color: #ddd !important;
}

.g-bg-dark-light-v3--checked.g-checked,
.g-checked .g-bg-dark-light-v3--checked {
  background-color: #ddd !important;
}

input[type="checkbox"]:checked + .g-bg-dark-light-v4--checked,
input[type="radio"]:checked + .g-bg-dark-light-v4--checked,
input[type="checkbox"]:checked + * .g-bg-dark-light-v4--checked,
input[type="radio"]:checked + * .g-bg-dark-light-v4--checked {
  background-color: #eee !important;
}

.g-bg-dark-light-v4--checked.g-checked,
.g-checked .g-bg-dark-light-v4--checked {
  background-color: #eee !important;
}

input[type="checkbox"]:checked + .g-bg-dark-light-v5--checked,
input[type="radio"]:checked + .g-bg-dark-light-v5--checked,
input[type="checkbox"]:checked + * .g-bg-dark-light-v5--checked,
input[type="radio"]:checked + * .g-bg-dark-light-v5--checked {
  background-color: #f7f7f7 !important;
}

.g-bg-dark-light-v5--checked.g-checked,
.g-checked .g-bg-dark-light-v5--checked {
  background-color: #f7f7f7 !important;
}

/* Transparent */
input[type="checkbox"]:checked + .g-bg-transparent--checked,
input[type="radio"]:checked + .g-bg-transparent--checked,
input[type="checkbox"]:checked + * .g-bg-transparent--checked,
input[type="radio"]:checked + * .g-bg-transparent--checked {
  background-color: transparent !important;
}

.g-bg-transparent--checked.g-checked,
.g-checked .g-bg-transparent--checked {
  background-color: transparent !important;
}

/* Color Red */
input[type="checkbox"]:checked + .g-bg-red--checked,
input[type="radio"]:checked + .g-bg-red--checked,
input[type="checkbox"]:checked + * .g-bg-red--checked,
input[type="radio"]:checked + * .g-bg-red--checked {
  background-color: #f00 !important;
}

.g-bg-red--checked.g-checked,
.g-checked .g-bg-red--checked {
  background-color: #f00 !important;
}

/*------------------------------------
  Checked state Colors
------------------------------------*/
/* Primary Colors */
input[type="checkbox"]:checked + .g-color-primary--checked,
input[type="radio"]:checked + .g-color-primary--checked,
input[type="checkbox"]:checked + * .g-color-primary--checked,
input[type="radio"]:checked + * .g-color-primary--checked {
  color: #2cb8f3 !important;
}

.g-color-primary--checked.g-checked,
.g-checked .g-color-primary--checked {
  color: #2cb8f3 !important;
}

/*------------------------------------
  Focus state background
------------------------------------*/
/* Black Colors */
.g-bg-black--focus:focus {
  background-color: #000 !important;
}

.g-bg-black-opacity-0_2--focus:focus {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.g-bg-black-opacity-0_4--focus:focus {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

/* Gray Colors */
.g-bg-gray-dark-v1--focus:focus {
  background-color: #111 !important;
}

.g-bg-gray-dark-v2--focus:focus {
  background-color: #333 !important;
}

.g-bg-gray-dark-v3--focus:focus {
  background-color: #555 !important;
}

.g-bg-gray-light-v4--focus:focus {
  background-color: #eee !important;
}

.g-bg-gray-light-v5--focus:focus {
  background-color: #f7f7f7 !important;
}

/* Transparent */
.g-bg-transparent--focus:focus {
  background-color: transparent;
}

/*------------------------------------
  Checked state border
------------------------------------*/
/* Primary Colors */
input[type="checkbox"]:checked + .g-brd-primary--checked,
input[type="radio"]:checked + .g-brd-primary--checked,
input[type="checkbox"]:checked + * .g-brd-primary--checked,
input[type="radio"]:checked + * .g-brd-primary--checked {
  border-color: #2cb8f3 !important;
}

.g-brd-primary--checked.g-checked,
.g-checked .g-brd-primary--checked {
  border-color: #2cb8f3 !important;
}

/* Black Colors */
input[type="checkbox"]:checked + .g-brd-black--checked,
input[type="radio"]:checked + .g-brd-black--checked,
input[type="checkbox"]:checked + * .g-brd-black--checked,
input[type="radio"]:checked + * .g-brd-black--checked {
  border-color: #000 !important;
}

.g-brd-black--checked.g-checked,
.g-checked .g-brd-black--checked {
  border-color: #000 !important;
}

/* White */
input[type="checkbox"]:checked + .g-brd-white--checked,
input[type="radio"]:checked + .g-brd-white--checked,
input[type="checkbox"]:checked + * .g-brd-white--checked,
input[type="radio"]:checked + * .g-brd-white--checked {
  border-color: #fff !important;
}

.g-brd-white--checked.g-checked,
.g-checked .g-brd-white--checked {
  border-color: #fff !important;
}

/* Gray Colors */
input[type="checkbox"]:checked + .g-brd-gray-dark-v1--checked,
input[type="radio"]:checked + .g-brd-gray-dark-v1--checked,
input[type="checkbox"]:checked + * .g-brd-gray-dark-v1--checked,
input[type="radio"]:checked + * .g-brd-gray-dark-v1--checked {
  border-color: #111 !important;
}

.g-brd-gray-dark-v1--checked.g-checked,
.g-checked .g-brd-gray-dark-v1--checked {
  border-color: #111 !important;
}

input[type="checkbox"]:checked + .g-brd-gray-dark-v2--checked,
input[type="radio"]:checked + .g-brd-gray-dark-v2--checked,
input[type="checkbox"]:checked + * .g-brd-gray-dark-v2--checked,
input[type="radio"]:checked + * .g-brd-gray-dark-v2--checked {
  border-color: #333 !important;
}

.g-brd-gray-dark-v2--checked.g-checked,
.g-checked .g-brd-gray-dark-v2--checked {
  border-color: #333 !important;
}

input[type="checkbox"]:checked + .g-brd-gray-dark-v3--checked,
input[type="radio"]:checked + .g-brd-gray-dark-v3--checked,
input[type="checkbox"]:checked + * .g-brd-gray-dark-v3--checked,
input[type="radio"]:checked + * .g-brd-gray-dark-v3--checked {
  border-color: #555 !important;
}

.g-brd-gray-dark-v3--checked.g-checked,
.g-checked .g-brd-gray-dark-v3--checked {
  border-color: #555 !important;
}

input[type="checkbox"]:checked + .g-brd-gray-dark-v4--checked,
input[type="radio"]:checked + .g-brd-gray-dark-v4--checked,
input[type="checkbox"]:checked + * .g-brd-gray-dark-v4--checked,
input[type="radio"]:checked + * .g-brd-gray-dark-v4--checked {
  border-color: #777 !important;
}

.g-brd-gray-dark-v4--checked.g-checked,
.g-checked .g-brd-gray-dark-v4--checked {
  border-color: #777 !important;
}

input[type="checkbox"]:checked + .g-brd-gray-dark-v5--checked,
input[type="radio"]:checked + .g-brd-gray-dark-v5--checked,
input[type="checkbox"]:checked + * .g-brd-gray-dark-v5--checked,
input[type="radio"]:checked + * .g-brd-gray-dark-v5--checked {
  border-color: #999 !important;
}

.g-brd-gray-dark-v5--checked.g-checked,
.g-checked .g-brd-gray-dark-v5--checked {
  border-color: #999 !important;
}

input[type="checkbox"]:checked + .g-brd-gray-light-v1--checked,
input[type="radio"]:checked + .g-brd-gray-light-v1--checked,
input[type="checkbox"]:checked + * .g-brd-gray-light-v1--checked,
input[type="radio"]:checked + * .g-brd-gray-light-v1--checked {
  border-color: #bbb !important;
}

.g-brd-gray-light-v1--checked.g-checked,
.g-checked .g-brd-gray-light-v1--checked {
  border-color: #bbb !important;
}

input[type="checkbox"]:checked + .g-brd-gray-light-v2--checked,
input[type="radio"]:checked + .g-brd-gray-light-v2--checked,
input[type="checkbox"]:checked + * .g-brd-gray-light-v2--checked,
input[type="radio"]:checked + * .g-brd-gray-light-v2--checked {
  border-color: #ccc !important;
}

.g-brd-gray-light-v2--checked.g-checked,
.g-checked .g-brd-gray-light-v2--checked {
  border-color: #ccc !important;
}

input[type="checkbox"]:checked + .g-brd-gray-light-v3--checked,
input[type="radio"]:checked + .g-brd-gray-light-v3--checked,
input[type="checkbox"]:checked + * .g-brd-gray-light-v3--checked,
input[type="radio"]:checked + * .g-brd-gray-light-v3--checked {
  border-color: #ddd !important;
}

.g-brd-gray-light-v3--checked.g-checked,
.g-checked .g-brd-gray-light-v3--checked {
  border-color: #ddd !important;
}

input[type="checkbox"]:checked + .g-brd-gray-light-v4--checked,
input[type="radio"]:checked + .g-brd-gray-light-v4--checked,
input[type="checkbox"]:checked + * .g-brd-gray-light-v4--checked,
input[type="radio"]:checked + * .g-brd-gray-light-v4--checked {
  border-color: #eee !important;
}

.g-brd-gray-light-v4--checked.g-checked,
.g-checked .g-brd-gray-light-v4--checked {
  border-color: #eee !important;
}

input[type="checkbox"]:checked + .g-brd-gray-light-v5--checked,
input[type="radio"]:checked + .g-brd-gray-light-v5--checked,
input[type="checkbox"]:checked + * .g-brd-gray-light-v5--checked,
input[type="radio"]:checked + * .g-brd-gray-light-v5--checked {
  border-color: #f7f7f7 !important;
}

.g-brd-gray-light-v5--checked.g-checked,
.g-checked .g-brd-gray-light-v5--checked {
  border-color: #f7f7f7 !important;
}

/* Transparent */
input[type="checkbox"]:checked + .g-brd-transparent--checked,
input[type="radio"]:checked + .g-brd-transparent--checked,
input[type="checkbox"]:checked + * .g-brd-transparent--checked,
input[type="radio"]:checked + * .g-brd-transparent--checked {
  border-color: transparent !important;
}

.g-brd-transparent--checked.g-checked,
.g-checked .g-brd-transparent--checked {
  border-color: transparent !important;
}

/* Color Red */
input[type="checkbox"]:checked + .g-brd-red--checked,
input[type="radio"]:checked + .g-brd-red--checked,
input[type="checkbox"]:checked + * .g-brd-red--checked,
input[type="radio"]:checked + * .g-brd-red--checked {
  border-color: #f00 !important;
}

.g-brd-red--checked.g-checked,
.g-checked .g-brd-red--checked {
  border-color: #f00 !important;
}

/*------------------------------------
  Focus state border
------------------------------------*/
/* Primary Colors */
.g-brd-primary--focus:focus,
.g-brd-primary--focus.g-state-focus *,
.g-brd-primary--focus.g-state-focus *:focus {
  border-color: #2cb8f3 !important;
}

/* Black Colors */
.g-brd-black--focus:focus,
.g-brd-black--focus.g-state-focus *,
.g-brd-black--focus.g-state-focus *:focus {
  border-color: #000 !important;
}

/* White */
.g-brd-white--focus:focus,
.g-brd-white--focus.g-state-focus *,
.g-brd-white--focus.g-state-focus *:focus {
  border-color: #fff !important;
}

/* Gray Colors */
.g-brd-gray-dark-v1--focus:focus,
.g-brd-gray-dark-v1--focus.g-state-focus *,
.g-brd-gray-dark-v1--focus.g-state-focus *:focus {
  border-color: #111 !important;
}

.g-brd-gray-dark-v2--focus:focus,
.g-brd-gray-dark-v2--focus.g-state-focus *,
.g-brd-gray-dark-v2--focus.g-state-focus *:focus {
  border-color: #333 !important;
}

.g-brd-gray-dark-v3--focus:focus,
.g-brd-gray-dark-v3--focus.g-state-focus *,
.g-brd-gray-dark-v3--focus.g-state-focus *:focus {
  border-color: #555 !important;
}

.g-brd-gray-dark-v4--focus:focus,
.g-brd-gray-dark-v4--focus.g-state-focus *,
.g-brd-gray-dark-v4--focus.g-state-focus *:focus {
  border-color: #777 !important;
}

.g-brd-gray-dark-v5--focus:focus,
.g-brd-gray-dark-v5--focus.g-state-focus *,
.g-brd-gray-dark-v5--focus.g-state-focus *:focus {
  border-color: #999 !important;
}

.g-brd-gray-dark-light-v1--focus:focus,
.g-brd-gray-dark-light-v1--focus.g-state-focus *,
.g-brd-gray-dark-light-v1--focus.g-state-focus *:focus {
  border-color: #bbb !important;
}

.g-brd-gray-dark-light-v2--focus:focus,
.g-brd-gray-dark-light-v2--focus.g-state-focus *,
.g-brd-gray-dark-light-v2--focus.g-state-focus *:focus {
  border-color: #ccc !important;
}

.g-brd-gray-dark-light-v3--focus:focus,
.g-brd-gray-dark-light-v3--focus.g-state-focus *,
.g-brd-gray-dark-light-v3--focus.g-state-focus *:focus {
  border-color: #ddd !important;
}

.g-brd-gray-dark-light-v4--focus:focus,
.g-brd-gray-dark-light-v4--focus.g-state-focus *,
.g-brd-gray-dark-light-v4--focus.g-state-focus *:focus {
  border-color: #eee !important;
}

.g-brd-gray-dark-light-v5--focus:focus,
.g-brd-gray-dark-light-v5--focus.g-state-focus *,
.g-brd-gray-dark-light-v5--focus.g-state-focus *:focus {
  border-color: #f7f7f7 !important;
}

/* Transparent */
.g-brd-transparent--focus:focus,
.g-brd-transparent--focus.g-state-focus *,
.g-brd-transparent--focus.g-state-focus *:focus {
  border-color: transparent !important;
}

/* Color Red */
.g-brd-red--focus:focus,
.g-brd-red--focus.g-state-focus *,
.g-brd-red--focus.g-state-focus *:focus {
  border-color: #f00 !important;
}

/*------------------------------------
  Checked state box shadow
------------------------------------*/
input[type="checkbox"]:checked + .u-shadow-v1-5--checked,
input[type="radio"]:checked + .u-shadow-v1-5--checked,
input[type="checkbox"]:checked + * .u-shadow-v1-5--checked,
input[type="radio"]:checked + * .u-shadow-v1-5--checked {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.u-shadow-v1-5--checked.g-checked,
.g-checked .u-shadow-v1-5--checked {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

input[type="checkbox"]:checked + .u-shadow-v1-v6--checked,
input[type="radio"]:checked + .u-shadow-v1-v6--checked,
input[type="checkbox"]:checked + * .u-shadow-v1-v6--checked,
input[type="radio"]:checked + * .u-shadow-v1-v6--checked {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.u-shadow-v1-v6--checked.g-checked,
.g-checked .u-shadow-v1-v6--checked {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

/*------------------------------------
  Checked state opacity
------------------------------------*/
input[type="checkbox"]:checked + .g-opacity-1--checked,
input[type="radio"]:checked + .g-opacity-1--checked,
input[type="checkbox"]:checked + * .g-opacity-1--checked,
input[type="radio"]:checked + * .g-opacity-1--checked {
  opacity: 1;
}

.g-opacity-1--checked.g-checked,
.g-checked .g-opacity-1--checked {
  opacity: 1;
}

/*------------------------------------
  Checked state overlay
------------------------------------*/
input[type="checkbox"]:checked + .g-overlay-black-0_5--checked::after,
input[type="radio"]:checked + .g-overlay-black-0_5--checked::after,
input[type="checkbox"]:checked + * > .g-overlay-black-0_5--checked::after,
input[type="radio"]:checked + * > .g-overlay-black-0_5--checked::after {
  background-color: rgba(0, 0, 0, 0.5);
}

input[type="checkbox"]:checked + .g-overlay-black-0_7--checked::after,
input[type="radio"]:checked + .g-overlay-black-0_7--checked::after,
input[type="checkbox"]:checked + * > .g-overlay-black-0_7--checked::after,
input[type="radio"]:checked + * > .g-overlay-black-0_7--checked::after {
  background-color: rgba(0, 0, 0, 0.7);
}

input[type="checkbox"]:checked + .g-overlay-black-gradient-v1--checked::after,
input[type="radio"]:checked + .g-overlay-black-gradient-v1--checked::after,
input[type="checkbox"]:checked + * > .g-overlay-black-gradient-v1--checked::after,
input[type="radio"]:checked + * > .g-overlay-black-gradient-v1--checked::after {
  background-image: linear-gradient(to bottom, transparent 40%, #000 100%);
}

input[type="checkbox"]:checked + .g-overlay-primary-0_5--checked::after,
input[type="radio"]:checked + .g-overlay-primary-0_5--checked::after,
input[type="checkbox"]:checked + * .g-overlay-primary-0_5--checked::after,
input[type="radio"]:checked + * .g-overlay-primary-0_5--checked::after {
  background-color: rgba(44, 184, 243, 0.5);
}

.g-overlay-primary-0_5--checked.g-checked::after,
.g-checked .g-overlay-primary-0_5--checked::after {
  background-color: rgba(44, 184, 243, 0.5);
}

input[type="checkbox"]:checked + .g-overlay-primary-0_9--checked::after,
input[type="radio"]:checked + .g-overlay-primary-0_9--checked::after,
input[type="checkbox"]:checked + * .g-overlay-primary-0_9--checked::after,
input[type="radio"]:checked + * .g-overlay-primary-0_9--checked::after {
  background-color: rgba(44, 184, 243, 0.9);
}

.g-overlay-primary-0_9--checked.g-checked::after,
.g-checked .g-overlay-primary-0_9--checked::after {
  background-color: rgba(44, 184, 243, 0.9);
}

/*------------------------------------
  Fields
------------------------------------*/
[data-capitalize] {
  text-transform: uppercase;
}

label.error {
  color: #d9534f;
  margin-top: .25rem;
  margin-bottom: 0;
}

input .error,
textarea.error,
select.error {
  border-color: #d9534f !important;
}

.js-autocomplete--ajax {
  position: relative;
  z-index: 10;
}

/*------------------------------------
  Fields Group v1
------------------------------------*/
.u-input-group-v1 {
  position: relative;
}

.u-input-group-v1 input ,
.u-input-group-v1 textarea,
.u-input-group-v1 [class*="u-select"] {
  line-height: 1.75rem;
  padding: 40px 15px 10px;
}

.u-input-group-v1 input :focus + label, .u-input-group-v1 input [value] + label, .u-input-group-v1 input [placeholder] + label, .u-input-group-v1 input .g-state-not-empty + label,
.u-input-group-v1 textarea:focus + label,
.u-input-group-v1 textarea[value] + label,
.u-input-group-v1 textarea[placeholder] + label,
.u-input-group-v1 textarea.g-state-not-empty + label,
.u-input-group-v1 [class*="u-select"]:focus + label,
.u-input-group-v1 [class*="u-select"][value] + label,
.u-input-group-v1 [class*="u-select"][placeholder] + label,
.u-input-group-v1 [class*="u-select"].g-state-not-empty + label {
  top: 20px;
  font-size: 90%;
}

.u-input-group-v1 label {
  position: absolute;
  top: 50%;
  left: 15px;
  transition-property: top, font-size;
  transition-duration: .2s;
  transition-timing-function: ease;
  transform: translateY(-50%);
  margin-bottom: 0;
}

/*------------------------------------
  Fields Group v2
------------------------------------*/
.u-input-group-v2 {
  position: relative;
}

.u-input-group-v2 input ,
.u-input-group-v2 textarea,
.u-input-group-v2 [class*="u-select"] {
  line-height: 1.75rem;
  padding: 10px 15px;
}

.u-input-group-v2 input :focus + label, .u-input-group-v2 input [value] + label, .u-input-group-v2 input [placeholder] + label, .u-input-group-v2 input .g-state-not-empty + label,
.u-input-group-v2 textarea:focus + label,
.u-input-group-v2 textarea[value] + label,
.u-input-group-v2 textarea[placeholder] + label,
.u-input-group-v2 textarea.g-state-not-empty + label,
.u-input-group-v2 [class*="u-select"]:focus + label,
.u-input-group-v2 [class*="u-select"][value] + label,
.u-input-group-v2 [class*="u-select"][placeholder] + label,
.u-input-group-v2 [class*="u-select"].g-state-not-empty + label {
  top: 0;
  font-size: 90%;
}

.u-input-group-v2 label {
  position: absolute;
  top: 50%;
  left: 10px;
  background-color: #fff;
  padding: 0 5px;
  margin-bottom: 0;
  transition-property: top, font-size;
  transition-duration: .2s;
  transition-timing-function: ease;
  transform: translateY(-50%);
}

.u-input-group-v2 textarea + label {
  top: 20px;
}

/*------------------------------------
  Fields Group v3
------------------------------------*/
.u-input-group-v3 input ,
.u-input-group-v3 textarea,
.u-input-group-v3 [class*="u-select"] {
  margin-top: -5px;
  border-color: rgba(204, 204, 204, 0.5);
  border-style: solid;
  border-width: 0 0 1px;
  transition: all .3s ease 0s;
}

.u-input-group-v3 input :focus,
.u-input-group-v3 textarea:focus,
.u-input-group-v3 [class*="u-select"]:focus {
  border-color: rgba(44, 184, 243, 0.5);
  box-shadow: 0 1px 0 0 #2cb8f3;
}

.u-input-group-v3 label {
  margin-bottom: 0;
}

/*------------------------------------
  Fields Group v4
------------------------------------*/
.u-input-group-v4 {
  position: relative;
}

.u-input-group-v4 input ,
.u-input-group-v4 textarea,
.u-input-group-v4 [class*="u-select"] {
  background-color: transparent;
  border-color: rgba(204, 204, 204, 0.5);
  border-style: solid;
  border-width: 0 0 1px;
}

.u-input-group-v4 input :focus, .u-input-group-v4 input [value], .u-input-group-v4 input [placeholder], .u-input-group-v4 input .g-state-not-empty,
.u-input-group-v4 textarea:focus,
.u-input-group-v4 textarea[value],
.u-input-group-v4 textarea[placeholder],
.u-input-group-v4 textarea.g-state-not-empty,
.u-input-group-v4 [class*="u-select"]:focus,
.u-input-group-v4 [class*="u-select"][value],
.u-input-group-v4 [class*="u-select"][placeholder],
.u-input-group-v4 [class*="u-select"].g-state-not-empty {
  background-color: transparent;
  border-color: rgba(44, 184, 243, 0.5);
  box-shadow: 0 1px 0 0 #2cb8f3;
}

.u-input-group-v4 input :focus + label, .u-input-group-v4 input [value] + label, .u-input-group-v4 input [placeholder] + label, .u-input-group-v4 input .g-state-not-empty + label,
.u-input-group-v4 textarea:focus + label,
.u-input-group-v4 textarea[value] + label,
.u-input-group-v4 textarea[placeholder] + label,
.u-input-group-v4 textarea.g-state-not-empty + label,
.u-input-group-v4 [class*="u-select"]:focus + label,
.u-input-group-v4 [class*="u-select"][value] + label,
.u-input-group-v4 [class*="u-select"][placeholder] + label,
.u-input-group-v4 [class*="u-select"].g-state-not-empty + label {
  top: 0;
  font-size: 90%;
}

.u-input-group-v4 label {
  position: absolute;
  top: 50%;
  left: 0;
  margin-bottom: 0;
  background-color: transparent;
  color: #999;
  transition-property: top, font-size;
  transition-duration: .2s;
  transition-timing-function: ease;
  transform: translateY(-50%);
}

.u-input-group-v4 textarea + label {
  top: 20px;
}

/*------------------------------------
  Checkboxes
------------------------------------*/
.u-check {
  position: relative;
  cursor: pointer;
}

.u-check-icon-font {
  display: inline-block;
  font-size: 0;
  padding-left: 1px;
  padding-right: 1px;
}

.u-check-icon-font i {
  font-size: 22px;
  color: #ccc;
}

.u-check-icon-font i::before {
  content: attr(data-uncheck-icon);
}

.u-check-icon-checkbox, .u-check-icon-radio {
  display: inline-block;
}

.u-check input[type="checkbox"]:checked + .u-check-icon-font i,
.u-check input[type="checkbox"]:checked + * .u-check-icon-font i,
.u-check input[type="radio"]:checked + .u-check-icon-font i,
.u-check input[type="radio"]:checked + * .u-check-icon-font i {
  color: #2cb8f3;
}

.u-check input[type="checkbox"]:checked + .u-check-icon-font i::before,
.u-check input[type="checkbox"]:checked + * .u-check-icon-font i::before,
.u-check input[type="radio"]:checked + .u-check-icon-font i::before,
.u-check input[type="radio"]:checked + * .u-check-icon-font i::before {
  content: attr(data-check-icon);
}

.g-hide-check {
  display: block !important;
}

.g-checked > * .g-hide-check,
input[type="checkbox"]:checked + .g-hide-check,
input[type="radio"]:checked + .g-hide-check,
input[type="checkbox"]:checked + * .g-hide-check,
input[type="radio"]:checked + * .g-hide-check {
  display: none !important;
}

.g-show-check {
  display: none !important;
}

.g-checked > * .g-show-check,
input[type="checkbox"]:checked + .g-show-check,
input[type="radio"]:checked + .g-show-check,
input[type="checkbox"]:checked + * .g-show-check,
input[type="radio"]:checked + * .g-show-check {
  display: block !important;
}

/*------------------------------------
  Checkboxes v1
------------------------------------*/
.u-check-icon-checkbox-v1, .u-check-icon-radio-v1 {
  background-color: #fff;
  border: solid 1px #ccc;
}

.u-check-icon-checkbox-v1 {
  border-radius: 1px;
}

.u-check-icon-radio-v1 {
  border-radius: 50%;
}

.u-check input[type="checkbox"]:checked + .u-check-icon-checkbox-v1,
.u-check input[type="checkbox"]:checked + * .u-check-icon-checkbox-v1,
.u-check input[type="radio"]:checked + .u-check-icon-checkbox-v1,
.u-check input[type="radio"]:checked + * .u-check-icon-checkbox-v1 {
  color: #fff;
  background-color: #2cb8f3;
  border-color: #2cb8f3;
}

.u-check input[type="checkbox"]:checked + .u-check-icon-checkbox-v1::before,
.u-check input[type="checkbox"]:checked + * .u-check-icon-checkbox-v1::before,
.u-check input[type="radio"]:checked + .u-check-icon-checkbox-v1::before,
.u-check input[type="radio"]:checked + * .u-check-icon-checkbox-v1::before {
  content: attr(data-check-icon);
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 12px;
  transform: translate(-50%, -50%);
}

.u-check input[type="checkbox"]:checked + .u-check-icon-radio-v1,
.u-check input[type="checkbox"]:checked + * .u-check-icon-radio-v1,
.u-check input[type="radio"]:checked + .u-check-icon-radio-v1,
.u-check input[type="radio"]:checked + * .u-check-icon-radio-v1 {
  border-width: 4px;
  border-color: #2cb8f3;
}

/*------------------------------------
  Checkboxes v2
------------------------------------*/
.u-check-icon-checkbox-v2, .u-check-icon-radio-v2 {
  width: 20px;
  height: 20px;
  border: solid 1px #2cb8f3;
}

.u-check-icon-checkbox-v2 {
  border-radius: 1px;
}

.u-check-icon-radio-v2 {
  border-radius: 50%;
}

/*------------------------------------
  Checkboxes v3
------------------------------------*/
.u-check-icon-checkbox-v3, .u-check-icon-radio-v3 {
  width: 40px;
  height: 40px;
  border: solid 1px #2cb8f3;
  border-radius: 50%;
}

/*------------------------------------
  Checkboxes v4
------------------------------------*/
.u-check-icon-checkbox-v4, .u-check-icon-radio-v4 {
  width: 18px;
  height: 18px;
  font-size: 12px;
  border: solid 1px #ccc;
}

.u-check-icon-checkbox-v4 i::before, .u-check-icon-radio-v4 i::before {
  content: attr(data-uncheck-icon);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.u-check-icon-radio-v4 {
  border-radius: 50%;
}

.u-check-icon-radio-v4 i {
  border-radius: 50%;
}

.u-check input[type="checkbox"]:checked + .u-check-icon-checkbox-v4,
.u-check input[type="checkbox"]:checked + .u-check-icon-radio-v4,
.u-check input[type="checkbox"]:checked + * .u-check-icon-checkbox-v4,
.u-check input[type="checkbox"]:checked + * .u-check-icon-radio-v4,
.u-check input[type="radio"]:checked + .u-check-icon-checkbox-v4,
.u-check input[type="radio"]:checked + .u-check-icon-radio-v4,
.u-check input[type="radio"]:checked + * .u-check-icon-checkbox-v4,
.u-check input[type="radio"]:checked + * .u-check-icon-radio-v4 {
  color: #2cb8f3;
  border-color: #2cb8f3;
}

.u-check input[type="checkbox"]:checked + .u-check-icon-checkbox-v4 i::before,
.u-check input[type="checkbox"]:checked + .u-check-icon-radio-v4 i::before,
.u-check input[type="checkbox"]:checked + * .u-check-icon-checkbox-v4 i::before,
.u-check input[type="checkbox"]:checked + * .u-check-icon-radio-v4 i::before,
.u-check input[type="radio"]:checked + .u-check-icon-checkbox-v4 i::before,
.u-check input[type="radio"]:checked + .u-check-icon-radio-v4 i::before,
.u-check input[type="radio"]:checked + * .u-check-icon-checkbox-v4 i::before,
.u-check input[type="radio"]:checked + * .u-check-icon-radio-v4 i::before {
  content: attr(data-check-icon);
}

/*------------------------------------
  Checkboxes v5
------------------------------------*/
.u-check-icon-checkbox-v5, .u-check-icon-radio-v5 {
  width: 18px;
  height: 18px;
}

.u-check-icon-checkbox-v5 i, .u-check-icon-radio-v5 i {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border: solid 1px #ccc;
  transform: translate(-50%, -50%);
}

.u-check-icon-checkbox-v5 {
  border-radius: 1px;
}

.u-check-icon-radio-v5 {
  border-radius: 50%;
}

.u-check-icon-radio-v5 i {
  border-radius: 50%;
}

.u-check input[type="checkbox"]:checked + .u-check-icon-checkbox-v5 i,
.u-check input[type="checkbox"]:checked + .u-check-icon-radio-v5 i,
.u-check input[type="checkbox"]:checked + * .u-check-icon-checkbox-v5 i,
.u-check input[type="checkbox"]:checked + * .u-check-icon-radio-v5 i,
.u-check input[type="radio"]:checked + .u-check-icon-checkbox-v5 i,
.u-check input[type="radio"]:checked + .u-check-icon-radio-v5 i,
.u-check input[type="radio"]:checked + * .u-check-icon-checkbox-v5 i,
.u-check input[type="radio"]:checked + * .u-check-icon-radio-v5 i {
  border-width: 4px;
  border-color: #2cb8f3;
}

/*------------------------------------
  Checkboxes v6
------------------------------------*/
.u-check-icon-checkbox-v6, .u-check-icon-radio-v6 {
  width: 18px;
  height: 18px;
  font-size: 12px;
}

.u-check-icon-checkbox-v6 i, .u-check-icon-radio-v6 i {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 100%;
  height: 100%;
  border: solid 1px #ccc;
  transform: translate(-50%, -50%);
}

.u-check-icon-checkbox-v6 i::before, .u-check-icon-radio-v6 i::before {
  content: attr(data-uncheck-icon);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.u-check-icon-radio-v6 {
  border-radius: 50%;
}

.u-check-icon-radio-v6 i {
  border-radius: 50%;
}

.u-check input[type="checkbox"]:checked + .u-check-icon-checkbox-v6 i,
.u-check input[type="checkbox"]:checked + .u-check-icon-radio-v6 i,
.u-check input[type="checkbox"]:checked + * .u-check-icon-checkbox-v6 i,
.u-check input[type="checkbox"]:checked + * .u-check-icon-radio-v6 i,
.u-check input[type="radio"]:checked + .u-check-icon-checkbox-v6 i,
.u-check input[type="radio"]:checked + .u-check-icon-radio-v6 i,
.u-check input[type="radio"]:checked + * .u-check-icon-checkbox-v6 i,
.u-check input[type="radio"]:checked + * .u-check-icon-radio-v6 i {
  color: #fff;
  background-color: #2cb8f3;
  border-color: #2cb8f3;
}

.u-check input[type="checkbox"]:checked + .u-check-icon-checkbox-v6 i::before,
.u-check input[type="checkbox"]:checked + .u-check-icon-radio-v6 i::before,
.u-check input[type="checkbox"]:checked + * .u-check-icon-checkbox-v6 i::before,
.u-check input[type="checkbox"]:checked + * .u-check-icon-radio-v6 i::before,
.u-check input[type="radio"]:checked + .u-check-icon-checkbox-v6 i::before,
.u-check input[type="radio"]:checked + .u-check-icon-radio-v6 i::before,
.u-check input[type="radio"]:checked + * .u-check-icon-checkbox-v6 i::before,
.u-check input[type="radio"]:checked + * .u-check-icon-radio-v6 i::before {
  content: attr(data-check-icon);
}

/*------------------------------------
  Checkboxes v7
------------------------------------*/
.u-check-icon-checkbox-v7, .u-check-icon-radio-v7 {
  cursor: pointer;
  display: block;
  width: 43px;
  height: 22px;
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  color: #ddd;
  border: solid 1px #ddd;
  border-radius: 12px;
}

.u-check-icon-checkbox-v7 i::before, .u-check-icon-checkbox-v7 i::after, .u-check-icon-radio-v7 i::before, .u-check-icon-radio-v7 i::after {
  content: "";
  display: block;
  position: absolute;
}

.u-check-icon-checkbox-v7 i::before, .u-check-icon-radio-v7 i::before {
  content: attr(data-uncheck-icon);
  top: 0;
  left: 0;
  width: 100%;
  height: 22px;
  line-height: 18px;
  text-transform: uppercase;
  text-align: right;
  padding: 2px 7px;
}

.u-check-icon-checkbox-v7 i::after, .u-check-icon-radio-v7 i::after {
  top: 50%;
  left: 4px;
  width: 16px;
  height: 16px;
  background-color: #ddd;
  border-radius: 50%;
  transform: translateY(-50%);
  transition-property: left;
  transition-duration: .1s;
  transition-timing-function: ease-in;
}

.u-check input[type="checkbox"]:checked + .u-check-icon-checkbox-v7,
.u-check input[type="checkbox"]:checked + .u-check-icon-radio-v7,
.u-check input[type="checkbox"]:checked + * .u-check-icon-checkbox-v7,
.u-check input[type="checkbox"]:checked + * .u-check-icon-radio-v7,
.u-check input[type="radio"]:checked + .u-check-icon-checkbox-v7,
.u-check input[type="radio"]:checked + .u-check-icon-radio-v7,
.u-check input[type="radio"]:checked + * .u-check-icon-checkbox-v7,
.u-check input[type="radio"]:checked + * .u-check-icon-radio-v7 {
  color: #fff;
  background-color: #2cb8f3;
  border-color: #2cb8f3;
}

.u-check input[type="checkbox"]:checked + .u-check-icon-checkbox-v7 i:before,
.u-check input[type="checkbox"]:checked + .u-check-icon-radio-v7 i:before,
.u-check input[type="checkbox"]:checked + * .u-check-icon-checkbox-v7 i:before,
.u-check input[type="checkbox"]:checked + * .u-check-icon-radio-v7 i:before,
.u-check input[type="radio"]:checked + .u-check-icon-checkbox-v7 i:before,
.u-check input[type="radio"]:checked + .u-check-icon-radio-v7 i:before,
.u-check input[type="radio"]:checked + * .u-check-icon-checkbox-v7 i:before,
.u-check input[type="radio"]:checked + * .u-check-icon-radio-v7 i:before {
  content: attr(data-check-icon);
  text-align: left;
}

.u-check input[type="checkbox"]:checked + .u-check-icon-checkbox-v7 i::after,
.u-check input[type="checkbox"]:checked + .u-check-icon-radio-v7 i::after,
.u-check input[type="checkbox"]:checked + * .u-check-icon-checkbox-v7 i::after,
.u-check input[type="checkbox"]:checked + * .u-check-icon-radio-v7 i::after,
.u-check input[type="radio"]:checked + .u-check-icon-checkbox-v7 i::after,
.u-check input[type="radio"]:checked + .u-check-icon-radio-v7 i::after,
.u-check input[type="radio"]:checked + * .u-check-icon-checkbox-v7 i::after,
.u-check input[type="radio"]:checked + * .u-check-icon-radio-v7 i::after {
  left: calc(100% - 19px);
  background-color: #fff;
}

/*------------------------------------
  Checkboxes v7
------------------------------------*/
.u-check-icon-checkbox-v8, .u-check-icon-radio-v8 {
  cursor: pointer;
  display: block;
  width: 43px;
  height: 22px;
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  color: #ddd;
  border: solid 1px #ddd;
  border-radius: 12px;
}

.u-check-icon-checkbox-v8 i::before, .u-check-icon-checkbox-v8 i::after, .u-check-icon-radio-v8 i::before, .u-check-icon-radio-v8 i::after {
  content: "";
  display: block;
  position: absolute;
}

.u-check-icon-checkbox-v8 i::before, .u-check-icon-radio-v8 i::before {
  content: attr(data-uncheck-icon);
  top: 0;
  left: 0;
  width: 100%;
  height: 22px;
  line-height: 18px;
  text-transform: uppercase;
  text-align: right;
  padding: 2px 7px;
}

.u-check-icon-checkbox-v8 i::after, .u-check-icon-radio-v8 i::after {
  top: 50%;
  left: 4px;
  width: 16px;
  height: 16px;
  background-color: #ddd;
  border-radius: 50%;
  transform: translateY(-50%);
  transition-property: left;
  transition-duration: .1s;
  transition-timing-function: ease-in;
}

.u-check input[type="checkbox"]:checked + .u-check-icon-checkbox-v8,
.u-check input[type="checkbox"]:checked + .u-check-icon-radio-v8,
.u-check input[type="checkbox"]:checked + * .u-check-icon-checkbox-v8,
.u-check input[type="checkbox"]:checked + * .u-check-icon-radio-v8,
.u-check input[type="radio"]:checked + .u-check-icon-checkbox-v8,
.u-check input[type="radio"]:checked + .u-check-icon-radio-v8,
.u-check input[type="radio"]:checked + * .u-check-icon-checkbox-v8,
.u-check input[type="radio"]:checked + * .u-check-icon-radio-v8 {
  color: #2cb8f3;
  border-color: #2cb8f3;
}

.u-check input[type="checkbox"]:checked + .u-check-icon-checkbox-v8 i:before,
.u-check input[type="checkbox"]:checked + .u-check-icon-radio-v8 i:before,
.u-check input[type="checkbox"]:checked + * .u-check-icon-checkbox-v8 i:before,
.u-check input[type="checkbox"]:checked + * .u-check-icon-radio-v8 i:before,
.u-check input[type="radio"]:checked + .u-check-icon-checkbox-v8 i:before,
.u-check input[type="radio"]:checked + .u-check-icon-radio-v8 i:before,
.u-check input[type="radio"]:checked + * .u-check-icon-checkbox-v8 i:before,
.u-check input[type="radio"]:checked + * .u-check-icon-radio-v8 i:before {
  content: attr(data-check-icon);
  text-align: left;
}

.u-check input[type="checkbox"]:checked + .u-check-icon-checkbox-v8 i::after,
.u-check input[type="checkbox"]:checked + .u-check-icon-radio-v8 i::after,
.u-check input[type="checkbox"]:checked + * .u-check-icon-checkbox-v8 i::after,
.u-check input[type="checkbox"]:checked + * .u-check-icon-radio-v8 i::after,
.u-check input[type="radio"]:checked + .u-check-icon-checkbox-v8 i::after,
.u-check input[type="radio"]:checked + .u-check-icon-radio-v8 i::after,
.u-check input[type="radio"]:checked + * .u-check-icon-checkbox-v8 i::after,
.u-check input[type="radio"]:checked + * .u-check-icon-radio-v8 i::after {
  left: calc(100% - 19px);
  background-color: #2cb8f3;
}

/* P */
[class*="u-checkbox-v1"] {
  display: none;
}

[class*="u-checkbox-v1"] + label {
  cursor: pointer;
}

.u-checkbox-v1--checked-color-primary:checked + label {
  color: #2cb8f3 !important;
}

.u-checkbox-v1--checked-brd-primary:checked + label {
  border-color: #2cb8f3 !important;
}

/*------------------------------------
  File Attachments
------------------------------------*/
/*------------------------------------
  File Attachments v1
------------------------------------*/
.u-file-attach-v1 {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.u-file-attach-v1 input[type="file"] {
  position: absolute;
  top: -25%;
  left: -25%;
  z-index: 10;
  width: 150%;
  height: 150%;
  opacity: 0;
  cursor: pointer;
}

.u-file-attach-v1 input[readonly] {
  background-color: transparent;
}

/*------------------------------------
  File Attachments v2
------------------------------------*/
.u-file-attach-v2 {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.u-file-attach-v2 input[type="file"] {
  position: absolute;
  top: -25%;
  left: -25%;
  z-index: 10;
  width: 150%;
  height: 150%;
  opacity: 0;
  cursor: pointer;
}

/*------------------------------------
  File Attachments v2
------------------------------------*/
.u-file-attach-v3 {
  cursor: pointer;
  position: relative;
  text-align: center;
  background-color: #f7f7f7;
  overflow: hidden;
  border: 1px dashed #ccc;
  padding: 60px;
  transition-property: background-color;
  transition-duration: .2s;
  transition-timing-function: ease-in;
}

.u-file-attach-v3 input[type="file"] {
  position: absolute;
  top: -25%;
  left: -25%;
  z-index: 10;
  width: 150%;
  height: 150%;
  opacity: 0;
  cursor: pointer;
}

.u-file-attach-v3:hover {
  background-color: #eee;
}

/*------------------------------------
  Selects
------------------------------------*/
.input-group select {
  -webkit-appearance: none;
}

/*------------------------------------
  Selects v1
------------------------------------*/
.u-select-v1 {
  position: relative;
  max-width: 100%;
  font-size: 1rem;
  color: rgba(85, 85, 85, 0.3);
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: #ccc;
  padding: .4rem 1rem;
}

.u-select-v1 .chosen-single {
  position: static;
  height: auto;
  color: inherit;
  background-image: none;
  background-color: transparent;
  border: none;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
  line-height: inherit;
}

.u-select-v1 .chosen-single span {
  margin-right: 0;
}

.u-select-v1 .chosen-single span img {
  position: relative;
  top: -2px;
  margin-right: 4px;
}

.u-select-v1 .chosen-single div {
  width: 40px;
}

.u-select-v1 .chosen-single div b {
  background: none !important;
}

.u-select-v1 .chosen-single div b i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.u-select-v1 .chosen-single div b i:first-child {
  display: inline-block;
}

.u-select-v1 .chosen-single div b i:last-child {
  display: none;
}

.u-select-v1 .chosen-single:focus {
  outline: 0 none;
}

.u-select-v1 .chosen-drop {
  width: calc(100% + 2px);
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: inherit;
  border-radius: 0;
  border-top-width: 1px;
  margin-left: -1px;
}

.u-select-v1 .chosen-results {
  padding: 0;
  margin: 0;
}

.u-select-v1 .chosen-results > li {
  position: relative;
  font-size: inherit;
  color: #999;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #ccc;
  padding: 12px 16px;
}

.u-select-v1 .chosen-results > li img {
  position: relative;
  top: -2px;
  margin-right: 4px;
}

.u-select-v1 .chosen-results > li div {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 100%;
}

.u-select-v1 .chosen-results > li div b {
  display: block;
  width: 100%;
  height: 100%;
}

.u-select-v1 .chosen-results > li div b i {
  position: absolute;
  top: 50%;
  left: 50%;
  display: none;
  transform: translate(-50%, -50%);
}

.u-select-v1 .chosen-results > li:last-child {
  border-bottom-width: 0;
}

.u-select-v1 .chosen-results > li.highlighted {
  color: #999;
  background-image: none;
  background-color: rgba(44, 184, 243, 0.2);
  border-bottom-color: rgba(44, 184, 243, 0.2);
}

.u-select-v1 .chosen-results > li.highlighted.g-color-white--active {
  color: #fff !important;
}

.u-select-v1 .chosen-results > li.highlighted.g-bg-primary--active {
  background-color: #2cb8f3 !important;
}

.u-select-v1 .chosen-results > li.result-selected {
  color: #999;
  background-color: rgba(44, 184, 243, 0.2);
  border-bottom-color: rgba(44, 184, 243, 0.2);
}

.u-select-v1 .chosen-results > li.result-selected div b i {
  display: inline-block;
}

.u-select-v1.chosen-container-active .chosen-single {
  background-image: none;
  box-shadow: none;
}

.u-select-v1.chosen-with-drop .chosen-single {
  border: none;
  border-radius: 0;
}

.u-select-v1.chosen-with-drop .chosen-single div b i:first-child {
  display: none;
}

.u-select-v1.chosen-with-drop .chosen-single div b i:last-child {
  display: inline-block;
}

.u-select-v1.chosen-container-multi:not(.u-select-multiple-custom) .chosen-choices {
  background-image: none;
  border: none;
  box-shadow: none;
  padding: 0;
}

.u-select-v1.chosen-container-multi:not(.u-select-multiple-custom) .chosen-choices .search-choice {
  font-size: 12px;
  color: #999;
  background-image: none;
  background-color: #fff;
  border-color: #ccc;
  border-radius: 0;
  padding: 5px 20px 5px 5px;
}

.u-select-v1.chosen-container-multi:not(.u-select-multiple-custom) .chosen-choices .search-choice-close {
  top: 50%;
  transform: translateY(-50%);
}

.u-select-v1.chosen-container-multi:not(.u-select-multiple-custom) .chosen-choices .search-field {
  height: 22px;
}

.u-select-v1.chosen-container-multi:not(.u-select-multiple-custom) .chosen-choices .search-field input[type="text"] {
  height: 22px;
  margin: 0;
}

.u-select-v1.u-select-multiple-custom .chosen-choices {
  display: none;
}

.u-select-v1.u-select-multiple-custom .chosen-drop {
  width: 100%;
  position: static;
  top: auto;
  left: auto;
  z-index: 3;
  border: none;
  box-shadow: none;
  margin-left: 0;
}

.u-select-v1.u-dropdown-sm {
  padding: .1rem .5rem;
  font-size: .875rem;
}

.u-select-v1.u-dropdown-sm .chosen-results > li {
  padding: 6px 8px;
}

.u-select-v1.u-dropdown-lg {
  padding: .55rem 1.5rem;
  font-size: 1.25rem;
}

.u-select-v1.u-dropdown-lg .chosen-results > li {
  padding: 14px 18px;
}

/*------------------------------------
  Selects v2
------------------------------------*/
.u-select-v2 {
  position: relative;
  max-width: 100%;
  font-size: 1rem;
  color: rgba(85, 85, 85, 0.3);
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: #ccc;
  padding: .4rem 1rem;
}

.u-select-v2 .chosen-single {
  position: static;
  height: auto;
  color: inherit;
  background-image: none;
  background-color: transparent;
  border: none;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
  line-height: inherit;
}

.u-select-v2 .chosen-single span {
  margin-right: 0;
}

.u-select-v2 .chosen-single span img {
  position: relative;
  top: -2px;
  margin-right: 4px;
}

.u-select-v2 .chosen-single div {
  width: 50px;
}

.u-select-v2 .chosen-single div b {
  background: none !important;
}

.u-select-v2 .chosen-single div b i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.u-select-v2 .chosen-single div b i:first-child {
  display: inline-block;
}

.u-select-v2 .chosen-single div b i:last-child {
  display: none;
}

.u-select-v2 .chosen-single:focus {
  outline: 0 none;
}

.u-select-v2 .chosen-drop,
.u-select-v2 .chosen-results {
  border-radius: 3px;
}

.u-select-v2 .chosen-drop {
  width: calc(100% + 2px);
  box-sizing: border-box;
  border: none;
  box-shadow: 0 6px 15px -6px rgba(0, 0, 0, 0.1);
  margin-left: -1px;
  margin-top: 7px;
}

.u-select-v2 .chosen-results {
  padding: 0;
  margin: 0;
}

.u-select-v2 .chosen-results > li {
  position: relative;
  font-size: inherit;
  color: #999;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #ccc;
  padding: 12px 16px;
}

.u-select-v2 .chosen-results > li img {
  position: relative;
  top: -2px;
  margin-right: 4px;
}

.u-select-v2 .chosen-results > li div {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 100%;
}

.u-select-v2 .chosen-results > li div b {
  display: block;
  width: 100%;
  height: 100%;
}

.u-select-v2 .chosen-results > li div b i {
  position: absolute;
  top: 50%;
  left: 50%;
  display: none;
  transform: translate(-50%, -50%);
}

.u-select-v2 .chosen-results > li:last-child {
  border-bottom-width: 0;
}

.u-select-v2 .chosen-results > li.highlighted {
  color: #999;
  background-image: none;
  background-color: rgba(44, 184, 243, 0.2);
  border-bottom-color: rgba(44, 184, 243, 0.2);
}

.u-select-v2 .chosen-results > li.highlighted.g-color-white--active {
  color: #fff !important;
}

.u-select-v2 .chosen-results > li.highlighted.g-bg-primary--active {
  background-color: #2cb8f3 !important;
}

.u-select-v2 .chosen-results > li.result-selected {
  color: #999;
  background-color: rgba(44, 184, 243, 0.2);
  border-bottom-color: rgba(44, 184, 243, 0.2);
}

.u-select-v2 .chosen-results > li.result-selected div b i {
  display: inline-block;
}

.u-select-v2.chosen-container-active .chosen-single {
  background-image: none;
  box-shadow: none;
}

.u-select-v2.chosen-with-drop .chosen-single {
  border: none;
  border-radius: 0;
}

.u-select-v2.chosen-with-drop .chosen-single div b i:first-child {
  display: none;
}

.u-select-v2.chosen-with-drop .chosen-single div b i:last-child {
  display: inline-block;
}

.u-select-v2.chosen-container-multi:not(.u-select-multiple-custom) .chosen-choices {
  background-image: none;
  border: none;
  box-shadow: none;
  padding: 0;
}

.u-select-v2.chosen-container-multi:not(.u-select-multiple-custom) .chosen-choices .search-choice {
  font-size: 12px;
  color: #999;
  background-image: none;
  background-color: #fff;
  border-color: #ccc;
  border-radius: 0;
  padding: 5px 20px 5px 5px;
}

.u-select-v2.chosen-container-multi:not(.u-select-multiple-custom) .chosen-choices .search-choice-close {
  top: 50%;
  transform: translateY(-50%);
}

.u-select-v2.chosen-container-multi:not(.u-select-multiple-custom) .chosen-choices .search-field {
  height: 22px;
}

.u-select-v2.chosen-container-multi:not(.u-select-multiple-custom) .chosen-choices .search-field input[type="text"] {
  height: 22px;
  margin: 0;
}

.u-select-v2.u-select-multiple-custom .chosen-choices {
  display: none;
}

.u-select-v2.u-select-multiple-custom .chosen-drop {
  width: 100%;
  position: static;
  top: auto;
  left: auto;
  z-index: 3;
  border: none;
  box-shadow: none;
  margin-left: 0;
}

.u-select-v2.u-dropdown-sm {
  padding: .1rem .5rem;
  font-size: .875rem;
}

.u-select-v2.u-dropdown-sm .chosen-results > li {
  padding: 6px 8px;
}

.u-select-v2.u-dropdown-lg {
  padding: .55rem 1.5rem;
  font-size: 1.25rem;
}

.u-select-v2.u-dropdown-lg .chosen-results > li {
  padding: 14px 18px;
}

/*------------------------------------
  Sliders
------------------------------------*/
/*------------------------------------
  Sliders v1
------------------------------------*/
.u-slider-v1.ui-slider, .u-slider-v1-2.ui-slider, .u-slider-v1-3.ui-slider {
  position: relative;
  background: #eee;
  border: none;
  border-radius: 0;
  margin-top: 12px;
  margin-left: 6px;
  margin-right: 6px;
}

.u-slider-v1.ui-slider .ui-slider-range, .u-slider-v1-2.ui-slider .ui-slider-range, .u-slider-v1-3.ui-slider .ui-slider-range {
  height: 100%;
}

.u-slider-v1.ui-slider .ui-slider-handle, .u-slider-v1-2.ui-slider .ui-slider-handle, .u-slider-v1-3.ui-slider .ui-slider-handle {
  position: absolute;
  top: 50%;
  border-style: solid;
  outline: none;
  background: #fff;
  border-radius: 0;
  cursor: pointer;
  transition-property: border-color;
  transition-duration: .3s;
  transition-timing-function: ease;
}

.u-slider-v1.ui-slider.ui-slider-content, .u-slider-v1-2.ui-slider.ui-slider-content, .u-slider-v1-3.ui-slider.ui-slider-content {
  border-color: #eee;
}

.u-slider-v1.ui-slider {
  height: 2px;
}

.u-slider-v1.ui-slider .ui-slider-range {
  background: #2cb8f3;
}

.u-slider-v1.ui-slider .ui-slider-handle {
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-width: 2px;
  border-color: #2cb8f3;
}

.u-slider-v1-2.ui-slider, .u-slider-v1-3.ui-slider {
  height: 4px;
}

.u-slider-v1-2.ui-slider .ui-slider-range, .u-slider-v1-3.ui-slider .ui-slider-range {
  background: #ddd;
}

.u-slider-v1-2.ui-slider .ui-slider-handle, .u-slider-v1-3.ui-slider .ui-slider-handle {
  width: 15px;
  height: 15px;
  margin-top: -8px;
  margin-left: -8px;
  border-width: 2px;
  border-color: #2cb8f3;
}

.u-slider-v1-3.ui-slider .ui-slider-handle {
  border-radius: 50%;
}

/*------------------------------------
  Sliders v2
------------------------------------*/
.u-slider-v2.ui-slider, .u-slider-v2-2.ui-slider, .u-slider-v2-3.ui-slider {
  position: relative;
  background: rgba(44, 184, 243, 0.5);
  border: none;
  border-radius: 0;
  margin-top: 12px;
  margin-left: 6px;
  margin-right: 6px;
}

.u-slider-v2.ui-slider .ui-slider-range, .u-slider-v2-2.ui-slider .ui-slider-range, .u-slider-v2-3.ui-slider .ui-slider-range {
  height: 100%;
}

.u-slider-v2.ui-slider .ui-slider-handle, .u-slider-v2-2.ui-slider .ui-slider-handle, .u-slider-v2-3.ui-slider .ui-slider-handle {
  position: absolute;
  top: 50%;
  border-style: solid;
  outline: none;
  background: #2cb8f3;
  border-radius: 0;
  cursor: pointer;
  transition-property: border-color, transform;
  transition-duration: .3s;
  transition-timing-function: ease;
}

.u-slider-v2.ui-slider .ui-slider-handle.ui-state-active, .u-slider-v2-2.ui-slider .ui-slider-handle.ui-state-active, .u-slider-v2-3.ui-slider .ui-slider-handle.ui-state-active {
  transform: scale(1.5);
}

.u-slider-v2.ui-slider {
  height: 2px;
}

.u-slider-v2.ui-slider .ui-slider-range {
  background: #2cb8f3;
}

.u-slider-v2.ui-slider .ui-slider-handle {
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-width: 2px;
  border-color: #2cb8f3;
}

.u-slider-v2-2.ui-slider, .u-slider-v2-3.ui-slider {
  height: 4px;
}

.u-slider-v2-2.ui-slider .ui-slider-range, .u-slider-v2-3.ui-slider .ui-slider-range {
  background: #2cb8f3;
}

.u-slider-v2-2.ui-slider .ui-slider-handle, .u-slider-v2-3.ui-slider .ui-slider-handle {
  width: 15px;
  height: 15px;
  margin-top: -8px;
  margin-left: -8px;
  border-width: 2px;
  border-color: #2cb8f3;
}

.u-slider-v2-3.ui-slider .ui-slider-handle {
  border-radius: 50%;
}

/*------------------------------------
  Datepickers
------------------------------------*/
.u-has-dropdowns-in-popup .ui-autocomplete,
.u-has-dropdowns-in-popup .ui-datepicker {
  z-index: 100000 !important;
}

/*------------------------------------
  Datepickers v1
------------------------------------*/
.u-datepicker-v1 {
  border-width: 1px;
  border-style: solid;
  border-radius: 0;
  z-index: 2 !important;
}

.u-datepicker-v1.ui-datepicker-inline {
  width: 100%;
}

.u-datepicker-v1.ui-datepicker {
  width: initial;
  padding: initial;
}

.u-datepicker-v1 .ui-datepicker {
  width: 100%;
  padding: 0;
  border: none;
}

.u-datepicker-v1 .ui-datepicker-header {
  background-color: transparent;
  border: none;
  border-radius: 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #ccc;
  padding: 0;
}

.u-datepicker-v1 .ui-datepicker-title {
  height: 45px;
  line-height: 45px;
  font-size: 16px;
  font-weight: 400;
  color: #555;
  margin-left: 45px;
  margin-right: 45px;
}

.u-datepicker-v1 .ui-datepicker-prev, .u-datepicker-v1 .ui-datepicker-next {
  width: 45px;
  height: 45px;
  line-height: 45px;
  top: 0;
  text-align: center;
  border-radius: 0;
  cursor: pointer;
}

.u-datepicker-v1 .ui-datepicker-prev > span, .u-datepicker-v1 .ui-datepicker-next > span {
  position: static;
  top: 0;
  left: 0;
  display: inline-block;
  width: auto;
  height: auto;
  font-size: 18px;
  color: #555;
  background-image: none;
  text-indent: 0;
  margin-top: 0;
  margin-left: 0;
}

.u-datepicker-v1 .ui-datepicker-prev-hover, .u-datepicker-v1 .ui-datepicker-next-hover {
  top: 0;
  background-color: transparent;
}

.u-datepicker-v1 .ui-datepicker-prev.ui-state-hover, .u-datepicker-v1 .ui-datepicker-next.ui-state-hover {
  border: none;
}

.u-datepicker-v1 .ui-datepicker-prev {
  left: 0;
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: #ccc;
}

.u-datepicker-v1 .ui-datepicker-prev-hover {
  left: 0;
}

.u-datepicker-v1 .ui-datepicker-prev.ui-state-hover {
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: #ccc;
}

.u-datepicker-v1 .ui-datepicker-next {
  right: 0;
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: #ccc;
}

.u-datepicker-v1 .ui-datepicker-next-hover {
  right: 0;
}

.u-datepicker-v1 .ui-datepicker-next.ui-state-hover {
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: #ccc;
}

.u-datepicker-v1 .ui-datepicker-calendar {
  margin-bottom: 0;
}

.u-datepicker-v1 .ui-datepicker-calendar th,
.u-datepicker-v1 .ui-datepicker-calendar td {
  text-align: center;
  padding: 3px;
}

.u-datepicker-v1 .ui-datepicker-calendar th span,
.u-datepicker-v1 .ui-datepicker-calendar th a,
.u-datepicker-v1 .ui-datepicker-calendar td span,
.u-datepicker-v1 .ui-datepicker-calendar td a {
  display: inline-block;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  color: #555;
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  padding: 0;
}

.u-datepicker-v1 .ui-datepicker-calendar th a,
.u-datepicker-v1 .ui-datepicker-calendar td a {
  border-radius: 50%;
  transition: all .2s;
}

.u-datepicker-v1 .ui-datepicker-calendar th a:hover, .u-datepicker-v1 .ui-datepicker-calendar th a.ui-state-active,
.u-datepicker-v1 .ui-datepicker-calendar td a:hover,
.u-datepicker-v1 .ui-datepicker-calendar td a.ui-state-active {
  color: #fff;
  background-color: #2cb8f3;
  border-color: #2cb8f3;
  transition: all .2s;
}

.u-datepicker-v1 .ui-datepicker-calendar th span,
.u-datepicker-v1 .ui-datepicker-calendar th a {
  font-size: 12px;
  font-weight: 400;
  color: #bbb;
  text-transform: uppercase;
}

.u-datepicker-v1 .ui-datepicker-calendar td span,
.u-datepicker-v1 .ui-datepicker-calendar td a {
  font-size: 14px;
}

@media (min-width: 768px) {
  .u-datepicker-v1 .ui-datepicker-title {
    height: 50px;
    line-height: 50px;
    margin-left: 50px;
    margin-right: 50px;
  }
  .u-datepicker-v1 .ui-datepicker-prev, .u-datepicker-v1 .ui-datepicker-next {
    width: 50px;
    height: 50px;
    line-height: 50px;
  }
  .u-datepicker-v1 .ui-datepicker-calendar {
    border-collapse: separate;
    border-spacing: 4px;
  }
  .u-datepicker-v1 .ui-datepicker-calendar th,
  .u-datepicker-v1 .ui-datepicker-calendar td {
    padding: 5px;
  }
  .u-datepicker-v1 .ui-datepicker-calendar th span,
  .u-datepicker-v1 .ui-datepicker-calendar th a,
  .u-datepicker-v1 .ui-datepicker-calendar td span,
  .u-datepicker-v1 .ui-datepicker-calendar td a {
    font-size: 14px;
  }
}

/*------------------------------------
  Quantity
------------------------------------*/
.u-quantity-v1 input {
  background-color: #fff !important;
}

.js-plus,
.js-minus {
  cursor: pointer;
}

/*------------------------------------
  Carousel indicators
------------------------------------*/
[class*="u-carousel-indicators"] {
  display: block;
  position: absolute;
  padding-left: 0;
  margin-bottom: 0;
}

[class*="u-carousel-indicators"] li {
  list-style: none;
}

[class*="u-carousel-indicators"] span {
  display: block;
  cursor: pointer;
}

/*------------------------------------
  Carousel indicators v1
------------------------------------*/
.u-carousel-indicators-v1,
.u-carousel-indicators-v1--white {
  display: flex !important;
}

.u-carousel-indicators-v1 li,
.u-carousel-indicators-v1--white li {
  margin: 0 5px;
}

.u-carousel-indicators-v1 li.slick-active span,
.u-carousel-indicators-v1--white li.slick-active span {
  background-color: #2cb8f3;
}

.u-carousel-indicators-v1 span,
.u-carousel-indicators-v1--white span {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #777;
  opacity: 1;
}

.u-carousel-indicators-v1 span {
  background-color: #777;
}

.u-carousel-indicators-v1--white li.slick-active span {
  background-color: #fff;
}

/*------------------------------------
  Carousel indicators v2
------------------------------------*/
.u-carousel-indicators-v2 li {
  margin: 0 5px;
}

.u-carousel-indicators-v2 li.slick-active span {
  opacity: 1;
}

.u-carousel-indicators-v2 span {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #fff;
  opacity: .3;
}

/*------------------------------------
  Carousel indicators v3
------------------------------------*/
.u-carousel-indicators-v3 li {
  margin: 0 3px;
}

.u-carousel-indicators-v3 li.slick-active span {
  width: 12px;
  height: 12px;
  background-color: #fff;
}

.u-carousel-indicators-v3 span {
  width: 10px;
  height: 10px;
  border: 1px solid #fff;
  border-radius: 50%;
  background-color: transparent;
}

/*------------------------------------
  Carousel indicators v4
------------------------------------*/
.u-carousel-indicators-v4 li {
  margin: 0 7px;
}

.u-carousel-indicators-v4 li.slick-active span {
  background-color: #2cb8f3;
}

.u-carousel-indicators-v4 span {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ccc;
}

/*------------------------------------
  Carousel indicators v5
------------------------------------*/
.u-carousel-indicators-v5 {
  bottom: auto;
  left: auto;
  right: 15px;
  top: 15px;
  width: auto;
}

.u-carousel-indicators-v5 li {
  margin: 0 3px;
}

.u-carousel-indicators-v5 li.slick-active span {
  width: 9px;
  height: 9px;
  background-color: #fff;
}

.u-carousel-indicators-v5 span {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  border: 1px solid #fff;
  opacity: 1;
  position: relative;
}

/*------------------------------------
  Carousel indicators v6
------------------------------------*/
.u-carousel-indicators-v6 {
  display: block;
  left: 50%;
  right: auto;
  bottom: 0;
  transform: translateX(-50%);
}

.u-carousel-indicators-v6::before {
  content: "";
  position: absolute;
  top: 7px;
  left: 50%;
  right: 50%;
  display: none;
  width: calc(100% - 130px);
  height: 0;
  border-bottom: 1px solid #111;
  transform: translate(-50%, 0px);
}

.u-carousel-indicators-v6 span {
  position: relative;
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  border: 1px solid #111;
  background-color: #fff;
  opacity: 1;
  margin: 4px auto 0;
}

.u-carousel-indicators-v6 li {
  display: table-cell;
  width: 1%;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 11px;
  color: #111;
  vertical-align: top;
  cursor: pointer;
  float: none;
}

.u-carousel-indicators-v6 li.slick-active {
  color: #2cb8f3;
}

.u-carousel-indicators-v6 li.slick-active span {
  display: block;
  width: 17px;
  height: 17px;
  border: 3px solid #2cb8f3;
  border-radius: 10px;
  margin: 0 auto;
}

.u-carousel-indicators-v6 .u-dot-title {
  display: none;
}

@media (min-width: 768px) {
  .u-carousel-indicators-v6 {
    top: 0;
    bottom: auto;
  }
  .u-carousel-indicators-v6::before {
    display: block;
  }
  .u-carousel-indicators-v6 span {
    margin: 3px auto 19px;
  }
  .u-carousel-indicators-v6 li.slick-active span {
    margin: 0 auto 15px;
  }
  .u-carousel-indicators-v6 .u-dot-title {
    display: block;
  }
}

/*------------------------------------
  Carousel indicators v7
------------------------------------*/
.u-carousel-indicators-v7 {
  position: absolute;
  left: 0;
  top: 50%;
  bottom: auto;
  display: block;
  width: 7px;
  transform: translateY(-50%);
}

.u-carousel-indicators-v7 span {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #fff;
  opacity: .3;
}

.u-carousel-indicators-v7 li {
  display: block;
  margin: 15px 0;
  float: none;
}

.u-carousel-indicators-v7 li.slick-active span {
  opacity: 1;
}

/*------------------------------------
  Carousel indicators v8
------------------------------------*/
.u-carousel-indicators-v8 {
  position: absolute;
  left: 0;
  top: 50%;
  bottom: auto;
  display: block;
  width: 7px;
  transform: translateY(-50%);
}

.u-carousel-indicators-v8 span {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #fff;
}

.u-carousel-indicators-v8 li {
  display: block;
  margin: 15px 0;
}

.u-carousel-indicators-v8 li.slick-active span {
  background-color: #2cb8f3;
}

/*------------------------------------
  Carousel indicators v9
------------------------------------*/
.u-carousel-indicators-v9 {
  padding-left: 0;
}

.u-carousel-indicators-v9 li {
  display: block;
  color: #000;
  text-transform: uppercase;
  background-color: #bbb;
  border-top-width: 0;
  border-top-style: solid;
  border-top-color: #fff;
  vertical-align: top;
  cursor: pointer;
  padding: 10px;
}

.u-carousel-indicators-v9 li + li {
  border-top-width: 1px;
}

.u-carousel-indicators-v9 li.slick-active {
  color: #fff;
  background-color: #2cb8f3;
}

@media (min-width: 576px) {
  .u-carousel-indicators-v9 li {
    display: table-cell;
    width: 1%;
    border-width: 1px;
    border-style: solid;
    border-color: #fff;
    border-left-width: 0;
    border-right-width: 0;
    float: none;
    vertical-align: top;
    cursor: pointer;
    padding: 10px;
  }
  .u-carousel-indicators-v9 li + li {
    border-left-width: 1px;
  }
}

/*------------------------------------
  Carousel indicators v10
------------------------------------*/
.u-carousel-indicators-v10 {
  padding-left: 0;
}

.u-carousel-indicators-v10 li {
  display: block;
  color: #000;
  text-transform: uppercase;
  background-color: #bbb;
  border-top-width: 0;
  border-top-style: solid;
  border-top-color: #fff;
  vertical-align: top;
  cursor: pointer;
  padding: 10px;
}

.u-carousel-indicators-v10 li + li {
  border-top-width: 1px;
}

.u-carousel-indicators-v10 li.slick-active {
  color: #fff;
  background-color: #2cb8f3;
}

@media (min-width: 576px) {
  .u-carousel-indicators-v10 {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
  }
  .u-carousel-indicators-v10 li {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

/*------------------------------------
  Carousel indicators v11
------------------------------------*/
.u-carousel-indicators-v11 span {
  display: block;
  width: 12px;
  height: 12px;
  border: 1px solid #bbb;
  border-radius: 50%;
  background-color: transparent;
}

.u-carousel-indicators-v11 li {
  margin: 0 3px;
}

.u-carousel-indicators-v11 li.slick-active span {
  background-color: #bbb;
}

/*------------------------------------
  Carousel indicators v12
------------------------------------*/
.u-carousel-indicators-v12,
.u-carousel-indicators-v12--white {
  white-space: nowrap;
}

.u-carousel-indicators-v12 li,
.u-carousel-indicators-v12--white li {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0 5px;
}

.u-carousel-indicators-v12 li span,
.u-carousel-indicators-v12--white li span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: transparent;
  border-radius: 50%;
  text-indent: -9999px;
  transition: background .3s ease;
}

.u-carousel-indicators-v12 li span::before, .u-carousel-indicators-v12 li span::after,
.u-carousel-indicators-v12--white li span::before,
.u-carousel-indicators-v12--white li span::after {
  content: "";
  display: none;
}

.u-carousel-indicators-v12 li span::before,
.u-carousel-indicators-v12--white li span::before {
  display: block;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  transition: height .3s ease;
}

.u-carousel-indicators-v12 li span:hover, .u-carousel-indicators-v12 li span:focus,
.u-carousel-indicators-v12--white li span:hover,
.u-carousel-indicators-v12--white li span:focus {
  background-color: rgba(0, 0, 0, 0.2);
}

.u-carousel-indicators-v12 li.slick-active span::before,
.u-carousel-indicators-v12--white li.slick-active span::before {
  height: 100%;
}

.u-carousel-indicators-v12--white li span {
  box-shadow: inset 0 0 0 2px #fff;
}

.u-carousel-indicators-v12--white li span::before {
  background-color: #fff;
  box-shadow: 0 0 1px #fff;
}

/*------------------------------------
  Carousel indicators v13
------------------------------------*/
.u-carousel-indicators-v13,
.u-carousel-indicators-v13--white {
  white-space: nowrap;
}

.u-carousel-indicators-v13 li,
.u-carousel-indicators-v13--white li {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0 5px;
}

.u-carousel-indicators-v13 li span,
.u-carousel-indicators-v13--white li span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  text-indent: -9999px;
  transition: transform .3s ease, background-color .3s ease;
}

.u-carousel-indicators-v13 li span::before, .u-carousel-indicators-v13 li span::after,
.u-carousel-indicators-v13--white li span::before,
.u-carousel-indicators-v13--white li span::after {
  content: "";
  display: none;
}

.u-carousel-indicators-v13 li.slick-active span,
.u-carousel-indicators-v13--white li.slick-active span {
  transform: scale(1.5);
}

.u-carousel-indicators-v13--white li span {
  background: rgba(255, 255, 255, 0.5);
}

.u-carousel-indicators-v13--white li span:hover, .u-carousel-indicators-v13--white li span:focus {
  background-color: #fff;
}

.u-carousel-indicators-v13--white li.slick-active span {
  background-color: #fff;
}

/*------------------------------------
  Carousel indicators v14
------------------------------------*/
.u-carousel-indicators-v14,
.u-carousel-indicators-v14--white {
  white-space: nowrap;
}

.u-carousel-indicators-v14 li,
.u-carousel-indicators-v14--white li {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0 5px;
}

.u-carousel-indicators-v14 li span,
.u-carousel-indicators-v14--white li span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  text-indent: -9999px;
  transition: box-shadow .3s ease, background-color .3s ease;
}

.u-carousel-indicators-v14 li span::before, .u-carousel-indicators-v14 li span::after,
.u-carousel-indicators-v14--white li span::before,
.u-carousel-indicators-v14--white li span::after {
  content: "";
  display: none;
}

.u-carousel-indicators-v14--white li span {
  background: rgba(255, 255, 255, 0.5);
}

.u-carousel-indicators-v14--white li span:hover, .u-carousel-indicators-v14--white li span:focus {
  background-color: #fff;
}

.u-carousel-indicators-v14--white li.slick-active span {
  background-color: transparent;
  box-shadow: 0 0 0 2px #fff;
}

/*------------------------------------
  Carousel indicators v15
------------------------------------*/
.u-carousel-indicators-v15,
.u-carousel-indicators-v15--white {
  white-space: nowrap;
}

.u-carousel-indicators-v15 li,
.u-carousel-indicators-v15--white li {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0 5px;
}

.u-carousel-indicators-v15 li span,
.u-carousel-indicators-v15--white li span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-radius: 50%;
  text-indent: -9999px;
  transition: box-shadow .3s ease;
}

.u-carousel-indicators-v15 li span::before, .u-carousel-indicators-v15 li span::after,
.u-carousel-indicators-v15--white li span::before,
.u-carousel-indicators-v15--white li span::after {
  content: "";
  display: none;
}

.u-carousel-indicators-v15--white li span {
  box-shadow: inset 0 0 0 2px #fff;
}

.u-carousel-indicators-v15--white li span:hover, .u-carousel-indicators-v15--white li span:focus {
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.6);
}

.u-carousel-indicators-v15--white li.slick-active span {
  box-shadow: inset 0 0 0 8px #fff;
}

/*------------------------------------
  Carousel indicators v16
------------------------------------*/
.u-carousel-indicators-v16,
.u-carousel-indicators-v16--white {
  white-space: nowrap;
}

.u-carousel-indicators-v16 li,
.u-carousel-indicators-v16--white li {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0 5px;
}

.u-carousel-indicators-v16 li span,
.u-carousel-indicators-v16--white li span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-radius: 50%;
  overflow: hidden;
  text-indent: -9999px;
  transition: opacity .3s ease;
}

.u-carousel-indicators-v16 li span::before, .u-carousel-indicators-v16 li span::after,
.u-carousel-indicators-v16--white li span::before,
.u-carousel-indicators-v16--white li span::after {
  content: "";
  display: none;
}

.u-carousel-indicators-v16 li span::before,
.u-carousel-indicators-v16--white li span::before {
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transform: scale(0);
  transform-origin: 50% 50%;
  transition: transform .3s ease;
}

.u-carousel-indicators-v16 li span:hover, .u-carousel-indicators-v16 li span:focus,
.u-carousel-indicators-v16--white li span:hover,
.u-carousel-indicators-v16--white li span:focus {
  opacity: .7;
}

.u-carousel-indicators-v16 li.slick-active span::before,
.u-carousel-indicators-v16--white li.slick-active span::before {
  transform: scale(1);
}

.u-carousel-indicators-v16--white li span {
  box-shadow: inset 0 0 0 2px #fff;
}

.u-carousel-indicators-v16--white li span::before {
  background-color: #fff;
}

.u-carousel-indicators-v16--sm li {
  width: 8px;
  height: 8px;
}

.u-carousel-indicators-v16--sm li span {
  box-shadow: inset 0 0 0 1px #fff;
}

/*------------------------------------
  Carousel indicators v17
------------------------------------*/
.u-carousel-indicators-v17,
.u-carousel-indicators-v17--white {
  white-space: nowrap;
}

.u-carousel-indicators-v17 li,
.u-carousel-indicators-v17--white li {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0 5px;
}

.u-carousel-indicators-v17 li span,
.u-carousel-indicators-v17--white li span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  text-indent: -9999px;
  transition: box-shadow 0.3s ease;
}

.u-carousel-indicators-v17 li span::before, .u-carousel-indicators-v17 li span::after,
.u-carousel-indicators-v17--white li span::before,
.u-carousel-indicators-v17--white li span::after {
  content: "";
  display: none;
}

.u-carousel-indicators-v17--white li span {
  box-shadow: inset 0 0 0 8px rgba(255, 255, 255, 0.5);
}

.u-carousel-indicators-v17--white li span:hover, .u-carousel-indicators-v17--white li span:focus {
  box-shadow: inset 0 0 0 8px #fff;
}

.u-carousel-indicators-v17--white li.slick-active span {
  box-shadow: inset 0 0 0 2px #fff;
}

/*------------------------------------
  Carousel indicators v18
------------------------------------*/
.u-carousel-indicators-v18,
.u-carousel-indicators-v18--white {
  white-space: nowrap;
}

.u-carousel-indicators-v18 li,
.u-carousel-indicators-v18--white li {
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;
  margin: 0 5px;
}

.u-carousel-indicators-v18 li span,
.u-carousel-indicators-v18--white li span {
  position: absolute;
  top: 3px;
  left: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  text-indent: -9999px;
  transition: opacity .3s ease;
}

.u-carousel-indicators-v18 li span::before, .u-carousel-indicators-v18 li span::after,
.u-carousel-indicators-v18--white li span::before,
.u-carousel-indicators-v18--white li span::after {
  content: "";
  display: none;
}

.u-carousel-indicators-v18 li span:hover, .u-carousel-indicators-v18 li span:focus,
.u-carousel-indicators-v18--white li span:hover,
.u-carousel-indicators-v18--white li span:focus {
  opacity: .5;
}

.u-carousel-indicators-v18 li svg,
.u-carousel-indicators-v18 li i,
.u-carousel-indicators-v18--white li svg,
.u-carousel-indicators-v18--white li i {
  z-index: 10;
  width: 100%;
  height: 100%;
}

.u-carousel-indicators-v18 li svg circle,
.u-carousel-indicators-v18 li svg path,
.u-carousel-indicators-v18 li svg polygon,
.u-carousel-indicators-v18 li i circle,
.u-carousel-indicators-v18 li i path,
.u-carousel-indicators-v18 li i polygon,
.u-carousel-indicators-v18--white li svg circle,
.u-carousel-indicators-v18--white li svg path,
.u-carousel-indicators-v18--white li svg polygon,
.u-carousel-indicators-v18--white li i circle,
.u-carousel-indicators-v18--white li i path,
.u-carousel-indicators-v18--white li i polygon {
  opacity: 0;
  fill: none;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 39 39;
  stroke-dashoffset: 39;
  transition: stroke-dashoffset .3s, opacity .3s;
}

.u-carousel-indicators-v18 li.slick-active span,
.u-carousel-indicators-v18--white li.slick-active span {
  opacity: .5;
}

.u-carousel-indicators-v18 li.slick-active svg circle,
.u-carousel-indicators-v18 li.slick-active svg path,
.u-carousel-indicators-v18 li.slick-active svg polygon,
.u-carousel-indicators-v18 li.slick-active i circle,
.u-carousel-indicators-v18 li.slick-active i path,
.u-carousel-indicators-v18 li.slick-active i polygon,
.u-carousel-indicators-v18--white li.slick-active svg circle,
.u-carousel-indicators-v18--white li.slick-active svg path,
.u-carousel-indicators-v18--white li.slick-active svg polygon,
.u-carousel-indicators-v18--white li.slick-active i circle,
.u-carousel-indicators-v18--white li.slick-active i path,
.u-carousel-indicators-v18--white li.slick-active i polygon {
  opacity: 1;
  stroke-dashoffset: 0;
  transition: stroke-dashoffset .3s, opacity .15s;
}

.u-carousel-indicators-v18--white li span {
  background-color: #2cb8f3;
}

.u-carousel-indicators-v18--white li svg circle,
.u-carousel-indicators-v18--white li svg path,
.u-carousel-indicators-v18--white li svg polygon,
.u-carousel-indicators-v18--white li i circle,
.u-carousel-indicators-v18--white li i path,
.u-carousel-indicators-v18--white li i polygon {
  stroke: #fff;
}

/*------------------------------------
  Carousel indicators v19
------------------------------------*/
.u-carousel-indicators-v19,
.u-carousel-indicators-v19--white {
  white-space: nowrap;
}

.u-carousel-indicators-v19 li,
.u-carousel-indicators-v19--white li {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin: 0 5px;
  transition: box-shadow .3s ease;
}

.u-carousel-indicators-v19 li span,
.u-carousel-indicators-v19--white li span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  text-indent: -9999px;
  transition: background-color .3s ease, transform .3s ease;
}

.u-carousel-indicators-v19 li span::before, .u-carousel-indicators-v19 li span::after,
.u-carousel-indicators-v19--white li span::before,
.u-carousel-indicators-v19--white li span::after {
  content: "";
  display: none;
}

.u-carousel-indicators-v19 li.slick-active span,
.u-carousel-indicators-v19--white li.slick-active span {
  background-color: #fff;
  transform: scale(0.4);
}

.u-carousel-indicators-v19--white li {
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0);
}

.u-carousel-indicators-v19--white li span {
  background-color: rgba(255, 255, 255, 0.7);
}

.u-carousel-indicators-v19--white li span:hover, .u-carousel-indicators-v19--white li span:focus {
  background-color: #fff;
}

.u-carousel-indicators-v19--white li.slick-active {
  box-shadow: 0 0 0 2px #fff;
}

/*------------------------------------
  Carousel indicators v20
------------------------------------*/
.u-carousel-indicators-v20,
.u-carousel-indicators-v20--white {
  white-space: nowrap;
}

.u-carousel-indicators-v20 li,
.u-carousel-indicators-v20--white li {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0 5px;
}

.u-carousel-indicators-v20 li span,
.u-carousel-indicators-v20--white li span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  text-indent: -9999px;
  transition: border-color .3s ease;
}

.u-carousel-indicators-v20 li span::before, .u-carousel-indicators-v20 li span::after,
.u-carousel-indicators-v20--white li span::before,
.u-carousel-indicators-v20--white li span::after {
  content: "";
  display: none;
}

.u-carousel-indicators-v20 li span::before,
.u-carousel-indicators-v20--white li span::before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  visibility: hidden;
  opacity: 0;
  transform: scale(3);
  transition: opacity .3s ease, transform .3s ease, visibility 0s .3s;
}

.u-carousel-indicators-v20 li span:hover, .u-carousel-indicators-v20 li span:focus,
.u-carousel-indicators-v20--white li span:hover,
.u-carousel-indicators-v20--white li span:focus {
  border-color: #2cb8f3;
}

.u-carousel-indicators-v20 li.slick-active span,
.u-carousel-indicators-v20--white li.slick-active span {
  border-color: #2cb8f3;
}

.u-carousel-indicators-v20 li.slick-active span::before,
.u-carousel-indicators-v20--white li.slick-active span::before {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
  transition: opacity .3s ease, transform .3s ease;
}

.u-carousel-indicators-v20--white li span {
  border: 2px solid #fff;
}

.u-carousel-indicators-v20--white li span::before {
  background: #2cb8f3;
  box-shadow: 0 0 1px #2cb8f3;
}

/*------------------------------------
  Carousel indicators v21
------------------------------------*/
.u-carousel-indicators-v21,
.u-carousel-indicators-v21--white {
  white-space: nowrap;
}

.u-carousel-indicators-v21 li,
.u-carousel-indicators-v21--white li {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0 5px;
  perspective: 1000px;
}

.u-carousel-indicators-v21 li span,
.u-carousel-indicators-v21--white li span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-radius: 50%;
  text-indent: -9999px;
  transform-style: preserve-3d;
  transition: transform .3s ease, opacity .3s ease;
}

.u-carousel-indicators-v21 li span::before, .u-carousel-indicators-v21 li span::after,
.u-carousel-indicators-v21--white li span::before,
.u-carousel-indicators-v21--white li span::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  text-indent: 0;
  backface-visibility: hidden;
}

.u-carousel-indicators-v21 li span::after,
.u-carousel-indicators-v21--white li span::after {
  transform: rotateY(180deg);
}

.u-carousel-indicators-v21 li span:hover,
.u-carousel-indicators-v21--white li span:hover {
  opacity: .8;
}

.u-carousel-indicators-v21 li.slick-active span,
.u-carousel-indicators-v21--white li.slick-active span {
  transform: rotateY(180deg);
}

.u-carousel-indicators-v21--white li span::before {
  background-color: #fff;
}

.u-carousel-indicators-v21--white li span::after {
  background-color: #2cb8f3;
}

/*------------------------------------
  Carousel indicators v22
------------------------------------*/
.u-carousel-indicators-v22,
.u-carousel-indicators-v22--white {
  white-space: nowrap;
}

.u-carousel-indicators-v22 li,
.u-carousel-indicators-v22--white li {
  position: relative;
  display: inline-block;
  z-index: 1;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 5px;
  transition: border-color .3s ease;
}

.u-carousel-indicators-v22 li span,
.u-carousel-indicators-v22--white li span {
  position: absolute;
  bottom: 250%;
  left: 50%;
  width: auto;
  height: auto;
  line-height: 2;
  opacity: 0;
  white-space: nowrap;
  visibility: hidden;
  border-radius: 0;
  padding: 0 10px;
  transform: translateX(-50%) translateY(-50%);
  transition: transform .3s ease, opacity .3s ease, visibility 0s .3s ease;
}

.u-carousel-indicators-v22 li span::before, .u-carousel-indicators-v22 li span::after,
.u-carousel-indicators-v22--white li span::before,
.u-carousel-indicators-v22--white li span::after {
  content: "";
  display: none;
}

.u-carousel-indicators-v22 li span::before,
.u-carousel-indicators-v22--white li span::before {
  display: block;
  position: absolute;
  top: 99%;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -10px;
  border: 10px solid transparent;
  pointer-events: none;
  backface-visibility: hidden;
  transition: opacity .3s ease;
}

.u-carousel-indicators-v22 li span:hover,
.u-carousel-indicators-v22--white li span:hover {
  z-index: 11;
  visibility: visible;
  opacity: 1;
  transform: translateX(-50%) translateY(0%);
  transition: transform .3s ease, opacity .3s ease;
}

.u-carousel-indicators-v22 li.slick-active span,
.u-carousel-indicators-v22--white li.slick-active span {
  z-index: 100;
  visibility: visible;
  opacity: 1;
  transform: translateX(-50%) translateY(0%);
  transition: transform .3s ease, opacity .3s ease;
}

.u-carousel-indicators-v22 li.slick-active:hover span,
.u-carousel-indicators-v22--white li.slick-active:hover span {
  opacity: 1 !important;
}

.u-carousel-indicators-v22 li:hover,
.u-carousel-indicators-v22--white li:hover {
  z-index: 11;
}

.u-carousel-indicators-v22:hover li.slick-active span,
.u-carousel-indicators-v22--white:hover li.slick-active span {
  opacity: .2;
}

.u-carousel-indicators-v22--white li {
  border: 2px solid #fff;
}

.u-carousel-indicators-v22--white li span {
  color: #fff;
  background-color: #2cb8f3;
}

.u-carousel-indicators-v22--white li span::before {
  border-top-color: #2cb8f3;
}

.u-carousel-indicators-v22--white li.slick-active {
  border-color: #2cb8f3;
}

/*------------------------------------
  Carousel indicators v23
------------------------------------*/
.u-carousel-indicators-v23,
.u-carousel-indicators-v23--white {
  white-space: nowrap;
}

.u-carousel-indicators-v23 li,
.u-carousel-indicators-v23--white li {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0 5px;
}

.u-carousel-indicators-v23 li span,
.u-carousel-indicators-v23--white li span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  text-indent: -9999px;
  transition: background-color .3s ease;
}

.u-carousel-indicators-v23 li span::before, .u-carousel-indicators-v23 li span::after,
.u-carousel-indicators-v23--white li span::before,
.u-carousel-indicators-v23--white li span::after {
  content: "";
  display: none;
}

.u-carousel-indicators-v23 li.slick-active:first-child ~ .u-dots-helper,
.u-carousel-indicators-v23--white li.slick-active:first-child ~ .u-dots-helper {
  transform: translateX(0);
}

.u-carousel-indicators-v23 li.slick-active:nth-child(2) ~ .u-dots-helper,
.u-carousel-indicators-v23--white li.slick-active:nth-child(2) ~ .u-dots-helper {
  transform: translateX(100%);
}

.u-carousel-indicators-v23 li.slick-active:nth-child(3) ~ .u-dots-helper,
.u-carousel-indicators-v23--white li.slick-active:nth-child(3) ~ .u-dots-helper {
  transform: translateX(200%);
}

.u-carousel-indicators-v23 li.slick-active:nth-child(4) ~ .u-dots-helper,
.u-carousel-indicators-v23--white li.slick-active:nth-child(4) ~ .u-dots-helper {
  transform: translateX(300%);
}

.u-carousel-indicators-v23 li.slick-active:nth-child(5) ~ .u-dots-helper,
.u-carousel-indicators-v23--white li.slick-active:nth-child(5) ~ .u-dots-helper {
  transform: translateX(400%);
}

.u-carousel-indicators-v23 li.slick-active:nth-child(6) ~ .u-dots-helper,
.u-carousel-indicators-v23--white li.slick-active:nth-child(6) ~ .u-dots-helper {
  transform: translateX(500%);
}

.u-carousel-indicators-v23 li.slick-active:nth-child(7) ~ .u-dots-helper,
.u-carousel-indicators-v23--white li.slick-active:nth-child(7) ~ .u-dots-helper {
  transform: translateX(600%);
}

.u-carousel-indicators-v23 li.slick-active:nth-child(8) ~ .u-dots-helper,
.u-carousel-indicators-v23--white li.slick-active:nth-child(8) ~ .u-dots-helper {
  transform: translateX(700%);
}

.u-carousel-indicators-v23 li.slick-active:nth-child(9) ~ .u-dots-helper,
.u-carousel-indicators-v23--white li.slick-active:nth-child(9) ~ .u-dots-helper {
  transform: translateX(800%);
}

.u-carousel-indicators-v23 li.slick-active:nth-child(10) ~ .u-dots-helper,
.u-carousel-indicators-v23--white li.slick-active:nth-child(10) ~ .u-dots-helper {
  transform: translateX(900%);
}

.u-carousel-indicators-v23 li.slick-active:nth-child(11) ~ .u-dots-helper,
.u-carousel-indicators-v23--white li.slick-active:nth-child(11) ~ .u-dots-helper {
  transform: translateX(1000%);
}

.u-carousel-indicators-v23 li.slick-active:nth-child(12) ~ .u-dots-helper,
.u-carousel-indicators-v23--white li.slick-active:nth-child(12) ~ .u-dots-helper {
  transform: translateX(1100%);
}

.u-carousel-indicators-v23 li.slick-active:nth-child(13) ~ .u-dots-helper,
.u-carousel-indicators-v23--white li.slick-active:nth-child(13) ~ .u-dots-helper {
  transform: translateX(1200%);
}

.u-carousel-indicators-v23 li.slick-active:nth-child(14) ~ .u-dots-helper,
.u-carousel-indicators-v23--white li.slick-active:nth-child(14) ~ .u-dots-helper {
  transform: translateX(1300%);
}

.u-carousel-indicators-v23 li.slick-active:nth-child(15) ~ .u-dots-helper,
.u-carousel-indicators-v23--white li.slick-active:nth-child(15) ~ .u-dots-helper {
  transform: translateX(1400%);
}

.u-carousel-indicators-v23 li.slick-active:nth-child(16) ~ .u-dots-helper,
.u-carousel-indicators-v23--white li.slick-active:nth-child(16) ~ .u-dots-helper {
  transform: translateX(1500%);
}

.u-carousel-indicators-v23 li.slick-active:nth-child(17) ~ .u-dots-helper,
.u-carousel-indicators-v23--white li.slick-active:nth-child(17) ~ .u-dots-helper {
  transform: translateX(1600%);
}

.u-carousel-indicators-v23 li.slick-active:nth-child(18) ~ .u-dots-helper,
.u-carousel-indicators-v23--white li.slick-active:nth-child(18) ~ .u-dots-helper {
  transform: translateX(1700%);
}

.u-carousel-indicators-v23 li.slick-active:nth-child(19) ~ .u-dots-helper,
.u-carousel-indicators-v23--white li.slick-active:nth-child(19) ~ .u-dots-helper {
  transform: translateX(1800%);
}

.u-carousel-indicators-v23 li.slick-active:nth-child(20) ~ .u-dots-helper,
.u-carousel-indicators-v23--white li.slick-active:nth-child(20) ~ .u-dots-helper {
  transform: translateX(1900%);
}

.u-carousel-indicators-v23 li.slick-active:nth-child(21) ~ .u-dots-helper,
.u-carousel-indicators-v23--white li.slick-active:nth-child(21) ~ .u-dots-helper {
  transform: translateX(2000%);
}

.u-carousel-indicators-v23 li.slick-active:nth-child(22) ~ .u-dots-helper,
.u-carousel-indicators-v23--white li.slick-active:nth-child(22) ~ .u-dots-helper {
  transform: translateX(2100%);
}

.u-carousel-indicators-v23 li.slick-active:nth-child(23) ~ .u-dots-helper,
.u-carousel-indicators-v23--white li.slick-active:nth-child(23) ~ .u-dots-helper {
  transform: translateX(2200%);
}

.u-carousel-indicators-v23 li.slick-active:nth-child(24) ~ .u-dots-helper,
.u-carousel-indicators-v23--white li.slick-active:nth-child(24) ~ .u-dots-helper {
  transform: translateX(2300%);
}

.u-carousel-indicators-v23 li.slick-active:nth-child(25) ~ .u-dots-helper,
.u-carousel-indicators-v23--white li.slick-active:nth-child(25) ~ .u-dots-helper {
  transform: translateX(2400%);
}

.u-carousel-indicators-v23 li.slick-active:nth-child(26) ~ .u-dots-helper,
.u-carousel-indicators-v23--white li.slick-active:nth-child(26) ~ .u-dots-helper {
  transform: translateX(2500%);
}

.u-carousel-indicators-v23 li.slick-active:nth-child(27) ~ .u-dots-helper,
.u-carousel-indicators-v23--white li.slick-active:nth-child(27) ~ .u-dots-helper {
  transform: translateX(2600%);
}

.u-carousel-indicators-v23 li.slick-active:nth-child(28) ~ .u-dots-helper,
.u-carousel-indicators-v23--white li.slick-active:nth-child(28) ~ .u-dots-helper {
  transform: translateX(2700%);
}

.u-carousel-indicators-v23 li.slick-active:nth-child(29) ~ .u-dots-helper,
.u-carousel-indicators-v23--white li.slick-active:nth-child(29) ~ .u-dots-helper {
  transform: translateX(2800%);
}

.u-carousel-indicators-v23 .u-dots-helper,
.u-carousel-indicators-v23--white .u-dots-helper {
  position: absolute;
  left: 0;
  top: 0;
  width: 26px;
  transition: transform .3s ease;
}

.u-carousel-indicators-v23 .u-dots-helper::before,
.u-carousel-indicators-v23--white .u-dots-helper::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  transform: translateX(-50%);
}

.u-carousel-indicators-v23--white li span {
  background: rgba(255, 255, 255, 0.5);
}

.u-carousel-indicators-v23--white li span:hover, .u-carousel-indicators-v23--white li span:focus {
  background-color: #fff;
}

.u-carousel-indicators-v23--white .u-dots-helper::before {
  background: #2cb8f3;
}

/*------------------------------------
  Carousel indicators v24
------------------------------------*/
.u-carousel-indicators-v24,
.u-carousel-indicators-v24--white {
  white-space: nowrap;
}

.u-carousel-indicators-v24 li,
.u-carousel-indicators-v24--white li {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0 5px;
}

.u-carousel-indicators-v24 li span,
.u-carousel-indicators-v24--white li span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 50%;
  text-indent: -9999px;
}

.u-carousel-indicators-v24 li span::before, .u-carousel-indicators-v24 li span::after,
.u-carousel-indicators-v24--white li span::before,
.u-carousel-indicators-v24--white li span::after {
  content: "";
  display: none;
}

.u-carousel-indicators-v24 li span::before,
.u-carousel-indicators-v24--white li span::before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
  transition: transform .3s ease .3s, opacity .3s ease, visibility 0s .3s ease;
  transform-origin: -200% 50%;
  transform: rotate(-100deg);
}

.u-carousel-indicators-v24 li.slick-active-right span::before,
.u-carousel-indicators-v24--white li.slick-active-right span::before {
  transition: none;
  transform-origin: 300% 50%;
  transform: rotate(100deg);
}

.u-carousel-indicators-v24 li.slick-current span::before,
.u-carousel-indicators-v24--white li.slick-current span::before {
  visibility: visible;
  opacity: 1;
  transition: transform .3s ease, opacity .3s ease;
  transform: rotate(0deg);
}

.u-carousel-indicators-v24--white li span {
  border: 2px solid #2cb8f3;
}

.u-carousel-indicators-v24--white li span::before {
  background: #fff;
}

.u-carousel-indicators-v24--white li span:focus {
  background: rgba(255, 255, 255, 0.2);
}

/*------------------------------------
  Carousel indicators v25
------------------------------------*/
.u-carousel-indicators-v25,
.u-carousel-indicators-v25--white {
  white-space: nowrap;
}

.u-carousel-indicators-v25 li,
.u-carousel-indicators-v25--white li {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0 5px;
}

.u-carousel-indicators-v25 li::before,
.u-carousel-indicators-v25--white li::before {
  content: "";
  position: absolute;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-200%);
  transition: transform .3s ease, opacity .3s ease, visibility 0s .3s;
}

.u-carousel-indicators-v25 li span,
.u-carousel-indicators-v25--white li span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  text-indent: -9999px;
  transition: transform .3s ease, opacity .3s ease, background-color .3s ease;
}

.u-carousel-indicators-v25 li span::before, .u-carousel-indicators-v25 li span::after,
.u-carousel-indicators-v25--white li span::before,
.u-carousel-indicators-v25--white li span::after {
  content: "";
  display: none;
}

.u-carousel-indicators-v25 li.slick-active::before,
.u-carousel-indicators-v25--white li.slick-active::before {
  opacity: 1;
  visibility: visible;
  transition: transform .3s ease, opacity .3s ease;
  transform: translateY(0);
}

.u-carousel-indicators-v25 li.slick-active span,
.u-carousel-indicators-v25--white li.slick-active span {
  opacity: 0;
  transform: translateY(200%);
}

.u-carousel-indicators-v25--white li::before {
  background-color: #2cb8f3;
}

.u-carousel-indicators-v25--white li span {
  background: rgba(255, 255, 255, 0.5);
}

.u-carousel-indicators-v25--white li span::before {
  background-color: #2cb8f3;
}

.u-carousel-indicators-v25--white li span:focus {
  background-color: #2cb8f3;
}

.u-carousel-indicators-v25--white li:hover span {
  background-color: #fff;
}

.u-carousel-indicators-v25--white li.slick-active span {
  background-color: #2cb8f3;
}

/*------------------------------------
  Carousel indicators v26
------------------------------------*/
.u-carousel-indicators-v26 li,
.u-carousel-indicators-v26--white li,
.u-carousel-indicators-v26--vertical li,
.u-carousel-indicators-v26--vertical--white li {
  position: relative;
  width: 20px;
  height: 20px;
  float: left;
  margin-right: 40px;
}

.u-carousel-indicators-v26 li::before,
.u-carousel-indicators-v26--white li::before,
.u-carousel-indicators-v26--vertical li::before,
.u-carousel-indicators-v26--vertical--white li::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 16px;
  height: 16px;
  border: 2px solid;
  border-radius: 50%;
  transition: width .3s, height .3s, border-color .3s, border-width .3s, background .3s;
  transform: translateX(-50%) translateY(-50%);
}

.u-carousel-indicators-v26 li::after,
.u-carousel-indicators-v26--white li::after,
.u-carousel-indicators-v26--vertical li::after,
.u-carousel-indicators-v26--vertical--white li::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  z-index: -1;
  display: block;
  width: 44px;
  height: 0;
  border-top: 2px solid;
  transform: translateY(-50%);
  margin-left: -2px;
}

.u-carousel-indicators-v26 li span,
.u-carousel-indicators-v26--white li span,
.u-carousel-indicators-v26--vertical li span,
.u-carousel-indicators-v26--vertical--white li span {
  display: block;
  position: absolute;
  bottom: 100%;
  left: 50%;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  white-space: nowrap;
  opacity: .3;
  transition: opacity .3s, color .3s;
  transform: translateX(-50%);
  padding: 0 0 15px 0;
}

.u-carousel-indicators-v26 li.slick-active::before,
.u-carousel-indicators-v26--white li.slick-active::before,
.u-carousel-indicators-v26--vertical li.slick-active::before,
.u-carousel-indicators-v26--vertical--white li.slick-active::before {
  width: 20px;
  height: 20px;
}

.u-carousel-indicators-v26 li.slick-active span,
.u-carousel-indicators-v26--white li.slick-active span,
.u-carousel-indicators-v26--vertical li.slick-active span,
.u-carousel-indicators-v26--vertical--white li.slick-active span {
  opacity: 1;
}

.u-carousel-indicators-v26 li:last-child,
.u-carousel-indicators-v26--white li:last-child,
.u-carousel-indicators-v26--vertical li:last-child,
.u-carousel-indicators-v26--vertical--white li:last-child {
  margin-bottom: 0;
  margin-right: 0;
}

.u-carousel-indicators-v26 li:last-child::after,
.u-carousel-indicators-v26--white li:last-child::after,
.u-carousel-indicators-v26--vertical li:last-child::after,
.u-carousel-indicators-v26--vertical--white li:last-child::after {
  display: none;
}

.u-carousel-indicators-v26--vertical,
.u-carousel-indicators-v26--vertical--white {
  width: 20px;
}

.u-carousel-indicators-v26--vertical li,
.u-carousel-indicators-v26--vertical--white li {
  display: block;
  float: none;
  margin-bottom: 15px;
  margin-right: 0;
}

.u-carousel-indicators-v26--vertical li::after,
.u-carousel-indicators-v26--vertical--white li::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  z-index: -1;
  display: block;
  width: 0;
  height: 19px;
  border-left: 2px solid;
  transform: translateX(-50%) translateY(0);
  margin-top: -2px;
  margin-left: 0;
}

.u-carousel-indicators-v26--vertical li span,
.u-carousel-indicators-v26--vertical--white li span {
  display: block;
  position: absolute;
  top: 50%;
  bottom: auto;
  left: 100%;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  white-space: nowrap;
  opacity: .3;
  transition: opacity .3s, color .3s;
  transform: translateX(0) translateY(-50%);
  padding: 0 0 0 15px;
}

.u-carousel-indicators-v26--white li::before,
.u-carousel-indicators-v26--vertical--white li::before {
  border-color: #2cb8f3;
}

.u-carousel-indicators-v26--white li::after,
.u-carousel-indicators-v26--vertical--white li::after {
  border-color: #2cb8f3;
}

.u-carousel-indicators-v26--white li span,
.u-carousel-indicators-v26--vertical--white li span {
  color: #fff;
}

.u-carousel-indicators-v26--white li:not(.slick-active):hover::before,
.u-carousel-indicators-v26--vertical--white li:not(.slick-active):hover::before {
  border-color: #fff;
  background-color: #fff;
}

.u-carousel-indicators-v26--white li.slick-active::before,
.u-carousel-indicators-v26--vertical--white li.slick-active::before {
  border-color: #fff;
}

.u-carousel-indicators-v26--white li.slick-active span,
.u-carousel-indicators-v26--vertical--white li.slick-active span {
  color: #fff;
}

/*------------------------------------
  Carousel indicators v27
------------------------------------*/
.u-carousel-indicators-v27 li,
.u-carousel-indicators-v27--white li,
.u-carousel-indicators-v27--vertical li,
.u-carousel-indicators-v27--vertical--white li {
  position: relative;
  width: 40px;
  height: 4px;
  float: left;
  margin: 0 5px;
}

.u-carousel-indicators-v27 li span,
.u-carousel-indicators-v27--white li span,
.u-carousel-indicators-v27--vertical li span,
.u-carousel-indicators-v27--vertical--white li span {
  position: relative;
  display: block;
  width: 100%;
  height: 4px;
  opacity: .7;
  overflow: hidden;
  transition: opacity .3s;
}

.u-carousel-indicators-v27 li span::before,
.u-carousel-indicators-v27--white li span::before,
.u-carousel-indicators-v27--vertical li span::before,
.u-carousel-indicators-v27--vertical--white li span::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  transform: translate3d(0, 100%, 0);
  transition: transform .5s;
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.u-carousel-indicators-v27 li strong,
.u-carousel-indicators-v27--white li strong,
.u-carousel-indicators-v27--vertical li strong,
.u-carousel-indicators-v27--vertical--white li strong {
  position: absolute;
  bottom: 105%;
  left: 0;
  display: block;
  width: 40px;
  font-weight: 700;
  font-size: 20px;
  opacity: 0;
  transform: translate3d(1em, 0, 0);
  transform-origin: 0 0;
  transition: transform .5s, opacity .5s;
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.u-carousel-indicators-v27 li:not(.slick-active):hover span, .u-carousel-indicators-v27 li:not(.slick-active):focus span,
.u-carousel-indicators-v27--white li:not(.slick-active):hover span,
.u-carousel-indicators-v27--white li:not(.slick-active):focus span,
.u-carousel-indicators-v27--vertical li:not(.slick-active):hover span,
.u-carousel-indicators-v27--vertical li:not(.slick-active):focus span,
.u-carousel-indicators-v27--vertical--white li:not(.slick-active):hover span,
.u-carousel-indicators-v27--vertical--white li:not(.slick-active):focus span {
  opacity: 1;
}

.u-carousel-indicators-v27 li.slick-active span::before,
.u-carousel-indicators-v27--white li.slick-active span::before,
.u-carousel-indicators-v27--vertical li.slick-active span::before,
.u-carousel-indicators-v27--vertical--white li.slick-active span::before {
  transform: translate3d(0, 0, 0);
}

.u-carousel-indicators-v27 li.slick-active strong,
.u-carousel-indicators-v27--white li.slick-active strong,
.u-carousel-indicators-v27--vertical li.slick-active strong,
.u-carousel-indicators-v27--vertical--white li.slick-active strong {
  opacity: 1;
  transform: rotate3d(0, 0, 1, 0deg);
}

.u-carousel-indicators-v27--vertical li,
.u-carousel-indicators-v27--vertical--white li {
  display: block;
  width: 40px;
  height: 40px;
  float: none;
  margin: 15px 0;
}

.u-carousel-indicators-v27--vertical li span,
.u-carousel-indicators-v27--vertical--white li span {
  position: relative;
  display: block;
  width: 4px;
  height: 100%;
  opacity: .7;
  overflow: hidden;
  transition: opacity .3s;
}

.u-carousel-indicators-v27--vertical li span::before,
.u-carousel-indicators-v27--vertical--white li span::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  transform: translate3d(0, 100%, 0);
  transition: transform .5s;
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.u-carousel-indicators-v27--vertical li strong,
.u-carousel-indicators-v27--vertical--white li strong {
  position: absolute;
  top: 0;
  bottom: auto;
  left: 105%;
  display: block;
  width: 40px;
  font-weight: 700;
  font-size: 20px;
  opacity: 0;
  transform: rotate3d(0, 0, 1, 90deg) translate3d(1em, 0, 0);
  transform-origin: 0 0;
  transition: transform .5s, opacity .5s;
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.u-carousel-indicators-v27--vertical li.slick-active span::before,
.u-carousel-indicators-v27--vertical--white li.slick-active span::before {
  transform: translate3d(0, 0, 0);
}

.u-carousel-indicators-v27--vertical li.slick-active strong,
.u-carousel-indicators-v27--vertical--white li.slick-active strong {
  opacity: 1;
  transform: rotate3d(0, 0, 1, 90deg);
}

.u-carousel-indicators-v27--white li span,
.u-carousel-indicators-v27--vertical--white li span {
  background: #fff;
}

.u-carousel-indicators-v27--white li span::before,
.u-carousel-indicators-v27--vertical--white li span::before {
  background: #2cb8f3;
}

.u-carousel-indicators-v27--white li strong,
.u-carousel-indicators-v27--vertical--white li strong {
  color: #fff;
}

/*------------------------------------
  Carousel indicators v28
------------------------------------*/
.u-carousel-indicators-v28 {
  position: absolute;
  left: 0;
  top: 50%;
  bottom: auto;
  display: block;
  width: 7px;
  transform: translateY(-50%);
}

.u-carousel-indicators-v28 li {
  display: block;
  margin: 15px 0;
  float: none;
}

.u-carousel-indicators-v28 li.slick-active span {
  background-color: #2cb8f3;
  opacity: 1;
}

.u-carousel-indicators-v28 span {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #777;
  opacity: .3;
}

/*------------------------------------
  Carousel indicators v29
------------------------------------*/
.u-carousel-indicators-v29 {
  position: absolute;
  white-space: nowrap;
  text-align: center;
}

.u-carousel-indicators-v29 li {
  display: inline-block;
  min-width: 30px;
  cursor: pointer;
  float: none;
  border-top: 3px solid #fff;
  margin: -2px 20px 0;
}

.u-carousel-indicators-v29 li.slick-active {
  color: #2cb8f3;
  border-top-color: #2cb8f3;
}

.u-carousel-indicators-v29 .u-dot-title {
  display: none;
}

@media (min-width: 576px) {
  .u-carousel-indicators-v29 {
    border-top: 1px solid;
  }
  .u-carousel-indicators-v29 li {
    border-top: 3px solid transparent;
    padding-top: 10px;
    margin: -2px 20px 0;
  }
  .u-carousel-indicators-v29 .u-dot-title {
    display: block;
  }
}

/*------------------------------------
  Carousel indicators v30
------------------------------------*/
.u-carousel-indicators-v30 {
  display: block;
  width: 100%;
}

.u-carousel-indicators-v30 > li {
  display: table-cell;
  width: 1%;
  text-align: center;
  float: none;
}

.u-carousel-indicators-v30 span {
  display: inline-block;
}

@media (min-width: 576px) {
  .u-carousel-indicators-v30 {
    display: block;
    width: 100%;
  }
  .u-carousel-indicators-v30 > li {
    display: table-cell;
    width: 1%;
    text-align: center;
    float: none;
  }
  .u-carousel-indicators-v30 span {
    display: inline-block;
  }
}

/*------------------------------------
  Carousel indicators v31
------------------------------------*/
.u-carousel-indicators-v31,
.u-carousel-indicators-v31--white {
  display: flex !important;
}

.u-carousel-indicators-v31 li,
.u-carousel-indicators-v31--white li {
  margin: 0 5px;
}

.u-carousel-indicators-v31 li.slick-active span,
.u-carousel-indicators-v31--white li.slick-active span {
  position: relative;
  top: 1px;
  width: 13px;
  height: 13px;
  background-color: #2cb8f3;
  transition-property: all;
  transition-timing-function: ease;
  transition-duration: .4s;
}

.u-carousel-indicators-v31 span,
.u-carousel-indicators-v31--white span {
  position: relative;
  top: 2px;
  width: 11px;
  height: 11px;
  border: 2px solid #fff;
  border-radius: 50%;
  opacity: 1;
  transition-property: all;
  transition-timing-function: ease;
  transition-duration: .4s;
  background-color: #ddd;
}

/*------------------------------------
  Carousel Indicators v32
------------------------------------*/
.u-carousel-indicators-v32 .slick-slide {
  align-items: center !important;
  transition-property: all;
  transition-timing-function: ease;
  transition-delay: 0s;
  transition-duration: .5s;
}

.u-carousel-indicators-v32 .slick-slide .u-carousel-indicators-v32-img {
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0);
}

.u-carousel-indicators-v32 .slick-center {
  width: 70px;
  height: 70px;
  -webkit-transform: scale(1.35);
}

.u-carousel-indicators-v32 .slick-center .u-carousel-indicators-v32-img {
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
  border-color: #fff;
}

/*------------------------------------
  Carousel indicators v33
------------------------------------*/
.u-carousel-indicators-v33 {
  position: relative;
  padding-top: 15px;
  padding-left: 0;
}

.u-carousel-indicators-v33 li {
  display: block;
  color: #eee;
  cursor: pointer;
  padding: 10px 0;
}

.u-carousel-indicators-v33 li:hover {
  color: rgba(44, 184, 243, 0.5);
}

.u-carousel-indicators-v33 li.slick-active {
  color: #2cb8f3;
}

.u-carousel-indicators-v33 li span {
  display: none;
}

/*------------------------------------
  Carousel indicators v34
------------------------------------*/
.u-carousel-indicators-v34 li {
  display: block;
  margin: 10px 0;
}

.u-carousel-indicators-v34 li.slick-active span {
  background-color: #2cb8f3;
  transform: scale(1);
}

.u-carousel-indicators-v34 span {
  width: 13px;
  height: 13px;
  border: 2px solid #fff;
  border-radius: 50%;
  transform: scale(0.7);
  transition: all .3s ease;
}

/*------------------------------------
  Carousel indicators v35
------------------------------------*/
.u-carousel-indicators-v35 li,
.u-carousel-indicators-v35--white li,
.u-carousel-indicators-v35--main li {
  display: inline-block;
  margin: 0 5px;
}

.u-carousel-indicators-v35 li.slick-active span,
.u-carousel-indicators-v35--white li.slick-active span,
.u-carousel-indicators-v35--main li.slick-active span {
  opacity: 1;
}

.u-carousel-indicators-v35 span,
.u-carousel-indicators-v35--white span,
.u-carousel-indicators-v35--main span {
  width: 13px;
  height: 13px;
  background-color: #2cb8f3;
  border: 2px solid transparent;
  opacity: .7;
  border-radius: 50%;
  transform: scale(0.6);
  transition: all .3s ease;
}

.u-carousel-indicators-v35 li.slick-active span,
.u-carousel-indicators-v35--white li.slick-active span,
.u-carousel-indicators-v35--main li.slick-active span {
  background-color: transparent;
  border-color: #2cb8f3;
  opacity: .5;
  transform: scale(0.9);
}

.u-carousel-indicators-v35--white li span {
  background-color: #fff;
}

.u-carousel-indicators-v35--white li.slick-active span {
  border-color: #fff;
}

.u-carousel-indicators-v35--main li span {
  background-color: #000;
}

.u-carousel-indicators-v35--main li.slick-active span {
  border-color: #000;
}

/*------------------------------------
  Breadcrumbs v1
------------------------------------*/
.u-breadcrumbs-v1 {
  padding-left: 0;
  margin-bottom: 0;
}

.u-breadcrumbs-v1 > * {
  display: inline-block;
  vertical-align: middle;
}

.u-breadcrumbs-v1 a:hover {
  text-decoration: none;
}

/*------------------------------------
  Breadcrumbs v2
------------------------------------*/
.u-breadcrumbs-v2 {
  padding-left: 0;
  margin-bottom: 0;
}

.u-breadcrumbs-v2 > * {
  display: inline-block;
  vertical-align: middle;
}

.u-breadcrumbs-v2 > * > * {
  display: block;
  border-radius: 3px;
}

.u-breadcrumbs-v2 a:hover {
  text-decoration: none;
}

.u-breadcrumbs-v2 [class*="u-triangle"] {
  display: none;
}

@media (min-width: 768px) {
  .u-breadcrumbs-v2 > * {
    position: relative;
    background-color: #fff;
  }
  .u-breadcrumbs-v2 > * > * {
    border-radius: 0;
  }
  .u-breadcrumbs-v2 > *:first-child > * {
    border-radius: 5px 0 0 5px;
  }
  .u-breadcrumbs-v2 > *:last-child > * {
    border-radius: 0 5px 5px 0;
  }
  .u-breadcrumbs-v2 [class*="u-triangle"] {
    top: 0;
    z-index: 2;
    display: block;
    width: 20px;
    height: 100%;
    margin-left: -0.05em;
  }
}

/*------------------------------------
  Dot line v1
------------------------------------*/
.u-dot-line-v1,
.u-dot-line-v1-2 {
  position: relative;
  display: block;
  width: 100%;
  font-size: 0;
}

.u-dot-line-v1::before, .u-dot-line-v1::after,
.u-dot-line-v1-2::before,
.u-dot-line-v1-2::after {
  content: "";
  position: absolute;
  top: 50%;
  display: block;
  width: calc(50% + 30px);
  border-top-width: 1px;
  border-top-style: solid;
  margin-top: -1px;
}

.u-dot-line-v1::before,
.u-dot-line-v1-2::before {
  left: -30px;
}

li:first-child .u-dot-line-v1::before, li:first-child
.u-dot-line-v1-2::before {
  display: none;
}

.u-dot-line-v1::after,
.u-dot-line-v1-2::after {
  right: -30px;
}

li:last-child .u-dot-line-v1::after, li:last-child
.u-dot-line-v1-2::after {
  display: none;
}

.u-dot-line-v1__inner,
.u-dot-line-v1-2__inner {
  position: relative;
  z-index: 2;
  display: inline-block;
  width: 30px;
  height: 30px;
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
}

.u-dot-line-v1__inner::before,
.u-dot-line-v1-2__inner::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 12px;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

.u-dot-line-v1-2::before, .u-dot-line-v1-2::after,
.u-dot-line-v1-2-2::before,
.u-dot-line-v1-2-2::after {
  display: none;
}

@media (min-width: 992px) {
  .u-dot-line-v1-2::before, .u-dot-line-v1-2::after {
    display: block;
  }
}

/*------------------------------------
  Dot line v2
------------------------------------*/
.u-dot-line-v2,
.u-dot-line-v2-2 {
  position: relative;
  display: block;
  width: 100%;
  font-size: 0;
}

.u-dot-line-v2::before, .u-dot-line-v2::after,
.u-dot-line-v2-2::before,
.u-dot-line-v2-2::after {
  content: "";
  position: absolute;
  top: 50%;
  display: block;
  width: calc(50% + 30px);
  border-top-width: 1px;
  border-top-style: solid;
  margin-top: -1px;
}

.u-dot-line-v2::before,
.u-dot-line-v2-2::before {
  left: -30px;
}

li:first-child .u-dot-line-v2::before, li:first-child
.u-dot-line-v2-2::before {
  display: none;
}

.u-dot-line-v2::after,
.u-dot-line-v2-2::after {
  right: -30px;
}

li:last-child .u-dot-line-v2::after, li:last-child
.u-dot-line-v2-2::after {
  display: none;
}

.u-dot-line-v2__inner,
.u-dot-line-v2-2__inner {
  position: relative;
  z-index: 2;
  display: inline-block;
  width: 20px;
  height: 20px;
}

.u-dot-line-v2__inner::before,
.u-dot-line-v2-2__inner::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  transform: translate(-50%, -50%);
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
}

.u-dot-line-v2-2::before, .u-dot-line-v2-2::after,
.u-dot-line-v2-2-2::before,
.u-dot-line-v2-2-2::after {
  display: none;
}

@media (min-width: 992px) {
  .u-dot-line-v2-2::before, .u-dot-line-v2-2::after {
    display: block;
  }
}

/*------------------------------------
  Heading-v1
------------------------------------*/
[class*="u-heading-v1-"] {
  position: relative;
}

[class*="u-heading-v1-"]::before, [class*="u-heading-v1-"]::after {
  content: "";
  position: absolute;
  left: auto;
  right: 0;
  width: 100%;
  height: 0;
  border-top-width: 1px;
  border-color: inherit;
}

[class*="u-heading-v1-"].text-center::before, [class*="u-heading-v1-"].text-center::after {
  left: 0;
  right: 0;
}

[class*="u-heading-v1-"].text-right::before, [class*="u-heading-v1-"].text-right::after {
  left: 0;
  right: auto;
}

.u-heading-v1__title {
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  padding-right: 1.07143rem;
  background-color: inherit;
  z-index: 2;
}

.text-right .u-heading-v1__title {
  padding-left: 1.07143rem;
  padding-right: 0;
}

.text-center .u-heading-v1__title {
  padding-left: 1.07143rem;
  padding-right: 1.07143rem;
}

/*------------------------------------
  Heading-v1-1
------------------------------------*/
.u-heading-v1-1::before {
  top: 48%;
  border-top-style: solid;
}

.u-heading-v1-1::after {
  top: 58%;
  border-top-style: solid;
}

/*------------------------------------
  Heading-v1-2
------------------------------------*/
.u-heading-v1-2::before {
  top: 48%;
  border-top-style: dashed;
}

.u-heading-v1-2::after {
  top: 58%;
  border-top-style: dashed;
}

/*------------------------------------
  Heading-v1-3
------------------------------------*/
.u-heading-v1-3::before {
  top: 48%;
  border-top-style: dotted;
}

.u-heading-v1-3::after {
  top: 58%;
  border-top-style: dotted;
}

/*------------------------------------
  Heading-v1-4
------------------------------------*/
.u-heading-v1-4::before {
  top: 50%;
  border-top-style: solid;
}

/*------------------------------------
  Heading-v1-5
------------------------------------*/
.u-heading-v1-5::before {
  top: 50%;
  border-top-style: dashed;
}

/*------------------------------------
  Heading-v1-6
------------------------------------*/
.u-heading-v1-6::before {
  top: 50%;
  border-top-style: dotted;
}

/*------------------------------------
  Heading-v1-7
------------------------------------*/
.u-heading-v1-7 {
  background: url('data:image/svg+xml,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="9px" height="6px"><polygon opacity="0.2" points="4.5,4.5 0,0 0,1.208 4.5,5.708 9,1.208 9,0 "/></svg>') 0 50% repeat-x;
}

/*------------------------------------
  Heading-v2
------------------------------------*/
.u-heading-v2-1--bottom::after, .u-heading-v2-1--top::before, .u-heading-v2-2--bottom::after, .u-heading-v2-2--top::before, .u-heading-v2-3--bottom::after, .u-heading-v2-3--top::before, .u-heading-v2-4--bottom::after, .u-heading-v2-4--top::before, .u-heading-v2-5--bottom::after, .u-heading-v2-5--top::before, .u-heading-v2-6--bottom::after, .u-heading-v2-6--top::before, .u-heading-v2-7--bottom::after, .u-heading-v2-7--top::before {
  content: "";
  display: inline-block;
  border-top-style: solid;
  border-color: inherit;
}

/*------------------------------------
  Heading-v2-1
------------------------------------*/
.u-heading-v2-1--bottom::after, .u-heading-v2-1--top::before {
  width: 3.14286rem;
  border-top-width: 2px;
}

.u-heading-v2-1--bottom::after {
  margin-top: 1.78571rem;
}

.u-heading-v2-1--top::before {
  margin-bottom: 1.78571rem;
}

/*------------------------------------
  Heading-v2-2
------------------------------------*/
.u-heading-v2-2--bottom::after, .u-heading-v2-2--top::before {
  width: 2.71429rem;
  border-top-width: 5px;
}

.u-heading-v2-2--bottom::after {
  margin-top: 1.78571rem;
}

.u-heading-v2-2--top::before {
  margin-bottom: 1.78571rem;
}

/*------------------------------------
  Heading-v2-3
------------------------------------*/
.u-heading-v2-3--bottom::after, .u-heading-v2-3--top::before {
  width: 5rem;
  border-top-width: 1px;
}

.u-heading-v2-3--bottom::after {
  margin-top: 1.07143rem;
}

.u-heading-v2-3--top::before {
  margin-bottom: 1.07143rem;
}

/*------------------------------------
  Heading-v2-4
------------------------------------*/
.u-heading-v2-4--bottom::after, .u-heading-v2-4--top::before {
  width: 5rem;
  border-top-width: 10px;
}

.u-heading-v2-4--bottom::after {
  margin-top: 2.14286rem;
}

.u-heading-v2-4--top::before {
  margin-bottom: 2.14286rem;
}

/*------------------------------------
  Heading-v2-5
------------------------------------*/
.u-heading-v2-5--bottom::after, .u-heading-v2-5--top::before {
  width: 5rem;
  border-top-width: 2px;
}

.u-heading-v2-5--bottom::after {
  margin-top: 1.42857rem;
}

.u-heading-v2-5--top::before {
  margin-bottom: 1.42857rem;
}

/*------------------------------------
  Heading-v2-6
------------------------------------*/
.u-heading-v2-6--bottom::after, .u-heading-v2-6--top::before {
  width: 2.14286rem;
  border-top-width: 1px;
}

.u-heading-v2-6--bottom::after {
  margin-top: 0.71429rem;
}

.u-heading-v2-6--top::before {
  margin-bottom: 0.71429rem;
}

/*------------------------------------
  Heading-v2-7
------------------------------------*/
.u-heading-v2-7--bottom::after, .u-heading-v2-7--top::before {
  width: 12rem;
  border-top-width: 10px;
}

.u-heading-v2-7--bottom::after {
  margin-top: 2.85714rem;
}

.u-heading-v2-7--top::before {
  margin-bottom: 2.85714rem;
}

/*------------------------------------
  Heading-v3
------------------------------------*/
[class*="u-heading-v3-"] {
  border-bottom: 1px dotted #ccc;
}

.u-heading-v3__title {
  position: relative;
  top: 1px;
  display: inline-block;
  margin: 0;
  padding-bottom: 0.71429rem;
  border-bottom: 1px solid #555;
}

/*------------------------------------
  Heading-v4
------------------------------------*/
[class*="u-heading-v4-"] {
  padding: 0.14286rem 0 0.21429rem 1.42857rem;
  border-left-width: 4px;
  border-left-style: solid;
  border-color: inherit;
}

[class*="u-heading-v4-"].text-right {
  padding: 0.14286rem 1.42857rem 0.21429rem 0;
  border-left-width: 0;
  border-right-width: 4px;
  border-right-style: solid;
}

/*------------------------------------
  Heading-v5
------------------------------------*/
.u-heading-v5__title {
  position: relative;
  display: inline-block;
  line-height: 1;
}

.u-heading-v5__title::before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0.85714rem;
  height: 0.85714rem;
  background: #555;
}

.u-heading-v5-color-primary .u-heading-v5__title::before {
  background: #2cb8f3;
}

.u-heading-v5-color-gray-light-v2 .u-heading-v5__title::before {
  background: #ccc;
}

.u-heading-v5-color-white .u-heading-v5__title::before {
  background: #fff;
}

.u-heading-v5-rounded-50x .u-heading-v5__title::before {
  border-radius: 50%;
}

/*------------------------------------
  Heading-v5-1
------------------------------------*/
.u-heading-v5-1 .u-heading-v5__title {
  padding-top: 1.78571rem;
}

.u-heading-v5-1 .u-heading-v5__title::before {
  top: 0;
  left: 2px;
}

.u-heading-v5-1.text-center .u-heading-v5__title::before {
  left: 50%;
  margin-left: -6px;
}

.u-heading-v5-1.text-right .u-heading-v5__title::before {
  left: auto;
  right: 2px;
}

/*------------------------------------
  Heading-v5-2
------------------------------------*/
.u-heading-v5-2 .u-heading-v5__title::before {
  left: 0;
  bottom: 3px;
}

.u-heading-v5-2.text-right .u-heading-v5__title::before {
  left: auto;
  right: 0;
}

.u-heading-v5-2 .u-heading-v5__title {
  padding-left: 1.78571rem;
}

.u-heading-v5-2.text-right .u-heading-v5__title {
  padding-right: 1.78571rem;
}

/*------------------------------------
  Heading-v5-3
------------------------------------*/
.u-heading-v5-3 .u-heading-v5__title::before {
  left: 2px;
  bottom: 0;
}

.u-heading-v5-3.text-center .u-heading-v5__title::before {
  left: 50%;
  margin-left: -6px;
}

.u-heading-v5-3.text-right .u-heading-v5__title::before {
  left: auto;
  right: 2px;
}

.u-heading-v5-3 .u-heading-v5__title {
  padding-bottom: 1.78571rem;
}

/*------------------------------------
  Heading-v6
------------------------------------*/
.u-heading-v6__title {
  display: inline-block;
  position: relative;
}

.u-heading-v6-1 .u-heading-v6__title::after, .u-heading-v6-1.text-center .u-heading-v6__title::before, .u-heading-v6-1.text-right .u-heading-v6__title::before, .u-heading-v6-2 .u-heading-v6__title::after, .u-heading-v6-2.text-center .u-heading-v6__title::before, .u-heading-v6-2.text-right .u-heading-v6__title::before {
  content: "";
  position: absolute;
  top: 50%;
  display: inline-block;
  width: 5rem;
  border-top-width: 1px;
  border-top-style: solid;
  border-color: inherit;
}

/*------------------------------------
  Heading-v6-1
------------------------------------*/
.u-heading-v6-1 .u-heading-v6__title,
.u-heading-v6-1 .u-heading-v6__subtitle {
  padding-right: 6.42857rem;
}

.u-heading-v6-1.text-center .u-heading-v6__title,
.u-heading-v6-1.text-right .u-heading-v6__title,
.u-heading-v6-1.text-center .u-heading-v6__subtitle,
.u-heading-v6-1.text-right .u-heading-v6__subtitle {
  padding-left: 6.42857rem;
}

.u-heading-v6-1.text-right .u-heading-v6__title,
.u-heading-v6-1.text-right .u-heading-v6__subtitle {
  padding-right: 0;
}

.u-heading-v6-1 .u-heading-v6__title::after {
  right: 0;
}

.u-heading-v6-1.text-center .u-heading-v6__title::before {
  left: 0;
}

.u-heading-v6-1.text-right .u-heading-v6__title::before {
  left: 0;
}

.u-heading-v6-1.text-right .u-heading-v6__title::after {
  display: none;
}

/*------------------------------------
  Heading-v6-1
------------------------------------*/
.u-heading-v6-2 .u-heading-v6__title,
.u-heading-v6-2 .u-heading-v6__subtitle {
  padding-left: 6.42857rem;
}

.u-heading-v6-2.text-center .u-heading-v6__title,
.u-heading-v6-2.text-right .u-heading-v6__title,
.u-heading-v6-2.text-center .u-heading-v6__subtitle,
.u-heading-v6-2.text-right .u-heading-v6__subtitle {
  padding-right: 6.42857rem;
}

.u-heading-v6-2.text-right .u-heading-v6__title,
.u-heading-v6-2.text-right .u-heading-v6__subtitle {
  padding-left: 0;
}

.u-heading-v6-2 .u-heading-v6__title::after {
  left: 0;
}

.u-heading-v6-2.text-center .u-heading-v6__title::before {
  right: 0;
}

.u-heading-v6-2.text-right .u-heading-v6__title::before {
  right: 0;
}

.u-heading-v6-2.text-right .u-heading-v6__title::after {
  display: none;
}

/*------------------------------------
  Heading-v7
------------------------------------*/
.u-heading-v7-divider::after, .text-center .u-heading-v7-divider::before, .text-right .u-heading-v7-divider::before {
  content: "";
  position: absolute;
  top: 52%;
  display: inline-block;
  width: 2.85714rem;
  border-top-width: 1px;
  border-top-style: solid;
  border-color: inherit;
}

.u-heading-v7-divider {
  position: relative;
  display: inline-block;
}

.u-heading-v7-divider::after {
  right: -3.92857rem;
}

.text-center .u-heading-v7-divider::before {
  left: -3.92857rem;
}

.text-right .u-heading-v7-divider::before {
  left: -3.92857rem;
}

.text-right .u-heading-v7-divider::after {
  display: none;
}

.u-heading-v7-divider__icon {
  display: inline-block;
  font-size: 1.4rem;
}

/*------------------------------------
  Heading-v7-1
------------------------------------*/
.u-heading-v7-1 .u-heading-v7-divider__icon {
  font-size: .6rem;
}

/*------------------------------------
  Heading-v7-3
------------------------------------*/
.u-heading-v7-3 .u-heading-v7-divider::after,
.u-heading-v7-3 .u-heading-v7-divider::before {
  display: none;
}

/*------------------------------------
  Heading-v8
------------------------------------*/
.u-heading-v8-1 .u-heading-v8__title strong, .u-heading-v8-2 .u-heading-v8__title strong {
  display: inline-block;
  font-weight: inherit;
}

/*------------------------------------
  Heading-v8-1
------------------------------------*/
.u-heading-v8-1 .u-heading-v8__title strong {
  padding: 0.07143rem 0.5rem;
}

/*------------------------------------
  Heading-v8-2
------------------------------------*/
.u-heading-v8-2 .u-heading-v8__title strong {
  padding: 0.71429rem 1.07143rem;
}

/*------------------------------------
  Heading-v9
------------------------------------*/
.u-heading-v9 {
  position: relative;
  text-align: center;
}

.u-heading-v9::before {
  content: "";
  position: absolute;
  left: 50%;
  display: block;
  margin-left: -35px;
  width: 70px;
  height: 96px;
  border: 2px solid #2cb8f3;
}

.u-heading-v9--left {
  padding-left: 35px;
  text-align: left;
}

.u-heading-v9--left::before {
  left: 0;
  margin-left: 0;
}

.u-heading-v9--right {
  padding-right: 35px;
  text-align: right;
}

.u-heading-v9--right::before {
  left: auto;
  right: 0;
  margin-left: 0;
}

.u-heading-v9 * {
  position: relative;
  z-index: 3;
}

/*------------------------------------
  Heading-v10
------------------------------------*/
.u-heading-v10 {
  position: relative;
  text-align: center;
}

.u-heading-v10__downer {
  font-size: 115px;
  line-height: 1;
  opacity: .04;
}

.u-heading-v10__upper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 34px;
  color: #2cb8f3;
}

/*------------------------------------
  Headings
------------------------------------*/
/* Massive Underline */
.u-heading-massive-underline {
  position: relative;
}

.u-heading-massive-underline::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: .2em;
  height: .3em;
  background-color: rgba(44, 184, 243, 0.8);
  z-index: -1;
}

/* Heading With Dot */
.u-header-title-dot::after {
  content: ".";
  color: #2cb8f3;
  display: inline-block;
  font-size: 35px;
}

/*------------------------------------
  Nonstandart BG's
------------------------------------*/
.u-triangle-v1, .u-triangle-v1-2, .u-triangle-v2, .u-triangle-v3, .u-triangle-v4, .u-triangle-v5, .u-triangle-v6 {
  position: relative;
}

.u-triangle-v1::before, .u-triangle-v1-2::before, .u-triangle-v2::before, .u-triangle-v3::before, .u-triangle-v4::before, .u-triangle-v5::before, .u-triangle-v6::before {
  content: "";
  position: absolute;
  display: block;
  z-index: 10;
}

.u-triangle-v1::before, .u-triangle-v1-2::before, .u-triangle-v2::before, .u-triangle-v5::before, .u-triangle-v6::before {
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
}

.u-triangle-v1.u-triangle-top.g-bg-primary::before, .u-triangle-v1.u-triangle-left.g-bg-primary::before, .u-triangle-v1-2.u-triangle-top.g-bg-primary::before, .u-triangle-v1-2.u-triangle-left.g-bg-primary::before, .u-triangle-v2.u-triangle-top.g-bg-primary::before, .u-triangle-v2.u-triangle-left.g-bg-primary::before, .u-triangle-v5.u-triangle-top.g-bg-primary::before, .u-triangle-v5.u-triangle-left.g-bg-primary::before, .u-triangle-v6.u-triangle-top.g-bg-primary::before, .u-triangle-v6.u-triangle-left.g-bg-primary::before {
  border-bottom-color: #2cb8f3;
}

.u-triangle-v1.u-triangle-top.g-bg-white::before, .u-triangle-v1.u-triangle-left.g-bg-white::before, .u-triangle-v1-2.u-triangle-top.g-bg-white::before, .u-triangle-v1-2.u-triangle-left.g-bg-white::before, .u-triangle-v2.u-triangle-top.g-bg-white::before, .u-triangle-v2.u-triangle-left.g-bg-white::before, .u-triangle-v5.u-triangle-top.g-bg-white::before, .u-triangle-v5.u-triangle-left.g-bg-white::before, .u-triangle-v6.u-triangle-top.g-bg-white::before, .u-triangle-v6.u-triangle-left.g-bg-white::before {
  border-bottom-color: #fff;
}

.u-triangle-v1.u-triangle-top.g-bg-gray-light-v9::before, .u-triangle-v1.u-triangle-left.g-bg-gray-light-v9::before, .u-triangle-v1-2.u-triangle-top.g-bg-gray-light-v9::before, .u-triangle-v1-2.u-triangle-left.g-bg-gray-light-v9::before, .u-triangle-v2.u-triangle-top.g-bg-gray-light-v9::before, .u-triangle-v2.u-triangle-left.g-bg-gray-light-v9::before, .u-triangle-v5.u-triangle-top.g-bg-gray-light-v9::before, .u-triangle-v5.u-triangle-left.g-bg-gray-light-v9::before, .u-triangle-v6.u-triangle-top.g-bg-gray-light-v9::before, .u-triangle-v6.u-triangle-left.g-bg-gray-light-v9::before {
  border-bottom-color: #f7f7f7;
}

.u-triangle-v1.u-triangle-top.g-bg-black::before, .u-triangle-v1.u-triangle-left.g-bg-black::before, .u-triangle-v1-2.u-triangle-top.g-bg-black::before, .u-triangle-v1-2.u-triangle-left.g-bg-black::before, .u-triangle-v2.u-triangle-top.g-bg-black::before, .u-triangle-v2.u-triangle-left.g-bg-black::before, .u-triangle-v5.u-triangle-top.g-bg-black::before, .u-triangle-v5.u-triangle-left.g-bg-black::before, .u-triangle-v6.u-triangle-top.g-bg-black::before, .u-triangle-v6.u-triangle-left.g-bg-black::before {
  border-bottom-color: #000;
}

.u-triangle-v1.u-triangle-bottom.g-bg-primary::before, .u-triangle-v1.u-triangle-right.g-bg-primary::before, .u-triangle-v1-2.u-triangle-bottom.g-bg-primary::before, .u-triangle-v1-2.u-triangle-right.g-bg-primary::before, .u-triangle-v2.u-triangle-bottom.g-bg-primary::before, .u-triangle-v2.u-triangle-right.g-bg-primary::before, .u-triangle-v5.u-triangle-bottom.g-bg-primary::before, .u-triangle-v5.u-triangle-right.g-bg-primary::before, .u-triangle-v6.u-triangle-bottom.g-bg-primary::before, .u-triangle-v6.u-triangle-right.g-bg-primary::before {
  border-top-color: #2cb8f3;
}

.u-triangle-v1.u-triangle-bottom.g-bg-white::before, .u-triangle-v1.u-triangle-right.g-bg-white::before, .u-triangle-v1-2.u-triangle-bottom.g-bg-white::before, .u-triangle-v1-2.u-triangle-right.g-bg-white::before, .u-triangle-v2.u-triangle-bottom.g-bg-white::before, .u-triangle-v2.u-triangle-right.g-bg-white::before, .u-triangle-v5.u-triangle-bottom.g-bg-white::before, .u-triangle-v5.u-triangle-right.g-bg-white::before, .u-triangle-v6.u-triangle-bottom.g-bg-white::before, .u-triangle-v6.u-triangle-right.g-bg-white::before {
  border-top-color: #fff;
}

.u-triangle-v1.u-triangle-bottom.g-bg-gray-light-v9::before, .u-triangle-v1.u-triangle-right.g-bg-gray-light-v9::before, .u-triangle-v1-2.u-triangle-bottom.g-bg-gray-light-v9::before, .u-triangle-v1-2.u-triangle-right.g-bg-gray-light-v9::before, .u-triangle-v2.u-triangle-bottom.g-bg-gray-light-v9::before, .u-triangle-v2.u-triangle-right.g-bg-gray-light-v9::before, .u-triangle-v5.u-triangle-bottom.g-bg-gray-light-v9::before, .u-triangle-v5.u-triangle-right.g-bg-gray-light-v9::before, .u-triangle-v6.u-triangle-bottom.g-bg-gray-light-v9::before, .u-triangle-v6.u-triangle-right.g-bg-gray-light-v9::before {
  border-top-color: #f7f7f7;
}

.u-triangle-v1.u-triangle-bottom.g-bg-black::before, .u-triangle-v1.u-triangle-right.g-bg-black::before, .u-triangle-v1-2.u-triangle-bottom.g-bg-black::before, .u-triangle-v1-2.u-triangle-right.g-bg-black::before, .u-triangle-v2.u-triangle-bottom.g-bg-black::before, .u-triangle-v2.u-triangle-right.g-bg-black::before, .u-triangle-v5.u-triangle-bottom.g-bg-black::before, .u-triangle-v5.u-triangle-right.g-bg-black::before, .u-triangle-v6.u-triangle-bottom.g-bg-black::before, .u-triangle-v6.u-triangle-right.g-bg-black::before {
  border-top-color: #000;
}

.u-triangle-v1.u-triangle-top::before, .u-triangle-v1.u-triangle-bottom::before, .u-triangle-v1.u-triangle-left::before, .u-triangle-v1.u-triangle-right::before {
  left: 50%;
  margin-left: -20px;
}

.u-triangle-v1.u-triangle-top::before, .u-triangle-v1.u-triangle-left::before {
  top: -25px;
  border-width: 0 20px 25px 20px;
}

.u-triangle-v1.u-triangle-bottom::before, .u-triangle-v1.u-triangle-right::before {
  bottom: -25px;
  border-width: 25px 20px 0 20px;
}

.u-triangle-v1-2.u-triangle-left.g-bg-primary::before, .u-triangle-v1-2.u-triangle-left.g-bg-white::before, .u-triangle-v1-2.u-triangle-left.g-bg-gray-light-v9::before, .u-triangle-v1-2.u-triangle-left.g-bg-black::before, .u-triangle-v1-2.u-triangle-right.g-bg-primary::before, .u-triangle-v1-2.u-triangle-right.g-bg-white::before, .u-triangle-v1-2.u-triangle-right.g-bg-gray-light-v9::before, .u-triangle-v1-2.u-triangle-right.g-bg-black::before {
  border-bottom-color: transparent;
  border-top-color: transparent;
}

.u-triangle-v1-2.u-triangle-left::before, .u-triangle-v1-2.u-triangle-right::before {
  top: 50%;
  bottom: auto;
  left: auto;
  margin-top: -5px;
  margin-left: 0;
}

.u-triangle-v1-2.u-triangle-left::before {
  left: -5px;
  border-width: 5px 5px 5px 0;
}

.u-triangle-v1-2.u-triangle-left.g-bg-primary::before {
  border-right-color: #2cb8f3;
}

.u-triangle-v1-2.u-triangle-left.g-bg-white::before {
  border-right-color: #fff;
}

.u-triangle-v1-2.u-triangle-left.g-bg-gray-light-v9::before {
  border-right-color: #f7f7f7;
}

.u-triangle-v1-2.u-triangle-left.g-bg-black::before {
  border-right-color: #000;
}

.u-triangle-v1-2.u-triangle-right::before {
  right: -5px;
  border-width: 5px 0 5px 5px;
}

.u-triangle-v1-2.u-triangle-right.g-bg-primary::before {
  border-left-color: #2cb8f3;
}

.u-triangle-v1-2.u-triangle-right.g-bg-white::before {
  border-left-color: #fff;
}

.u-triangle-v1-2.u-triangle-right.g-bg-gray-light-v9::before {
  border-left-color: #f7f7f7;
}

.u-triangle-v1-2.u-triangle-right.g-bg-black::before {
  border-left-color: #000;
}

.u-triangle-v2.u-triangle-top::before, .u-triangle-v2.u-triangle-bottom::before {
  left: 45px;
}

.u-triangle-v2.u-triangle-left::before, .u-triangle-v2.u-triangle-right::before {
  top: 23px;
}

.u-triangle-v2.u-triangle-top::before {
  top: -15px;
  border-width: 0 17px 15px 17px;
}

.u-triangle-v2.u-triangle-bottom::before {
  bottom: -15px;
  border-width: 15px 17px 0 17px;
}

.u-triangle-v2.u-triangle-left::before {
  left: -15px;
  border-width: 17px 15px 17px 0;
}

.u-triangle-v2.u-triangle-right::before {
  right: -15px;
  border-width: 17px 0 17px 15px;
}

.u-triangle-v3::before, .u-triangle-v4::before {
  background-repeat: no-repeat;
}

.u-triangle-v3.u-triangle-top::before, .u-triangle-v3.u-triangle-bottom::before, .u-triangle-v3.u-triangle-left::before, .u-triangle-v3.u-triangle-right::before, .u-triangle-v4.u-triangle-top::before, .u-triangle-v4.u-triangle-bottom::before, .u-triangle-v4.u-triangle-left::before, .u-triangle-v4.u-triangle-right::before {
  height: 50px;
  left: 0;
}

.u-triangle-v3.u-triangle-top::before, .u-triangle-v3.u-triangle-left::before, .u-triangle-v4.u-triangle-top::before, .u-triangle-v4.u-triangle-left::before {
  top: -50px;
}

.u-triangle-v3.u-triangle-bottom::before, .u-triangle-v3.u-triangle-right::before, .u-triangle-v4.u-triangle-bottom::before, .u-triangle-v4.u-triangle-right::before {
  bottom: -50px;
}

.u-triangle-v3.u-triangle-top::before, .u-triangle-v3.u-triangle-left::before {
  width: 100%;
  background-size: 100%;
  background-position: top right;
}

.u-triangle-v3.u-triangle-top.g-bg-primary::before, .u-triangle-v3.u-triangle-left.g-bg-primary::before {
  background-image: linear-gradient(to bottom right, transparent 49.6%, #2cb8f3 50%);
}

.u-triangle-v3.u-triangle-top.g-bg-white::before, .u-triangle-v3.u-triangle-left.g-bg-white::before {
  background-image: linear-gradient(to bottom right, transparent 49.6%, #fff 50%);
}

.u-triangle-v3.u-triangle-top.g-bg-gray-light-v9::before, .u-triangle-v3.u-triangle-left.g-bg-gray-light-v9::before {
  background-image: linear-gradient(to bottom right, transparent 49.6%, #f7f7f7 50%);
}

.u-triangle-v3.u-triangle-top.g-bg-black::before, .u-triangle-v3.u-triangle-left.g-bg-black::before {
  background-image: linear-gradient(to bottom right, transparent 49.6%, #000 50%);
}

.u-triangle-v3.u-triangle-bottom::before, .u-triangle-v3.u-triangle-right::before {
  width: 100%;
  background-size: 100%;
  background-position: top right;
}

.u-triangle-v3.u-triangle-bottom.g-bg-primary::before, .u-triangle-v3.u-triangle-right.g-bg-primary::before {
  background-image: linear-gradient(to top right, transparent 49.6%, #2cb8f3 50%);
}

.u-triangle-v3.u-triangle-bottom.g-bg-white::before, .u-triangle-v3.u-triangle-right.g-bg-white::before {
  background-image: linear-gradient(to top right, transparent 49.6%, #fff 50%);
}

.u-triangle-v3.u-triangle-bottom.g-bg-gray-light-v9::before, .u-triangle-v3.u-triangle-right.g-bg-gray-light-v9::before {
  background-image: linear-gradient(to top right, transparent 49.6%, #f7f7f7 50%);
}

.u-triangle-v3.u-triangle-bottom.g-bg-black::before, .u-triangle-v3.u-triangle-right.g-bg-black::before {
  background-image: linear-gradient(to top right, transparent 49.6%, #000 50%);
}

.u-triangle-v4.u-triangle-top::before, .u-triangle-v4.u-triangle-left::before {
  width: 100%;
  background-size: 50.2% 100%;
  background-position: top left, top right;
}

.u-triangle-v4.u-triangle-top.g-bg-primary::before, .u-triangle-v4.u-triangle-left.g-bg-primary::before {
  background-image: linear-gradient(to bottom right, transparent 49.6%, #2cb8f3 50%), linear-gradient(to bottom left, transparent 49.6%, #2cb8f3 50%);
}

.u-triangle-v4.u-triangle-top.g-bg-white::before, .u-triangle-v4.u-triangle-left.g-bg-white::before {
  background-image: linear-gradient(to bottom right, transparent 49.6%, #fff 50%), linear-gradient(to bottom left, transparent 49.6%, #fff 50%);
}

.u-triangle-v4.u-triangle-top.g-bg-gray-light-v9::before, .u-triangle-v4.u-triangle-left.g-bg-gray-light-v9::before {
  background-image: linear-gradient(to bottom right, transparent 49.6%, #f7f7f7 50%), linear-gradient(to bottom left, transparent 49.6%, #f7f7f7 50%);
}

.u-triangle-v4.u-triangle-top.g-bg-black::before, .u-triangle-v4.u-triangle-left.g-bg-black::before {
  background-image: linear-gradient(to bottom right, transparent 49.6%, #000 50%), linear-gradient(to bottom left, transparent 49.6%, #000 50%);
}

.u-triangle-v4.u-triangle-bottom::before, .u-triangle-v4.u-triangle-right::before {
  width: 100%;
  background-size: 50.2% 100%;
  background-position: top left, top right;
}

.u-triangle-v4.u-triangle-bottom.g-bg-primary::before, .u-triangle-v4.u-triangle-right.g-bg-primary::before {
  background-image: linear-gradient(to top right, transparent 49.6%, #2cb8f3 50%), linear-gradient(to top left, transparent 49.6%, #2cb8f3 50%);
}

.u-triangle-v4.u-triangle-bottom.g-bg-white::before, .u-triangle-v4.u-triangle-right.g-bg-white::before {
  background-image: linear-gradient(to top right, transparent 49.6%, #fff 50%), linear-gradient(to top left, transparent 49.6%, #fff 50%);
}

.u-triangle-v4.u-triangle-bottom.g-bg-gray-light-v9::before, .u-triangle-v4.u-triangle-right.g-bg-gray-light-v9::before {
  background-image: linear-gradient(to top right, transparent 49.6%, #f7f7f7 50%), linear-gradient(to top left, transparent 49.6%, #f7f7f7 50%);
}

.u-triangle-v4.u-triangle-bottom.g-bg-black::before, .u-triangle-v4.u-triangle-right.g-bg-black::before {
  background-image: linear-gradient(to top right, transparent 49.6%, #000 50%), linear-gradient(to top left, transparent 49.6%, #000 50%);
}

.u-triangle-v5.u-triangle-top::before, .u-triangle-v5.u-triangle-bottom::before {
  left: 80px;
}

.u-triangle-v5.u-triangle-left::before, .u-triangle-v5.u-triangle-right::before {
  top: 23px;
}

.u-triangle-v5.u-triangle-top::before {
  top: -22px;
  border-width: 22px 0 0 22px;
}

.u-triangle-v5.u-triangle-bottom::before {
  bottom: -22px;
  border-width: 22px 22px 0 0;
}

.u-triangle-v5.u-triangle-left::before {
  left: -22px;
  border-width: 0 22px 22px 0;
}

.u-triangle-v5.u-triangle-right::before {
  right: -22px;
  border-width: 22px 22px 0 0;
}

.u-triangle-v6.u-triangle-top::before, .u-triangle-v6.u-triangle-bottom::before {
  left: 8%;
}

.u-triangle-v6.u-triangle-left::before, .u-triangle-v6.u-triangle-right::before {
  top: 8%;
}

.u-triangle-v6.u-triangle-top::before {
  top: -22px;
  border-width: 22px 22px 0 0;
}

.u-triangle-v6.u-triangle-bottom::before {
  bottom: -22px;
  border-width: 22px 0 0 22px;
}

.u-triangle-v6.u-triangle-left::before {
  left: -22px;
  border-width: 22px 22px 0 0;
}

.u-triangle-v6.u-triangle-right::before {
  right: -22px;
  border-width: 0 22px 22px 0;
}

[class*="u-triangle-inclusive-v1"]:not([class*="__front"]):not([class*="__back"]),
[class*="u-triangle-inclusive-v2"]:not([class*="__front"]):not([class*="__back"]) {
  position: absolute;
}

.u-triangle-inclusive-v1--left {
  right: -14px;
}

.u-triangle-inclusive-v1--left__front {
  position: absolute;
  top: 1px;
  left: 0;
}

.u-triangle-inclusive-v1--left__front {
  border-width: 14px 0 14px 14px;
  border-style: solid;
  border-color: transparent;
}

.u-triangle-inclusive-v1--left__back {
  border-width: 15px 0 15px 15px;
  border-style: solid;
  border-color: transparent;
}

.u-triangle-inclusive-v1--right {
  left: -14px;
}

.u-triangle-inclusive-v1--right__front {
  position: absolute;
  top: 1px;
  right: 0;
}

.u-triangle-inclusive-v1--right__front {
  border-width: 14px 14px 14px 0;
  border-style: solid;
  border-color: transparent;
}

.u-triangle-inclusive-v1--right__back {
  border-width: 15px 15px 15px 0;
  border-style: solid;
  border-color: transparent;
}

.u-triangle-inclusive-v1--top {
  top: -14px;
}

.u-triangle-inclusive-v1--top__front {
  position: absolute;
  left: 1px;
  bottom: 0;
}

.u-triangle-inclusive-v1--top__front {
  border-width: 0 14px 14px 14px;
  border-style: solid;
  border-color: transparent;
}

.u-triangle-inclusive-v1--top__back {
  border-width: 0 15px 15px 15px;
  border-style: solid;
  border-color: transparent;
}

.u-triangle-inclusive-v1--bottom {
  bottom: -14px;
}

.u-triangle-inclusive-v1--bottom__front {
  position: absolute;
  left: 1px;
  bottom: 1px;
}

.u-triangle-inclusive-v1--bottom__front {
  border-width: 14px 14px 0 14px;
  border-style: solid;
  border-color: transparent;
}

.u-triangle-inclusive-v1--bottom__back {
  border-width: 15px 15px 0 15px;
  border-style: solid;
  border-color: transparent;
}

.u-triangle-inclusive-v2--left {
  left: 100%;
}

.u-triangle-inclusive-v2--left__front, .u-triangle-inclusive-v2--left__back {
  position: absolute;
  left: 0;
  height: 100%;
}

.u-triangle-inclusive-v2--left__front svg, .u-triangle-inclusive-v2--left__back svg {
  height: 100%;
  vertical-align: middle;
}

.u-triangle-inclusive-v2--left__front {
  z-index: 2;
}

.u-triangle-inclusive-v2--left__front svg polygon {
  fill: #eee;
}

.u-triangle-inclusive-v2--left__back {
  z-index: 1;
}

.u-triangle-inclusive-v2--left__back svg polygon {
  fill: #fff;
}

.u-triangle-inclusive-v2--right {
  right: 100%;
}

.u-triangle-inclusive-v2--right__front, .u-triangle-inclusive-v2--right__back {
  position: absolute;
  right: 0;
  height: 100%;
}

.u-triangle-inclusive-v2--right__front svg, .u-triangle-inclusive-v2--right__back svg {
  height: 100%;
  vertical-align: middle;
}

.u-triangle-inclusive-v2--right__front {
  z-index: 2;
}

.u-triangle-inclusive-v2--right__front svg polygon {
  fill: #eee;
}

.u-triangle-inclusive-v2--right__back {
  z-index: 1;
}

.u-triangle-inclusive-v2--right__back svg polygon {
  fill: #fff;
}

.u-triangle-inclusive-v2--top {
  bottom: 100%;
}

.u-triangle-inclusive-v2--top__front, .u-triangle-inclusive-v2--top__back {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.u-triangle-inclusive-v2--top__front svg, .u-triangle-inclusive-v2--top__back svg {
  width: 100%;
  vertical-align: bottom;
}

.u-triangle-inclusive-v2--top__front {
  z-index: 2;
}

.u-triangle-inclusive-v2--top__front svg polygon {
  fill: #eee;
}

.u-triangle-inclusive-v2--top__back {
  z-index: 1;
}

.u-triangle-inclusive-v2--top__back svg polygon {
  fill: #fff;
}

.u-triangle-inclusive-v2--bottom {
  top: 100%;
}

.u-triangle-inclusive-v2--bottom__front, .u-triangle-inclusive-v2--bottom__back {
  position: absolute;
  top: 0;
  width: 100%;
}

.u-triangle-inclusive-v2--bottom__front svg, .u-triangle-inclusive-v2--bottom__back svg {
  width: 100%;
  vertical-align: top;
}

.u-triangle-inclusive-v2--bottom__front {
  z-index: 2;
}

.u-triangle-inclusive-v2--bottom__front svg polygon {
  fill: #eee;
}

.u-triangle-inclusive-v2--bottom__back {
  z-index: 1;
}

.u-triangle-inclusive-v2--bottom__back svg polygon {
  fill: #fff;
}

.u-semicircle-v1 {
  position: relative;
  z-index: 10;
}

.u-semicircle-v1::before {
  content: "";
  position: absolute;
  z-index: -1;
  display: block;
  width: 150%;
  height: 150%;
  border-radius: 50%;
}

.u-semicircle-v1.g-bg-primary::before {
  background: #2cb8f3;
}

.u-semicircle-v1.g-bg-white::before {
  background: #fff;
}

.u-semicircle-v1.g-bg-gray-light-v9::before {
  background: #f7f7f7;
}

.u-semicircle-v1.g-bg-black::before {
  background: #000;
}

.u-semicircle-top::before, .u-semicircle-right::before {
  top: -20%;
  left: -25%;
}

.u-semicircle-bottom::before, .u-semicircle-left::before {
  bottom: -20%;
  left: -25%;
}

@media (max-width: 576px - 1) {
  .u-triangle-none--xs::before,
  .u-semicircle-none--xs::before {
    display: none;
  }
}

@media (min-width: 576px) {
  .u-triangle-v3.u-triangle-top::before, .u-triangle-v3.u-triangle-bottom::before, .u-triangle-v4.u-triangle-top::before, .u-triangle-v4.u-triangle-bottom::before {
    height: 75px;
  }
  .u-triangle-v3.u-triangle-left::before, .u-triangle-v3.u-triangle-right::before, .u-triangle-v4.u-triangle-left::before, .u-triangle-v4.u-triangle-right::before {
    width: 75px;
  }
  .u-triangle-v3.u-triangle-top::before, .u-triangle-v4.u-triangle-top::before {
    top: -75px;
  }
  .u-triangle-v3.u-triangle-bottom::before, .u-triangle-v4.u-triangle-bottom::before {
    bottom: -75px;
  }
  .u-triangle-v3.u-triangle-left::before, .u-triangle-v4.u-triangle-left::before {
    left: -75px;
  }
  .u-triangle-v3.u-triangle-right::before, .u-triangle-v4.u-triangle-right::before {
    right: -75px;
  }
}

@media (max-width: 768px - 1) {
  .u-triangle-none--sm::before,
  .u-semicircle-none--sm::before {
    display: none;
  }
}

@media (min-width: 768px) {
  .u-triangle-v1.u-triangle-left.g-bg-primary::before, .u-triangle-v1.u-triangle-left.g-bg-white::before, .u-triangle-v1.u-triangle-left.g-bg-gray-light-v9::before, .u-triangle-v1.u-triangle-left.g-bg-black::before, .u-triangle-v1.u-triangle-right.g-bg-primary::before, .u-triangle-v1.u-triangle-right.g-bg-white::before, .u-triangle-v1.u-triangle-right.g-bg-gray-light-v9::before, .u-triangle-v1.u-triangle-right.g-bg-black::before {
    border-bottom-color: transparent;
    border-top-color: transparent;
  }
  .u-triangle-v1.u-triangle-left::before, .u-triangle-v1.u-triangle-right::before {
    top: 50%;
    bottom: auto;
    left: auto;
    margin-top: -20px;
    margin-left: 0;
  }
  .u-triangle-v1.u-triangle-left::before {
    left: -25px;
    border-width: 20px 25px 20px 0;
  }
  .u-triangle-v1.u-triangle-left.g-bg-primary::before {
    border-right-color: #2cb8f3;
  }
  .u-triangle-v1.u-triangle-left.g-bg-white::before {
    border-right-color: #fff;
  }
  .u-triangle-v1.u-triangle-left.g-bg-gray-light-v9::before {
    border-right-color: #f7f7f7;
  }
  .u-triangle-v1.u-triangle-left.g-bg-black::before {
    border-right-color: #000;
  }
  .u-triangle-v1.u-triangle-right::before {
    right: -25px;
    border-width: 20px 0 20px 25px;
  }
  .u-triangle-v1.u-triangle-right.g-bg-primary::before {
    border-left-color: #2cb8f3;
  }
  .u-triangle-v1.u-triangle-right.g-bg-white::before {
    border-left-color: #fff;
  }
  .u-triangle-v1.u-triangle-right.g-bg-gray-light-v9::before {
    border-left-color: #f7f7f7;
  }
  .u-triangle-v1.u-triangle-right.g-bg-black::before {
    border-left-color: #000;
  }
  .u-triangle-v3.u-triangle-left::before, .u-triangle-v3.u-triangle-right::before, .u-triangle-v4.u-triangle-left::before, .u-triangle-v4.u-triangle-right::before {
    height: 100%;
    left: auto;
  }
  .u-triangle-v3.u-triangle-left::before, .u-triangle-v4.u-triangle-left::before {
    top: 0;
  }
  .u-triangle-v3.u-triangle-right::before, .u-triangle-v4.u-triangle-right::before {
    top: 0;
    bottom: auto;
  }
  .u-triangle-v3.u-triangle-top::before, .u-triangle-v3.u-triangle-bottom::before, .u-triangle-v4.u-triangle-top::before, .u-triangle-v4.u-triangle-bottom::before {
    height: 100px;
  }
  .u-triangle-v3.u-triangle-left::before, .u-triangle-v3.u-triangle-right::before, .u-triangle-v4.u-triangle-left::before, .u-triangle-v4.u-triangle-right::before {
    width: 100px;
  }
  .u-triangle-v3.u-triangle-top::before, .u-triangle-v4.u-triangle-top::before {
    top: -100px;
  }
  .u-triangle-v3.u-triangle-bottom::before, .u-triangle-v4.u-triangle-bottom::before {
    bottom: -100px;
  }
  .u-triangle-v3.u-triangle-left::before, .u-triangle-v4.u-triangle-left::before {
    left: -100px;
  }
  .u-triangle-v3.u-triangle-right::before, .u-triangle-v4.u-triangle-right::before {
    right: -100px;
  }
  .u-triangle-v3.u-triangle-left.g-bg-primary::before {
    background-image: linear-gradient(to bottom right, transparent 49.6%, #2cb8f3 50%);
  }
  .u-triangle-v3.u-triangle-left.g-bg-white::before {
    background-image: linear-gradient(to bottom right, transparent 49.6%, #fff 50%);
  }
  .u-triangle-v3.u-triangle-left.g-bg-gray-light-v9::before {
    background-image: linear-gradient(to bottom right, transparent 49.6%, #f7f7f7 50%);
  }
  .u-triangle-v3.u-triangle-left.g-bg-black::before {
    background-image: linear-gradient(to bottom right, transparent 49.6%, #000 50%);
  }
  .u-triangle-v3.u-triangle-right.g-bg-primary::before {
    background-image: linear-gradient(to bottom left, transparent 49.6%, #2cb8f3 50%);
  }
  .u-triangle-v3.u-triangle-right.g-bg-white::before {
    background-image: linear-gradient(to bottom left, transparent 49.6%, #fff 50%);
  }
  .u-triangle-v3.u-triangle-right.g-bg-gray-light-v9::before {
    background-image: linear-gradient(to bottom left, transparent 49.6%, #f7f7f7 50%);
  }
  .u-triangle-v3.u-triangle-right.g-bg-black::before {
    background-image: linear-gradient(to bottom left, transparent 49.6%, #000 50%);
  }
  .u-triangle-v4.u-triangle-left::before {
    height: 100%;
    background-size: 100% 50.2%;
    background-position: top right, bottom left;
  }
  .u-triangle-v4.u-triangle-left.g-bg-primary::before {
    background-image: linear-gradient(to bottom right, transparent 49.6%, #2cb8f3 50%), linear-gradient(to top right, transparent 49.6%, #2cb8f3 50%);
  }
  .u-triangle-v4.u-triangle-left.g-bg-white::before {
    background-image: linear-gradient(to bottom right, transparent 49.6%, #fff 50%), linear-gradient(to top right, transparent 49.6%, #fff 50%);
  }
  .u-triangle-v4.u-triangle-left.g-bg-gray-light-v9::before {
    background-image: linear-gradient(to bottom right, transparent 49.6%, #f7f7f7 50%), linear-gradient(to top right, transparent 49.6%, #f7f7f7 50%);
  }
  .u-triangle-v4.u-triangle-left.g-bg-black::before {
    background-image: linear-gradient(to bottom right, transparent 49.6%, #000 50%), linear-gradient(to top right, transparent 49.6%, #000 50%);
  }
  .u-triangle-v4.u-triangle-right::before {
    height: 100%;
    background-size: 100% 50.2%;
    background-position: top right, bottom left;
  }
  .u-triangle-v4.u-triangle-right.g-bg-primary::before {
    background-image: linear-gradient(to bottom left, transparent 49.6%, #2cb8f3 50%), linear-gradient(to top left, transparent 49.6%, #2cb8f3 50%);
  }
  .u-triangle-v4.u-triangle-right.g-bg-white::before {
    background-image: linear-gradient(to bottom left, transparent 49.6%, #fff 50%), linear-gradient(to top left, transparent 49.6%, #fff 50%);
  }
  .u-triangle-v4.u-triangle-right.g-bg-gray-light-v9::before {
    background-image: linear-gradient(to bottom left, transparent 49.6%, #f7f7f7 50%), linear-gradient(to top left, transparent 49.6%, #f7f7f7 50%);
  }
  .u-triangle-v4.u-triangle-right.g-bg-black::before {
    background-image: linear-gradient(to bottom left, transparent 49.6%, #000 50%), linear-gradient(to top left, transparent 49.6%, #000 50%);
  }
  .u-semicircle-left::before {
    top: -25%;
    left: -20%;
  }
  .u-semicircle-right::before {
    top: -25%;
    bottom: auto;
    left: auto;
    right: -20%;
  }
}

@media (max-width: 992px - 1) {
  .u-triangle-none--md::before,
  .u-semicircle-none--md::before {
    display: none;
  }
}

@media (min-width: 992px) {
  .u-triangle-v3.u-triangle-top::before, .u-triangle-v3.u-triangle-bottom::before, .u-triangle-v4.u-triangle-top::before, .u-triangle-v4.u-triangle-bottom::before {
    height: 150px;
  }
  .u-triangle-v3.u-triangle-left::before, .u-triangle-v3.u-triangle-right::before, .u-triangle-v4.u-triangle-left::before, .u-triangle-v4.u-triangle-right::before {
    width: 150px;
  }
  .u-triangle-v3.u-triangle-top::before, .u-triangle-v4.u-triangle-top::before {
    top: -150px;
  }
  .u-triangle-v3.u-triangle-bottom::before, .u-triangle-v4.u-triangle-bottom::before {
    bottom: -150px;
  }
  .u-triangle-v3.u-triangle-left::before, .u-triangle-v4.u-triangle-left::before {
    left: -150px;
  }
  .u-triangle-v3.u-triangle-right::before, .u-triangle-v4.u-triangle-right::before {
    right: -150px;
  }
}

@media (max-width: 1200px) {
  .u-triangle-none--lg::before,
  .u-semicircle-none--lg::before {
    display: none;
  }
}

@media (min-width: 1200px) {
  .u-triangle-v3.u-triangle-top::before, .u-triangle-v3.u-triangle-bottom::before, .u-triangle-v4.u-triangle-top::before, .u-triangle-v4.u-triangle-bottom::before {
    height: 200px;
  }
  .u-triangle-v3.u-triangle-left::before, .u-triangle-v3.u-triangle-right::before, .u-triangle-v4.u-triangle-left::before, .u-triangle-v4.u-triangle-right::before {
    width: 200px;
  }
  .u-triangle-v3.u-triangle-top::before, .u-triangle-v4.u-triangle-top::before {
    top: -200px;
  }
  .u-triangle-v3.u-triangle-bottom::before, .u-triangle-v4.u-triangle-bottom::before {
    bottom: -200px;
  }
  .u-triangle-v3.u-triangle-left::before, .u-triangle-v4.u-triangle-left::before {
    left: -200px;
  }
  .u-triangle-v3.u-triangle-right::before, .u-triangle-v4.u-triangle-right::before {
    right: -200px;
  }
}

.u-triangle-none--xl::before,
.u-semicircle-none--xl::before {
  display: none;
}

.u-zigzag-top {
  position: relative;
  background: #f7f7f7;
  z-index: 1;
}

.u-zigzag-top::before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  display: block;
  height: 20px;
  background: -webkit-linear-gradient(-225deg, #f7f7f7 10px, transparent 0) 0 10px, -webkit-linear-gradient(-315deg, #f7f7f7 10px, transparent 0) 0 10px;
  background-position: top left;
  background-repear: repeat-x;
  background-size: 20px 20px;
}

.u-zigzag-bottom {
  position: relative;
  background: #f7f7f7;
  z-index: 1;
}

.u-zigzag-bottom::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  display: block;
  height: 20px;
  background: -webkit-linear-gradient(225deg, #f7f7f7 10px, transparent 0) 0 10px, -webkit-linear-gradient(315deg, #f7f7f7 10px, transparent 0) 0 10px;
  background-position: top left;
  background-repear: repeat-x;
  background-size: 20px 20px;
}

.u-zigzag-top-white {
  position: relative;
  background: #fff;
  z-index: 1;
}

.u-zigzag-top-white::before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  display: block;
  height: 20px;
  background: -webkit-linear-gradient(-225deg, #fff 10px, transparent 0) 0 10px, -webkit-linear-gradient(-315deg, #fff 10px, transparent 0) 0 10px;
  background-position: top left;
  background-repear: repeat-x;
  background-size: 20px 20px;
}

.u-zigzag-top-primary {
  position: relative;
  background: #2cb8f3;
  z-index: 1;
}

.u-zigzag-top-primary::before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  display: block;
  height: 20px;
  background: -webkit-linear-gradient(-225deg, #2cb8f3 10px, transparent 0) 0 10px, -webkit-linear-gradient(-315deg, #2cb8f3 10px, transparent 0) 0 10px;
  background-position: top left;
  background-repear: repeat-x;
  background-size: 20px 20px;
}

.u-zigzag-top-black {
  position: relative;
  background: #000;
  z-index: 1;
}

.u-zigzag-top-black::before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  display: block;
  height: 20px;
  background: -webkit-linear-gradient(-225deg, #000 10px, transparent 0) 0 10px, -webkit-linear-gradient(-315deg, #000 10px, transparent 0) 0 10px;
  background-position: top left;
  background-repear: repeat-x;
  background-size: 20px 20px;
}

.u-zigzag-top-gray-dark-v1 {
  position: relative;
  background: #111;
  z-index: 1;
}

.u-zigzag-top-gray-dark-v1::before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  display: block;
  height: 20px;
  background: -webkit-linear-gradient(-225deg, #111 10px, transparent 0) 0 10px, -webkit-linear-gradient(-315deg, #111 10px, transparent 0) 0 10px;
  background-position: top left;
  background-repear: repeat-x;
  background-size: 20px 20px;
}

/*------------------------------------
  Nonstandard-bg
------------------------------------*/
[class*="u-ns-bg-v"] {
  position: relative;
}

[class*="u-ns-bg-v"]::before {
  content: "";
  position: absolute;
  display: block;
  z-index: 3;
}

/*------------------------------------
  Nonstandard Background v1
------------------------------------*/
[class*="u-ns-bg-v1"] {
  position: relative;
}

[class*="u-ns-bg-v1"]::before {
  content: "";
  position: absolute;
  z-index: 3;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
}

.u-ns-bg-v1-top::before, .u-ns-bg-v1-bottom::before {
  left: 50%;
  margin-left: -20px;
}

.u-ns-bg-v1-left::before, .u-ns-bg-v1-right::before {
  top: 50%;
  margin-top: -20px;
}

.u-ns-bg-v1-top::before {
  top: -25px;
  border-width: 0 20px 25px 20px;
}

.u-ns-bg-v1-top.g-bg-primary::before,
.g-parent.g-bg-primary .u-ns-bg-v1-top::before {
  border-bottom-color: #2cb8f3;
}

.u-ns-bg-v1-top.g-bg-white::before,
.g-parent.g-bg-white .u-ns-bg-v1-top::before {
  border-bottom-color: #fff;
}

.u-ns-bg-v1-top.g-bg-gray-light-v2::before,
.g-parent.g-bg-gray-light-v2 .u-ns-bg-v1-top::before {
  border-bottom-color: #ccc;
}

.u-ns-bg-v1-top.g-bg-black::before,
.g-parent.g-bg-black .u-ns-bg-v1-top::before {
  border-bottom-color: #000;
}

.u-ns-bg-v1-bottom::before {
  bottom: -25px;
  border-width: 25px 20px 0 20px;
}

.u-ns-bg-v1-bottom.g-bg-primary::before,
.g-parent.g-bg-primary .u-ns-bg-v1-bottom::before {
  border-top-color: #2cb8f3;
}

.u-ns-bg-v1-bottom.g-bg-white::before,
.g-parent.g-bg-white .u-ns-bg-v1-bottom::before {
  border-top-color: #fff;
}

.u-ns-bg-v1-bottom.g-bg-gray-light-v2::before,
.g-parent.g-bg-gray-light-v2 .u-ns-bg-v1-bottom::before {
  border-top-color: #ccc;
}

.u-ns-bg-v1-bottom.g-bg-black::before,
.g-parent.g-bg-black .u-ns-bg-v1-bottom::before {
  border-top-color: #000;
}

.u-ns-bg-v1-left::before {
  left: -25px;
  border-width: 20px 25px 20px 0;
}

.u-ns-bg-v1-left.g-bg-primary::before,
.g-parent.g-bg-primary .u-ns-bg-v1-left::before {
  border-right-color: #2cb8f3;
}

.u-ns-bg-v1-left.g-bg-white::before,
.g-parent.g-bg-white .u-ns-bg-v1-left::before {
  border-right-color: #fff;
}

.u-ns-bg-v1-left.g-bg-gray-light-v2::before,
.g-parent.g-bg-gray-light-v2 .u-ns-bg-v1-left::before {
  border-right-color: #ccc;
}

.u-ns-bg-v1-left.g-bg-black::before,
.g-parent.g-bg-black .u-ns-bg-v1-left::before {
  border-right-color: #000;
}

.u-ns-bg-v1-right::before {
  right: -25px;
  border-width: 20px 0 20px 25px;
}

.u-ns-bg-v1-right.g-bg-primary::before,
.g-parent.g-bg-primary .u-ns-bg-v1-right::before {
  border-left-color: #2cb8f3;
}

.u-ns-bg-v1-right.g-bg-white::before,
.g-parent.g-bg-white .u-ns-bg-v1-right::before {
  border-left-color: #fff;
}

.u-ns-bg-v1-right.g-bg-gray-light-v2::before,
.g-parent.g-bg-gray-light-v2 .u-ns-bg-v1-right::before {
  border-left-color: #ccc;
}

.u-ns-bg-v1-right.g-bg-black::before,
.g-parent.g-bg-black .u-ns-bg-v1-right::before {
  border-left-color: #000;
}

@media (min-width: 768px) {
  .u-ns-bg-v1-top--md::before, .u-ns-bg-v1-bottom--md::before, .u-ns-bg-v1-left--md::before, .u-ns-bg-v1-right--md::before {
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .u-ns-bg-v1-top--md.g-bg-primary::before, .u-ns-bg-v1-top--md.g-bg-white::before, .u-ns-bg-v1-top--md.g-bg-gray-light-v2::before, .u-ns-bg-v1-top--md.g-bg-black::before, .u-ns-bg-v1-bottom--md.g-bg-primary::before, .u-ns-bg-v1-bottom--md.g-bg-white::before, .u-ns-bg-v1-bottom--md.g-bg-gray-light-v2::before, .u-ns-bg-v1-bottom--md.g-bg-black::before, .u-ns-bg-v1-left--md.g-bg-primary::before, .u-ns-bg-v1-left--md.g-bg-white::before, .u-ns-bg-v1-left--md.g-bg-gray-light-v2::before, .u-ns-bg-v1-left--md.g-bg-black::before, .u-ns-bg-v1-right--md.g-bg-primary::before, .u-ns-bg-v1-right--md.g-bg-white::before, .u-ns-bg-v1-right--md.g-bg-gray-light-v2::before, .u-ns-bg-v1-right--md.g-bg-black::before {
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
  }
  .u-ns-bg-v1-top--md::before, .u-ns-bg-v1-bottom--md::before {
    left: 50%;
    margin-left: -20px;
  }
  .u-ns-bg-v1-left--md::before, .u-ns-bg-v1-right--md::before {
    top: 50%;
    margin-top: -20px;
  }
  .u-ns-bg-v1-top--md::before {
    top: -25px;
    border-width: 0 20px 25px 20px;
  }
  .u-ns-bg-v1-top--md.g-bg-primary::before {
    border-bottom-color: #2cb8f3;
  }
  .u-ns-bg-v1-top--md.g-bg-white::before {
    border-bottom-color: #fff;
  }
  .u-ns-bg-v1-top--md.g-bg-gray-light-v2::before {
    border-bottom-color: #ccc;
  }
  .u-ns-bg-v1-top--md.g-bg-black::before {
    border-bottom-color: #000;
  }
  .u-ns-bg-v1-bottom--md::before {
    bottom: -25px;
    border-width: 25px 20px 0 20px;
  }
  .u-ns-bg-v1-bottom--md.g-bg-primary::before {
    border-top-color: #2cb8f3;
  }
  .u-ns-bg-v1-bottom--md.g-bg-white::before {
    border-top-color: #fff;
  }
  .u-ns-bg-v1-bottom--md.g-bg-gray-light-v2::before {
    border-top-color: #ccc;
  }
  .u-ns-bg-v1-bottom--md.g-bg-black::before {
    border-top-color: #000;
  }
  .u-ns-bg-v1-left--md::before {
    left: -25px;
    border-width: 20px 25px 20px 0;
  }
  .u-ns-bg-v1-left--md.g-bg-primary::before {
    border-right-color: #2cb8f3;
  }
  .u-ns-bg-v1-left--md.g-bg-white::before {
    border-right-color: #fff;
  }
  .u-ns-bg-v1-left--md.g-bg-gray-light-v2::before {
    border-right-color: #ccc;
  }
  .u-ns-bg-v1-left--md.g-bg-black::before {
    border-right-color: #000;
  }
  .u-ns-bg-v1-right--md::before {
    right: -25px;
    border-width: 20px 0 20px 25px;
  }
  .u-ns-bg-v1-right--md.g-bg-primary::before {
    border-left-color: #2cb8f3;
  }
  .u-ns-bg-v1-right--md.g-bg-white::before {
    border-left-color: #fff;
  }
  .u-ns-bg-v1-right--md.g-bg-gray-light-v2::before {
    border-left-color: #ccc;
  }
  .u-ns-bg-v1-right--md.g-bg-black::before {
    border-left-color: #000;
  }
}

/*------------------------------------
  Nonstandard Background v2
------------------------------------*/
[class*="u-ns-bg-v2"] {
  position: relative;
}

[class*="u-ns-bg-v2"]::before {
  content: "";
  position: absolute;
  z-index: 3;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
}

.u-ns-bg-v2-top::before, .u-ns-bg-v2-bottom::before,
.u-ns-bg-v2-2-top::before,
.u-ns-bg-v2-2-bottom::before {
  left: 45px;
}

.u-ns-bg-v2-left::before, .u-ns-bg-v2-right::before,
.u-ns-bg-v2-2-left::before,
.u-ns-bg-v2-2-right::before {
  top: 23px;
}

.u-ns-bg-v2-top::before,
.u-ns-bg-v2-2-top::before {
  top: -15px;
  border-width: 0 17px 15px 17px;
}

.u-ns-bg-v2-top.g-bg-primary::before,
.u-ns-bg-v2-2-top.g-bg-primary::before {
  border-bottom-color: #2cb8f3;
}

.u-ns-bg-v2-top.g-bg-white::before,
.u-ns-bg-v2-2-top.g-bg-white::before {
  border-bottom-color: #fff;
}

.u-ns-bg-v2-top.g-bg-gray-light-v2::before,
.u-ns-bg-v2-2-top.g-bg-gray-light-v2::before {
  border-bottom-color: #ccc;
}

.u-ns-bg-v2-top.g-bg-gray-light-v5::before,
.u-ns-bg-v2-2-top.g-bg-gray-light-v5::before {
  border-bottom-color: #f7f7f7;
}

.u-ns-bg-v2-top.g-bg-black::before,
.u-ns-bg-v2-2-top.g-bg-black::before {
  border-bottom-color: #000;
}

.u-ns-bg-v2-bottom::before,
.u-ns-bg-v2-2-bottom::before {
  bottom: -15px;
  border-width: 15px 17px 0 17px;
}

.u-ns-bg-v2-bottom.g-bg-primary::before,
.u-ns-bg-v2-2-bottom.g-bg-primary::before {
  border-top-color: #2cb8f3;
}

.u-ns-bg-v2-bottom.g-bg-white::before,
.u-ns-bg-v2-2-bottom.g-bg-white::before {
  border-top-color: #fff;
}

.u-ns-bg-v2-bottom.g-bg-gray-light-v2::before,
.u-ns-bg-v2-2-bottom.g-bg-gray-light-v2::before {
  border-top-color: #ccc;
}

.u-ns-bg-v2-bottom.g-bg-black::before,
.u-ns-bg-v2-2-bottom.g-bg-black::before {
  border-top-color: #000;
}

.u-ns-bg-v2-left::before,
.u-ns-bg-v2-2-left::before {
  left: -15px;
  border-width: 17px 15px 17px 0;
}

.u-ns-bg-v2-left.g-bg-primary::before,
.u-ns-bg-v2-2-left.g-bg-primary::before {
  border-right-color: #2cb8f3;
}

.u-ns-bg-v2-left.g-bg-white::before,
.u-ns-bg-v2-2-left.g-bg-white::before {
  border-right-color: #fff;
}

.u-ns-bg-v2-left.g-bg-gray-light-v2::before,
.u-ns-bg-v2-2-left.g-bg-gray-light-v2::before {
  border-right-color: #ccc;
}

.u-ns-bg-v2-left.g-bg-black::before,
.u-ns-bg-v2-2-left.g-bg-black::before {
  border-right-color: #000;
}

.u-ns-bg-v2-right::before,
.u-ns-bg-v2-2-right::before {
  right: -15px;
  border-width: 17px 0 17px 15px;
}

.u-ns-bg-v2-right.g-bg-primary::before,
.u-ns-bg-v2-2-right.g-bg-primary::before {
  border-left-color: #2cb8f3;
}

.u-ns-bg-v2-right.g-bg-white::before,
.u-ns-bg-v2-2-right.g-bg-white::before {
  border-left-color: #fff;
}

.u-ns-bg-v2-right.g-bg-gray-light-v2::before,
.u-ns-bg-v2-2-right.g-bg-gray-light-v2::before {
  border-left-color: #ccc;
}

.u-ns-bg-v2-right.g-bg-black::before,
.u-ns-bg-v2-2-right.g-bg-black::before {
  border-left-color: #000;
}

.u-ns-bg-v2-2-top::before, .u-ns-bg-v2-2-bottom::before,
.u-ns-bg-v2-2-2-top::before,
.u-ns-bg-v2-2-2-bottom::before {
  left: 20px;
}

.u-ns-bg-v2-2-left::before, .u-ns-bg-v2-2-right::before,
.u-ns-bg-v2-2-2-left::before,
.u-ns-bg-v2-2-2-right::before {
  top: 30px;
}

.u-ns-bg-v2-2-top::before,
.u-ns-bg-v2-2-2-top::before {
  border-width: 0 15px 15px 15px;
}

.u-ns-bg-v2-2-bottom::before,
.u-ns-bg-v2-2-2-bottom::before {
  border-width: 15px 15px 0 15px;
}

.u-ns-bg-v2-2-left::before,
.u-ns-bg-v2-2-2-left::before {
  border-width: 15px 15px 15px 0;
}

.u-ns-bg-v2-2-right::before,
.u-ns-bg-v2-2-2-right::before {
  border-width: 15px 0 15px 15px;
}

@media (min-width: 768px) {
  .u-ns-bg-v2-top--md::before, .u-ns-bg-v2-bottom--md::before, .u-ns-bg-v2-left--md::before, .u-ns-bg-v2-right--md::before,
  .u-ns-bg-v2-2-top--md::before,
  .u-ns-bg-v2-2-bottom--md::before,
  .u-ns-bg-v2-2-left--md::before,
  .u-ns-bg-v2-2-right--md::before {
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .u-ns-bg-v2-top--md.g-bg-primary::before, .u-ns-bg-v2-top--md.g-bg-white::before, .u-ns-bg-v2-top--md.g-bg-gray-light-v2::before, .u-ns-bg-v2-top--md.g-bg-black::before, .u-ns-bg-v2-bottom--md.g-bg-primary::before, .u-ns-bg-v2-bottom--md.g-bg-white::before, .u-ns-bg-v2-bottom--md.g-bg-gray-light-v2::before, .u-ns-bg-v2-bottom--md.g-bg-black::before, .u-ns-bg-v2-left--md.g-bg-primary::before, .u-ns-bg-v2-left--md.g-bg-white::before, .u-ns-bg-v2-left--md.g-bg-gray-light-v2::before, .u-ns-bg-v2-left--md.g-bg-black::before, .u-ns-bg-v2-right--md.g-bg-primary::before, .u-ns-bg-v2-right--md.g-bg-white::before, .u-ns-bg-v2-right--md.g-bg-gray-light-v2::before, .u-ns-bg-v2-right--md.g-bg-black::before,
  .u-ns-bg-v2-2-top--md.g-bg-primary::before,
  .u-ns-bg-v2-2-top--md.g-bg-white::before,
  .u-ns-bg-v2-2-top--md.g-bg-gray-light-v2::before,
  .u-ns-bg-v2-2-top--md.g-bg-black::before,
  .u-ns-bg-v2-2-bottom--md.g-bg-primary::before,
  .u-ns-bg-v2-2-bottom--md.g-bg-white::before,
  .u-ns-bg-v2-2-bottom--md.g-bg-gray-light-v2::before,
  .u-ns-bg-v2-2-bottom--md.g-bg-black::before,
  .u-ns-bg-v2-2-left--md.g-bg-primary::before,
  .u-ns-bg-v2-2-left--md.g-bg-white::before,
  .u-ns-bg-v2-2-left--md.g-bg-gray-light-v2::before,
  .u-ns-bg-v2-2-left--md.g-bg-black::before,
  .u-ns-bg-v2-2-right--md.g-bg-primary::before,
  .u-ns-bg-v2-2-right--md.g-bg-white::before,
  .u-ns-bg-v2-2-right--md.g-bg-gray-light-v2::before,
  .u-ns-bg-v2-2-right--md.g-bg-black::before {
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
  }
  .u-ns-bg-v2-top--md::before,
  .u-ns-bg-v2-2-top--md::before {
    top: -15px;
    border-width: 0 17px 15px 17px;
  }
  .u-ns-bg-v2-top--md.g-bg-primary::before,
  .u-ns-bg-v2-2-top--md.g-bg-primary::before {
    border-bottom-color: #2cb8f3;
  }
  .u-ns-bg-v2-top--md.g-bg-white::before,
  .u-ns-bg-v2-2-top--md.g-bg-white::before {
    border-bottom-color: #fff;
  }
  .u-ns-bg-v2-top--md.g-bg-gray-light-v2::before,
  .u-ns-bg-v2-2-top--md.g-bg-gray-light-v2::before {
    border-bottom-color: #ccc;
  }
  .u-ns-bg-v2-top--md.g-bg-black::before,
  .u-ns-bg-v2-2-top--md.g-bg-black::before {
    border-bottom-color: #000;
  }
  .u-ns-bg-v2-bottom--md::before,
  .u-ns-bg-v2-2-bottom--md::before {
    bottom: -15px;
    border-width: 15px 17px 0 17px;
  }
  .u-ns-bg-v2-bottom--md.g-bg-primary::before,
  .u-ns-bg-v2-2-bottom--md.g-bg-primary::before {
    border-top-color: #2cb8f3;
  }
  .u-ns-bg-v2-bottom--md.g-bg-white::before,
  .u-ns-bg-v2-2-bottom--md.g-bg-white::before {
    border-top-color: #fff;
  }
  .u-ns-bg-v2-bottom--md.g-bg-gray-light-v2::before,
  .u-ns-bg-v2-2-bottom--md.g-bg-gray-light-v2::before {
    border-top-color: #ccc;
  }
  .u-ns-bg-v2-bottom--md.g-bg-black::before,
  .u-ns-bg-v2-2-bottom--md.g-bg-black::before {
    border-top-color: #000;
  }
  .u-ns-bg-v2-left--md::before,
  .u-ns-bg-v2-2-left--md::before {
    left: -15px;
    border-width: 17px 15px 17px 0;
  }
  .u-ns-bg-v2-left--md.g-bg-primary::before,
  .u-ns-bg-v2-2-left--md.g-bg-primary::before {
    border-right-color: #2cb8f3;
  }
  .u-ns-bg-v2-left--md.g-bg-white::before,
  .u-ns-bg-v2-2-left--md.g-bg-white::before {
    border-right-color: #fff;
  }
  .u-ns-bg-v2-left--md.g-bg-gray-light-v2::before,
  .u-ns-bg-v2-2-left--md.g-bg-gray-light-v2::before {
    border-right-color: #ccc;
  }
  .u-ns-bg-v2-left--md.g-bg-black::before,
  .u-ns-bg-v2-2-left--md.g-bg-black::before {
    border-right-color: #000;
  }
  .u-ns-bg-v2-right--md::before,
  .u-ns-bg-v2-2-right--md::before {
    right: -15px;
    border-width: 17px 0 17px 15px;
  }
  .u-ns-bg-v2-right--md.g-bg-primary::before,
  .u-ns-bg-v2-2-right--md.g-bg-primary::before {
    border-left-color: #2cb8f3;
  }
  .u-ns-bg-v2-right--md.g-bg-white::before,
  .u-ns-bg-v2-2-right--md.g-bg-white::before {
    border-left-color: #fff;
  }
  .u-ns-bg-v2-right--md.g-bg-gray-light-v2::before,
  .u-ns-bg-v2-2-right--md.g-bg-gray-light-v2::before {
    border-left-color: #ccc;
  }
  .u-ns-bg-v2-right--md.g-bg-black::before,
  .u-ns-bg-v2-2-right--md.g-bg-black::before {
    border-left-color: #000;
  }
  .u-ns-bg-v2-2-top--md::before, .u-ns-bg-v2-2-bottom--md::before,
  .u-ns-bg-v2-2-2-top--md::before,
  .u-ns-bg-v2-2-2-bottom--md::before {
    left: 20px;
  }
  .u-ns-bg-v2-2-left--md::before, .u-ns-bg-v2-2-right--md::before,
  .u-ns-bg-v2-2-2-left--md::before,
  .u-ns-bg-v2-2-2-right--md::before {
    top: 30px;
  }
  .u-ns-bg-v2-2-top--md::before,
  .u-ns-bg-v2-2-2-top--md::before {
    border-width: 0 15px 15px 15px;
  }
  .u-ns-bg-v2-2-bottom--md::before,
  .u-ns-bg-v2-2-2-bottom--md::before {
    border-width: 15px 15px 0 15px;
  }
  .u-ns-bg-v2-2-left--md::before,
  .u-ns-bg-v2-2-2-left--md::before {
    border-width: 15px 15px 15px 0;
  }
  .u-ns-bg-v2-2-right--md::before,
  .u-ns-bg-v2-2-2-right--md::before {
    border-width: 15px 0 15px 15px;
  }
}

/*------------------------------------
  Nonstandard Background v3
------------------------------------*/
[class*="u-ns-bg-v3"] {
  position: relative;
}

[class*="u-ns-bg-v3"]::before {
  content: "";
  position: absolute;
  z-index: 3;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
}

.u-ns-bg-v3-top::before, .u-ns-bg-v3-bottom::before {
  left: 80px;
}

.u-ns-bg-v3-left::before, .u-ns-bg-v3-right::before {
  top: 23px;
}

.u-ns-bg-v3-top.g-bg-primary::before, .u-ns-bg-v3-left.g-bg-primary::before {
  border-bottom-color: #2cb8f3;
}

.u-ns-bg-v3-top.g-bg-white::before, .u-ns-bg-v3-left.g-bg-white::before {
  border-bottom-color: #fff;
}

.u-ns-bg-v3-top.g-bg-gray-light-v2::before, .u-ns-bg-v3-left.g-bg-gray-light-v2::before {
  border-bottom-color: #ccc;
}

.u-ns-bg-v3-top.g-bg-black::before, .u-ns-bg-v3-left.g-bg-black::before {
  border-bottom-color: #000;
}

.u-ns-bg-v3-top.g-bg-facebook::before, .u-ns-bg-v3-left.g-bg-facebook::before {
  border-bottom-color: #3b5998;
}

.u-ns-bg-v3-bottom.g-bg-primary::before, .u-ns-bg-v3-right.g-bg-primary::before {
  border-top-color: #2cb8f3;
}

.u-ns-bg-v3-bottom.g-bg-white::before, .u-ns-bg-v3-right.g-bg-white::before {
  border-top-color: #fff;
}

.u-ns-bg-v3-bottom.g-bg-gray-light-v2::before, .u-ns-bg-v3-right.g-bg-gray-light-v2::before {
  border-top-color: #ccc;
}

.u-ns-bg-v3-bottom.g-bg-black::before, .u-ns-bg-v3-right.g-bg-black::before {
  border-top-color: #000;
}

.u-ns-bg-v3-bottom.g-bg-lightred::before, .u-ns-bg-v3-right.g-bg-lightred::before {
  border-top-color: #e64b3b;
}

.u-ns-bg-v3-bottom.g-bg-blue::before, .u-ns-bg-v3-right.g-bg-blue::before {
  border-top-color: #3398dc;
}

.u-ns-bg-v3-bottom.g-bg-purple::before, .u-ns-bg-v3-right.g-bg-purple::before {
  border-top-color: #9a69cb;
}

.u-ns-bg-v3-bottom.g-bg-cyan::before, .u-ns-bg-v3-right.g-bg-cyan::before {
  border-top-color: #00bed6;
}

.u-ns-bg-v3-bottom.g-bg-teal::before, .u-ns-bg-v3-right.g-bg-teal::before {
  border-top-color: #18ba9b;
}

.u-ns-bg-v3-bottom.g-bg-pink::before, .u-ns-bg-v3-right.g-bg-pink::before {
  border-top-color: #e81c62;
}

.u-ns-bg-v3-bottom.g-bg-red::before, .u-ns-bg-v3-right.g-bg-red::before {
  border-top-color: #f00;
}

.u-ns-bg-v3-bottom.g-bg-facebook::before, .u-ns-bg-v3-right.g-bg-facebook::before {
  border-top-color: #3b5998;
}

.u-ns-bg-v3-top::before {
  top: -22px;
  border-width: 22px 0 0 22px;
}

.u-ns-bg-v3-bottom::before {
  bottom: -22px;
  border-width: 22px 22px 0 0;
}

.u-ns-bg-v3-left::before {
  left: -22px;
  border-width: 0 22px 22px 0;
}

.u-ns-bg-v3-right::before {
  right: -22px;
  border-width: 22px 22px 0 0;
}

/*------------------------------------
  Nonstandard Background v4
------------------------------------*/
[class*="u-ns-bg-v4"] {
  position: relative;
}

[class*="u-ns-bg-v4"]::before {
  content: "";
  position: absolute;
  z-index: 3;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
}

.u-ns-bg-v4-top::before, .u-ns-bg-v4-bottom::before {
  left: 8%;
}

.u-ns-bg-v4-left::before, .u-ns-bg-v4-right::before {
  top: 8%;
}

.u-ns-bg-v4-top.g-bg-primary::before, .u-ns-bg-v4-left.g-bg-primary::before {
  border-bottom-color: #2cb8f3;
}

.u-ns-bg-v4-top.g-bg-white::before, .u-ns-bg-v4-left.g-bg-white::before {
  border-bottom-color: #fff;
}

.u-ns-bg-v4-top.g-bg-gray-light-v2::before, .u-ns-bg-v4-left.g-bg-gray-light-v2::before {
  border-bottom-color: #ccc;
}

.u-ns-bg-v4-top.g-bg-black::before, .u-ns-bg-v4-left.g-bg-black::before {
  border-bottom-color: #000;
}

.u-ns-bg-v4-bottom.g-bg-primary::before, .u-ns-bg-v4-right.g-bg-primary::before {
  border-top-color: #2cb8f3;
}

.u-ns-bg-v4-bottom.g-bg-white::before, .u-ns-bg-v4-right.g-bg-white::before {
  border-top-color: #fff;
}

.u-ns-bg-v4-bottom.g-bg-gray-light-v2::before, .u-ns-bg-v4-right.g-bg-gray-light-v2::before {
  border-top-color: #ccc;
}

.u-ns-bg-v4-bottom.g-bg-black::before, .u-ns-bg-v4-right.g-bg-black::before {
  border-top-color: #000;
}

.u-ns-bg-v4-top::before {
  top: -22px;
  border-width: 22px 22px 0 0;
}

.u-ns-bg-v4-bottom::before {
  bottom: -22px;
  border-width: 22px 0 0 22px;
}

.u-ns-bg-v4-left::before {
  left: -22px;
  border-width: 22px 22px 0 0;
}

.u-ns-bg-v4-right::before {
  right: -22px;
  border-width: 0 22px 22px 0;
}

/*------------------------------------
  Nonstandard Background v5
------------------------------------*/
[class*="u-ns-bg-v5"] {
  position: relative;
}

[class*="u-ns-bg-v5"] .u-ns-bg-before {
  position: absolute;
  z-index: 2;
}

[class*="u-ns-bg-v5"].g-bg-primary svg polygon,
[class*="u-ns-bg-v5"].g-bg-primary svg path {
  fill: #2cb8f3;
}

[class*="u-ns-bg-v5"].g-bg-white svg polygon,
[class*="u-ns-bg-v5"].g-bg-white svg path {
  fill: #fff;
}

[class*="u-ns-bg-v5"].g-bg-gray-light-v2 svg polygon,
[class*="u-ns-bg-v5"].g-bg-gray-light-v2 svg path {
  fill: #ccc;
}

[class*="u-ns-bg-v5"].g-bg-black svg polygon,
[class*="u-ns-bg-v5"].g-bg-black svg path {
  fill: #000;
}

.u-ns-bg-v5-top .u-ns-bg-before, .u-ns-bg-v5-bottom .u-ns-bg-before {
  width: 100%;
}

.u-ns-bg-v5-top svg, .u-ns-bg-v5-bottom svg {
  width: 100%;
}

.u-ns-bg-v5-left .u-ns-bg-before, .u-ns-bg-v5-right .u-ns-bg-before {
  height: 100%;
}

.u-ns-bg-v5-left svg, .u-ns-bg-v5-right svg {
  height: 100%;
  vertical-align: middle;
}

.u-ns-bg-v5-top .u-ns-bg-before {
  bottom: 100%;
}

.u-ns-bg-v5-top svg {
  vertical-align: bottom;
}

.u-ns-bg-v5-bottom .u-ns-bg-before {
  top: 100%;
}

.u-ns-bg-v5-bottom svg {
  vertical-align: top;
}

.u-ns-bg-v5-left .u-ns-bg-before {
  left: 100%;
}

.u-ns-bg-v5-right .u-ns-bg-before {
  right: 100%;
}

@media (min-width: 768px) {
  .u-ns-bg-v5-top--md .u-ns-bg-before, .u-ns-bg-v5-bottom--md .u-ns-bg-before, .u-ns-bg-v5-left--md .u-ns-bg-before, .u-ns-bg-v5-right--md .u-ns-bg-before {
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    width: auto;
    height: auto;
  }
  .u-ns-bg-v5-top--md svg, .u-ns-bg-v5-bottom--md svg, .u-ns-bg-v5-left--md svg, .u-ns-bg-v5-right--md svg {
    width: auto;
    height: auto;
  }
  .u-ns-bg-v5-top--md .u-ns-bg-before, .u-ns-bg-v5-bottom--md .u-ns-bg-before {
    width: 100%;
  }
  .u-ns-bg-v5-top--md svg, .u-ns-bg-v5-bottom--md svg {
    width: 100%;
  }
  .u-ns-bg-v5-left--md .u-ns-bg-before, .u-ns-bg-v5-right--md .u-ns-bg-before {
    height: 100%;
  }
  .u-ns-bg-v5-left--md svg, .u-ns-bg-v5-right--md svg {
    height: 100%;
    vertical-align: middle;
  }
  .u-ns-bg-v5-top--md .u-ns-bg-before {
    bottom: 100%;
  }
  .u-ns-bg-v5-top--md svg {
    vertical-align: bottom;
  }
  .u-ns-bg-v5-bottom--md .u-ns-bg-before {
    top: 100%;
  }
  .u-ns-bg-v5-bottom--md svg {
    vertical-align: top;
  }
  .u-ns-bg-v5-left--md .u-ns-bg-before {
    left: 100%;
  }
  .u-ns-bg-v5-right--md .u-ns-bg-before {
    right: 100%;
  }
}

/*------------------------------------
  Nonstandard Background v6
------------------------------------*/
[class*="u-ns-bg-v6"] {
  position: relative;
}

[class*="u-ns-bg-v6"] .u-ns-bg-before {
  position: absolute;
  z-index: 2;
}

.u-ns-bg-v6-top .u-ns-bg-before::before, .u-ns-bg-v6-top .u-ns-bg-before::after, .u-ns-bg-v6-bottom .u-ns-bg-before::before, .u-ns-bg-v6-bottom .u-ns-bg-before::after, .u-ns-bg-v6-left .u-ns-bg-before::before, .u-ns-bg-v6-left .u-ns-bg-before::after, .u-ns-bg-v6-right .u-ns-bg-before::before, .u-ns-bg-v6-right .u-ns-bg-before::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
}

.u-ns-bg-v6-top .u-ns-bg-before::after, .u-ns-bg-v6-bottom .u-ns-bg-before::after, .u-ns-bg-v6-left .u-ns-bg-before::after, .u-ns-bg-v6-right .u-ns-bg-before::after {
  position: absolute;
}

.u-ns-bg-v6-top .u-ns-bg-before, .u-ns-bg-v6-bottom .u-ns-bg-before {
  left: 20px;
}

.u-ns-bg-v6-left .u-ns-bg-before, .u-ns-bg-v6-right .u-ns-bg-before {
  top: 30px;
}

.u-ns-bg-v6-top .u-ns-bg-before {
  top: -15px;
}

.u-ns-bg-v6-top .u-ns-bg-before::before {
  border-width: 0 15px 15px 15px;
}

.u-ns-bg-v6-top .u-ns-bg-before::after {
  bottom: 0;
  left: 1px;
  border-width: 0 14px 14px 14px;
}

.u-ns-bg-v6-top.g-brd-primary .u-ns-bg-before::before {
  border-bottom-color: #2cb8f3;
}

.u-ns-bg-v6-top.g-brd-primary .u-ns-bg-before::after {
  border-bottom-color: #fff;
}

.u-ns-bg-v6-top.g-brd-gray-light-v2 .u-ns-bg-before::before {
  border-bottom-color: #ccc;
}

.u-ns-bg-v6-top.g-brd-gray-light-v2 .u-ns-bg-before::after {
  border-bottom-color: #fff;
}

.u-ns-bg-v6-top.g-brd-black .u-ns-bg-before::before {
  border-bottom-color: #000;
}

.u-ns-bg-v6-top.g-brd-black .u-ns-bg-before::after {
  border-bottom-color: #fff;
}

.u-ns-bg-v6-bottom .u-ns-bg-before {
  bottom: -15px;
}

.u-ns-bg-v6-bottom .u-ns-bg-before::before {
  border-width: 15px 15px 0 15px;
}

.u-ns-bg-v6-bottom .u-ns-bg-before::after {
  bottom: 1px;
  left: 1px;
  border-width: 14px 14px 0 14px;
}

.u-ns-bg-v6-bottom.g-brd-primary .u-ns-bg-before::before {
  border-top-color: #2cb8f3;
}

.u-ns-bg-v6-bottom.g-brd-primary .u-ns-bg-before::after {
  border-top-color: #fff;
}

.u-ns-bg-v6-bottom.g-brd-gray-light-v2 .u-ns-bg-before::before {
  border-top-color: #ccc;
}

.u-ns-bg-v6-bottom.g-brd-gray-light-v2 .u-ns-bg-before::after {
  border-top-color: #fff;
}

.u-ns-bg-v6-bottom.g-brd-black .u-ns-bg-before::before {
  border-top-color: #000;
}

.u-ns-bg-v6-bottom.g-brd-black .u-ns-bg-before::after {
  border-top-color: #fff;
}

.u-ns-bg-v6-left .u-ns-bg-before {
  left: -15px;
}

.u-ns-bg-v6-left .u-ns-bg-before::before {
  border-width: 15px 15px 15px 0;
}

.u-ns-bg-v6-left .u-ns-bg-before::after {
  top: 1px;
  right: 0;
  border-width: 14px 14px 14px 0;
}

.u-ns-bg-v6-left.g-brd-primary .u-ns-bg-before::before {
  border-right-color: #2cb8f3;
}

.u-ns-bg-v6-left.g-brd-primary .u-ns-bg-before::after {
  border-right-color: #fff;
}

.u-ns-bg-v6-left.g-brd-gray-light-v2 .u-ns-bg-before::before {
  border-right-color: #ccc;
}

.u-ns-bg-v6-left.g-brd-gray-light-v2 .u-ns-bg-before::after {
  border-right-color: #fff;
}

.u-ns-bg-v6-left.g-brd-black .u-ns-bg-before::before {
  border-right-color: #000;
}

.u-ns-bg-v6-left.g-brd-black .u-ns-bg-before::after {
  border-right-color: #fff;
}

.u-ns-bg-v6-right .u-ns-bg-before {
  right: -15px;
}

.u-ns-bg-v6-right .u-ns-bg-before::before {
  border-width: 15px 0 15px 15px;
}

.u-ns-bg-v6-right .u-ns-bg-before::after {
  top: 1px;
  left: 0;
  border-width: 14px 0 14px 14px;
}

.u-ns-bg-v6-right.g-brd-primary .u-ns-bg-before::before {
  border-left-color: #ccc;
}

.u-ns-bg-v6-right.g-brd-primary .u-ns-bg-before::after {
  border-left-color: #fff;
}

.u-ns-bg-v6-right.g-brd-gray-light-v2 .u-ns-bg-before::before {
  border-left-color: #ccc;
}

.u-ns-bg-v6-right.g-brd-gray-light-v2 .u-ns-bg-before::after {
  border-left-color: #fff;
}

.u-ns-bg-v6-right.g-brd-black .u-ns-bg-before::before {
  border-left-color: #ccc;
}

.u-ns-bg-v6-right.g-brd-black .u-ns-bg-before::after {
  border-left-color: #fff;
}

@media (min-width: 768px) {
  .u-ns-bg-v6-top--md.g-brd-primary .u-ns-bg-before::before, .u-ns-bg-v6-top--md.g-brd-gray-light-v2 .u-ns-bg-before::before, .u-ns-bg-v6-top--md.g-brd-black .u-ns-bg-before::before, .u-ns-bg-v6-bottom--md.g-brd-primary .u-ns-bg-before::before, .u-ns-bg-v6-bottom--md.g-brd-gray-light-v2 .u-ns-bg-before::before, .u-ns-bg-v6-bottom--md.g-brd-black .u-ns-bg-before::before, .u-ns-bg-v6-left--md.g-brd-primary .u-ns-bg-before::before, .u-ns-bg-v6-left--md.g-brd-gray-light-v2 .u-ns-bg-before::before, .u-ns-bg-v6-left--md.g-brd-black .u-ns-bg-before::before, .u-ns-bg-v6-right--md.g-brd-primary .u-ns-bg-before::before, .u-ns-bg-v6-right--md.g-brd-gray-light-v2 .u-ns-bg-before::before, .u-ns-bg-v6-right--md.g-brd-black .u-ns-bg-before::before {
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
  }
  .u-ns-bg-v6-top--md.g-brd-primary .u-ns-bg-before::after, .u-ns-bg-v6-top--md.g-brd-gray-light-v2 .u-ns-bg-before::after, .u-ns-bg-v6-top--md.g-brd-black .u-ns-bg-before::after, .u-ns-bg-v6-bottom--md.g-brd-primary .u-ns-bg-before::after, .u-ns-bg-v6-bottom--md.g-brd-gray-light-v2 .u-ns-bg-before::after, .u-ns-bg-v6-bottom--md.g-brd-black .u-ns-bg-before::after, .u-ns-bg-v6-left--md.g-brd-primary .u-ns-bg-before::after, .u-ns-bg-v6-left--md.g-brd-gray-light-v2 .u-ns-bg-before::after, .u-ns-bg-v6-left--md.g-brd-black .u-ns-bg-before::after, .u-ns-bg-v6-right--md.g-brd-primary .u-ns-bg-before::after, .u-ns-bg-v6-right--md.g-brd-gray-light-v2 .u-ns-bg-before::after, .u-ns-bg-v6-right--md.g-brd-black .u-ns-bg-before::after {
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
  }
  .u-ns-bg-v6-top--md .u-ns-bg-before, .u-ns-bg-v6-bottom--md .u-ns-bg-before {
    left: 20px;
  }
  .u-ns-bg-v6-left--md .u-ns-bg-before, .u-ns-bg-v6-right--md .u-ns-bg-before {
    top: 30px;
  }
  .u-ns-bg-v6-top--md .u-ns-bg-before {
    bottom: auto;
    top: -15px;
  }
  .u-ns-bg-v6-top--md .u-ns-bg-before::before {
    border-width: 0 15px 15px 15px;
  }
  .u-ns-bg-v6-top--md .u-ns-bg-before::after {
    top: auto;
    bottom: 0;
    left: 1px;
    border-width: 0 14px 14px 14px;
  }
  .u-ns-bg-v6-top--md.g-brd-primary .u-ns-bg-before::before {
    border-bottom-color: #2cb8f3;
  }
  .u-ns-bg-v6-top--md.g-brd-primary .u-ns-bg-before::after {
    border-bottom-color: #fff;
  }
  .u-ns-bg-v6-top--md.g-brd-gray-light-v2 .u-ns-bg-before::before {
    border-bottom-color: #ccc;
  }
  .u-ns-bg-v6-top--md.g-brd-gray-light-v2 .u-ns-bg-before::after {
    border-bottom-color: #fff;
  }
  .u-ns-bg-v6-top--md.g-brd-black .u-ns-bg-before::before {
    border-bottom-color: #000;
  }
  .u-ns-bg-v6-top--md.g-brd-black .u-ns-bg-before::after {
    border-bottom-color: #fff;
  }
  .u-ns-bg-v6-bottom--md .u-ns-bg-before {
    top: auto;
    bottom: -15px;
  }
  .u-ns-bg-v6-bottom--md .u-ns-bg-before::before {
    border-width: 15px 15px 0 15px;
  }
  .u-ns-bg-v6-bottom--md .u-ns-bg-before::after {
    top: auto;
    bottom: 1px;
    left: 1px;
    border-width: 14px 14px 0 14px;
  }
  .u-ns-bg-v6-bottom--md.g-brd-primary .u-ns-bg-before::before {
    border-top-color: #2cb8f3;
  }
  .u-ns-bg-v6-bottom--md.g-brd-primary .u-ns-bg-before::after {
    border-top-color: #fff;
  }
  .u-ns-bg-v6-bottom--md.g-brd-gray-light-v2 .u-ns-bg-before::before {
    border-top-color: #ccc;
  }
  .u-ns-bg-v6-bottom--md.g-brd-gray-light-v2 .u-ns-bg-before::after {
    border-top-color: #fff;
  }
  .u-ns-bg-v6-bottom--md.g-brd-black .u-ns-bg-before::before {
    border-top-color: #000;
  }
  .u-ns-bg-v6-bottom--md.g-brd-black .u-ns-bg-before::after {
    border-top-color: #fff;
  }
  .u-ns-bg-v6-left--md .u-ns-bg-before {
    left: -15px;
    right: auto;
  }
  .u-ns-bg-v6-left--md .u-ns-bg-before::before {
    border-width: 15px 15px 15px 0;
  }
  .u-ns-bg-v6-left--md .u-ns-bg-before::after {
    top: 1px;
    bottom: auto;
    right: 0;
    border-width: 14px 14px 14px 0;
  }
  .u-ns-bg-v6-left--md.g-brd-primary .u-ns-bg-before::before {
    border-right-color: #2cb8f3;
  }
  .u-ns-bg-v6-left--md.g-brd-primary .u-ns-bg-before::after {
    border-right-color: #fff;
  }
  .u-ns-bg-v6-left--md.g-brd-gray-light-v2 .u-ns-bg-before::before {
    border-right-color: #ccc;
  }
  .u-ns-bg-v6-left--md.g-brd-gray-light-v2 .u-ns-bg-before::after {
    border-right-color: #fff;
  }
  .u-ns-bg-v6-left--md.g-brd-black .u-ns-bg-before::before {
    border-right-color: #000;
  }
  .u-ns-bg-v6-left--md.g-brd-black .u-ns-bg-before::after {
    border-right-color: #fff;
  }
  .u-ns-bg-v6-right--md .u-ns-bg-before {
    left: auto;
    right: -15px;
  }
  .u-ns-bg-v6-right--md .u-ns-bg-before::before {
    border-width: 15px 0 15px 15px;
  }
  .u-ns-bg-v6-right--md .u-ns-bg-before::after {
    top: 1px;
    bottom: auto;
    left: 0;
    border-width: 14px 0 14px 14px;
  }
  .u-ns-bg-v6-right--md.g-brd-primary .u-ns-bg-before::before {
    border-left-color: #2cb8f3;
  }
  .u-ns-bg-v6-right--md.g-brd-primary .u-ns-bg-before::after {
    border-left-color: #fff;
  }
  .u-ns-bg-v6-right--md.g-brd-gray-light-v2 .u-ns-bg-before::before {
    border-left-color: #ccc;
  }
  .u-ns-bg-v6-right--md.g-brd-gray-light-v2 .u-ns-bg-before::after {
    border-left-color: #fff;
  }
  .u-ns-bg-v6-right--md.g-brd-black .u-ns-bg-before::before {
    border-left-color: #000;
  }
  .u-ns-bg-v6-right--md.g-brd-black .u-ns-bg-before::after {
    border-left-color: #fff;
  }
}

/*------------------------------------
  Nonstandard Background v7
------------------------------------*/
[class*="u-ns-bg-v7"] {
  position: relative;
}

[class*="u-ns-bg-v7"]::before {
  content: "";
  position: absolute;
  z-index: 3;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
}

.u-ns-bg-v7-top::before, .u-ns-bg-v7-bottom::before {
  left: 50%;
  margin-left: -5px;
}

.u-ns-bg-v7-left::before, .u-ns-bg-v7-right::before {
  top: 50%;
  margin-top: -5px;
}

.u-ns-bg-v7-top::before {
  top: -5px;
  border-width: 0 5px 5px 5px;
}

.u-ns-bg-v7-top.g-bg-primary::before {
  border-bottom-color: #2cb8f3;
}

.u-ns-bg-v7-top.g-bg-white::before {
  border-bottom-color: #fff;
}

.u-ns-bg-v7-top.g-bg-gray-light-v2::before {
  border-bottom-color: #ccc;
}

.u-ns-bg-v7-top.g-bg-black::before {
  border-bottom-color: #000;
}

.u-ns-bg-v7-bottom::before {
  bottom: -5px;
  border-width: 5px 5px 0 5px;
}

.u-ns-bg-v7-bottom.g-bg-primary::before {
  border-top-color: #2cb8f3;
}

.u-ns-bg-v7-bottom.g-bg-white::before {
  border-top-color: #fff;
}

.u-ns-bg-v7-bottom.g-bg-gray-light-v2::before {
  border-top-color: #ccc;
}

.u-ns-bg-v7-bottom.g-bg-black::before {
  border-top-color: #000;
}

.u-ns-bg-v7-left::before {
  left: -5px;
  border-width: 5px 5px 5px 0;
}

.u-ns-bg-v7-left.g-bg-primary::before {
  border-right-color: #2cb8f3;
}

.u-ns-bg-v7-left.g-bg-white::before {
  border-right-color: #fff;
}

.u-ns-bg-v7-left.g-bg-gray-light-v2::before {
  border-right-color: #ccc;
}

.u-ns-bg-v7-left.g-bg-black::before {
  border-right-color: #000;
}

.u-ns-bg-v7-right::before {
  right: -5px;
  border-width: 5px 0 5px 5px;
}

.u-ns-bg-v7-right.g-bg-primary::before {
  border-left-color: #2cb8f3;
}

.u-ns-bg-v7-right.g-bg-white::before {
  border-left-color: #fff;
}

.u-ns-bg-v7-right.g-bg-gray-light-v2::before {
  border-left-color: #ccc;
}

.u-ns-bg-v7-right.g-bg-black::before {
  border-left-color: #000;
}

/*------------------------------------
  Progress Bars
------------------------------------*/
.progress-bar {
  min-height: 0 !important;
  display: block;
  line-height: 1.4;
  height: 1rem;
}

.u-progress__pointer-v1 {
  position: absolute;
  top: 50%;
  right: 0;
  width: 42px;
  height: 42px;
  margin: -21px -21px 0 0;
  background-color: transparent;
  line-height: 42px;
  z-index: 1;
}

.u-progress__pointer-v2 {
  position: absolute;
  bottom: 100%;
  right: 0;
  min-width: 46px;
  white-space: nowrap;
  padding-left: 5px;
  padding-right: 5px;
  transform: translateX(50%);
  margin-bottom: 5px;
  background-color: transparent;
  z-index: 1;
}

.u-progress__pointer-v2::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -5px;
  margin-left: -5px;
  border-style: solid;
  border-color: #2cb8f3 transparent;
  border-width: 5px 5px 0;
}

.u-progress__pointer-v3 {
  position: relative;
  float: right;
  white-space: nowrap;
  line-height: 1.2;
}

.u-progress__pointer-v3::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -10px;
  margin-right: -5px;
  border-style: solid;
  border-color: #2cb8f3 transparent;
  border-width: 5px 5px 0;
}

[class*="u-progress-bar-vertical"] {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
}

.u-progress-bar-vertical-v1 {
  height: 200px;
}

/*------------------------------------
  Progress Bars Sizes
------------------------------------*/
.u-progress-bar--2xs {
  min-height: 1px !important;
}

.u-progress-bar--xs {
  min-height: 3px !important;
}

.u-progress-bar--sm {
  min-height: 7px !important;
}

.u-progress-bar--lg {
  min-height: 18px !important;
}

.u-progress-bar--xl {
  min-height: 22px !important;
}

.u-progress-bar--2xl {
  min-height: 33px !important;
}

/*------------------------------------
  Rating v1
------------------------------------*/
.u-rating-v1 {
  display: inline-block;
  padding-left: 0;
  margin-bottom: 0;
}

.u-rating-v1 > * {
  float: left;
  list-style: none;
  cursor: pointer;
  transition-property: color;
  transition-duration: .2s;
  transition-timing-function: ease;
}

.u-rating-v1 > * + * {
  padding-left: 6px;
}

/*------------------------------------
  Searchform v1
------------------------------------*/
.u-searchform-v1,
.u-searchform-v1--align-left {
  min-width: initial;
  width: 21.42857rem;
  position: absolute;
  right: -1.07143rem;
  top: 100%;
  box-shadow: 0 1px 3px #ddd;
}

.u-searchform-v1 .form-control,
.u-searchform-v1--align-left .form-control {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 0.85714rem;
}

.u-searchform-v1--align-left,
.u-searchform-v1--align-left--align-left {
  position: absolute;
  left: -1.07143rem;
}

.u-searchform-v1 .input-group-addon,
.u-searchform-v1--align-left .input-group-addon {
  border: 0;
}

.u-searchform-v1 .input-group-addon button[type="submit"],
.u-searchform-v1--align-left .input-group-addon button[type="submit"] {
  height: 100%;
}

/*------------------------------------
  Searchform v2
------------------------------------*/
.u-searchform-v2 .form-control {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 0.85714rem;
  color: rgba(255, 255, 255, 0.85);
  text-transform: uppercase;
  height: 4.71429rem;
  padding: 0.85714rem 0;
  background-color: transparent;
  border: none;
}

.u-searchform-v2 .form-control::placeholder {
  color: g-color-white;
  opacity: .85;
}

.u-searchform-v2 .form-control::-webkit-input-placeholder {
  color: g-color-white;
  opacity: .85;
}

.u-searchform-v2 .form-control::-moz-placeholder {
  color: g-color-white;
  opacity: .85;
}

.u-searchform-v2 .input-group-addon {
  border: 0;
}

.u-searchform-v2 .input-group-addon button {
  cursor: pointer;
  height: 100%;
  border: none;
  outline: none;
  background-color: transparent;
}

/*------------------------------------
  Searchform v3
------------------------------------*/
.u-searchform-v3 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
}

.u-searchform-v3 .container {
  height: 100%;
}

.u-searchform-v3 .input-group {
  align-items: center;
  height: 100%;
}

.u-searchform-v3 .form-control {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-size: inherit;
  color: inherit;
  text-transform: uppercase;
  height: 4.71429rem;
  padding: 0.85714rem 0;
  background-color: transparent;
  border: none;
}

.u-searchform-v3 .form-control::placeholder {
  color: g-color-white;
  opacity: .85;
}

.u-searchform-v3 .form-control::-webkit-input-placeholder {
  color: g-color-white;
  opacity: .85;
}

.u-searchform-v3 .form-control::-moz-placeholder {
  color: g-color-white;
  opacity: .85;
}

.u-searchform-v3 .input-group-addon {
  border: 0;
}

.u-searchform-v3 .input-group-addon button {
  cursor: pointer;
  height: 100%;
  border: none;
  outline: none;
  background-color: transparent;
}

/*------------------------------------
  Searchform v4
------------------------------------*/
.u-searchform-v4 .form-control {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 0.85714rem;
  width: 20rem;
  padding: 0.71429rem 2.85714rem 0.71429rem 0.71429rem;
}

.u-searchform-v4 .form-control::placeholder {
  color: g-color-gray-dark-v4;
  opacity: 1;
}

.u-searchform-v4 .form-control::-webkit-input-placeholder {
  color: g-color-gray-dark-v4;
  opacity: 1;
}

.u-searchform-v4 .form-control::-moz-placeholder {
  color: g-color-gray-dark-v4;
  opacity: 1;
}

.u-searchform-v4 .form-control:not(:last-child) {
  border-right: none;
}

.u-searchform-v4 .input-group-addon {
  border-left: none;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 2;
}

.u-searchform-v4 .input-group-addon button[type="submit"] {
  height: 100%;
  cursor: pointer;
  outline: none;
  border: none;
  background-color: transparent;
}

/*------------------------------------
  Searchform v5
------------------------------------*/
.u-searchform-v5 .input-group {
  border: solid 1px #eee;
}

.u-searchform-v5 .form-control {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 0.85714rem;
  text-transform: uppercase;
  color: #777;
  height: 3.07143rem;
  padding: 0.35714rem 0.35714rem 0.35714rem 2rem;
  border-color: transparent;
}

.u-searchform-v5 .form-control::placeholder {
  color: g-color-gray-dark-v4;
  opacity: 1;
}

.u-searchform-v5 .form-control::-webkit-input-placeholder {
  color: g-color-gray-dark-v4;
  opacity: 1;
}

.u-searchform-v5 .form-control::-moz-placeholder {
  color: g-color-gray-dark-v4;
  opacity: 1;
}

.u-searchform-v5 .form-control:focus {
  border-color: transparent;
}

.u-searchform-v5 .input-group-addon {
  border-color: transparent;
}

.u-searchform-v5 .input-group-addon button[type="submit"] {
  font-size: 1.35714rem;
  height: 100%;
  cursor: pointer;
  outline: none;
  border: none;
  background-color: transparent;
}

/*------------------------------------
  Steps v1
------------------------------------*/
.u-steps-v1 {
  padding: .75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #eee;
  border-radius: .25rem;
}

.u-steps-v1::after {
  content: "";
  display: table;
  width: 100%;
}

.u-steps-v1 > * {
  display: inline-block;
  vertical-align: middle;
}

.u-steps-v1__item {
  float: left;
  margin-right: 15px;
}

.u-steps-v1__item a:hover {
  text-decoration: none;
}

@media (min-width: 768px) {
  .u-steps-v1 {
    padding: 0;
    margin-bottom: 0;
    background-color: transparent;
    border-radius: 0;
  }
  .u-steps-v1__item {
    list-style: none;
    margin-right: 40px;
  }
  .u-steps-v1__item a,
  .u-steps-v1__item span {
    position: relative;
    display: inline-block;
    padding: 10px 15px;
    border-radius: 5px;
    transition-property: background-color;
    transition-duration: .2s;
    transition-timing-function: ease-in;
  }
  .u-steps-v1__item a::before,
  .u-steps-v1__item span::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    display: block;
    width: 70px;
    height: 4px;
    transform: translateY(-50%);
  }
  .u-steps-v1__item a {
    color: #fff;
    background-color: #2cb8f3;
  }
  .u-steps-v1__item a::before {
    background-color: #2cb8f3;
  }
  .u-steps-v1__item a:hover {
    background-color: #333;
  }
  .u-steps-v1__item span {
    background-color: #eee;
  }
  .u-steps-v1__item span::before {
    background-color: #eee;
  }
  .u-steps-v1__item > i {
    font-size: 10px;
  }
  .u-steps-v1__item:last-child {
    margin-right: 0;
  }
  .u-steps-v1__item:last-child a::before,
  .u-steps-v1__item:last-child span::before {
    display: none;
  }
  .u-steps-v1__item + .u-breadcrumb-v1__item::before {
    display: none;
  }
  .u-steps-v1__item.active a,
  .u-steps-v1__item.active span {
    color: #fff;
    background-color: #2cb8f3;
  }
  .u-steps-v1 a:hover {
    text-decoration: none;
  }
}

/*------------------------------------
  Steps v2
------------------------------------*/
.u-steps-v2 {
  padding: .75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #eee;
  border-radius: .25rem;
}

.u-steps-v2::after {
  content: "";
  display: table;
  width: 100%;
}

.u-steps-v2 > * {
  display: inline-block;
  vertical-align: middle;
}

.u-steps-v2__item {
  float: left;
  margin-right: 15px;
}

.u-steps-v2__item a:hover {
  text-decoration: none;
}

@media (min-width: 768px) {
  .u-steps-v2 {
    padding: 0;
    margin-bottom: 0;
    background-color: transparent;
    border-radius: 0;
  }
  .u-steps-v2__indicator {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #2cb8f3;
    box-shadow: 0;
    transform: translateX(-50%);
    transition-property: box-shadow;
    transition-duration: .2s;
    transition-timing-function: ease-in;
  }
  .u-steps-v2__item {
    text-align: center;
    list-style: none;
    margin-right: 40px;
  }
  .u-steps-v2__item a,
  .u-steps-v2__item span {
    position: relative;
    display: block;
    color: #333;
    padding-bottom: 20px;
    transition-property: color;
    transition-duration: .2s;
    transition-timing-function: ease-in;
  }
  .u-steps-v2__item a::before,
  .u-steps-v2__item span::before {
    content: "";
    position: absolute;
    bottom: 4px;
    left: 50%;
    display: block;
    width: 120px;
    height: 4px;
    margin-left: 6px;
  }
  .u-steps-v2__item a::before {
    background-color: #2cb8f3;
  }
  .u-steps-v2__item a .u-steps-v2__indicator {
    background-color: #2cb8f3;
  }
  .u-steps-v2__item a:hover {
    text-decoration: none;
  }
  .u-steps-v2__item span::before {
    background-color: #eee;
  }
  .u-steps-v2__item span .u-steps-v2__indicator {
    background-color: #eee;
  }
  .u-steps-v2__item:last-child a::before,
  .u-steps-v2__item:last-child span::before {
    display: none;
  }
  .u-steps-v2__item:hover a {
    color: #2cb8f3;
  }
  .u-steps-v2__item:hover a .u-steps-v2__indicator {
    box-shadow: 0 0 0 3px rgba(44, 184, 243, 0.3);
  }
  .u-steps-v2__item.active a .u-steps-v2__indicator,
  .u-steps-v2__item.active span .u-steps-v2__indicator {
    background-color: #2cb8f3;
  }
}

/*------------------------------------
  Steps v3
------------------------------------*/
.u-steps-v3 {
  padding: .75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #eee;
  border-radius: .25rem;
}

.u-steps-v3::after {
  content: "";
  display: table;
  width: 100%;
}

.u-steps-v3 > * {
  display: inline-block;
  vertical-align: middle;
}

.u-steps-v3__item {
  float: left;
  margin-right: 15px;
}

.u-steps-v3__item a:hover {
  text-decoration: none;
}

.u-steps-v3__indicator {
  font-style: normal;
}

.u-steps-v3__indicator::after {
  content: " - ";
}

@media (min-width: 768px) {
  .u-steps-v3 {
    padding: 0;
    margin-bottom: 0;
    background-color: transparent;
    border-radius: 0;
  }
  .u-steps-v3__indicator {
    position: absolute;
    top: 0;
    left: 50%;
    width: 26px;
    height: 26px;
    line-height: 26px;
    text-align: center;
    background-color: #2cb8f3;
    box-shadow: 0;
    border-radius: 50%;
    transform: translateX(-50%);
    transition-property: box-shadow;
    transition-duration: .2s;
    transition-timing-function: ease-in;
  }
  .u-steps-v3__indicator::after {
    display: none;
  }
  .u-steps-v3__item {
    text-align: center;
    float: left;
    list-style: none;
    margin-right: 40px;
  }
  .u-steps-v3__item a,
  .u-steps-v3__item span {
    position: relative;
    display: block;
    color: #333;
    padding-top: 40px;
    transition-property: color;
    transition-duration: .2s;
    transition-timing-function: ease-in;
  }
  .u-steps-v3__item a::before,
  .u-steps-v3__item span::before {
    content: "";
    position: absolute;
    top: 11px;
    left: 50%;
    display: block;
    width: 120px;
    height: 4px;
    margin-left: 13px;
  }
  .u-steps-v3__item a::before {
    background-color: #2cb8f3;
  }
  .u-steps-v3__item a .u-steps-v3__indicator {
    color: #fff;
    background-color: #2cb8f3;
  }
  .u-steps-v3__item a:hover {
    text-decoration: none;
  }
  .u-steps-v3__item span::before {
    background-color: #eee;
  }
  .u-steps-v3__item span .u-steps-v3__indicator {
    color: #333;
    background-color: #eee;
  }
  .u-steps-v3__item:last-child a::before,
  .u-steps-v3__item:last-child span::before {
    display: none;
  }
  .u-steps-v3__item:hover a {
    color: #2cb8f3;
  }
  .u-steps-v3__item:hover a .u-steps-v3__indicator {
    box-shadow: 0 0 0 3px rgba(44, 184, 243, 0.3);
  }
  .u-steps-v3__item.active a .u-steps-v3__indicator,
  .u-steps-v3__item.active span .u-steps-v3__indicator {
    color: #fff;
    background-color: #2cb8f3;
  }
}

/*------------------------------------
  Time Lines
------------------------------------*/
[class*="u-timeline-v1-wrap"],
[class*="u-timeline-v2-wrap"],
[class*="u-timeline-v3-wrap"] {
  position: relative;
}

[class*="u-timeline-v1-wrap"]::before,
[class*="u-timeline-v2-wrap"]::before,
[class*="u-timeline-v3-wrap"]::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  display: block;
}

/*------------------------------------
  Time Lines v1
------------------------------------*/
.u-timeline-v1 {
  position: relative;
  border-width: 1px;
  border-style: solid;
  border-color: #eee;
}

.u-timeline-v1-wrap::before {
  left: 0;
  width: 1px;
  height: 100%;
  background-color: #f7f7f7;
  margin-left: 20px;
}

.u-timeline-v1__icon {
  position: absolute;
  top: 30px;
  font-size: 20px;
  z-index: 3;
}

.g-orientation-bottom .u-timeline-v1__icon,
.g-orientation-right .u-timeline-v1__icon,
.g-orientation-left .u-timeline-v1__icon {
  left: 0;
}

@media (min-width: 768px) {
  .u-timeline-v1-wrap:not([class*="--horizontal"])::before {
    left: 50%;
    margin-left: -2px;
  }
  .u-timeline-v1-wrap--horizontal::before {
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 3px;
    margin-left: 0;
    margin-top: -2px;
  }
  .g-orientation-bottom .u-timeline-v1__icon {
    top: 100%;
    left: 0;
    transform: translateX(-50%) translateY(-50%);
  }
  .g-orientation-left .u-timeline-v1__icon {
    right: 100%;
    left: auto;
  }
  .g-orientation-right .u-timeline-v1__icon {
    right: auto;
    left: 100%;
  }
}

/*------------------------------------
  Time Lines v2
------------------------------------*/
@media (min-width: 768px) {
  .u-timeline-v2-wrap::before {
    left: 25%;
    width: 3px;
    height: 100%;
    margin-left: -1px;
    background-color: #f7f7f7;
  }
  .u-timeline-v2__icon {
    font-size: 16px;
    position: absolute;
  }
  .g-orientation-right .u-timeline-v2__icon {
    left: 100%;
    transform: translateX(-50%);
  }
  .g-orientation-left .u-timeline-v2__icon {
    right: 100%;
    transform: translateX(50%);
  }
  .g-orientation-bottom .u-timeline-v2__icon {
    top: 100%;
    transform: translateY(-50%);
  }
}

/*------------------------------------
  Time Lines v3
------------------------------------*/
.u-timeline-v3-wrap::before {
  left: 21%;
  width: 1px;
  height: 100%;
  display: none;
  background-color: #eee;
}

.u-timeline-v3 {
  position: relative;
}

.u-timeline-v3__icon {
  left: 21%;
}

@media (min-width: 768px) {
  .u-timeline-v3-wrap::before {
    display: block;
  }
}

@media (min-width: 992px) {
  .u-timeline-v3-wrap::before {
    left: 18%;
  }
  .u-timeline-v3__icon {
    left: 18%;
  }
}

/*------------------------------------
  Background Colors
------------------------------------*/
.u-label {
  display: inline-block;
  padding: .35rem .58rem;
  font-size: .9rem;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  color: #fff;
  /* Label Size
  ------------------------------------*/
  /* Label Styles
  ------------------------------------*/
  /* Label Num
  ------------------------------------*/
}

.u-label:empty {
  display: none;
}

.btn .u-label {
  position: relative;
  top: -1px;
}

.u-label-default {
  background-color: #777;
}

.u-label-default[href]:hover, .u-label-default[href]:focus {
  background-color: #555;
}

.u-label-primary {
  background-color: #5cb85c;
}

.u-label-primary[href]:hover, .u-label-primary[href]:focus {
  background-color: #55b555;
}

.u-label-success {
  background-color: #5cb85c;
}

.u-label-success[href]:hover, .u-label-success[href]:focus {
  background-color: #55b555;
}

.u-label-info {
  background-color: #5bc0de;
}

.u-label-info[href]:hover, .u-label-info[href]:focus {
  background-color: #53bddc;
}

.u-label-warning {
  background-color: #f0ad4e;
}

.u-label-warning[href]:hover, .u-label-warning[href]:focus {
  background-color: #efa945;
}

.u-label-danger {
  background-color: #d9534f;
}

.u-label-danger[href]:hover, .u-label-danger[href]:focus {
  background-color: #d74b47;
}

.u-label.g-rounded-10 {
  padding: .35rem .7rem;
}

.u-label--sm {
  font-size: .8rem;
}

.u-label--lg {
  font-size: 1.1rem;
}

.u-label.u-label-with-icon {
  padding: .5rem .85rem;
}

.u-label.u-label-with-icon i {
  margin-right: .5rem;
}

.u-label-num {
  min-width: 2rem;
  height: 2rem;
  padding: 0 .35rem;
  line-height: 2rem;
}

.u-label-num.u-label--sm {
  min-width: 1.6rem;
  height: 1.6rem;
  line-height: 1.6rem;
}

.u-label-num.u-label--lg {
  min-width: 2.2rem;
  height: 2.2rem;
  line-height: 2.2rem;
}

/*------------------------------------
  Link Styles
------------------------------------*/
.u-link-v1 {
  border-bottom: solid 1px;
}

.u-link-v1:hover, .u-link-v1:focus {
  border-bottom: none;
  text-decoration: none;
}

.u-link-v2 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-indent: -100%;
  white-space: nowrap;
  overflow: hidden;
  z-index: 2;
}

.u-link-v3 {
  text-decoration: underline;
}

.u-link-v3:hover {
  text-decoration: none;
}

.u-link-v4 {
  transition: all .3s;
}

.u-link-v4:hover {
  opacity: .8;
}

.u-link-v5 {
  text-decoration: none;
  transition: all .2s;
}

.u-link-v5:hover, .u-link-v5:focus {
  text-decoration: none;
}

.u-link-v6 {
  display: block;
  overflow: hidden;
}

.u-link-v6-arrow {
  margin-left: -50px;
  visibility: hidden;
  opacity: 0;
  transition: all .4s;
}

.u-link .u-block-hover:hover-v6-arrow, .u-link-v6:hover-v6-arrow {
  margin-left: 5px;
  visibility: visible;
  opacity: 1;
}

.u-link-v7 {
  margin-right: 30px;
  transition: all .4s;
}

.u-link-v7-arrow {
  margin-left: -50px;
  visibility: hidden;
  opacity: 0;
  transition: all .4s;
}

.u-link .u-block-hover:hover, .u-link-v7:hover {
  margin-right: 0;
}

.u-link .u-block-hover:hover-v7-arrow, .u-link-v7:hover-v7-arrow {
  margin-left: 10px;
  visibility: visible;
  opacity: 1;
}

/*------------------------------------
  List Styles
------------------------------------*/
.u-list-inline {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.img-bordered {
  border: solid 6px #777;
}

.g-order-1 {
  order: 1;
}

.g-order-2 {
  order: 2;
}

@media (min-width: 576px) {
  .g-order-1--sm {
    order: 1;
  }
  .g-order-2--sm {
    order: 2;
  }
}

@media (min-width: 768px) {
  .g-order-1--md {
    order: 1;
  }
  .g-order-2--md {
    order: 2;
  }
}

@media (min-width: 992px) {
  .g-order-1--lg {
    order: 1;
  }
  .g-order-2--lg {
    order: 2;
  }
}

/*------------------------------------
  Paginations
------------------------------------*/
/* Pagination v1 */
.u-pagination-v1__item {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  border: solid 1px transparent;
  transition: all .3s ease;
}

.u-pagination-v1__item--active, .u-pagination-v1__item:hover, .u-pagination-v1__item:focus {
  text-decoration: none;
  cursor: pointer;
}

.u-pagination-v1__item-info {
  display: inline-block;
  text-align: center;
  text-decoration: none;
}

.u-pagination-v1__item--disabled {
  opacity: .5;
  pointer-events: none;
}

/* Pagination Style v1 */
.u-pagination-v1-1 {
  color: #999;
  border-color: #999;
}

.u-pagination-v1-1--active, .u-pagination-v1-1:hover, .u-pagination-v1-1:focus {
  background-color: #2cb8f3;
  color: #fff;
  border-color: #2cb8f3;
}

/* Pagination Style v2 */
.u-pagination-v1-2 {
  color: #555;
  border-color: #555;
}

.u-pagination-v1-2:hover, .u-pagination-v1-2:focus {
  color: #2cb8f3;
  border-color: #2cb8f3;
}

.u-pagination-v1-2--active, .u-pagination-v1-2--nav {
  background-color: #2cb8f3;
  color: #fff;
  border-color: #2cb8f3;
}

.u-pagination-v1-2--active:hover, .u-pagination-v1-2--active:focus, .u-pagination-v1-2--nav:hover, .u-pagination-v1-2--nav:focus {
  color: #fff;
}

.u-pagination-v1-2--nav:hover {
  background-color: rgba(44, 184, 243, 0.8);
}

/* Pagination Style v3 */
.u-pagination-v1-3 {
  color: #333;
  border-color: #333;
}

.u-pagination-v1-3--active, .u-pagination-v1-3:hover, .u-pagination-v1-3:focus {
  background-color: #333;
  color: #fff;
  border-color: #333;
}

/* Pagination Style v4 */
.u-pagination-v1-4 {
  color: #333;
  border-color: transparent;
}

.u-pagination-v1-4:hover, .u-pagination-v1-4:focus {
  color: #2cb8f3;
  border-color: #2cb8f3;
}

.u-pagination-v1-4--active {
  color: #fff;
  background-color: #2cb8f3;
  border-color: #2cb8f3;
}

.u-pagination-v1-4--active:hover, .u-pagination-v1-4--active:focus {
  color: #fff;
}

/* Pagination Style v5 */
.u-pagination-v1-5 {
  color: #999;
  border-color: #ccc;
}

.u-pagination-v1-5--active, .u-pagination-v1-5:hover, .u-pagination-v1-5:focus {
  background-color: #2cb8f3;
  color: #fff;
  border-color: #2cb8f3;
}

/*------------------------------------
  Ribbons
------------------------------------*/
.u-ribbon-v1, .u-ribbon-v2 {
  position: absolute;
  font-size: .9rem;
}

.u-ribbon-center {
  left: 50%;
  transform: translateX(-50%);
}

.u-ribbon--sm {
  font-size: .7rem;
}

.u-ribbon--lg {
  font-size: 1.1rem;
}

/* Ribbon Style Type 1
------------------------------------*/
.u-ribbon-v1 {
  display: inline-block;
  padding: .35rem .7rem;
  /* Ribbon Type 1 With icon */
}

.u-ribbon-v1.u-ribbon-with-icon {
  padding: .5rem .85rem;
}

.u-ribbon-v1 .u-ribbon-icon--left {
  margin-right: .5rem;
}

.u-ribbon-v1 .u-ribbon-icon--right {
  margin-left: .5rem;
}

/* Ribbon Style Type 2
------------------------------------*/
.u-ribbon-v2 {
  display: inline-block;
  padding: 1rem 1.1rem;
}

.u-ribbon-v2.u-ribbon--sm {
  padding: 1rem 1.2rem;
}

.u-ribbon-v2.u-ribbon--lg {
  padding: 1.2rem 1.1rem;
}

/* Bookmarked Ribbon
------------------------------------*/
.u-ribbon-bookmark::after {
  content: "";
  position: absolute;
  bottom: -1.1rem;
  left: 0;
  width: 100%;
  height: 0;
  border-style: solid;
  border-left-width: 1.5rem;
  border-right-width: 1.5rem;
  border-bottom: 1rem solid transparent !important;
}

.u-ribbon--lg.u-ribbon-bookmark::after {
  border-right-width: 1.4rem;
}

/* Ribbon Colors
------------------------------------*/
.u-ribbon-bookmark.g-bg-primary::after {
  border-color: #2cb8f3;
}

.u-ribbon-bookmark.g-bg-black::after {
  border-color: #000;
}

.u-ribbon-bookmark.g-bg-white::after {
  border-color: #fff;
}

.u-ribbon-bookmark.g-bg-light-opacity::after {
  border-color: rgba(255, 255, 255, 0.7);
}

.u-ribbon-bookmark.g-bg-dark-opacity::after {
  border-color: rgba(30, 30, 30, 0.7);
}

.u-ribbon-bookmark.g-color-gray-light-v3::after {
  border-color: #ddd;
}

.u-ribbon-bookmark.g-color-gray-light-v4::after {
  border-color: #eee;
}

.u-ribbon-bookmark.g-color-gray-dark-v5::after {
  border-color: #999;
}

.u-ribbon-bookmark.g-bg-green::after {
  border-color: #72c02c;
}

.u-ribbon-bookmark.g-bg-blue::after {
  border-color: #3398dc;
}

.u-ribbon-bookmark.g-bg-lightblue::after {
  border-color: #edf2f8;
}

.u-ribbon-bookmark.g-bg-lightblue-v1::after {
  border-color: #d6e2ee;
}

.u-ribbon-bookmark.g-bg-darkblue::after {
  border-color: #009;
}

.u-ribbon-bookmark.g-bg-indigo::after {
  border-color: #4263a3;
}

.u-ribbon-bookmark.g-bg-red::after {
  border-color: #f00;
}

.u-ribbon-bookmark.g-bg-lightred::after {
  border-color: #e64b3b;
}

.u-ribbon-bookmark.g-bg-darkred::after {
  border-color: #a10f2b;
}

.u-ribbon-bookmark.g-bg-purple::after {
  border-color: #9a69cb;
}

.u-ribbon-bookmark.g-bg-darkpurple::after {
  border-color: #6639b6;
}

.u-ribbon-bookmark.g-bg-pink::after {
  border-color: #e81c62;
}

.u-ribbon-bookmark.g-bg-orange::after {
  border-color: #a10f2b;
}

.u-ribbon-bookmark.g-bg-deeporange::after {
  border-color: #fe541e;
}

.u-ribbon-bookmark.g-bg-yellow::after {
  border-color: #a10f2b;
}

.u-ribbon-bookmark.g-bg-aqua::after {
  border-color: #29d6e6;
}

.u-ribbon-bookmark.g-bg-cyan::after {
  border-color: #00bed6;
}

.u-ribbon-bookmark.g-bg-teal::after {
  border-color: #18ba9b;
}

.u-ribbon-bookmark.g-bg-brown::after {
  border-color: #a10f2b;
}

.u-ribbon-bookmark.g-bg-bluegray::after {
  border-color: #585f69;
}

/* Clipped-v1 Ribbon
------------------------------------*/
.u-ribbon-clip-v1::before,
.u-ribbon-clip-v2::before {
  content: "";
  position: absolute;
  bottom: -0.71429rem;
  border-style: solid;
}

.u-ribbon--left.u-ribbon-clip-v1::before,
.u-ribbon--left.u-ribbon-clip-v2::before {
  left: 0;
  border-width: 0 0.71429rem 0.71429rem 0;
  border-right-color: #999;
}

.u-ribbon--right.u-ribbon-clip-v1::before,
.u-ribbon--right.u-ribbon-clip-v2::before {
  right: 0;
  border-width: 0 0 0.71429rem 0.71429rem;
  border-left-color: #999;
}

/* Clipped-v2 Ribbon
------------------------------------*/
.u-ribbon-clip-v2 {
  padding: .35rem 1.35rem;
  transform: skewX(-10deg) translateZ(1px);
}

.u-ribbon-clip-v2.u-ribbon-with-icon {
  padding: .5rem 1.35rem;
}

.u-ribbon-clip-v2__inner {
  display: inline-block;
  transform: skewX(10deg) translateZ(1px);
}

.u-ribbon-clip-v2::before {
  transform: skewX(10deg);
}

.u-ribbon--left.u-ribbon-clip-v2::before {
  left: 0.07143rem;
}

.u-ribbon--right.u-ribbon-clip-v2::before {
  right: -0.07143rem;
}

/* Clipped-v3 Ribbon
------------------------------------*/
.u-ribbon-clip-v3 {
  width: calc(100% + 20px);
  text-align: center;
}

.u-ribbon-clip-v3::before,
.u-ribbon-clip-v3::after {
  content: "";
  position: absolute;
}

.u-ribbon-clip-v3::before {
  left: 0;
  bottom: -0.71429rem;
  width: 0;
  height: 0;
  border-top: 10px solid #999;
  border-left: 10px solid transparent;
}

.u-ribbon-clip-v3::after {
  right: 0;
  bottom: -0.71429rem;
  width: 0;
  height: 0;
  border-top: 10px solid #999;
  border-right: 10px solid transparent;
}

/* Clipped-v4 Ribbon
------------------------------------*/
.u-ribbon-clip-v4 {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.u-ribbon-clip-v4::before,
.u-ribbon-clip-v4::after {
  content: "";
  position: absolute;
}

.u-ribbon-clip-v4::before {
  top: 0;
  width: 2.85714rem;
  height: 0.42857rem;
  background: #999;
  border-radius: 0.57143rem 0.57143rem 0 0;
}

.u-ribbon-clip-v4.u-ribbon--left::before {
  left: 7.14286rem;
}

.u-ribbon-clip-v4.u-ribbon--right::before {
  right: 7.14286rem;
}

.u-ribbon-clip-v4::after {
  top: 7.14286rem;
  width: 0.42857rem;
  height: 2.85714rem;
  background: #999;
}

.u-ribbon-clip-v4.u-ribbon--left::after {
  left: 0;
  border-radius: 0.57143rem 0 0 0.57143rem;
}

.u-ribbon-clip-v4.u-ribbon--right::after {
  right: 0;
  border-radius: 0 0.57143rem 0.57143rem 0;
}

.u-ribbon-clip-v4__inner {
  position: absolute;
  top: 2.14286rem;
  width: 14.28571rem;
  height: 2.85714rem;
  line-height: 2.85714rem;
  overflow: hidden;
  transform: rotate(45deg);
  text-align: center;
  z-index: 2;
}

.u-ribbon--left .u-ribbon-clip-v4__inner {
  left: -3.57143rem;
  transform: rotate(-45deg);
}

.u-ribbon--right .u-ribbon-clip-v4__inner {
  right: -3.57143rem;
  transform: rotate(45deg);
}

.u-ribbon-clip-v4__inner--bordered {
  border: 1px dashed rgba(255, 255, 255, 0.7);
}

.u-ribbon-clip-v4__inner.g-bg-primary {
  box-shadow: 0 0 0 3px #2cb8f3, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-black {
  box-shadow: 0 0 0 3px #000, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-white {
  box-shadow: 0 0 0 3px #fff, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-light-opacity {
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.7), 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-dark-opacity {
  box-shadow: 0 0 0 3px rgba(30, 30, 30, 0.7), 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-color-gray-light-v3 {
  box-shadow: 0 0 0 3px #ddd, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-color-gray-light-v4 {
  box-shadow: 0 0 0 3px #eee, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-color-gray-dark-v5 {
  box-shadow: 0 0 0 3px #999, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-green {
  box-shadow: 0 0 0 3px #72c02c, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-blue {
  box-shadow: 0 0 0 3px #3398dc, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-lightblue {
  box-shadow: 0 0 0 3px #edf2f8, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-lightblue-v1 {
  box-shadow: 0 0 0 3px #d6e2ee, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-darkblue {
  box-shadow: 0 0 0 3px #009, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-indigo {
  box-shadow: 0 0 0 3px #4263a3, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-red {
  box-shadow: 0 0 0 3px #f00, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-lightred {
  box-shadow: 0 0 0 3px #e64b3b, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-darkred {
  box-shadow: 0 0 0 3px #a10f2b, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-purple {
  box-shadow: 0 0 0 3px #9a69cb, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-darkpurple {
  box-shadow: 0 0 0 3px #6639b6, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-pink {
  box-shadow: 0 0 0 3px #e81c62, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-orange {
  box-shadow: 0 0 0 3px #a10f2b, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-deeporange {
  box-shadow: 0 0 0 3px #fe541e, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-yellow {
  box-shadow: 0 0 0 3px #a10f2b, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-aqua {
  box-shadow: 0 0 0 3px #29d6e6, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-cyan {
  box-shadow: 0 0 0 3px #00bed6, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-teal {
  box-shadow: 0 0 0 3px #18ba9b, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-brown {
  box-shadow: 0 0 0 3px #a10f2b, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-bluegray {
  box-shadow: 0 0 0 3px #585f69, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

/*------------------------------------
  Animation on the scroll
------------------------------------*/
[data-animation]:not(.u-in-viewport) {
  visibility: hidden;
}

[data-animation].js-carousel {
  visibility: visible;
}

.u-in-viewport {
  visibility: visible;
}

/*------------------------------------
  Stickers
------------------------------------*/
.u-sticker {
  position: absolute;
}

/* Sticker Position
------------------------------------*/
.u-sticker-center {
  left: 50%;
  transform: translateX(-50%);
}

/*------------------------------------
  Sticky blocks
------------------------------------*/
[class*="sticky-block"] {
  max-width: 100%;
}

/*------------------------------------
  Tables
------------------------------------*/
[class*="text"][class*="center"] td,
[class*="text"][class*="center"] th {
  text-align: center;
}

.u-table--v1 td,
.u-table--v1 th {
  padding: 8px;
}

.u-table--v1 thead th {
  border-bottom-width: 1px;
}

.u-table--v2 td,
.u-table--v2 th {
  padding: 15px;
}

.u-table--v2 thead th {
  background-color: inherit;
  border-bottom-width: 1px;
}

.g-col-border-top-0 td,
.g-col-border-top-0 th {
  border-top-width: 0;
}

.g-col-border-side-0 td,
.g-col-border-side-0 th {
  border-left-width: 0;
  border-right-width: 0;
}

/*------------------------------------
  Tabs
------------------------------------*/
/* Colors
------------------------------------*/
.g-color-primary--active.active {
  color: #2cb8f3;
}

.g-color-black--active.active {
  color: #000;
}

.g-color-gray-dark-v2--active.active {
  color: #333;
}

.g-color-white--active.active {
  color: #fff;
}

/* Style for Icons
------------------------------------*/
.u-tab-line-icon-pro {
  position: relative;
  top: 2px;
}

/* HZ
------------------------------------*/
.nav-item > a,
.nav-item > .nav-link,
[class*="u-tab-link"]:not([class*="-icon"]) {
  transition-property: color, background-color, border-color;
  transition-duration: .2s;
  transition-timing-function: ease-in;
}

[role="tablist"]:not([data-tabs-mobile-type="slide-up-down"]):not(
[data-tabs-mobile-type="accordion"]):not(
[data-scroll]) {
  display: block;
}

[role="tablist"]  .nav-item {
  display: inline-block;
}

[role="tablist"]  .js-tabs-mobile {
  position: relative;
  display: none;
}

[role="tablist"]  .js-tabs-mobile-control {
  position: relative;
  display: block;
}

[role="tablist"]  .js-tabs-mobile-control::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
  width: 0;
  height: 0;
  border-width: 5px 3.5px 0 3.5px;
  border-style: solid;
  border-color: #777 transparent transparent transparent;
  margin-top: -1px;
}

[role="tablist"]  .js-tabs-mobile .nav-inner {
  position: absolute;
  top: calc(100% + 1px);
  right: 0;
  display: none;
  background-color: #fff;
  border: 1px solid;
  padding-left: 0;
}

[role="tablist"]  .js-tabs-mobile .nav-inner .nav-item {
  display: block;
  white-space: nowrap;
}

[data-scroll]:not([data-tabs-mobile-type="slide-up-down"]):not(
[data-tabs-mobile-type="accordion"]) {
  width: 100%;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
}

[data-scroll] ::-webkit-scrollbar {
  display: none;
}

@-moz-document url-prefix() {
  [data-scroll]:not([data-tabs-mobile-type="slide-up-down"]):not(
  [data-tabs-mobile-type="accordion"]) {
    padding-bottom: 15px;
  }
}

@media (min-width: 768px) {
  [data-scroll] {
    width: 100%;
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    flex-wrap: nowrap;
  }
  [data-scroll]::-webkit-scrollbar {
    display: none;
  }
  @-moz-document url-prefix() {
    [data-scroll] {
      padding-bottom: 15px;
    }
  }
}

/*------------------------------------
  Tabs v1
------------------------------------*/
/* Tabs v1
------------------------------------*/
@media (min-width: 768px) {
  [class*="u-nav-v1"].u-nav-rounded-3 .nav-link {
    border-radius: 3px;
  }
  [class*="u-nav-v1"].u-nav-rounded-5 .nav-link {
    border-radius: 5px;
  }
  [class*="u-nav-v1"].u-nav-rounded-7 .nav-link {
    border-radius: 7px;
  }
  [class*="u-nav-v1"].u-nav-rounded-10 .nav-link {
    border-radius: 10px;
  }
  .u-nav-v1-1 .nav-link.active {
    background-color: #eee;
  }
  .u-nav-v1-1.u-nav-primary .nav-link.active {
    color: #fff;
    background-color: #2cb8f3;
  }
  .u-nav-v1-1.u-nav-dark .nav-link.active {
    color: #fff;
    background-color: #333;
  }
  .u-nav-v1-1.u-nav-light .nav-link {
    color: #fff;
  }
  .u-nav-v1-1.u-nav-light .nav-link.active {
    color: #333;
    background-color: #fff;
  }
  .u-nav-v1-2 .nav-link {
    border: solid 1px transparent;
  }
  .u-nav-v1-2 .nav-link.active {
    border-color: #eee;
  }
  .u-nav-v1-2.u-nav-primary .nav-link.active {
    border-color: #2cb8f3;
  }
  .u-nav-v1-2.u-nav-dark .nav-link.active {
    border-color: #333;
  }
  .u-nav-v1-2.u-nav-light .nav-link {
    color: #fff;
  }
  .u-nav-v1-2.u-nav-light .nav-link.active {
    border-color: #fff;
  }
}

/*------------------------------------
  Tabs v2
------------------------------------*/
@media (min-width: 768px) {
  [class*="u-nav-v2"] .nav-link {
    border-style: solid;
    border-color: #eee;
    border-width: 1px;
    margin-left: -1px;
  }
  [class*="u-nav-v2"] .nav-item:first-child .nav-link {
    margin-left: 0;
  }
  [class*="u-nav-v2"].flex-column .nav-link {
    margin: -1px 0 0;
  }
  [class*="u-nav-v2"].flex-column .nav-item:first-child .nav-link {
    margin-top: 0;
  }
  [class*="u-nav-v2"].u-nav-primary .nav-link {
    border-color: #2cb8f3;
  }
  [class*="u-nav-v2"].u-nav-primary .nav-link.active,
  [class*="u-nav-v2"].u-nav-primary .cbp-filter-item-active .nav-link {
    border-color: #2cb8f3 !important;
  }
  [class*="u-nav-v2"].u-nav-dark .nav-link {
    border-color: #333;
  }
  [class*="u-nav-v2"].u-nav-dark .nav-link.active,
  [class*="u-nav-v2"].u-nav-dark .cbp-filter-item-active .nav-link {
    border-color: #333 !important;
  }
  [class*="u-nav-v2"].u-nav-light .nav-link {
    color: #fff;
    border-color: #fff;
  }
  [class*="u-nav-v2"].u-nav-light .nav-link.active,
  [class*="u-nav-v2"].u-nav-light .cbp-filter-item-active .nav-link {
    border-color: #fff !important;
  }
  [class*="u-nav-v2"].u-nav-rounded-3 .nav-item:first-child .nav-link {
    border-radius: 3px 0 0 3px;
  }
  [class*="u-nav-v2"].u-nav-rounded-3 .nav-item:last-child .nav-link {
    border-radius: 0 3px 3px 0;
  }
  [class*="u-nav-v2"].u-nav-rounded-3.flex-column .nav-item:first-child .nav-link {
    border-radius: 3px 3px 0 0;
  }
  [class*="u-nav-v2"].u-nav-rounded-3.flex-column .nav-item:last-child .nav-link {
    border-radius: 0 0 3px 3px;
  }
  [class*="u-nav-v2"].u-nav-rounded-5 .nav-item:first-child .nav-link {
    border-radius: 5px 0 0 5px;
  }
  [class*="u-nav-v2"].u-nav-rounded-5 .nav-item:last-child .nav-link {
    border-radius: 0 5px 5px 0;
  }
  [class*="u-nav-v2"].u-nav-rounded-5.flex-column .nav-item:first-child .nav-link {
    border-radius: 5px 5px 0 0;
  }
  [class*="u-nav-v2"].u-nav-rounded-5.flex-column .nav-item:last-child .nav-link {
    border-radius: 0 0 5px 5px;
  }
  [class*="u-nav-v2"].u-nav-rounded-7 .nav-item:first-child .nav-link {
    border-radius: 7px 0 0 7px;
  }
  [class*="u-nav-v2"].u-nav-rounded-7 .nav-item:last-child .nav-link {
    border-radius: 0 7px 7px 0;
  }
  [class*="u-nav-v2"].u-nav-rounded-7.flex-column .nav-item:first-child .nav-link {
    border-radius: 7px 7px 0 0;
  }
  [class*="u-nav-v2"].u-nav-rounded-7.flex-column .nav-item:last-child .nav-link {
    border-radius: 0 0 7px 7px;
  }
  [class*="u-nav-v2"].u-nav-rounded-10 .nav-item:first-child .nav-link {
    border-radius: 10px 0 0 10px;
  }
  [class*="u-nav-v2"].u-nav-rounded-10 .nav-item:last-child .nav-link {
    border-radius: 0 10px 10px 0;
  }
  [class*="u-nav-v2"].u-nav-rounded-10.flex-column .nav-item:first-child .nav-link {
    border-radius: 10px 10px 0 0;
  }
  [class*="u-nav-v2"].u-nav-rounded-10.flex-column .nav-item:last-child .nav-link {
    border-radius: 0 0 10px 10px;
  }
  .u-nav-v2-1 .nav-link.active,
  .u-nav-v2-1 .cbp-filter-item-active .nav-link {
    background-color: #eee;
  }
  .u-nav-v2-1.u-nav-primary .nav-link.active,
  .u-nav-v2-1.u-nav-primary .cbp-filter-item-active .nav-link {
    color: #fff;
    background-color: #2cb8f3;
  }
  .u-nav-v2-1.u-nav-dark .nav-link.active,
  .u-nav-v2-1.u-nav-dark .nav-link.cbp-filter-item-active {
    color: #fff;
    background-color: #333;
  }
  .u-nav-v2-1.u-nav-light .nav-link.active,
  .u-nav-v2-1.u-nav-light .nav-link.cbp-filter-item-active {
    color: #333;
    background-color: #fff;
  }
  .u-nav-v2-2 .nav-link.active,
  .u-nav-v2-2 .cbp-filter-item-active .nav-link {
    color: #2cb8f3;
  }
}

/*------------------------------------
  Tabs v3
------------------------------------*/
@media (min-width: 768px) {
  [class*="u-nav-v3"] .nav-link {
    border-style: solid;
    border-width: 1px 0;
    border-color: #eee;
  }
  [class*="u-nav-v3"].flex-column .nav-link {
    margin: -1px 0 0;
  }
  [class*="u-nav-v3"].flex-column .nav-item:first-child .nav-link {
    margin-top: 0;
  }
  [class*="u-nav-v3"] .nav-link.active {
    color: #2cb8f3;
  }
  [class*="u-nav-v3"].u-nav-light .nav-link {
    color: #fff;
    border-color: #fff;
  }
  [class*="u-nav-v3"].u-nav-light .nav-link.active {
    color: #2cb8f3;
    border-color: #fff !important;
  }
  .u-nav-v3-2 .nav-link {
    z-index: 1;
  }
  .u-nav-v3-2 .nav-link.active {
    border-color: #2cb8f3;
    z-index: 2;
    position: relative;
  }
}

/*------------------------------------
  Tabs v4
------------------------------------*/
@media (min-width: 768px) {
  .u-nav-v4-1 {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #eee;
  }
  .u-nav-v4-1 .nav-item {
    margin-bottom: -1px;
  }
  .u-nav-v4-1 .nav-link {
    border-style: solid;
    border-width: 2px 1px 1px;
    border-color: transparent;
  }
  .u-nav-v4-1 .nav-link.active {
    border-color: #ccc #eee #fff;
  }
  .u-nav-v4-1.u-nav-light .nav-link {
    color: #fff;
  }
  .u-nav-v4-1.u-nav-light .nav-link.active {
    color: #fff;
    border-color: #ccc #eee transparent;
    background-color: #333;
  }
  .u-nav-v4-1.u-nav-light.flex-column .nav-link.active {
    border-color: #ccc transparent #ccc #eee;
    border-width: 1px 0 1px 1px;
    background-color: #333;
  }
  .u-nav-v4-1.u-nav-primary .nav-link.active {
    border-color: #2cb8f3 #eee #fff;
  }
  .u-nav-v4-1.u-nav-dark .nav-link.active {
    border-color: #333 #eee #fff;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .u-nav-v4-1-column {
    display: flex;
  }
  .u-nav-v4-1-column .u-nav-v4-1 {
    border-bottom: none;
  }
  .u-nav-v4-1-column .u-nav-v4-1 .nav-item {
    margin: 0 -1px 0 0;
    z-index: 2;
  }
  .u-nav-v4-1-column .u-nav-v4-1 .nav-link {
    border-style: solid;
    border-width: 1px 1px 1px 2px;
    border-color: transparent;
  }
  .u-nav-v4-1-column .u-nav-v4-1 .nav-link.active {
    border-color: #eee #fff #eee #ccc;
  }
  .u-nav-v4-1-column .u-nav-v4-1.u-nav-primary .nav-link.active {
    border-color: #eee #fff #eee #2cb8f3;
  }
  .u-nav-v4-1-column .u-nav-v4-1.u-nav-dark .nav-link.active {
    border-color: #eee #fff #eee #333;
  }
  .u-nav-v4-1-column .nav {
    width: 30%;
  }
  .u-nav-v4-1-column .tab-content {
    width: 70%;
  }
}

/*------------------------------------
  Tabs v5
------------------------------------*/
@media (min-width: 768px) {
  [class*="u-nav-v5"] .nav-item {
    margin-bottom: -1px;
  }
  [class*="u-nav-v5"] .nav-link {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: transparent;
  }
  [class*="u-nav-v5"] .nav-link.active {
    border-color: #ccc;
  }
  [class*="u-nav-v5"].u-nav-primary .nav-link.active {
    border-color: #2cb8f3;
    color: #2cb8f3;
  }
  [class*="u-nav-v5"].u-nav-dark .nav-link.active {
    border-color: #333;
    color: #333;
  }
  [class*="u-nav-v5"].u-nav-light .nav-link {
    color: #fff;
  }
  [class*="u-nav-v5"].u-nav-light .nav-link.active {
    color: #fff;
    border-color: #fff;
  }
  .u-nav-v5-2 .nav-link {
    border-bottom-width: 2px;
  }
  .u-nav-v5-3 .nav-link {
    border-bottom-width: 5px;
  }
}

/*------------------------------------
  Tabs v6
------------------------------------*/
@media (min-width: 768px) {
  [class*="u-nav-v6"] .nav-link {
    position: relative;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: transparent;
    transition: none;
  }
  [class*="u-nav-v6"] .nav-link:after, [class*="u-nav-v6"] .nav-link:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  [class*="u-nav-v6"] .nav-link:after {
    border-top-color: #fff;
    border-width: 4px;
    margin-left: -4px;
  }
  [class*="u-nav-v6"] .nav-link:before {
    border-width: 6px;
    margin-left: -6px;
  }
  [class*="u-nav-v6"] .nav-link.active {
    border-color: #2cb8f3;
  }
  [class*="u-nav-v6"] .nav-link.active::after, [class*="u-nav-v6"] .nav-link.active::before {
    opacity: 1;
  }
  [class*="u-nav-v6"] .nav-link.active::before {
    border-top-color: #2cb8f3;
  }
  [class*="u-nav-v6"].u-nav-light .nav-link {
    color: #fff;
  }
  [class*="u-nav-v6"].u-nav-light .nav-link::after {
    border-top-color: #333;
  }
  [class*="u-nav-v6"].u-nav-light .nav-link.active {
    color: #fff;
    border-color: #fff;
  }
  [class*="u-nav-v6"].u-nav-light .nav-link.active:before {
    border-top-color: #fff;
  }
  [class*="u-nav-v6"].u-nav-light .nav-link.active::after {
    border-top-color: #333;
  }
  [class*="u-nav-v6"].flex-column .nav-link {
    border-bottom: none;
    border-right-style: solid;
    border-right-width: 2px;
    border-right-color: transparent;
  }
  [class*="u-nav-v6"].flex-column .nav-link:after, [class*="u-nav-v6"].flex-column .nav-link:before {
    top: 50%;
    left: auto;
  }
  [class*="u-nav-v6"].flex-column .nav-link:after {
    border-top-color: transparent;
    border-left-color: #fff;
    border-width: 4px;
    margin-left: 0;
    margin-top: -5px;
    right: -8px;
  }
  [class*="u-nav-v6"].flex-column .nav-link:before {
    border-top-color: transparent;
    border-left-color: #fff;
    border-width: 6px;
    margin-left: 0;
    margin-top: -7px;
    right: -13px;
  }
  [class*="u-nav-v6"].flex-column .nav-link.active {
    border-color: #2cb8f3;
  }
  [class*="u-nav-v6"].flex-column .nav-link.active::before {
    border-left-color: #2cb8f3;
  }
}

/*------------------------------------
  Tabs v7
------------------------------------*/
@media (min-width: 768px) {
  [class*="u-nav-v7"] .nav-item {
    position: relative;
  }
  [class*="u-nav-v7"] .nav-item::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    display: block;
    width: 0;
    height: 35%;
    border-right-style: solid;
    border-right-width: 1px;
    border-right-color: #ccc;
    transform: translateY(-50%);
  }
  [class*="u-nav-v7"] .nav-item:last-child::after {
    border-right-color: transparent;
  }
  [class*="u-nav-v7"] .nav-link.active {
    color: #2cb8f3;
  }
  [class*="u-nav-v7"].u-nav-dark .nav-link.active {
    color: #333;
  }
  [class*="u-nav-v7"].u-nav-light .nav-link {
    color: #fff;
  }
  [class*="u-nav-v7"].u-nav-light .nav-link.active {
    color: #fff;
    opacity: .5;
  }
  [class*="u-nav-v7"].flex-column .nav-item::after {
    top: 100%;
    left: 0;
    right: auto;
    width: 35%;
    height: 0;
    border-right: none;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #ccc;
    transform: translateY(0);
  }
  [class*="u-nav-v7"].flex-column .nav-item:last-child::after {
    border-bottom-color: transparent;
  }
  [class*="u-nav-v7"].flex-column .nav-link {
    padding-left: 0;
    padding-right: 0;
  }
  [class*="u-nav-v7"].flex-column.text-center .nav-item::after {
    left: 50%;
    transform: translateX(-50%);
  }
}

/*------------------------------------
  Tabs v8
------------------------------------*/
[class*="u-nav-v8"] .nav-link {
  position: relative;
  min-height: 100%;
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  transition: none;
}

[class*="u-nav-v8"] .nav-link::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100%;
  background-image: linear-gradient(to left, #333 0%, rgba(17, 17, 17, 0.2) 100%);
  background-repeat: repeat-y;
  z-index: 1;
}

[class*="u-nav-v8"] .nav-link.active {
  background-color: #2cb8f3;
}

[class*="u-nav-v8"] .nav-link.active::after {
  display: none;
}

[class*="u-nav-v8"] .nav-link.active .u-nav-v8__icon {
  background-color: #2cb8f3;
}

[class*="u-nav-v8"] .nav-link.active .u-nav-v8__description {
  color: rgba(255, 255, 255, 0.7);
}

[class*="u-nav-v8"].u-nav-light .nav-link {
  color: #333;
}

[class*="u-nav-v8"].u-nav-light .nav-link.active {
  color: #555;
  background-color: #fff;
}

[class*="u-nav-v8"].u-nav-light .nav-link.active .u-nav-v8__icon {
  color: #fff;
  background-color: #2cb8f3;
}

[class*="u-nav-v8"].u-nav-light .nav-link.active .u-nav-v8__title, [class*="u-nav-v8"].u-nav-light .nav-link.active .u-nav-v8__description {
  color: #555;
}

[class*="u-nav-v8"].u-nav-light .u-nav-v8__icon {
  background-color: #fff;
  color: #fff;
}

[class*="u-nav-v8"].u-nav-light .u-nav-v8__title, [class*="u-nav-v8"].u-nav-light .u-nav-v8__description {
  color: #fff;
}

.u-nav-v8__icon, .u-nav-v8__title, .u-nav-v8__description {
  position: relative;
  z-index: 3;
}

.u-nav-v8__icon {
  display: none;
  background-color: #333;
  color: #fff;
  transform: translateY(-51%);
  transition: none;
}

.u-nav-v8__title {
  display: block;
}

.u-nav-v8__description {
  color: inherit;
  font-style: normal;
}

@media (min-width: 768px) {
  [class*="u-nav-v8"] .nav-link {
    padding: 0 20px 25px;
  }
  [class*="u-nav-v8"].u-nav-light .nav-link {
    background-color: #fff;
  }
  [class*="u-nav-v8"].u-nav-light .nav-link::before {
    background-image: linear-gradient(to bottom left, transparent 49.6%, #fff 50%), linear-gradient(to top left, transparent 49.6%, #fff 50%);
  }
  [class*="u-nav-v8"].u-nav-light .nav-link::after {
    background-image: linear-gradient(to left, #fff 0%, rgba(204, 204, 204, 0.2) 100%);
  }
  [class*="u-nav-v8"].u-nav-light .nav-link.active {
    color: #fff;
    background-color: #2cb8f3;
  }
  [class*="u-nav-v8"].u-nav-light .nav-link.active::before {
    background-image: linear-gradient(to bottom left, transparent 49.6%, #2cb8f3 50%), linear-gradient(to top left, transparent 49.6%, #2cb8f3 50%);
  }
  [class*="u-nav-v8"].u-nav-light .nav-link.active .u-nav-v8__title {
    color: #fff;
  }
  [class*="u-nav-v8"].u-nav-light .nav-link.active .u-nav-v8__description {
    color: rgba(255, 255, 255, 0.7);
  }
  [class*="u-nav-v8"].u-nav-light .u-nav-v8__icon, [class*="u-nav-v8"].u-nav-light .u-nav-v8__title, [class*="u-nav-v8"].u-nav-light .u-nav-v8__description {
    color: #555;
  }
  [class*="u-nav-v8"].justify-content-end .nav-item:first-child .nav-link::before, [class*="u-nav-v8"].text-right .nav-item:first-child .nav-link::before {
    display: none;
  }
  [class*="u-nav-v8"].justify-content-end .nav-item:last-child .nav-link, [class*="u-nav-v8"].text-right .nav-item:last-child .nav-link {
    padding: 0 20px 25px;
  }
  [class*="u-nav-v8"].justify-content-end .nav-item:last-child .nav-link::before, [class*="u-nav-v8"].text-right .nav-item:last-child .nav-link::before {
    display: block;
  }
  [class*="u-nav-v8"].justify-content-end .nav-link::before, [class*="u-nav-v8"].text-right .nav-link::before {
    left: -26px;
    right: auto;
    background-position: top right, bottom left;
    background-image: linear-gradient(to bottom right, transparent 49.6%, #333 50%), linear-gradient(to top right, transparent 49.6%, #333 50%);
  }
  [class*="u-nav-v8"].justify-content-end .nav-link::after, [class*="u-nav-v8"].text-right .nav-link::after {
    left: auto;
    right: 0;
    background-image: linear-gradient(to right, #333 0%, rgba(17, 17, 17, 0.2) 100%);
  }
  [class*="u-nav-v8"].justify-content-end .nav-link.active::before, [class*="u-nav-v8"].text-right .nav-link.active::before {
    background-image: linear-gradient(to bottom right, transparent 49.6%, #2cb8f3 50%), linear-gradient(to top right, transparent 49.6%, #2cb8f3 50%);
  }
  [class*="u-nav-v8"].flex-column .nav-item {
    margin-bottom: 1px;
  }
  [class*="u-nav-v8"].flex-column .nav-item:first-child .nav-link {
    padding: 20px 20px 20px 50px;
  }
  [class*="u-nav-v8"].flex-column .nav-item:last-child .nav-link::before {
    display: block;
  }
  [class*="u-nav-v8"].flex-column .nav-link {
    padding: 20px 20px 20px 50px;
  }
  [class*="u-nav-v8"].flex-column .nav-link::before {
    opacity: 0;
  }
  [class*="u-nav-v8"].flex-column .nav-link.active::before {
    opacity: 1;
  }
  [class*="u-nav-v8"].flex-column .u-nav-v8__icon {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateX(-50%) translateY(-50%);
  }
  .u-nav-v8__icon {
    display: inline-block;
  }
  .u-nav-v8__description {
    color: rgba(255, 255, 255, 0.5);
  }
  .u-nav-v8-2 .nav-item:first-child .nav-link {
    padding: 0 20px 25px;
  }
  .u-nav-v8-2 .nav-item:last-child .nav-link::before {
    display: none;
  }
  .u-nav-v8-2 .nav-link {
    padding: 0 20px 25px 50px;
  }
  .u-nav-v8-2 .nav-link::before {
    content: "";
    position: absolute;
    top: 0;
    right: -26px;
    display: block;
    width: 26px;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 50.8%;
    background-position: top right, bottom left;
    background-image: linear-gradient(to bottom left, transparent 49.6%, #333 50%), linear-gradient(to top left, transparent 49.6%, #333 50%);
    z-index: 2;
  }
  .u-nav-v8-2 .nav-link.active::before {
    background-image: linear-gradient(to bottom left, transparent 49.6%, #2cb8f3 50%), linear-gradient(to top left, transparent 49.6%, #2cb8f3 50%);
  }
  .u-nav-v8-2.justify-content-end .nav-item:first-child .nav-link, .u-nav-v8-2.text-right .nav-item:first-child .nav-link {
    padding: 0 50px 25px 20px;
  }
  .u-nav-v8-2.justify-content-end .nav-link, .u-nav-v8-2.text-right .nav-link {
    padding: 0 50px 25px 20px;
  }
}

@media (max-width: 768px - 1) {
  [data-tabs-mobile-type="slide-up-down"],
  [data-tabs-mobile-type="accordion"] {
    display: none;
  }
  [data-tabs-mobile-type="accordion"] {
    display: none;
  }
  [role="tablist"]:not([data-tabs-mobile-type="slide-up-down"]):not(
  [data-tabs-mobile-type="accordion"]) {
    border-color: #2cb8f3;
  }
  [role="tablist"]  .nav-item > a,
  [role="tablist"]  .nav-item > .nav-link,
  [role="tablist"]  [class*="u-tab-link"]:not([class*="-icon"]) {
    white-space: nowrap;
    padding: 5px 10px;
  }
  [role="tablist"]  .nav-item > a,
  [role="tablist"]  .nav-item > .nav-link,
  [role="tablist"]  [class*="u-tab-link"] {
    display: block;
  }
  [data-tabs-mobile-type="slide-up-down"] {
    width: 100%;
    padding-top: 10px;
    border-bottom: none !important;
  }
  [data-tabs-mobile-type="slide-up-down"] .nav-item {
    margin: 0;
  }
  [data-tabs-mobile-type="slide-up-down"] .nav-link {
    text-align: center;
    border-style: solid !important;
    border-color: #eee !important;
    border-width: 1px !important;
    border-radius: 0 !important;
    margin: -1px 0 0;
  }
  [data-tabs-mobile-type="slide-up-down"] .nav-item:first-child .nav-link {
    margin-top: 0;
  }
  [data-tabs-mobile-type="slide-up-down"].u-nav-primary .nav-link, [data-tabs-mobile-type="slide-up-down"].nav-pills .nav-link {
    border-color: #2cb8f3 !important;
  }
  [data-tabs-mobile-type="slide-up-down"].u-nav-primary .nav-link.active,
  [data-tabs-mobile-type="slide-up-down"].u-nav-primary .nav-item.show .nav-link, [data-tabs-mobile-type="slide-up-down"].nav-pills .nav-link.active,
  [data-tabs-mobile-type="slide-up-down"].nav-pills .nav-item.show .nav-link {
    border-color: #2cb8f3 !important;
  }
  [data-tabs-mobile-type="slide-up-down"].u-nav-dark .nav-link {
    border-color: #333 !important;
  }
  [data-tabs-mobile-type="slide-up-down"].u-nav-dark .nav-link.active,
  [data-tabs-mobile-type="slide-up-down"].u-nav-dark .nav-item.show .nav-link {
    border-color: #333 !important;
  }
  [data-tabs-mobile-type="slide-up-down"].u-nav-light .nav-item.show .nav-link {
    border-color: #fff;
  }
  [data-tabs-mobile-type="slide-up-down"].u-nav-light .nav-link {
    color: #fff;
    border-color: #fff;
  }
  [data-tabs-mobile-type="slide-up-down"].u-nav-light .nav-link.active {
    color: #555;
    background-color: #fff;
    border-color: #fff;
  }
  [data-tabs-mobile-type="slide-up-down"].u-nav-rounded-3 .nav-item:first-child .nav-link,
  [data-tabs-mobile-type="slide-up-down"].nav-tabs .nav-item:first-child .nav-link,
  [data-tabs-mobile-type="slide-up-down"].nav-pills .nav-item:first-child .nav-link {
    border-radius: 3px 3px 0 0 !important;
  }
  [data-tabs-mobile-type="slide-up-down"].u-nav-rounded-3 .nav-item:last-child .nav-link,
  [data-tabs-mobile-type="slide-up-down"].nav-tabs .nav-item:last-child .nav-link,
  [data-tabs-mobile-type="slide-up-down"].nav-pills .nav-item:last-child .nav-link {
    border-radius: 0 0 3px 3px !important;
  }
  [data-tabs-mobile-type="slide-up-down"].u-nav-rounded-5 .nav-item:first-child .nav-link {
    border-radius: 5px 5px 0 0 !important;
  }
  [data-tabs-mobile-type="slide-up-down"].u-nav-rounded-5 .nav-item:last-child .nav-link {
    border-radius: 0 0 5px 5px !important;
  }
  [data-tabs-mobile-type="slide-up-down"].u-nav-rounded-7 .nav-item:first-child .nav-link {
    border-radius: 7px 7px 0 0 !important;
  }
  [data-tabs-mobile-type="slide-up-down"].u-nav-rounded-7 .nav-item:last-child .nav-link {
    border-radius: 0 0 7px 7px !important;
  }
  [data-tabs-mobile-type="slide-up-down"].u-nav-rounded-10 .nav-item:first-child .nav-link {
    border-radius: 10px 10px 0 0 !important;
  }
  [data-tabs-mobile-type="slide-up-down"].u-nav-rounded-10 .nav-item:last-child .nav-link {
    border-radius: 0 0 10px 10px !important;
  }
  .tab-content {
    order: 2;
  }
}

/*------------------------------------
  Tags
------------------------------------*/
/* Pagination v1 */
.u-tags-v1 {
  display: inline-block;
  text-decoration: none;
  transition: all .3s ease;
}

.u-tags-v1:hover, .u-tags-v1:focus {
  text-decoration: none;
  cursor: pointer;
}

.u-tags-v1:focus {
  color: inherit;
}

/*------------------------------------
  Text Animation Slideshow
------------------------------------*/
.u-text-slideshow {
  position: relative;
  overflow: visible !important;
  vertical-align: inherit;
}

.u-text-slideshow__slide {
  position: relative;
  z-index: 1;
  display: inline-block;
  opacity: 0;
  transition: .3s ease;
}

.u-text-slideshow__slide:not(:first-child) {
  position: absolute;
  top: 0;
  left: 0;
}

.u-text-slideshow__slide--current {
  z-index: 2;
  opacity: 1;
}

.u-text-slideshow__slide-target {
  flex-wrap: nowrap;
}

/*------------------------------------
  Text-shadow
------------------------------------*/
.u-txt-shadow-v1 {
  text-shadow: 1px 1px rgba(67, 70, 72, 0.05), 2px 2px rgba(147, 150, 152, 0.07), 3px 3px rgba(178, 182, 183, 0.086), 4px 4px rgba(195, 199, 200, 0.106), 5px 5px rgba(206, 210, 211, 0.125), 6px 6px rgba(213, 217, 218, 0.145), 7px 7px rgba(218, 222, 223, 0.165), 8px 8px rgba(222, 226, 227, 0.184), 9px 9px rgba(226, 230, 231, 0.204), 10px 10px rgba(228, 232, 233, 0.22), 11px 11px rgba(230, 234, 235, 0.24), 12px 12px rgba(232, 236, 237, 0.26), 13px 13px rgba(233, 237, 238, 0.28), 14px 14px rgba(235, 239, 240, 0.298), 15px 15px rgba(236, 240, 241, 0.318), 16px 16px rgba(237, 241, 242, 0.333), 17px 17px rgba(238, 242, 243, 0.353), 18px 18px rgba(238, 242, 243, 0.373), 19px 19px rgba(239, 243, 244, 0.392), 20px 20px rgba(240, 244, 245, 0.41), 21px 21px rgba(240, 244, 245, 0.43), 22px 22px rgba(241, 245, 246, 0.447), 23px 23px rgba(241, 245, 246, 0.467), 24px 24px rgba(242, 246, 247, 0.486), 25px 25px rgba(242, 246, 247, 0.506), 26px 26px rgba(242, 246, 247, 0.525), 27px 27px rgba(243, 247, 248, 0.545), 28px 28px rgba(243, 247, 248, 0.565), 29px 29px rgba(243, 247, 248, 0.58), 30px 30px rgba(244, 248, 249, 0.6), 31px 31px rgba(244, 248, 249, 0.62), 32px 32px rgba(244, 248, 249, 0.64), 33px 33px rgba(244, 248, 249, 0.66), 34px 34px rgba(245, 249, 250, 0.68), 35px 35px rgba(245, 249, 250, 0.694), 36px 36px rgba(245, 249, 250, 0.714), 37px 37px rgba(245, 249, 250, 0.733), 38px 38px rgba(245, 249, 250, 0.753), 39px 39px rgba(246, 250, 251, 0.773), 40px 40px rgba(246, 250, 251, 0.792), 41px 41px rgba(246, 250, 251, 0.81), 42px 42px rgba(246, 250, 251, 0.827), 43px 43px rgba(246, 250, 251, 0.847), 44px 44px rgba(246, 250, 251, 0.867), 45px 45px rgba(246, 250, 251, 0.886), 46px 46px rgba(246, 250, 251, 0.906), 47px 47px rgba(247, 251, 252, 0.925), 48px 48px rgba(247, 251, 252, 0.94), 49px 49px rgba(247, 251, 252, 0.96), 50px 50px rgba(247, 251, 252, 0.98);
}

.u-txt-shadow-v2 {
  text-shadow: 0 6px 55px #999;
}

/*------------------------------------
  Marker-bg
------------------------------------*/
.u-marker-bg-primary, .u-marker-bg-green, .u-marker-bg-black, .u-marker-bg-white, .u-marker-bg-red, .u-marker-bg-yellow {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: 100% 70%;
  white-space: nowrap;
}

.u-marker-bg-primary {
  background-image: url(../img/bg/marker/marker-bg-primary.png);
}

.u-marker-bg-green {
  background-image: url(../img/bg/marker/marker-bg-green.png);
}

.u-marker-bg-black {
  background-image: url(../img/bg/marker/marker-bg-black.png);
}

.u-marker-bg-white {
  background-image: url(../img/bg/marker/marker-bg-white.png);
}

.u-marker-bg-red {
  background-image: url(../img/bg/marker/marker-bg-red.png);
}

.u-marker-bg-yellow {
  background-image: url(../img/bg/marker/marker-bg-yellow.png);
}

/*------------------------------------
  Tooltips
------------------------------------*/
.u-tooltip--v1 {
  color: #fff;
  background-color: #000;
  padding: 2px 8px 3px;
}

.u-tooltip--v1:after {
  content: "";
  position: absolute;
  display: block;
}

.u-tooltip--v1.tooltip-top-left, .u-tooltip--v1.tooltip-top-right {
  bottom: 100%;
  margin-bottom: 5px;
}

.u-tooltip--v1.tooltip-top-left:after, .u-tooltip--v1.tooltip-top-right:after {
  top: 100%;
  border-top: 4px solid rgba(0, 0, 0, 0.9);
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.u-tooltip--v1.tooltip-bottom-left, .u-tooltip--v1.tooltip-bottom-right {
  top: 100%;
  margin-top: 5px;
}

.u-tooltip--v1.tooltip-bottom-left:after, .u-tooltip--v1.tooltip-bottom-right:after {
  bottom: 100%;
  border-bottom: 4px solid rgba(0, 0, 0, 0.9);
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.u-tooltip--v1.tooltip-top-left, .u-tooltip--v1.tooltip-bottom-left {
  left: 0;
}

.u-tooltip--v1.tooltip-top-left:after, .u-tooltip--v1.tooltip-bottom-left:after {
  left: 16px;
}

.u-tooltip--v1.tooltip-top-right, .u-tooltip--v1.tooltip-bottom-right {
  right: 0;
}

.u-tooltip--v1.tooltip-top-right:after, .u-tooltip--v1.tooltip-bottom-right:after {
  right: 16px;
}

.u-tooltip--v1.tooltip-left, .u-tooltip--v1.tooltip-right {
  top: 50%;
  transform: translateY(-50%);
}

.u-tooltip--v1.tooltip-left:after, .u-tooltip--v1.tooltip-right:after {
  top: 50%;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  transform: translateY(-50%);
}

.u-tooltip--v1.tooltip-left {
  right: 100%;
  margin-right: 5px;
}

.u-tooltip--v1.tooltip-left:after {
  right: -4px;
  border-left: 4px solid rgba(0, 0, 0, 0.9);
}

.u-tooltip--v1.tooltip-right {
  left: 100%;
  margin-left: 5px;
}

.u-tooltip--v1.tooltip-right:after {
  left: -4px;
  border-right: 4px solid rgba(0, 0, 0, 0.9);
}

input:focus + .u-tooltip--v1,
textarea:focus + .u-tooltip--v1 {
  opacity: 1;
}

/*------------------------------------
  Typography
------------------------------------*/
.popovers--no-title .popover-title {
  display: none;
}

.g-nowrap {
  white-space: nowrap;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

video {
  max-width: 100%;
}

.u-video-v1 {
  margin-bottom: 15px;
}

.u-video-v1-info {
  display: none;
  padding-left: 0;
}

.u-video-v1-info__item {
  display: inline-block;
  list-style: none;
}

.u-audio-v1 {
  margin-bottom: 15px;
}

.u-audio-v1-info {
  display: none;
  padding-left: 0;
}

.u-audio-v1-info__item {
  display: inline-block;
  list-style: none;
}

.u-audio-v2 .plyr__controls {
  background-color: transparent;
  border: none;
}

.u-audio-v2 .plyr__controls button {
  color: #fff;
}

/*------------------------------------
  Navigation
------------------------------------*/
/* Base Abstractions */
.navbar .u-main-nav-v1 .nav-link, .navbar .u-main-nav-v2 .nav-link, .navbar .u-main-nav-v3 .nav-link, .navbar .u-main-nav-v4 .nav-link, .navbar .u-main-nav-v5 .nav-link, .navbar .u-main-nav-v6 .nav-link, .navbar .u-main-nav-v7 .nav-link, .navbar .u-main-nav-v8 .nav-link, .navbar .u-main-nav-v9 .nav-link {
  display: block;
}

.navbar .u-main-nav-v1 .nav-item.hs-has-sub-menu > a, .navbar .u-main-nav-v1 .nav-item.hs-has-mega-menu > a, .navbar .u-main-nav-v1 .nav-item.dropdown > a, .navbar .u-main-nav-v2 .nav-item.hs-has-sub-menu > a, .navbar .u-main-nav-v2 .nav-item.hs-has-mega-menu > a, .navbar .u-main-nav-v2 .nav-item.dropdown > a, .navbar .u-main-nav-v3 .nav-item.hs-has-sub-menu > a, .navbar .u-main-nav-v3 .nav-item.hs-has-mega-menu > a, .navbar .u-main-nav-v3 .nav-item.dropdown > a, .navbar .u-main-nav-v4 .nav-item.hs-has-sub-menu > a, .navbar .u-main-nav-v4 .nav-item.hs-has-mega-menu > a, .navbar .u-main-nav-v4 .nav-item.dropdown > a, .navbar .u-main-nav-v5 .nav-item.hs-has-sub-menu > a, .navbar .u-main-nav-v5 .nav-item.hs-has-mega-menu > a, .navbar .u-main-nav-v5 .nav-item.dropdown > a, .navbar .u-main-nav-v6 .nav-item.hs-has-sub-menu > a, .navbar .u-main-nav-v6 .nav-item.hs-has-mega-menu > a, .navbar .u-main-nav-v6 .nav-item.dropdown > a, .navbar .u-main-nav-v7 .nav-item.hs-has-sub-menu > a, .navbar .u-main-nav-v7 .nav-item.hs-has-mega-menu > a, .navbar .u-main-nav-v7 .nav-item.dropdown > a, .navbar .u-main-nav-v8 .nav-item.hs-has-sub-menu > a, .navbar .u-main-nav-v8 .nav-item.hs-has-mega-menu > a, .navbar .u-main-nav-v8 .nav-item.dropdown > a, .navbar .u-main-nav-v9 .nav-item.hs-has-sub-menu > a, .navbar .u-main-nav-v9 .nav-item.hs-has-mega-menu > a {
  position: relative;
  z-index: 1;
  padding-right: 1.42857rem;
}

.navbar .u-main-nav-v1 .nav-item.hs-has-sub-menu > a::after, .navbar .u-main-nav-v1 .nav-item.hs-has-mega-menu > a::after, .navbar .u-main-nav-v1 .nav-item.dropdown > a::after, .navbar .u-main-nav-v2 .nav-item.hs-has-sub-menu > a::after, .navbar .u-main-nav-v2 .nav-item.hs-has-mega-menu > a::after, .navbar .u-main-nav-v2 .nav-item.dropdown > a::after, .navbar .u-main-nav-v3 .nav-item.hs-has-sub-menu > a::after, .navbar .u-main-nav-v3 .nav-item.hs-has-mega-menu > a::after, .navbar .u-main-nav-v3 .nav-item.dropdown > a::after, .navbar .u-main-nav-v4 .nav-item.hs-has-sub-menu > a::after, .navbar .u-main-nav-v4 .nav-item.hs-has-mega-menu > a::after, .navbar .u-main-nav-v4 .nav-item.dropdown > a::after, .navbar .u-main-nav-v5 .nav-item.hs-has-sub-menu > a::after, .navbar .u-main-nav-v5 .nav-item.hs-has-mega-menu > a::after, .navbar .u-main-nav-v5 .nav-item.dropdown > a::after, .navbar .u-main-nav-v6 .nav-item.hs-has-sub-menu > a::after, .navbar .u-main-nav-v6 .nav-item.hs-has-mega-menu > a::after, .navbar .u-main-nav-v6 .nav-item.dropdown > a::after, .navbar .u-main-nav-v7 .nav-item.hs-has-sub-menu > a::after, .navbar .u-main-nav-v7 .nav-item.hs-has-mega-menu > a::after, .navbar .u-main-nav-v7 .nav-item.dropdown > a::after, .navbar .u-main-nav-v8 .nav-item.hs-has-sub-menu > a::after, .navbar .u-main-nav-v8 .nav-item.hs-has-mega-menu > a::after, .navbar .u-main-nav-v8 .nav-item.dropdown > a::after, .navbar .u-main-nav-v9 .nav-item.hs-has-sub-menu > a::after, .navbar .u-main-nav-v9 .nav-item.hs-has-mega-menu > a::after {
  content: "\e900";
  font-family: "hs-icons";
  font-weight: inherit;
  line-height: 1;
  position: absolute;
  top: 50%;
  right: 1.07143rem;
  transform: translate3d(0, -50%, 0);
}

/* Base stylesheets */
.u-header__section--dark .navbar [class*="u-main-nav-v"] .nav-link {
  color: #fff;
}

.u-header__section--dark .navbar [class*="u-main-nav-v"] .nav-item.active > .nav-link,
.u-header__section--dark .navbar [class*="u-main-nav-v"] .nav-item:hover > .nav-link,
.u-header__section--dark .navbar [class*="u-main-nav-v"] .nav-item:focus > .nav-link,
.u-header__section--dark .navbar [class*="u-main-nav-v"] .nav-item.show > .nav-link,
.u-header__section--dark .navbar [class*="u-main-nav-v"] .nav-item.hs-sub-menu-opened > .nav-link,
.u-header__section--dark .navbar [class*="u-main-nav-v"] .nav-item .nav-link:focus {
  color: #fff;
}

/* Import */
/* Main navigation styles */
/*------------------------------------
  Navigation Style v1
------------------------------------*/
.navbar .u-main-nav-v1 .nav-link {
  color: #333;
  padding: 0.78571rem 2.14286rem;
  border-radius: 1.57143rem;
}

.navbar .u-main-nav-v1 .nav-item.hs-has-sub-menu > a, .navbar .u-main-nav-v1 .nav-item.hs-has-mega-menu > a, .navbar .u-main-nav-v1 .nav-item.dropdown > a {
  padding-right: 3.21429rem;
}

.navbar .u-main-nav-v1 .nav-item.hs-has-sub-menu > a::after, .navbar .u-main-nav-v1 .nav-item.hs-has-mega-menu > a::after, .navbar .u-main-nav-v1 .nav-item.dropdown > a::after {
  right: 1.78571rem;
}

.navbar .u-main-nav-v1 .nav-item.active > .nav-link,
.navbar .u-main-nav-v1 .nav-item:hover > .nav-link,
.navbar .u-main-nav-v1 .nav-item:focus > .nav-link,
.navbar .u-main-nav-v1 .nav-item.show > .nav-link,
.navbar .u-main-nav-v1 .nav-item.hs-sub-menu-opened > .nav-link,
.navbar .u-main-nav-v1 .nav-item .nav-link:focus {
  color: #fff;
  background-color: #2cb8f3;
}

@media all and (max-width: 1200px - 1) {
  .navbar .u-main-nav-v1 .nav-link {
    padding: 0.64286rem 1.42857rem;
  }
  .navbar .u-main-nav-v1 .nav-item.hs-has-sub-menu > a, .navbar .u-main-nav-v1 .nav-item.hs-has-mega-menu > a, .navbar .u-main-nav-v1 .nav-item.dropdown > a {
    padding-right: 2.5rem;
  }
  .navbar .u-main-nav-v1 .nav-item.hs-has-sub-menu > a::after, .navbar .u-main-nav-v1 .nav-item.hs-has-mega-menu > a::after, .navbar .u-main-nav-v1 .nav-item.dropdown > a::after {
    right: 1.07143rem;
  }
}

/*------------------------------------
  Navigation Style v2
------------------------------------*/
.navbar .u-main-nav-v2 .nav-link {
  color: #333;
  padding: 0.64286rem 2.14286rem;
  border-radius: 1.57143rem;
  border: 2px solid transparent;
}

.navbar .u-main-nav-v2 .nav-item.hs-has-sub-menu > a, .navbar .u-main-nav-v2 .nav-item.hs-has-mega-menu > a, .navbar .u-main-nav-v2 .nav-item.dropdown > a {
  padding-right: 3.21429rem;
}

.navbar .u-main-nav-v2 .nav-item.hs-has-sub-menu > a::after, .navbar .u-main-nav-v2 .nav-item.hs-has-mega-menu > a::after, .navbar .u-main-nav-v2 .nav-item.dropdown > a::after {
  right: 1.78571rem;
}

.navbar .u-main-nav-v2 .nav-item.active > .nav-link,
.navbar .u-main-nav-v2 .nav-item:hover > .nav-link,
.navbar .u-main-nav-v2 .nav-item:focus > .nav-link,
.navbar .u-main-nav-v2 .nav-item.show > .nav-link,
.navbar .u-main-nav-v2 .nav-item.hs-sub-menu-opened > .nav-link,
.navbar .u-main-nav-v2 .nav-item .nav-link:focus {
  color: #333;
  border-color: #2cb8f3;
  background-color: transparent;
}

@media all and (max-width: 1200px - 1) {
  .navbar .u-main-nav-v2 .nav-link {
    padding: 0.5rem 1.42857rem;
  }
  .navbar .u-main-nav-v2 .nav-item.hs-has-sub-menu > a, .navbar .u-main-nav-v2 .nav-item.hs-has-mega-menu > a, .navbar .u-main-nav-v2 .nav-item.dropdown > a {
    padding-right: 2.5rem;
  }
  .navbar .u-main-nav-v2 .nav-item.hs-has-sub-menu > a::after, .navbar .u-main-nav-v2 .nav-item.hs-has-mega-menu > a::after, .navbar .u-main-nav-v2 .nav-item.dropdown > a::after {
    right: 1.07143rem;
  }
}

/*------------------------------------
  Navigation Style v3
------------------------------------*/
.navbar .u-main-nav-v3 .nav-link {
  color: #333;
  padding: 2.85714rem 2.57143rem 2.57143rem;
  border-bottom: 4px solid transparent;
}

.navbar .u-main-nav-v3 .nav-item.hs-has-sub-menu > a::after, .navbar .u-main-nav-v3 .nav-item.hs-has-mega-menu > a::after, .navbar .u-main-nav-v3 .nav-item.dropdown > a::after {
  position: static;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.71429rem;
  transform: none;
}

.navbar .u-main-nav-v3 .nav-item.active > .nav-link,
.navbar .u-main-nav-v3 .nav-item:hover > .nav-link,
.navbar .u-main-nav-v3 .nav-item:focus > .nav-link,
.navbar .u-main-nav-v3 .nav-item.show > .nav-link,
.navbar .u-main-nav-v3 .nav-item.hs-sub-menu-opened > .nav-link,
.navbar .u-main-nav-v3 .nav-item .nav-link:focus {
  border-color: #2cb8f3;
}

@media all and (max-width: 1200px - 1) {
  .navbar .u-main-nav-v3 .nav-link {
    padding: 2.14286rem 1.78571rem;
  }
}

@media all and (max-width: 992px - 1) {
  .navbar .u-main-nav-v3 .nav-link {
    padding: 0.71429rem 1.42857rem;
  }
}

/*------------------------------------
  Navigation Style v4
------------------------------------*/
.navbar .u-main-nav-v4 .nav-link {
  color: #333;
  padding: 2.57143rem 2.57143rem 2.85714rem;
  border-top: 4px solid transparent;
}

.navbar .u-main-nav-v4 .nav-item.hs-has-sub-menu > a::after, .navbar .u-main-nav-v4 .nav-item.hs-has-mega-menu > a::after, .navbar .u-main-nav-v4 .nav-item.dropdown > a::after {
  position: static;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.71429rem;
  transform: none;
}

.navbar .u-main-nav-v4 .nav-item.active > .nav-link,
.navbar .u-main-nav-v4 .nav-item:hover > .nav-link,
.navbar .u-main-nav-v4 .nav-item:focus > .nav-link,
.navbar .u-main-nav-v4 .nav-item.show > .nav-link,
.navbar .u-main-nav-v4 .nav-item.hs-sub-menu-opened > .nav-link,
.navbar .u-main-nav-v4 .nav-item .nav-link:focus {
  border-color: #2cb8f3;
}

@media all and (max-width: 1200px - 1) {
  .navbar .u-main-nav-v4 .nav-link {
    padding: 2.14286rem 1.78571rem;
  }
}

@media all and (max-width: 992px - 1) {
  .navbar .u-main-nav-v4 .nav-link {
    padding: 0.71429rem 1.42857rem;
  }
}

/*------------------------------------
  Navigation Style v5
------------------------------------*/
.navbar .u-main-nav-v5 .nav-link {
  color: #333;
  padding: 0.28571rem 0;
  border-bottom: 2px solid transparent;
}

.navbar .u-main-nav-v5 .nav-item.hs-has-sub-menu > a, .navbar .u-main-nav-v5 .nav-item.hs-has-mega-menu > a, .navbar .u-main-nav-v5 .nav-item.dropdown > a {
  padding-right: 1.42857rem;
}

.navbar .u-main-nav-v5 .nav-item.hs-has-sub-menu > a::after, .navbar .u-main-nav-v5 .nav-item.hs-has-mega-menu > a::after, .navbar .u-main-nav-v5 .nav-item.dropdown > a::after {
  right: 0;
}

.navbar .u-main-nav-v5 .nav-item.active > .nav-link,
.navbar .u-main-nav-v5 .nav-item:hover > .nav-link,
.navbar .u-main-nav-v5 .nav-item:focus > .nav-link,
.navbar .u-main-nav-v5 .nav-item.show > .nav-link,
.navbar .u-main-nav-v5 .nav-item.hs-sub-menu-opened > .nav-link,
.navbar .u-main-nav-v5 .nav-item .nav-link:focus {
  border-color: #2cb8f3;
}

/*------------------------------------
  Navigation Style v6
------------------------------------*/
.navbar .u-main-nav-v6 .nav-link {
  color: #333;
  height: 100%;
  padding: 2.85714rem 2.71429rem;
}

.navbar .u-main-nav-v6 .nav-item.hs-has-sub-menu > a, .navbar .u-main-nav-v6 .nav-item.hs-has-mega-menu > a, .navbar .u-main-nav-v6 .nav-item.dropdown > a {
  padding-right: 2.71429rem;
}

.navbar .u-main-nav-v6 .nav-item.hs-has-sub-menu > a::after, .navbar .u-main-nav-v6 .nav-item.hs-has-mega-menu > a::after, .navbar .u-main-nav-v6 .nav-item.dropdown > a::after {
  position: static;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.71429rem;
  transform: none;
}

.navbar .u-main-nav-v6 .nav-item.active > .nav-link,
.navbar .u-main-nav-v6 .nav-item:hover > .nav-link,
.navbar .u-main-nav-v6 .nav-item:focus > .nav-link,
.navbar .u-main-nav-v6 .nav-item.show > .nav-link,
.navbar .u-main-nav-v6 .nav-item.hs-sub-menu-opened > .nav-link,
.navbar .u-main-nav-v6 .nav-item .nav-link:focus {
  color: #fff;
  background-color: #2cb8f3;
}

@media all and (max-width: 1200px - 1) {
  .navbar .u-main-nav-v6 .nav-link {
    padding: 2.14286rem 1.42857rem;
  }
  .navbar .u-main-nav-v6 .nav-item.hs-has-sub-menu > a, .navbar .u-main-nav-v6 .nav-item.hs-has-mega-menu > a, .navbar .u-main-nav-v6 .nav-item.dropdown > a {
    padding-right: 1.42857rem;
  }
}

/*------------------------------------
  Navigation Style v7
------------------------------------*/
.navbar .u-main-nav-v7 .nav-link {
  color: #333;
  padding: 0.71429rem 2.14286rem;
  border: 2px solid transparent;
}

.navbar .u-main-nav-v7 .nav-item.hs-has-sub-menu > a, .navbar .u-main-nav-v7 .nav-item.hs-has-mega-menu > a, .navbar .u-main-nav-v7 .nav-item.dropdown > a {
  padding-right: 3.21429rem;
}

.navbar .u-main-nav-v7 .nav-item.hs-has-sub-menu > a::after, .navbar .u-main-nav-v7 .nav-item.hs-has-mega-menu > a::after, .navbar .u-main-nav-v7 .nav-item.dropdown > a::after {
  right: 1.78571rem;
}

.navbar .u-main-nav-v7 .nav-item.active > .nav-link,
.navbar .u-main-nav-v7 .nav-item:hover > .nav-link,
.navbar .u-main-nav-v7 .nav-item:focus > .nav-link,
.navbar .u-main-nav-v7 .nav-item.show > .nav-link,
.navbar .u-main-nav-v7 .nav-item.hs-sub-menu-opened > .nav-link,
.navbar .u-main-nav-v7 .nav-item .nav-link:focus {
  border-color: #2cb8f3;
}

@media all and (max-width: 1200px - 1) {
  .navbar .u-main-nav-v7 .nav-link {
    padding: 0.64286rem 1.42857rem;
  }
  .navbar .u-main-nav-v7 .nav-item.hs-has-sub-menu > a, .navbar .u-main-nav-v7 .nav-item.hs-has-mega-menu > a, .navbar .u-main-nav-v7 .nav-item.dropdown > a {
    padding-right: 2.14286rem;
  }
  .navbar .u-main-nav-v7 .nav-item.hs-has-sub-menu > a::after, .navbar .u-main-nav-v7 .nav-item.hs-has-mega-menu > a::after, .navbar .u-main-nav-v7 .nav-item.dropdown > a::after {
    right: 1.07143rem;
  }
}

/*------------------------------------
  Navigation Style v8
------------------------------------*/
.navbar .u-main-nav-v8 .nav-link {
  color: #333;
  padding: 0.78571rem 2.14286rem;
}

.navbar .u-main-nav-v8 .nav-item.hs-has-sub-menu > a, .navbar .u-main-nav-v8 .nav-item.hs-has-mega-menu > a, .navbar .u-main-nav-v8 .nav-item.dropdown > a {
  padding-right: 3.21429rem;
}

.navbar .u-main-nav-v8 .nav-item.hs-has-sub-menu > a::after, .navbar .u-main-nav-v8 .nav-item.hs-has-mega-menu > a::after, .navbar .u-main-nav-v8 .nav-item.dropdown > a::after {
  right: 1.78571rem;
}

.navbar .u-main-nav-v8 .nav-item.active > .nav-link,
.navbar .u-main-nav-v8 .nav-item:hover > .nav-link,
.navbar .u-main-nav-v8 .nav-item:focus > .nav-link,
.navbar .u-main-nav-v8 .nav-item.show > .nav-link,
.navbar .u-main-nav-v8 .nav-item.hs-sub-menu-opened > .nav-link,
.navbar .u-main-nav-v8 .nav-item .nav-link:focus {
  color: #fff;
  background-color: #2cb8f3;
}

@media all and (max-width: 1200px - 1) {
  .navbar .u-main-nav-v8 .nav-link {
    padding: 0.64286rem 1.42857rem;
  }
  .navbar .u-main-nav-v8 .nav-item.hs-has-sub-menu > a, .navbar .u-main-nav-v8 .nav-item.hs-has-mega-menu > a, .navbar .u-main-nav-v8 .nav-item.dropdown > a {
    padding-right: 2.14286rem;
  }
  .navbar .u-main-nav-v8 .nav-item.hs-has-sub-menu > a::after, .navbar .u-main-nav-v8 .nav-item.hs-has-mega-menu > a::after, .navbar .u-main-nav-v8 .nav-item.dropdown > a::after {
    right: 1.07143rem;
  }
}

/*------------------------------------
  Navigation Style v9
------------------------------------*/
.navbar .u-main-nav-v9 .nav-link {
  color: #777;
  padding: 0 1.42857rem 0 0;
}

.navbar .u-main-nav-v9 .nav-item > a {
  padding: 1.42857rem 0;
}

.navbar .u-main-nav-v9 .nav-item.hs-has-sub-menu > a::after, .navbar .u-main-nav-v9 .nav-item.hs-has-mega-menu > a::after {
  font-size: 1.28571rem;
  right: 0;
}

.navbar .u-main-nav-v9 .nav-item.active > .nav-link,
.navbar .u-main-nav-v9 .nav-item:hover > .nav-link,
.navbar .u-main-nav-v9 .nav-item:focus > .nav-link,
.navbar .u-main-nav-v9 .nav-item .nav-link:focus {
  color: #000;
}

.navbar .u-main-nav-v9 .nav-item.active > .nav-link::after,
.navbar .u-main-nav-v9 .nav-item:hover > .nav-link::after,
.navbar .u-main-nav-v9 .nav-item:focus > .nav-link::after,
.navbar .u-main-nav-v9 .nav-item .nav-link:focus::after {
  color: #2cb8f3;
}

/* Dropdown navigation styles */
/*------------------------------------
  Navigation Dropdown Style v1
------------------------------------*/
.navbar .u-sub-menu-v1 .hs-has-sub-menu .hs-has-sub-menu.active > a,
.navbar .u-sub-menu-v1 .hs-has-sub-menu .hs-has-sub-menu:active > a,
.navbar .u-sub-menu-v1 .hs-has-sub-menu .hs-has-sub-menu:focus > a,
.navbar .u-sub-menu-v1 .hs-has-sub-menu .hs-has-sub-menu:hover > a, .navbar .u-sub-menu-v1 .hs-sub-menu .dropdown-item.active > a, .navbar .u-sub-menu-v1 .hs-sub-menu .dropdown-item:active > a, .navbar .u-sub-menu-v1 .hs-sub-menu .dropdown-item:focus > a, .navbar .u-sub-menu-v1 .hs-sub-menu .dropdown-item:hover > a,
.navbar .u-sub-menu-v1 .dropdown-menu .dropdown-item.active > a,
.navbar .u-sub-menu-v1 .dropdown-menu .dropdown-item:active > a,
.navbar .u-sub-menu-v1 .dropdown-menu .dropdown-item:focus > a,
.navbar .u-sub-menu-v1 .dropdown-menu .dropdown-item:hover > a, .navbar .u-sub-menu-v1 .hs-sub-menu a:active, .navbar .u-sub-menu-v1 .hs-sub-menu a:focus, .navbar .u-sub-menu-v1 .hs-sub-menu a:hover,
.navbar .u-sub-menu-v1 .dropdown-menu a:active,
.navbar .u-sub-menu-v1 .dropdown-menu a:focus,
.navbar .u-sub-menu-v1 .dropdown-menu a:hover {
  color: #2cb8f3 !important;
  background-color: transparent;
  border: none;
}

.navbar .u-sub-menu-v1 .hs-has-sub-menu .hs-has-sub-menu > a::after {
  content: "\e902";
  font-family: "hs-icons";
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate3d(0, -50%, 0);
}

.navbar .u-sub-menu-v1 .hs-has-mega-menu .hs-has-sub-menu > a::after {
  content: "\e902";
}

.navbar .u-sub-menu-v1 .hs-sub-menu,
.navbar .u-sub-menu-v1 .dropdown-menu {
  font-size: 0.92857rem;
  min-width: 16.42857rem;
  padding: 0.71429rem 0;
}

.navbar .u-sub-menu-v1 .hs-sub-menu .dropdown-item,
.navbar .u-sub-menu-v1 .dropdown-menu .dropdown-item {
  font-weight: inherit;
  color: inherit;
  padding: 0;
}

.navbar .u-sub-menu-v1 .hs-sub-menu .dropdown-item.active, .navbar .u-sub-menu-v1 .hs-sub-menu .dropdown-item:active, .navbar .u-sub-menu-v1 .hs-sub-menu .dropdown-item:focus, .navbar .u-sub-menu-v1 .hs-sub-menu .dropdown-item:hover,
.navbar .u-sub-menu-v1 .dropdown-menu .dropdown-item.active,
.navbar .u-sub-menu-v1 .dropdown-menu .dropdown-item:active,
.navbar .u-sub-menu-v1 .dropdown-menu .dropdown-item:focus,
.navbar .u-sub-menu-v1 .dropdown-menu .dropdown-item:hover {
  border: none;
  background-color: transparent;
}

.navbar .u-sub-menu-v1 .hs-sub-menu a,
.navbar .u-sub-menu-v1 .dropdown-menu a {
  color: #777;
  text-decoration: none !important;
  display: block;
  padding: 0.42857rem 1.78571rem;
}

.navbar .u-sub-menu-v1 .hs-sub-menu,
.navbar .u-sub-menu-v1 .hs-mega-menu,
.navbar .u-sub-menu-v1 .dropdown-menu {
  font-weight: normal;
  text-transform: none;
  margin-top: 0;
  color: inherit;
  background-color: #fff;
  background-clip: border-box;
  border-width: 3px 0 0 0;
  border-style: solid;
  border-color: #2cb8f3;
  border-radius: 0;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.navbar .u-sub-menu-v1 .hs-sub-menu .hs-sub-menu,
.navbar .u-sub-menu-v1 .hs-mega-menu .hs-sub-menu,
.navbar .u-sub-menu-v1 .dropdown-menu .hs-sub-menu {
  margin-top: -1.07143rem;
}

.navbar .u-sub-menu-v1.hs-mobile-state .hs-sub-menu {
  width: auto;
}

.navbar .u-sub-menu-v1.hs-mobile-state .hs-sub-menu .hs-sub-menu,
.navbar .u-sub-menu-v1.hs-mobile-state .hs-sub-menu .hs-mega-menu,
.navbar .u-sub-menu-v1.hs-mobile-state .hs-sub-menu .hs-sub-menu.hs-reversed,
.navbar .u-sub-menu-v1.hs-mobile-state .hs-sub-menu .hs-mega-menu.hs-reversed,
.navbar .u-sub-menu-v1.hs-mobile-state .hs-mega-menu .hs-sub-menu,
.navbar .u-sub-menu-v1.hs-mobile-state .hs-mega-menu .hs-mega-menu,
.navbar .u-sub-menu-v1.hs-mobile-state .hs-mega-menu .hs-sub-menu.hs-reversed,
.navbar .u-sub-menu-v1.hs-mobile-state .hs-mega-menu .hs-mega-menu.hs-reversed {
  margin: 0.21429rem;
}

.navbar .hs-mobile-state .u-sub-menu-v1 .hs-sub-menu {
  width: auto;
}

.navbar .hs-mobile-state .u-sub-menu-v1 .hs-sub-menu .hs-sub-menu,
.navbar .hs-mobile-state .u-sub-menu-v1 .hs-sub-menu .hs-mega-menu,
.navbar .hs-mobile-state .u-sub-menu-v1 .hs-sub-menu .hs-sub-menu.hs-reversed,
.navbar .hs-mobile-state .u-sub-menu-v1 .hs-sub-menu .hs-mega-menu.hs-reversed,
.navbar .hs-mobile-state .u-sub-menu-v1 .hs-mega-menu .hs-sub-menu,
.navbar .hs-mobile-state .u-sub-menu-v1 .hs-mega-menu .hs-mega-menu,
.navbar .hs-mobile-state .u-sub-menu-v1 .hs-mega-menu .hs-sub-menu.hs-reversed,
.navbar .hs-mobile-state .u-sub-menu-v1 .hs-mega-menu .hs-mega-menu.hs-reversed {
  margin: 0.21429rem;
}

/*------------------------------------
  Navigation Dropdown Style v2
------------------------------------*/
.navbar .u-sub-menu-v2 .hs-has-sub-menu .hs-has-sub-menu.active > a,
.navbar .u-sub-menu-v2 .hs-has-sub-menu .hs-has-sub-menu:active > a,
.navbar .u-sub-menu-v2 .hs-has-sub-menu .hs-has-sub-menu:focus > a,
.navbar .u-sub-menu-v2 .hs-has-sub-menu .hs-has-sub-menu:hover > a, .navbar .u-sub-menu-v2 .hs-sub-menu .dropdown-item.active > a, .navbar .u-sub-menu-v2 .hs-sub-menu .dropdown-item:active > a, .navbar .u-sub-menu-v2 .hs-sub-menu .dropdown-item:focus > a, .navbar .u-sub-menu-v2 .hs-sub-menu .dropdown-item:hover > a,
.navbar .u-sub-menu-v2 .dropdown-menu .dropdown-item.active > a,
.navbar .u-sub-menu-v2 .dropdown-menu .dropdown-item:active > a,
.navbar .u-sub-menu-v2 .dropdown-menu .dropdown-item:focus > a,
.navbar .u-sub-menu-v2 .dropdown-menu .dropdown-item:hover > a, .navbar .u-sub-menu-v2 .hs-sub-menu a:active, .navbar .u-sub-menu-v2 .hs-sub-menu a:focus, .navbar .u-sub-menu-v2 .hs-sub-menu a:hover,
.navbar .u-sub-menu-v2 .dropdown-menu a:active,
.navbar .u-sub-menu-v2 .dropdown-menu a:focus,
.navbar .u-sub-menu-v2 .dropdown-menu a:hover {
  color: #777 !important;
  background-color: #eee;
  border: none;
}

.navbar .u-sub-menu-v2 .hs-has-sub-menu .hs-has-sub-menu > a::after {
  content: "\e902";
  font-family: "hs-icons";
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate3d(0, -50%, 0);
}

.navbar .u-sub-menu-v2 .hs-has-mega-menu .hs-has-sub-menu > a::after {
  content: "\e902";
}

.navbar .u-sub-menu-v2 .hs-sub-menu,
.navbar .u-sub-menu-v2 .dropdown-menu {
  font-size: 0.92857rem;
  min-width: 16.42857rem;
  padding: 0.71429rem 0;
}

.navbar .u-sub-menu-v2 .hs-sub-menu .dropdown-item,
.navbar .u-sub-menu-v2 .dropdown-menu .dropdown-item {
  font-weight: inherit;
  color: inherit;
  padding: 0;
}

.navbar .u-sub-menu-v2 .hs-sub-menu .dropdown-item.active, .navbar .u-sub-menu-v2 .hs-sub-menu .dropdown-item:active, .navbar .u-sub-menu-v2 .hs-sub-menu .dropdown-item:focus, .navbar .u-sub-menu-v2 .hs-sub-menu .dropdown-item:hover,
.navbar .u-sub-menu-v2 .dropdown-menu .dropdown-item.active,
.navbar .u-sub-menu-v2 .dropdown-menu .dropdown-item:active,
.navbar .u-sub-menu-v2 .dropdown-menu .dropdown-item:focus,
.navbar .u-sub-menu-v2 .dropdown-menu .dropdown-item:hover {
  border-color: #eee;
  background-color: transparent;
}

.navbar .u-sub-menu-v2 .hs-sub-menu a,
.navbar .u-sub-menu-v2 .dropdown-menu a {
  color: #777;
  text-decoration: none !important;
  display: block;
  padding: 0.42857rem 1.78571rem;
}

.navbar .u-sub-menu-v2 .hs-sub-menu > li:not(:last-child),
.navbar .u-sub-menu-v2 .dropdown-menu > li:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.navbar .u-sub-menu-v2 .hs-sub-menu,
.navbar .u-sub-menu-v2 .hs-mega-menu,
.navbar .u-sub-menu-v2 .dropdown-menu {
  font-weight: normal;
  text-transform: none;
  min-width: 14.28571rem;
  margin-top: 0;
  padding: 0;
  color: inherit;
  background-color: #fff;
  background-clip: border-box;
  border-width: 2px 0 2px 0;
  border-style: solid;
  border-top-color: #2cb8f3;
  border-bottom-color: #777;
  border-radius: 0;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.navbar .u-sub-menu-v2 .hs-sub-menu .hs-sub-menu,
.navbar .u-sub-menu-v2 .hs-mega-menu .hs-sub-menu,
.navbar .u-sub-menu-v2 .dropdown-menu .hs-sub-menu {
  margin-top: -1.07143rem;
}

.navbar .u-sub-menu-v2.hs-mobile-state .hs-sub-menu {
  width: auto;
}

.navbar .u-sub-menu-v2.hs-mobile-state .hs-sub-menu .hs-sub-menu,
.navbar .u-sub-menu-v2.hs-mobile-state .hs-sub-menu .hs-mega-menu,
.navbar .u-sub-menu-v2.hs-mobile-state .hs-sub-menu .hs-sub-menu.hs-reversed,
.navbar .u-sub-menu-v2.hs-mobile-state .hs-sub-menu .hs-mega-menu.hs-reversed,
.navbar .u-sub-menu-v2.hs-mobile-state .hs-mega-menu .hs-sub-menu,
.navbar .u-sub-menu-v2.hs-mobile-state .hs-mega-menu .hs-mega-menu,
.navbar .u-sub-menu-v2.hs-mobile-state .hs-mega-menu .hs-sub-menu.hs-reversed,
.navbar .u-sub-menu-v2.hs-mobile-state .hs-mega-menu .hs-mega-menu.hs-reversed {
  margin: 0.21429rem;
}

.navbar .hs-mobile-state .u-sub-menu-v2 .hs-sub-menu {
  width: auto;
}

.navbar .hs-mobile-state .u-sub-menu-v2 .hs-sub-menu .hs-sub-menu,
.navbar .hs-mobile-state .u-sub-menu-v2 .hs-sub-menu .hs-mega-menu,
.navbar .hs-mobile-state .u-sub-menu-v2 .hs-sub-menu .hs-sub-menu.hs-reversed,
.navbar .hs-mobile-state .u-sub-menu-v2 .hs-sub-menu .hs-mega-menu.hs-reversed,
.navbar .hs-mobile-state .u-sub-menu-v2 .hs-mega-menu .hs-sub-menu,
.navbar .hs-mobile-state .u-sub-menu-v2 .hs-mega-menu .hs-mega-menu,
.navbar .hs-mobile-state .u-sub-menu-v2 .hs-mega-menu .hs-sub-menu.hs-reversed,
.navbar .hs-mobile-state .u-sub-menu-v2 .hs-mega-menu .hs-mega-menu.hs-reversed {
  margin: 0.21429rem;
}

/*------------------------------------
  Navigation Dropdown Style v3
------------------------------------*/
.navbar .u-sub-menu-v3 .hs-has-sub-menu .hs-has-sub-menu.active > a,
.navbar .u-sub-menu-v3 .hs-has-sub-menu .hs-has-sub-menu:active > a,
.navbar .u-sub-menu-v3 .hs-has-sub-menu .hs-has-sub-menu:focus > a,
.navbar .u-sub-menu-v3 .hs-has-sub-menu .hs-has-sub-menu:hover > a, .navbar .u-sub-menu-v3 .hs-sub-menu .dropdown-item.active > a, .navbar .u-sub-menu-v3 .hs-sub-menu .dropdown-item:active > a, .navbar .u-sub-menu-v3 .hs-sub-menu .dropdown-item:focus > a, .navbar .u-sub-menu-v3 .hs-sub-menu .dropdown-item:hover > a,
.navbar .u-sub-menu-v3 .dropdown-menu .dropdown-item.active > a,
.navbar .u-sub-menu-v3 .dropdown-menu .dropdown-item:active > a,
.navbar .u-sub-menu-v3 .dropdown-menu .dropdown-item:focus > a,
.navbar .u-sub-menu-v3 .dropdown-menu .dropdown-item:hover > a, .navbar .u-sub-menu-v3 .hs-sub-menu a:active, .navbar .u-sub-menu-v3 .hs-sub-menu a:focus, .navbar .u-sub-menu-v3 .hs-sub-menu a:hover,
.navbar .u-sub-menu-v3 .dropdown-menu a:active,
.navbar .u-sub-menu-v3 .dropdown-menu a:focus,
.navbar .u-sub-menu-v3 .dropdown-menu a:hover {
  color: #fff !important;
  background-color: #2cb8f3;
  border: none;
}

.navbar .u-sub-menu-v3 .hs-has-sub-menu .hs-has-sub-menu > a::after {
  content: "\e902";
  font-family: "hs-icons";
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate3d(0, -50%, 0);
}

.navbar .u-sub-menu-v3 .hs-has-mega-menu .hs-has-sub-menu > a::after {
  content: "\e902";
}

.navbar .u-sub-menu-v3 .hs-sub-menu,
.navbar .u-sub-menu-v3 .dropdown-menu {
  font-size: 0.92857rem;
  min-width: 16.42857rem;
  padding: 0.71429rem 0;
}

.navbar .u-sub-menu-v3 .hs-sub-menu .dropdown-item,
.navbar .u-sub-menu-v3 .dropdown-menu .dropdown-item {
  font-weight: inherit;
  color: inherit;
  padding: 0;
}

.navbar .u-sub-menu-v3 .hs-sub-menu .dropdown-item.active, .navbar .u-sub-menu-v3 .hs-sub-menu .dropdown-item:active, .navbar .u-sub-menu-v3 .hs-sub-menu .dropdown-item:focus, .navbar .u-sub-menu-v3 .hs-sub-menu .dropdown-item:hover,
.navbar .u-sub-menu-v3 .dropdown-menu .dropdown-item.active,
.navbar .u-sub-menu-v3 .dropdown-menu .dropdown-item:active,
.navbar .u-sub-menu-v3 .dropdown-menu .dropdown-item:focus,
.navbar .u-sub-menu-v3 .dropdown-menu .dropdown-item:hover {
  border-color: #eee;
  background-color: transparent;
}

.navbar .u-sub-menu-v3 .hs-sub-menu a,
.navbar .u-sub-menu-v3 .dropdown-menu a {
  color: #777;
  text-decoration: none !important;
  display: block;
  padding: 0.42857rem 1.78571rem;
}

.navbar .u-sub-menu-v3 .hs-sub-menu > li:not(:last-child),
.navbar .u-sub-menu-v3 .dropdown-menu > li:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.navbar .u-sub-menu-v3 .hs-sub-menu,
.navbar .u-sub-menu-v3 .hs-mega-menu,
.navbar .u-sub-menu-v3 .dropdown-menu {
  font-weight: normal;
  text-transform: none;
  min-width: 14.28571rem;
  margin-top: 0;
  padding: 0;
  color: inherit;
  background-color: #fff;
  background-clip: border-box;
  border-width: 2px 0 2px 0;
  border-style: solid;
  border-top-color: #2cb8f3;
  border-bottom-color: #777;
  border-radius: 0;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.navbar .u-sub-menu-v3 .hs-sub-menu .hs-sub-menu,
.navbar .u-sub-menu-v3 .hs-mega-menu .hs-sub-menu,
.navbar .u-sub-menu-v3 .dropdown-menu .hs-sub-menu {
  margin-top: -1.07143rem;
}

.navbar .u-sub-menu-v3.hs-mobile-state .hs-sub-menu {
  width: auto;
}

.navbar .u-sub-menu-v3.hs-mobile-state .hs-sub-menu .hs-sub-menu,
.navbar .u-sub-menu-v3.hs-mobile-state .hs-sub-menu .hs-mega-menu,
.navbar .u-sub-menu-v3.hs-mobile-state .hs-sub-menu .hs-sub-menu.hs-reversed,
.navbar .u-sub-menu-v3.hs-mobile-state .hs-sub-menu .hs-mega-menu.hs-reversed,
.navbar .u-sub-menu-v3.hs-mobile-state .hs-mega-menu .hs-sub-menu,
.navbar .u-sub-menu-v3.hs-mobile-state .hs-mega-menu .hs-mega-menu,
.navbar .u-sub-menu-v3.hs-mobile-state .hs-mega-menu .hs-sub-menu.hs-reversed,
.navbar .u-sub-menu-v3.hs-mobile-state .hs-mega-menu .hs-mega-menu.hs-reversed {
  margin: 0.21429rem;
}

.navbar .hs-mobile-state .u-sub-menu-v3 .hs-sub-menu {
  width: auto;
}

.navbar .hs-mobile-state .u-sub-menu-v3 .hs-sub-menu .hs-sub-menu,
.navbar .hs-mobile-state .u-sub-menu-v3 .hs-sub-menu .hs-mega-menu,
.navbar .hs-mobile-state .u-sub-menu-v3 .hs-sub-menu .hs-sub-menu.hs-reversed,
.navbar .hs-mobile-state .u-sub-menu-v3 .hs-sub-menu .hs-mega-menu.hs-reversed,
.navbar .hs-mobile-state .u-sub-menu-v3 .hs-mega-menu .hs-sub-menu,
.navbar .hs-mobile-state .u-sub-menu-v3 .hs-mega-menu .hs-mega-menu,
.navbar .hs-mobile-state .u-sub-menu-v3 .hs-mega-menu .hs-sub-menu.hs-reversed,
.navbar .hs-mobile-state .u-sub-menu-v3 .hs-mega-menu .hs-mega-menu.hs-reversed {
  margin: 0.21429rem;
}

/*------------------------------------
  Navigation Dropdown Style v4
------------------------------------*/
.navbar .u-sub-menu-v4 .hs-has-sub-menu .hs-has-sub-menu.active > a,
.navbar .u-sub-menu-v4 .hs-has-sub-menu .hs-has-sub-menu:active > a,
.navbar .u-sub-menu-v4 .hs-has-sub-menu .hs-has-sub-menu:focus > a,
.navbar .u-sub-menu-v4 .hs-has-sub-menu .hs-has-sub-menu:hover > a, .navbar .u-sub-menu-v4 .hs-sub-menu .dropdown-item.active > a, .navbar .u-sub-menu-v4 .hs-sub-menu .dropdown-item:active > a, .navbar .u-sub-menu-v4 .hs-sub-menu .dropdown-item:focus > a, .navbar .u-sub-menu-v4 .hs-sub-menu .dropdown-item:hover > a,
.navbar .u-sub-menu-v4 .dropdown-menu .dropdown-item.active > a,
.navbar .u-sub-menu-v4 .dropdown-menu .dropdown-item:active > a,
.navbar .u-sub-menu-v4 .dropdown-menu .dropdown-item:focus > a,
.navbar .u-sub-menu-v4 .dropdown-menu .dropdown-item:hover > a, .navbar .u-sub-menu-v4 .hs-sub-menu a:active, .navbar .u-sub-menu-v4 .hs-sub-menu a:focus, .navbar .u-sub-menu-v4 .hs-sub-menu a:hover,
.navbar .u-sub-menu-v4 .dropdown-menu a:active,
.navbar .u-sub-menu-v4 .dropdown-menu a:focus,
.navbar .u-sub-menu-v4 .dropdown-menu a:hover {
  color: #fff !important;
  background-color: #2cb8f3;
  border: none;
}

.navbar .u-sub-menu-v4 .hs-has-sub-menu .hs-has-sub-menu > a::after {
  content: "\e902";
  font-family: "hs-icons";
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate3d(0, -50%, 0);
}

.navbar .u-sub-menu-v4 .hs-has-mega-menu .hs-has-sub-menu > a::after {
  content: "\e902";
}

.navbar .u-sub-menu-v4 .hs-sub-menu,
.navbar .u-sub-menu-v4 .dropdown-menu {
  font-size: 0.92857rem;
  min-width: 16.42857rem;
  padding: 0.71429rem 0;
}

.navbar .u-sub-menu-v4 .hs-sub-menu .dropdown-item,
.navbar .u-sub-menu-v4 .dropdown-menu .dropdown-item {
  font-weight: inherit;
  color: inherit;
  padding: 0;
}

.navbar .u-sub-menu-v4 .hs-sub-menu .dropdown-item.active, .navbar .u-sub-menu-v4 .hs-sub-menu .dropdown-item:active, .navbar .u-sub-menu-v4 .hs-sub-menu .dropdown-item:focus, .navbar .u-sub-menu-v4 .hs-sub-menu .dropdown-item:hover,
.navbar .u-sub-menu-v4 .dropdown-menu .dropdown-item.active,
.navbar .u-sub-menu-v4 .dropdown-menu .dropdown-item:active,
.navbar .u-sub-menu-v4 .dropdown-menu .dropdown-item:focus,
.navbar .u-sub-menu-v4 .dropdown-menu .dropdown-item:hover {
  border: none;
  background-color: transparent;
}

.navbar .u-sub-menu-v4 .hs-sub-menu a,
.navbar .u-sub-menu-v4 .dropdown-menu a {
  color: #777;
  text-decoration: none !important;
  display: block;
  padding: 0.42857rem 1.78571rem;
}

.navbar .u-sub-menu-v4 .hs-sub-menu > li:not(:last-child),
.navbar .u-sub-menu-v4 .dropdown-menu > li:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.navbar .u-sub-menu-v4 .hs-sub-menu,
.navbar .u-sub-menu-v4 .hs-mega-menu,
.navbar .u-sub-menu-v4 .dropdown-menu {
  font-weight: normal;
  text-transform: none;
  min-width: 14.28571rem;
  margin-top: 0;
  padding: 0;
  color: inherit;
  background-color: #fff;
  background-clip: border-box;
  border-width: 2px 0 2px 0;
  border-style: solid;
  border-top-color: #2cb8f3;
  border-bottom-color: #777;
  border-radius: 0;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.navbar .u-sub-menu-v4 .hs-sub-menu .hs-sub-menu,
.navbar .u-sub-menu-v4 .hs-mega-menu .hs-sub-menu,
.navbar .u-sub-menu-v4 .dropdown-menu .hs-sub-menu {
  margin-top: -1.07143rem;
}

.navbar .u-sub-menu-v4.hs-mobile-state .hs-sub-menu {
  width: auto;
}

.navbar .u-sub-menu-v4.hs-mobile-state .hs-sub-menu .hs-sub-menu,
.navbar .u-sub-menu-v4.hs-mobile-state .hs-sub-menu .hs-mega-menu,
.navbar .u-sub-menu-v4.hs-mobile-state .hs-sub-menu .hs-sub-menu.hs-reversed,
.navbar .u-sub-menu-v4.hs-mobile-state .hs-sub-menu .hs-mega-menu.hs-reversed,
.navbar .u-sub-menu-v4.hs-mobile-state .hs-mega-menu .hs-sub-menu,
.navbar .u-sub-menu-v4.hs-mobile-state .hs-mega-menu .hs-mega-menu,
.navbar .u-sub-menu-v4.hs-mobile-state .hs-mega-menu .hs-sub-menu.hs-reversed,
.navbar .u-sub-menu-v4.hs-mobile-state .hs-mega-menu .hs-mega-menu.hs-reversed {
  margin: 0.21429rem;
}

.navbar .hs-mobile-state .u-sub-menu-v4 .hs-sub-menu {
  width: auto;
}

.navbar .hs-mobile-state .u-sub-menu-v4 .hs-sub-menu .hs-sub-menu,
.navbar .hs-mobile-state .u-sub-menu-v4 .hs-sub-menu .hs-mega-menu,
.navbar .hs-mobile-state .u-sub-menu-v4 .hs-sub-menu .hs-sub-menu.hs-reversed,
.navbar .hs-mobile-state .u-sub-menu-v4 .hs-sub-menu .hs-mega-menu.hs-reversed,
.navbar .hs-mobile-state .u-sub-menu-v4 .hs-mega-menu .hs-sub-menu,
.navbar .hs-mobile-state .u-sub-menu-v4 .hs-mega-menu .hs-mega-menu,
.navbar .hs-mobile-state .u-sub-menu-v4 .hs-mega-menu .hs-sub-menu.hs-reversed,
.navbar .hs-mobile-state .u-sub-menu-v4 .hs-mega-menu .hs-mega-menu.hs-reversed {
  margin: 0.21429rem;
}

/*------------------------------------
  Navigation Dropdown Style v5
------------------------------------*/
.u-dropdown-v5 {
  display: block;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: opacity .3s, max-height .3s;
}

.u-dropdown-v5.show {
  max-height: 200px;
  opacity: 1;
}

/*------------------------------------
  Navigation Dropdown Style Inline
------------------------------------*/
.u-navbar--inline-submenu .dropdown-menu {
  white-space: nowrap;
  top: 98%;
  left: 50%;
  border-radius: 0;
  overflow: visible !important;
  border-left: none;
  border-right: none;
  transform: translate3d(-50%, 0, 0);
}

.u-navbar--inline-submenu .dropdown-menu::after, .u-navbar--inline-submenu .dropdown-menu::before {
  content: "";
  position: absolute;
  top: -1px;
  bottom: 0;
  width: 2000%;
  border-top: inherit;
  border-bottom: inherit;
  background-color: inherit;
}

.u-navbar--inline-submenu .dropdown-menu::after {
  left: 100%;
}

.u-navbar--inline-submenu .dropdown-menu::before {
  right: 100%;
}

.u-navbar--inline-submenu .dropdown-menu > li {
  display: inline-block;
}

.u-navbar--inline-submenu.u-navbar--overflow .dropdown,
.u-navbar--inline-submenu.u-navbar--overflow .container {
  position: static;
}

.u-navbar--inline-submenu.u-navbar--overflow .dropdown-menu {
  white-space: normal;
  left: 0;
  transform: translate3d(0, 0, 0);
  margin-top: 0;
}

@media all and (min-width: 1200px) {
  .u-navbar--inline-submenu--xl .dropdown-menu {
    white-space: nowrap;
    top: 98%;
    left: 50%;
    border-radius: 0;
    overflow: visible !important;
    border-left: none;
    border-right: none;
    transform: translate3d(-50%, 0, 0);
  }
  .u-navbar--inline-submenu--xl .dropdown-menu::after, .u-navbar--inline-submenu--xl .dropdown-menu::before {
    content: "";
    position: absolute;
    top: -1px;
    bottom: 0;
    width: 2000%;
    border-top: inherit;
    border-bottom: inherit;
    background-color: inherit;
  }
  .u-navbar--inline-submenu--xl .dropdown-menu::after {
    left: 100%;
  }
  .u-navbar--inline-submenu--xl .dropdown-menu::before {
    right: 100%;
  }
  .u-navbar--inline-submenu--xl .dropdown-menu > li {
    display: inline-block;
  }
  .u-navbar--inline-submenu--xl.u-navbar--overflow .dropdown,
  .u-navbar--inline-submenu--xl.u-navbar--overflow .container {
    position: static;
  }
  .u-navbar--inline-submenu--xl.u-navbar--overflow .dropdown-menu {
    white-space: normal;
    left: 0;
    transform: translate3d(0, 0, 0);
    margin-top: 0;
  }
}

@media all and (min-width: 992px) {
  .u-navbar--inline-submenu--lg .dropdown-menu {
    white-space: nowrap;
    top: 98%;
    left: 50%;
    border-radius: 0;
    overflow: visible !important;
    border-left: none;
    border-right: none;
    transform: translate3d(-50%, 0, 0);
  }
  .u-navbar--inline-submenu--lg .dropdown-menu::after, .u-navbar--inline-submenu--lg .dropdown-menu::before {
    content: "";
    position: absolute;
    top: -1px;
    bottom: 0;
    width: 2000%;
    border-top: inherit;
    border-bottom: inherit;
    background-color: inherit;
  }
  .u-navbar--inline-submenu--lg .dropdown-menu::after {
    left: 100%;
  }
  .u-navbar--inline-submenu--lg .dropdown-menu::before {
    right: 100%;
  }
  .u-navbar--inline-submenu--lg .dropdown-menu > li {
    display: inline-block;
  }
  .u-navbar--inline-submenu--lg.u-navbar--overflow .dropdown,
  .u-navbar--inline-submenu--lg.u-navbar--overflow .container {
    position: static;
  }
  .u-navbar--inline-submenu--lg.u-navbar--overflow .dropdown-menu {
    white-space: normal;
    left: 0;
    transform: translate3d(0, 0, 0);
    margin-top: 0;
  }
}

@media all and (min-width: 768px) {
  .u-navbar--inline-submenu--md .dropdown-menu {
    white-space: nowrap;
    top: 98%;
    left: 50%;
    border-radius: 0;
    overflow: visible !important;
    border-left: none;
    border-right: none;
    transform: translate3d(-50%, 0, 0);
  }
  .u-navbar--inline-submenu--md .dropdown-menu::after, .u-navbar--inline-submenu--md .dropdown-menu::before {
    content: "";
    position: absolute;
    top: -1px;
    bottom: 0;
    width: 2000%;
    border-top: inherit;
    border-bottom: inherit;
    background-color: inherit;
  }
  .u-navbar--inline-submenu--md .dropdown-menu::after {
    left: 100%;
  }
  .u-navbar--inline-submenu--md .dropdown-menu::before {
    right: 100%;
  }
  .u-navbar--inline-submenu--md .dropdown-menu > li {
    display: inline-block;
  }
  .u-navbar--inline-submenu--md.u-navbar--overflow .dropdown,
  .u-navbar--inline-submenu--md.u-navbar--overflow .container {
    position: static;
  }
  .u-navbar--inline-submenu--md.u-navbar--overflow .dropdown-menu {
    white-space: normal;
    left: 0;
    transform: translate3d(0, 0, 0);
    margin-top: 0;
  }
}

@media all and (min-width: 576px) {
  .u-navbar--inline-submenu--sm .dropdown-menu {
    white-space: nowrap;
    top: 98%;
    left: 50%;
    border-radius: 0;
    overflow: visible !important;
    border-left: none;
    border-right: none;
    transform: translate3d(-50%, 0, 0);
  }
  .u-navbar--inline-submenu--sm .dropdown-menu::after, .u-navbar--inline-submenu--sm .dropdown-menu::before {
    content: "";
    position: absolute;
    top: -1px;
    bottom: 0;
    width: 2000%;
    border-top: inherit;
    border-bottom: inherit;
    background-color: inherit;
  }
  .u-navbar--inline-submenu--sm .dropdown-menu::after {
    left: 100%;
  }
  .u-navbar--inline-submenu--sm .dropdown-menu::before {
    right: 100%;
  }
  .u-navbar--inline-submenu--sm .dropdown-menu > li {
    display: inline-block;
  }
  .u-navbar--inline-submenu--sm.u-navbar--overflow .dropdown,
  .u-navbar--inline-submenu--sm.u-navbar--overflow .container {
    position: static;
  }
  .u-navbar--inline-submenu--sm.u-navbar--overflow .dropdown-menu {
    white-space: normal;
    left: 0;
    transform: translate3d(0, 0, 0);
    margin-top: 0;
  }
}

/*------------------------------------
  Navigation Dropdown With Columns
------------------------------------*/
@media (min-width: 992px) {
  [class*="u-dropdown-col-"] {
    column-gap: 1px;
    column-rule: 1px solid #f7f7f7;
  }
  [class*="u-dropdown-col-"] > * {
    min-width: 200px;
    -webkit-column-break-inside: avoid;
    column-break-inside: avoid;
  }
  .u-dropdown-col-2 {
    columns: 2;
  }
  .u-dropdown-col-3 {
    columns: 3;
  }
}

/*------------------------------------
  Navigation Dropdown Static
------------------------------------*/
.u-dropdown-static {
  position: static !important;
  display: block;
  max-height: 0;
  margin: 0 1.07143rem;
  padding: 0.35714rem 0;
  transform: translate3d(0, 0, 0) !important;
  border: none;
  background-color: transparent;
  overflow: hidden;
  opacity: 0;
  transition: opacity .3s, max-height .3s;
}

.u-dropdown-static.show {
  max-height: 100%;
  opacity: 1;
}

/* Main navigation behaviors */
/*------------------------------------
  Navigation Behavior (Overlay)
------------------------------------*/
body.u-main-nav--overlay-left .u-main-nav__overlay, body.u-main-nav--overlay-right .u-main-nav__overlay {
  overflow: hidden;
}

body.u-main-nav--overlay-right .u-main-nav--overlay {
  right: -21.42857rem;
}

body.u-main-nav--overlay-right .u-main-nav__overlay {
  right: 100%;
}

body.u-main-nav--overlay-right.u-main-nav--overlay-opened .u-main-nav--overlay {
  right: 0;
}

body.u-main-nav--overlay-right.u-main-nav--overlay-opened .u-main-nav__overlay {
  opacity: 1;
  visibility: visible;
}

body.u-main-nav--overlay-left .u-main-nav--overlay {
  left: -21.42857rem;
}

body.u-main-nav--overlay-left .u-main-nav__overlay {
  left: 100%;
}

body.u-main-nav--overlay-left.u-main-nav--overlay-opened .u-main-nav--overlay {
  left: 0;
}

body.u-main-nav--overlay-left.u-main-nav--overlay-opened .u-main-nav__overlay {
  opacity: 1;
  visibility: visible;
}

.navbar-collapse.u-main-nav--overlay {
  position: fixed;
  top: 0;
  height: 100%;
  z-index: 5;
  margin: 0 !important;
  width: 21.42857rem;
  align-items: flex-start !important;
}

.navbar-collapse.u-main-nav--overlay .navbar-nav {
  width: 100%;
  flex-direction: column;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.navbar-collapse.u-main-nav--overlay .dropdown-menu {
  position: static;
  width: 100%;
}

.navbar-collapse.u-main-nav--overlay .u-main-nav__list-wrapper {
  margin-left: 0;
  margin-right: 0;
  padding: 2.14286rem 0.71429rem;
  width: 100%;
  height: 100%;
}

.navbar-collapse.u-main-nav--overlay .u-main-nav__list-wrapper .navbar-nav {
  padding: 0.71429rem;
}

/*------------------------------------
  Navigation Behavior (Push)
------------------------------------*/
body.u-main-nav--push-left, body.u-main-nav--push-right {
  width: 100%;
}

body.u-main-nav--push-left .u-main-nav__push, body.u-main-nav--push-right .u-main-nav__push {
  overflow: hidden;
}

body.u-main-nav--push-right .u-main-nav--push {
  right: -21.42857rem;
}

body.u-main-nav--push-right .u-main-nav__overlay {
  right: 100%;
}

body.u-main-nav--push-right.u-main-nav--overlay-opened {
  margin-right: 21.42857rem;
}

body.u-main-nav--push-right.u-main-nav--overlay-opened .u-main-nav--push {
  right: 0;
}

body.u-main-nav--push-right.u-main-nav--overlay-opened .u-main-nav__overlay {
  opacity: 1;
  visibility: visible;
}

body.u-main-nav--push-left .u-main-nav--push {
  left: -21.42857rem;
}

body.u-main-nav--push-left .u-main-nav__overlay {
  left: 100%;
}

body.u-main-nav--push-left.u-main-nav--overlay-opened {
  margin-left: 21.42857rem;
}

body.u-main-nav--push-left.u-main-nav--overlay-opened .u-main-nav--push {
  left: 0;
}

body.u-main-nav--push-left.u-main-nav--overlay-opened .u-main-nav__overlay {
  opacity: 1;
  visibility: visible;
}

.navbar-collapse.u-main-nav--push {
  position: fixed;
  top: 0;
  height: 100%;
  z-index: 5;
  margin: 0 !important;
  width: 21.42857rem;
  max-width: 85%;
  align-items: flex-start !important;
}

.navbar-collapse.u-main-nav--push .navbar-nav {
  width: 100%;
  flex-direction: column;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.navbar-collapse.u-main-nav--push .dropdown-menu {
  position: static;
  width: 100%;
}

.navbar-collapse.u-main-nav--push .u-main-nav__list-wrapper {
  margin-left: 0;
  margin-right: 0;
  padding: 2.14286rem 0.71429rem;
  width: 100%;
  height: 100%;
}

.navbar-collapse.u-main-nav--push .u-main-nav__list-wrapper .navbar-nav {
  padding: 0.71429rem;
}

/*------------------------------------
  Navigation Behavior (Smart)
------------------------------------*/
.u-smart-nav {
  position: fixed;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transform: scale3d(0.2, 0.2, 0.2);
}

.u-smart-nav .navbar {
  position: absolute;
  width: 21.42857rem;
  opacity: 0;
  visibility: hidden;
}

.u-smart-nav .navbar .dropdown {
  z-index: 1;
}

.u-smart-nav .navbar .dropdown-menu {
  position: relative;
  width: 100%;
}

.u-smart-nav .navbar li {
  transform: translate3d(30px, 0, 0);
  transition: all .3s ease;
}

.u-smart-nav .navbar li:nth-child(1) {
  transition-delay: 60ms;
}

.u-smart-nav .navbar li:nth-child(2) {
  transition-delay: 90ms;
}

.u-smart-nav .navbar li:nth-child(3) {
  transition-delay: 120ms;
}

.u-smart-nav .navbar li:nth-child(4) {
  transition-delay: 150ms;
}

.u-smart-nav .navbar li:nth-child(5) {
  transition-delay: 180ms;
}

.u-smart-nav .navbar li:nth-child(6) {
  transition-delay: 210ms;
}

.u-smart-nav .navbar li:nth-child(7) {
  transition-delay: 240ms;
}

.u-smart-nav .navbar li:nth-child(8) {
  transition-delay: 270ms;
}

.u-smart-nav .navbar li:nth-child(9) {
  transition-delay: 300ms;
}

.u-smart-nav .navbar li:nth-child(10) {
  transition-delay: 330ms;
}

.u-smart-nav .navbar li:nth-child(11) {
  transition-delay: 360ms;
}

.u-smart-nav .navbar li:nth-child(12) {
  transition-delay: 390ms;
}

.u-smart-nav .navbar li:nth-child(13) {
  transition-delay: 420ms;
}

.u-smart-nav .navbar li:nth-child(14) {
  transition-delay: 450ms;
}

.u-smart-nav .navbar li:nth-child(15) {
  transition-delay: 480ms;
}

.u-smart-nav .navbar li:nth-child(16) {
  transition-delay: 510ms;
}

.u-smart-nav .navbar li:nth-child(17) {
  transition-delay: 540ms;
}

.u-smart-nav .navbar li:nth-child(18) {
  transition-delay: 570ms;
}

.u-smart-nav .navbar li:nth-child(19) {
  transition-delay: 600ms;
}

.u-smart-nav__toggler {
  position: relative;
  z-index: 1;
}

.u-smart-nav--opened .navbar {
  opacity: 1;
  visibility: visible;
  transform: scale3d(1, 1, 1) !important;
}

.u-smart-nav--opened .navbar li {
  transform: translate3d(0, 0, 0);
}

.u-smart-nav--shown {
  opacity: 1;
  visibility: visible;
  transform: scale3d(1, 1, 1);
}

.u-smart-nav--bottom-right {
  right: 0.71429rem;
  bottom: 0.71429rem;
}

.u-smart-nav--bottom-right .navbar {
  bottom: 0;
  right: 0;
  transform: scale3d(0.1, 0.1, 0.1);
  transform-origin: 100% 100%;
  padding-right: 5.71429rem;
}

.u-smart-nav--bottom-left {
  left: 0.71429rem;
  bottom: 0.71429rem;
}

.u-smart-nav--bottom-left .navbar {
  text-align: right;
  bottom: 0;
  left: 0;
  transform: scale3d(0.1, 0.1, 0.1);
  transform-origin: 0% 100%;
  padding-left: 5.71429rem;
}

.u-smart-nav--top-left {
  left: 0.71429rem;
  top: 0.71429rem;
}

.u-smart-nav--top-left .navbar {
  text-align: right;
  top: 0;
  left: 0;
  transform: scale3d(0.1, 0.1, 0.1);
  transform-origin: 0% 0%;
  padding-left: 5.71429rem;
}

.u-smart-nav--top-right {
  right: 0.71429rem;
  top: 0.71429rem;
}

.u-smart-nav--top-right .navbar {
  top: 0;
  right: 0;
  transform: scale3d(0.1, 0.1, 0.1);
  transform-origin: 100% 0%;
  padding-right: 5.71429rem;
}

/* Secondary navigation styles */
/*------------------------------------
  Secondary Navigation
------------------------------------*/
.u-secondary-navigation {
  z-index: 3;
}

.u-secondary-navigation .active a {
  color: #2cb8f3;
}

/* Sidebar shortcode navigation styles */
/*------------------------------------
  Sidebar Navigation
------------------------------------*/
@media all and (min-width: 992px) {
  .u-sidebar-navigation {
    position: fixed;
    top: 0;
    left: -21.42857rem;
    width: 300px;
  }
}

.u-sidebar-navigation-inner {
  background-color: #1c2434;
  color: #fff;
  padding: 20px 0;
}

.u-sidebar-navigation__search-input {
  border-radius: 3px;
  border: none;
}

.u-sidebar-navigation .nav-link {
  color: #fff;
  padding: 0.5rem 1.42857rem;
}

.u-sidebar-navigation .nav-link:hover {
  background-color: rgba(255, 255, 255, 0.15);
}

.u-sidebar-navigation .nav-link.active {
  background-color: rgba(255, 255, 255, 0.1);
}

.u-sidebar-navigation__toggler {
  position: fixed;
  top: 120px;
  left: 0;
  width: 53px;
  height: 53px;
  background-color: #1c2434;
  color: #fff;
  border-radius: 0 3px 3px 0;
  font-size: 22px;
  z-index: 2;
}

.u-sidebar-navigation__toggler:hover, .u-sidebar-navigation__toggler:focus {
  color: #fff;
}

.u-sidebar-navigation__closer {
  position: absolute;
  top: 22px;
  right: 15px;
  width: 25px;
  height: 25px;
  padding: 0;
  color: #fff;
  background-color: transparent;
  z-index: 3;
}

.u-sidebar-navigation__closer:hover, .u-sidebar-navigation__closer:focus {
  color: #fff;
}

@media all and (max-width: 992px - 1) {
  .u-sidebar-navigation-list {
    display: block;
    columns: 4;
  }
  .u-sidebar-navigation-list .dropdown-toggle {
    display: none;
  }
  .u-sidebar-navigation-list .dropdown-menu {
    display: block;
    max-height: 100%;
    margin: 0;
    float: none;
    opacity: 1;
  }
}

@media all and (max-width: 768px - 1) {
  .u-sidebar-navigation-list {
    columns: 3;
  }
}

@media all and (max-width: 576px - 1) {
  .u-sidebar-navigation-list {
    columns: 1;
  }
}

.u-has-sidebar-navigation .u-header__overlay {
  display: none;
}

/* Override vendor stylesheets */
.hamburger-inner::before,
.hamburger-inner::after {
  background-color: inherit;
}

[class*="u-main-nav-"] .dropdown-toggle::after {
  width: auto;
  height: auto;
  vertical-align: inherit;
  border: none;
}

/* Overlay */
.u-main-nav__overlay {
  position: absolute;
  top: 0;
  height: 100%;
  width: 2000%;
  opacity: 0;
  visibility: hidden;
}

/* Override necessary navigation styles in side-header pages */
body.u-body--header-side-static-left .navbar [class*="u-main-nav-"] .dropdown-toggle::after,
body.u-body--header-side-static-left .navbar [class*="u-main-nav-"] > .hs-has-sub-menu > a::after,
body.u-body--header-side-static-left .navbar [class*="u-main-nav-"] > .hs-has-mega-menu > a::after {
  content: "\e900";
}

body.u-body--header-side-static-right .navbar [class*="u-main-nav-"] .dropdown-toggle::after,
body.u-body--header-side-static-right .navbar [class*="u-main-nav-"] > .hs-has-sub-menu > a::after,
body.u-body--header-side-static-right .navbar [class*="u-main-nav-"] > .hs-has-mega-menu > a::after {
  content: "\e901";
}

/* Scroll on mobile devices */
@media (max-width: 992px - 1) {
  .u-nav-mobile-scroll {
    overflow-y: auto;
  }
}

/*------------------------------------
  Basket Bar
------------------------------------*/
/* Container */
.u-basket {
  position: relative;
  z-index: 1;
}

/* Bar */
.u-basket__bar {
  position: absolute;
  right: 0;
  top: 100%;
  text-align: left;
  width: 21.42857rem;
  background-color: #fff;
  box-shadow: 0 5px 5px 0px rgba(90, 90, 90, 0.075);
}

/* Product */
.u-basket__product {
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding: 0.71429rem;
  border-bottom: solid 1px #eee;
}

/* Product Image */
.u-basket__product-img {
  width: 7.14286rem;
}

.u-basket__product-img img {
  width: 100%;
}

/* Product Remove Button */
.u-basket__product-remove {
  position: absolute;
  top: 1.42857rem;
  right: 1.42857rem;
  padding: 0;
  margin: 0;
  font-size: 1.5rem;
  font-family: inherit;
  font-weight: 700;
  color: #000;
  border: none;
  background-color: transparent;
  cursor: pointer;
  opacity: .2;
}

.u-basket__product-remove:hover, .u-basket__product-remove:focus {
  opacity: 1;
}

/*------------------------------------
	Dropdowns
------------------------------------*/
[class*="u-dropdown--"] [class*="u-dropdown--"] {
  left: 100%;
  top: 0;
}

[class*="u-dropdown--"] [class*="u-dropdown--"].u-dropdown--reverse-x {
  left: auto;
  right: 100%;
}

.u-dropdown--simple.u-dropdown--reverse-y,
.u-dropdown--jquery-slide.u-dropdown--reverse-y,
.u-dropdown--css-animation.u-dropdown--reverse-y {
  top: auto;
  bottom: 100%;
  margin-top: 0 !important;
  margin-bottom: 1.42857rem;
}

/* Simple Dropdown */
.u-dropdown--simple.u-dropdown--hidden {
  display: none;
}

/* CSS Animation Dropdown */
.u-dropdown--css-animation {
  animation-duration: 1s;
}

.u-dropdown--css-animation.u-dropdown--hidden {
  opacity: 0;
  visibility: hidden;
}

/* jQuery Slide */
.u-dropdown--jquery-slide.u-dropdown--hidden {
  opacity: 0;
  visibility: hidden;
}

.u-dropdown--hidden:not(.hs-sub-menu-opened) * {
  transition: none !important;
}

.u-dropdown--hidden:not(.hs-sub-menu-opened) *::before, .u-dropdown--hidden:not(.hs-sub-menu-opened) *::after {
  transition: none !important;
}

/*------------------------------------
  Compressed Form
------------------------------------*/
.u-compressed-form {
  display: inline-block;
  overflow: hidden;
  width: 20rem;
  transition: all .35s ease;
}

.u-compressed-form--hidden {
  width: 5.71429rem;
}

.u-compressed-form .input-group {
  display: block;
  float: right;
}

/*------------------------------------
  Dropcaps
------------------------------------*/
.u-dropcap, .u-dropcap-underline, .u-dropcap-bg, .u-dropcap-bordered {
  float: left;
  font-size: 3.57rem;
  line-height: 1;
}

.u-dropcap-underline {
  padding-bottom: 0.35714rem;
  border-bottom: 2px solid;
}

.u-dropcap-bg {
  width: 5rem;
  height: 5rem;
  margin-top: 0.35714rem;
  text-align: center;
  line-height: 4.78rem;
}

.u-dropcap-bordered {
  width: 5rem;
  height: 5rem;
  margin-top: 0.35714rem;
  text-align: center;
  line-height: 4.35rem;
  border: 3px solid;
}

/*------------------------------------
  BG Angles
------------------------------------*/
.u-angle-v1--top-left--bg-light,
.u-angle-v1--top-right--bg-light,
.u-angle-v1--bottom-left--bg-light,
.u-angle-v1--bottom-right--bg-light,
.u-angle-v1--top-left--bg-dark,
.u-angle-v1--top-right--bg-dark,
.u-angle-v1--bottom-left--bg-dark,
.u-angle-v1--bottom-right--bg-dark,
.u-angle-v2--top-left--bg-light,
.u-angle-v2--top-right--bg-light,
.u-angle-v2--bottom-left--bg-light,
.u-angle-v2--bottom-right--bg-light,
.u-angle-v2--top-left--bg-dark,
.u-angle-v2--top-right--bg-dark,
.u-angle-v2--bottom-left--bg-dark,
.u-angle-v2--bottom-right--bg-dark {
  position: relative;
  overflow: hidden;
}

/* V-1
------------------------------------*/
/* Light */
.u-angle-v1--top-left--bg-light::after {
  content: "";
  position: absolute;
  top: -10px;
  left: -100px;
  transform: rotate(-55deg);
  width: 250px;
  height: 120px;
  background-color: rgba(255, 255, 255, 0.1);
}

.u-angle-v1--top-right--bg-light::after {
  content: "";
  position: absolute;
  top: -10px;
  right: -100px;
  transform: rotate(55deg);
  width: 250px;
  height: 120px;
  background-color: rgba(255, 255, 255, 0.1);
}

.u-angle-v1--bottom-left--bg-light::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: -100px;
  transform: rotate(55deg);
  width: 250px;
  height: 120px;
  background-color: rgba(255, 255, 255, 0.1);
}

.u-angle-v1--bottom-right--bg-light::after {
  content: "";
  position: absolute;
  bottom: -10px;
  right: -100px;
  transform: rotate(-55deg);
  width: 250px;
  height: 120px;
  background-color: rgba(255, 255, 255, 0.1);
}

/* Dark */
.u-angle-v1--top-left--bg-dark::after {
  content: "";
  position: absolute;
  top: -10px;
  left: -100px;
  transform: rotate(-55deg);
  width: 250px;
  height: 120px;
  background-color: rgba(0, 0, 0, 0.1);
}

.u-angle-v1--top-right--bg-dark::after {
  content: "";
  position: absolute;
  top: -10px;
  right: -100px;
  transform: rotate(55deg);
  width: 250px;
  height: 120px;
  background-color: rgba(0, 0, 0, 0.1);
}

.u-angle-v1--bottom-left--bg-dark::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: -100px;
  transform: rotate(55deg);
  width: 250px;
  height: 120px;
  background-color: rgba(0, 0, 0, 0.1);
}

.u-angle-v1--bottom-right--bg-dark::after {
  content: "";
  position: absolute;
  bottom: -10px;
  right: -100px;
  transform: rotate(-55deg);
  width: 250px;
  height: 120px;
  background-color: rgba(0, 0, 0, 0.1);
}

/* V-2
------------------------------------*/
/* Light */
.u-angle-v2--top-left--bg-light::after {
  content: "";
  position: absolute;
  top: -10px;
  left: -100px;
  transform: rotate(-55deg);
  width: 250px;
  height: 120px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
}

.u-angle-v2--top-right--bg-light::after {
  content: "";
  position: absolute;
  top: -10px;
  right: -100px;
  transform: rotate(55deg);
  width: 250px;
  height: 120px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
}

.u-angle-v2--bottom-left--bg-light::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: -100px;
  transform: rotate(55deg);
  width: 250px;
  height: 120px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
}

.u-angle-v2--bottom-right--bg-light::after {
  content: "";
  position: absolute;
  bottom: -10px;
  right: -100px;
  transform: rotate(-55deg);
  width: 250px;
  height: 120px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
}

/* Dark */
.u-angle-v2--top-left--bg-dark::after {
  content: "";
  position: absolute;
  top: -10px;
  left: -100px;
  transform: rotate(-55deg);
  width: 250px;
  height: 120px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}

.u-angle-v2--top-right--bg-dark::after {
  content: "";
  position: absolute;
  top: -10px;
  right: -100px;
  transform: rotate(55deg);
  width: 250px;
  height: 120px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}

.u-angle-v2--bottom-left--bg-dark::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: -100px;
  transform: rotate(55deg);
  width: 250px;
  height: 120px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}

.u-angle-v2--bottom-right--bg-dark::after {
  content: "";
  position: absolute;
  bottom: -10px;
  right: -100px;
  transform: rotate(-55deg);
  width: 250px;
  height: 120px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}

/*------------------------------------
  Block Hovers
------------------------------------*/
[class*="u-block-hover"] {
  position: relative;
  max-width: 100%;
  margin: 0;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: translateZ(0);
}

[class*="u-block-hover"], [class*="u-block-hover"]::before, [class*="u-block-hover"]::after {
  transition: all .3s ease;
}

.u-block-hover--uncroped {
  overflow: visible;
}

.u-block-hover--uncroped:hover {
  z-index: 2;
}

.u-block-hover__img {
  max-width: 100%;
  vertical-align: top;
}

.u-block-hover__block {
  min-height: 100%;
}

[class*="u-block-hover__additional"] {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

[class*="u-block-hover__additional--v1"] {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 15px;
  opacity: 0;
}

.u-block-hover:hover .u-block-hover__additional--v1 {
  opacity: 1;
}

.u-block-hover--shadow:hover,
.u-block-hover--shadow:hover .u-block-hover__additional-shadow {
  box-shadow: 0 0 10px #777;
}

.u-block-hover--shadow-v2:hover,
.u-block-hover--shadow-v2:hover .u-block-hover__additional-shadow {
  box-shadow: 0 0 15px #ddd;
}

.u-block-hover--shadow-v3:hover,
.u-block-hover--shadow-v3:hover .u-block-hover__additional-shadow {
  box-shadow: 0 0 3px #ddd;
}

.u-block-hover--scale:hover,
.u-block-hover--scale-img:hover .u-block-hover__img {
  transform: scale3d(1.1, 1.1, 1.1);
}

.u-block-hover__additional-scale {
  z-index: -1;
  max-width: initial;
}

.u-block-hover:hover .u-block-hover__additional-scale {
  top: -20px;
  right: -20px;
  bottom: -20px;
  left: -20px;
}

.u-block-hover--scale-down:hover,
.u-block-hover--scale-down-img:hover .u-block-hover__img {
  transform: scale3d(0.9, 0.9, 0.9);
}

.u-block-hover__additional-scale-down {
  z-index: -1;
  max-width: initial;
}

.u-block-hover:hover .u-block-hover__additional-scale-down {
  top: 20px;
  right: 20px;
  bottom: 20px;
  left: 20px;
}

.u-block-hover__main--zoom-v1,
.u-block-hover__main--zoom-v2 {
  -webkit-transform: scale(1.01);
}

.u-block-hover:hover .u-block-hover__main--zoom-v1 {
  transform: scale(1.1);
}

.u-block-hover:hover .u-block-hover__main--zoom-v2 {
  transform: scale(1.04);
}

.u-block-hover [class*="icon-"] {
  padding: 1px;
}

.u-block-hover__main--grayscale {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
  /* Firefox 10+, Firefox on Android */
  filter: gray;
  -webkit-filter: grayscale(100%);
}

.u-block-hover:hover .u-block-hover__main--grayscale {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(0%);
}

.u-block-hover__main--white {
  filter: brightness(0) invert(1);
}

.u-block-hover__additional--blur {
  opacity: 0;
}

.u-block-hover:hover .u-block-hover__additional--blur {
  opacity: 1;
  transition-delay: .11s;
}

.u-block-hover:hover .u-block-hover__main--blur {
  filter: blur(30px);
  transform: scale(1.2);
  opacity: 0;
}

[class*="u-block-hover-image-overlay"]::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: all .35s ease;
}

.u-block-hover:hover .u-block-hover-image-overlay::after {
  opacity: 1;
}

.u-block-hover__additional--fade {
  opacity: 0;
}

.u-block-hover:hover .u-block-hover__additional--fade {
  opacity: 1;
  transform: translate(0, 0);
}

.u-block-hover__additional--fade-up {
  transform: translate(0, 15%);
}

.u-block-hover__additional--fade-down {
  transform: translate(0, -15%);
}

.u-block-hover__additional--fade-left {
  transform: translate(-15%, 0);
}

.u-block-hover__additional--fade-right {
  transform: translate(15%, 0);
}

.u-block-hover:hover [class*="u-block-hover__additional--push"] {
  transform: translate(0, 0);
}

.u-block-hover:hover .u-block-hover__main--push-up {
  transform: translateY(-100%);
}

.u-block-hover__additional--push-up {
  transform: translateY(100%);
}

.u-block-hover:hover .u-block-hover__main--push-down {
  transform: translateY(100%);
}

.u-block-hover__additional--push-down {
  transform: translateY(-100%);
}

.u-block-hover:hover .u-block-hover__main--push-left {
  transform: translateX(-100%);
}

.u-block-hover__additional--push-left {
  transform: translateX(100%);
}

.u-block-hover:hover .u-block-hover__main--push-right {
  transform: translateX(100%);
}

.u-block-hover__additional--push-right {
  transform: translateX(-100%);
}

.u-block-hover:hover [class*="u-block-hover__additional--slide"],
.u-block-hover:hover [class*="u-block-hover__additional--partially-slide"] {
  transform: translate(0, 0);
}

.u-block-hover__additional--slide-up {
  transform: translateY(100%);
}

.u-block-hover__additional--slide-down {
  transform: translateY(-100%);
}

.u-block-hover__additional--slide-left {
  transform: translateX(100%);
}

.u-block-hover__additional--slide-right {
  transform: translateX(-100%);
}

.u-block-hover__additional--slide-bottom-right {
  transform: translate(-100%, -100%);
}

.u-block-hover__additional--slide-bottom-left {
  transform: translate(100%, -100%);
}

.u-block-hover__additional--slide-top-right {
  transform: translate(-100%, 100%);
}

.u-block-hover__additional--slide-top-left {
  transform: translate(100%, 100%);
}

.u-block-hover__additional--partially-slide-up {
  top: auto;
  transform: translate3d(0, 100%, 0);
  overflow: visible;
}

.u-block-hover__additional--partially-slide-up .u-block-hover__visible {
  position: absolute;
  bottom: 100%;
  margin-bottom: -1px;
  left: 0;
  right: 0;
  padding-left: inherit;
  padding-right: inherit;
  background: inherit;
}

.u-block-hover__additional--partially-slide-down {
  bottom: auto;
  transform: translate3d(0, -100%, 0);
  overflow: visible;
}

.u-block-hover__additional--partially-slide-down .u-block-hover__visible {
  position: absolute;
  top: 100%;
  margin-top: -1px;
  left: 0;
  right: 0;
  padding-left: inherit;
  padding-right: inherit;
  background: inherit;
}

.u-block-hover--cot {
  perspective: 50em;
}

[class*="u-block-hover__additional--cot"] {
  opacity: 0;
  z-index: 1;
}

.u-block-hover--cot:hover [class*="u-block-hover__main--cot"] {
  opacity: 0;
}

.u-block-hover--cot:hover [class*="u-block-hover__additional--cot"] {
  opacity: 1;
  transition-delay: 0.21s;
}

.u-block-hover__main--cot-up {
  transform-origin: 50% 0;
}

.u-block-hover__additional--cot-up {
  transform: rotateX(90deg);
  transform-origin: 50% 100%;
}

.u-block-hover--cot:hover .u-block-hover__main--cot-up {
  transform: rotateX(-90deg);
}

.u-block-hover--cot:hover .u-block-hover__additional--cot-up {
  transform: rotateX(0deg);
}

.u-block-hover__main--cot-down {
  transform-origin: 50% 100%;
}

.u-block-hover__additional--cot-down {
  transform: rotateX(-90deg);
  transform-origin: 50% -50%;
}

.u-block-hover--cot:hover .u-block-hover__main--cot-down {
  transform: rotateX(90deg);
  opacity: 0;
}

.u-block-hover--cot:hover .u-block-hover__additional--cot-down {
  transform: rotateX(0deg);
}

.u-block-hover__main--cot-left {
  transform-origin: 0% 50%;
}

.u-block-hover__additional--cot-left {
  transform: rotateY(-90deg);
  transform-origin: 100% 50%;
}

.u-block-hover--cot:hover .u-block-hover__main--cot-left {
  transform: rotateY(90deg);
}

.u-block-hover--cot:hover .u-block-hover__additional--cot-left {
  transform: rotateY(0deg);
}

.u-block-hover__main--cot-right {
  transform-origin: 100% 50%;
}

.u-block-hover__additional--cot-right {
  transform: rotateY(90deg);
  transform-origin: 0 50%;
}

.u-block-hover--cot:hover .u-block-hover__main--cot-right {
  transform: rotateY(-90deg);
}

.u-block-hover--cot:hover .u-block-hover__additional--cot-right {
  transform: rotateY(0deg);
}

.u-block-hover--flip {
  perspective: 50em;
}

[class*="u-block-hover__main--flip"] {
  backface-visibility: hidden;
}

[class*="u-block-hover__additional--flip"] {
  opacity: 0;
}

.u-block-hover--flip:hover [class*="u-block-hover__main--flip"] {
  opacity: 0;
}

.u-block-hover--flip:hover [class*="u-block-hover__additional--flip"] {
  opacity: 1;
  transition-delay: 0.13999999999999999s;
}

.u-block-hover__additional--flip-horiz {
  transform: rotateX(90deg);
  transform-origin: 0 50%;
}

.u-block-hover--flip:hover .u-block-hover__main--flip-horiz {
  transform: rotateX(-180deg);
}

.u-block-hover--flip:hover .u-block-hover__additional--flip-horiz {
  transform: rotateX(0deg);
}

.u-block-hover__additional--flip-vert {
  transform: rotateY(90deg);
  transform-origin: 50% 0%;
}

.u-block-hover--flip:hover .u-block-hover__main--flip-vert {
  transform: rotateY(-180deg);
}

.u-block-hover--flip:hover .u-block-hover__additional--flip-vert {
  transform: rotateY(0deg);
}

.u-block-hover__additional--flip-diag-1 {
  transform: rotate3d(1, 1, 0, 100deg);
}

.u-block-hover--flip:hover .u-block-hover__main--flip-diag-1 {
  transform: rotate3d(-1, -1, 0, 100deg);
}

.u-block-hover--flip:hover .u-block-hover__additional--flip-diag-1 {
  transform: rotate3d(0, 0, 0, 0deg);
}

.u-block-hover__additional--flip-diag-2 {
  transform: rotate3d(1, -1, 0, 100deg);
}

.u-block-hover--flip:hover .u-block-hover__main--flip-diag-2 {
  transform: rotate3d(-1, 1, 0, 100deg);
}

.u-block-hover--flip:hover .u-block-hover__additional--flip-diag-2 {
  transform: rotate3d(0, 0, 0, 0deg);
}

.u-block-hover--fold {
  perspective: 50em;
}

[class*="u-block-hover__main--fold"] {
  transform-origin: 50% 0;
}

[class*="u-block-hover__additional--fold"] {
  z-index: 1;
  opacity: 0;
}

.u-block-hover--fold:hover [class*="u-block-hover__main--fold"] {
  opacity: 0;
  transition-delay: 0;
}

.u-block-hover--fold:hover [class*="u-block-hover__additional--fold"] {
  transform: rotateX(0deg) translate3d(0, 0, 0) scale(1);
  opacity: 1;
  transition-delay: .21s;
}

.u-block-hover__main--fold-up {
  transform-origin: 50% 0;
}

.u-block-hover__additional--fold-up {
  transform: rotateX(-90deg) translate3d(0, -50%, 0) scale(0.6);
  transform-origin: 50% 100%;
}

.u-block-hover--fold:hover .u-block-hover__main--fold-up {
  transform: rotateX(90deg) scale(0.6) translateY(50%);
}

.u-block-hover__main--fold-down {
  transform-origin: 50% 100%;
}

.u-block-hover__additional--fold-down {
  transform: rotateX(90deg) translate3d(0, 50%, 0) scale(0.6);
  transform-origin: 50% 0;
}

.u-block-hover--fold:hover .u-block-hover__main--fold-down {
  transform: rotateX(-90deg) scale(0.6) translateY(-50%);
}

.u-block-hover__main--fold-left {
  transform-origin: 0 50%;
}

.u-block-hover__additional--fold-left {
  transform: rotateY(90deg) translate3d(-50%, 0, 0) scale(0.6);
  transform-origin: 100% 50%;
}

.u-block-hover--fold:hover .u-block-hover__main--fold-left {
  transform: rotateY(-90deg) scale(0.6) translateX(50%);
}

.u-block-hover__main--fold-right {
  transform-origin: 100% 50%;
}

.u-block-hover__additional--fold-right {
  transform: rotateY(-90deg) translate3d(50%, 0, 0) scale(0.6);
  transform-origin: 0 50%;
}

.u-block-hover--fold:hover .u-block-hover__main--fold-right {
  transform: rotateY(90deg) scale(0.6) translateX(-50%);
}

.u-block-hover__additional--zoom-in {
  opacity: 0;
  transform: scale(0.5);
}

.u-block-hover:hover .u-block-hover__additional--zoom-in {
  transform: scale(1);
  opacity: 1;
}

.u-block-hover__additional--zoom-out {
  transform: scale(0.5);
  transform-origin: 50% 50%;
  opacity: 0;
}

.u-block-hover:hover .u-block-hover__main--zoom-out {
  transform: scale(0.5);
  opacity: 0;
}

.u-block-hover:hover .u-block-hover__additional--zoom-out {
  transform: scale(1);
  opacity: 1;
  transition-delay: .35s;
}

[class*="u-block-hover--shutter-out"]::after {
  content: "";
  position: absolute;
  transition-delay: .105s;
  z-index: 1;
}

.u-block-hover__additional--shutter-out {
  opacity: 0;
  transition-delay: 0s;
  z-index: 2;
}

[class*="u-block-hover--shutter-out"]:hover::after {
  transition-delay: 0s;
}

[class*="u-block-hover--shutter-out"]:hover .u-block-hover__additional--shutter-out {
  opacity: 1;
  transition-delay: .105s;
}

.u-block-hover--shutter-out-horiz::after {
  left: 50%;
  right: 50%;
  top: 0;
  bottom: 0;
}

.u-block-hover--shutter-out-horiz:hover::after {
  left: 0;
  right: 0;
}

.u-block-hover--shutter-out-vert::after {
  top: 50%;
  bottom: 50%;
  left: 0;
  right: 0;
}

.u-block-hover--shutter-out-vert:hover::after {
  top: 0;
  bottom: 0;
}

.u-block-hover--shutter-out-diag-1::after {
  top: 50%;
  bottom: 50%;
  left: -35%;
  right: -35%;
  transform: rotate(45deg);
}

.u-block-hover--shutter-out-diag-1:hover::after {
  top: -35%;
  bottom: -35%;
}

.u-block-hover--shutter-out-diag-2::after {
  top: 50%;
  bottom: 50%;
  left: -35%;
  right: -35%;
  transform: rotate(-45deg);
}

.u-block-hover--shutter-out-diag-2:hover::after {
  top: -35%;
  bottom: -35%;
}

[class*="u-block-hover--shutter-in"]::after,
[class*="u-block-hover--shutter-in"]::before {
  content: "";
  position: absolute;
  z-index: 1;
}

[class*="u-block-hover--shutter-in"]::after {
  top: 0;
  left: 0;
}

[class*="u-block-hover--shutter-in"]::before {
  right: 0;
  bottom: 0;
}

.u-block-hover__additional--shutter-in {
  opacity: 0;
  z-index: 2;
}

[class*="u-block-hover--shutter-in"]:hover .u-block-hover__additional--shutter-in {
  opacity: 1;
  transition-delay: .21s;
}

.u-block-hover--shutter-in-horiz::after,
.u-block-hover--shutter-in-horiz::before {
  width: 0;
  height: 100%;
}

.u-block-hover--shutter-in-horiz:hover::after,
.u-block-hover--shutter-in-horiz:hover::before {
  width: 100%;
}

.u-block-hover--shutter-in-vert::after,
.u-block-hover--shutter-in-vert::before {
  height: 0;
  width: 100%;
}

.u-block-hover--shutter-in-vert:hover::after,
.u-block-hover--shutter-in-vert:hover::before {
  height: 100%;
}

.u-block-hover--shutter-in-diag-1::after,
.u-block-hover--shutter-in-diag-1::before {
  width: 200%;
  height: 200%;
  transition: all .6s ease;
}

.u-block-hover--shutter-in-diag-1::after {
  transform: skew(-45deg) translateX(-150%);
}

.u-block-hover--shutter-in-diag-1::before {
  transform: skew(-45deg) translateX(150%);
}

.u-block-hover--shutter-in-diag-1:hover::after {
  transform: skew(-45deg) translateX(-50%);
}

.u-block-hover--shutter-in-diag-1:hover::before {
  transform: skew(-45deg) translateX(50%);
}

.u-block-hover--shutter-in-diag-2::after,
.u-block-hover--shutter-in-diag-2::before {
  width: 200%;
  height: 200%;
  transition: all .6s ease;
}

.u-block-hover--shutter-in-diag-2::after {
  transform: skew(45deg) translateX(-100%);
}

.u-block-hover--shutter-in-diag-2::before {
  transform: skew(45deg) translateX(100%);
}

.u-block-hover--shutter-in-diag-2:hover::after {
  transform: skew(45deg) translateX(0);
}

.u-block-hover--shutter-in-diag-2:hover::before {
  transform: skew(45deg) translateX(0);
}

[class*="u-block-hover--shutter-in-out"]::after,
[class*="u-block-hover--shutter-in-out"]::before {
  content: "";
  position: absolute;
  z-index: 1;
}

[class*="u-block-hover--shutter-in-out"]::after {
  top: 0;
  left: 0;
}

[class*="u-block-hover--shutter-in-out"]::before {
  right: 0;
  bottom: 0;
}

.u-block-hover__additional--shutter-in-out {
  opacity: 0;
  z-index: 2;
}

[class*="u-block-hover--shutter-in-out"]:hover .u-block-hover__additional--shutter-in-out {
  opacity: 1;
  transition-delay: .21s;
}

.u-block-hover--shutter-in-out-horiz::after,
.u-block-hover--shutter-in-out-horiz::before {
  width: 0;
  height: 100%;
}

.u-block-hover--shutter-in-out-horiz:hover::after,
.u-block-hover--shutter-in-out-horiz:hover::before {
  width: 100%;
  opacity: .75;
}

.u-block-hover--shutter-in-out-vert::after,
.u-block-hover--shutter-in-out-vert::before {
  height: 0;
  width: 100%;
}

.u-block-hover--shutter-in-out-vert:hover::after,
.u-block-hover--shutter-in-out-vert:hover::before {
  height: 100%;
  opacity: .75;
}

.u-block-hover--shutter-in-out-diag-1::after,
.u-block-hover--shutter-in-out-diag-1::before {
  width: 200%;
  height: 200%;
  transition: all .6s ease;
  opacity: .75;
}

.u-block-hover--shutter-in-out-diag-1::after {
  transform: skew(-45deg) translateX(-150%);
}

.u-block-hover--shutter-in-out-diag-1::before {
  transform: skew(-45deg) translateX(150%);
}

.u-block-hover--shutter-in-out-diag-1:hover::after {
  transform: skew(-45deg) translateX(-50%);
}

.u-block-hover--shutter-in-out-diag-1:hover::before {
  transform: skew(-45deg) translateX(50%);
}

.u-block-hover--shutter-in-out-diag-2::after,
.u-block-hover--shutter-in-out-diag-2::before {
  width: 200%;
  height: 200%;
  transition: all .6s ease;
  opacity: .75;
}

.u-block-hover--shutter-in-out-diag-2::after {
  transform: skew(45deg) translateX(-100%);
}

.u-block-hover--shutter-in-out-diag-2::before {
  transform: skew(45deg) translateX(100%);
}

.u-block-hover--shutter-in-out-diag-2:hover::after {
  transform: skew(45deg) translateX(0);
}

.u-block-hover--shutter-in-out-diag-2:hover::before {
  transform: skew(45deg) translateX(0);
}

.u-block-hover--strip-shutter::before,
.u-block-hover--strip-shutter::after,
.u-block-hover__additional--strip-shutter::before,
.u-block-hover__additional--strip-shutter::after {
  content: "";
  position: absolute;
  top: 0;
  width: 25%;
  height: 100%;
  transform: scaleY(0);
  opacity: 0;
}

.u-block-hover--strip-shutter::before,
.u-block-hover--strip-shutter::after {
  z-index: 1;
}

.u-block-hover--strip-shutter::before {
  left: 0;
  transition-delay: 0s;
}

.u-block-hover--strip-shutter::after {
  left: 25%;
  transition-delay: .105s;
}

.u-block-hover__additional--strip-shutter {
  z-index: 3;
}

.u-block-hover__additional--strip-shutter::before,
.u-block-hover__additional--strip-shutter::after {
  z-index: -1;
}

.u-block-hover__additional--strip-shutter::before {
  left: 50%;
  transition-delay: .21s;
}

.u-block-hover__additional--strip-shutter::after {
  left: 75%;
  transition-delay: .35s;
}

.u-block-hover--strip-shutter:hover::before,
.u-block-hover--strip-shutter:hover::after,
.u-block-hover--strip-shutter:hover .u-block-hover__additional--strip-shutter::before,
.u-block-hover--strip-shutter:hover .u-block-hover__additional--strip-shutter::after {
  transform: scale(1);
  opacity: 1;
}

.u-block-hover__additional--strip-shutter__inner {
  opacity: 0;
}

.u-block-hover--strip-shutter:hover .u-block-hover__additional--strip-shutter__inner {
  opacity: 1;
  transition-delay: .35s;
}

.u-block-hover--tile::before,
.u-block-hover--tile::after,
.u-block-hover__additional--tile::before,
.u-block-hover__additional--tile::after {
  content: "";
  position: absolute;
  width: 50%;
  height: 50%;
  transform: scale(0);
  opacity: 0;
}

.u-block-hover--tile::before,
.u-block-hover--tile::after {
  z-index: 1;
}

.u-block-hover--tile::before {
  top: 0;
  left: 0;
  transition-delay: 0s;
}

.u-block-hover--tile::after {
  top: 0;
  left: 50%;
  transition-delay: .105s;
}

.u-block-hover__additional--tile {
  z-index: 3;
}

.u-block-hover__additional--tile::before,
.u-block-hover__additional--tile::after {
  z-index: -1;
}

.u-block-hover__additional--tile::before {
  top: 50%;
  left: 0;
  transition-delay: .21s;
}

.u-block-hover__additional--tile::after {
  top: 50%;
  left: 50%;
  transition-delay: .35s;
}

.u-block-hover--tile:hover::before,
.u-block-hover--tile:hover::after,
.u-block-hover--tile:hover .u-block-hover__additional--tile::before,
.u-block-hover--tile:hover .u-block-hover__additional--tile::after {
  transform: scale(1);
  opacity: 1;
}

.u-block-hover__additional--tile__inner {
  opacity: 0;
}

.u-block-hover--tile:hover .u-block-hover__additional--tile__inner {
  opacity: 1;
  transition-delay: .35s;
}

.u-block-hover--cube {
  overflow: visible;
  background-color: transparent;
  perspective: 50em;
  transform-style: preserve-3d;
}

[class*="u-block-hover__main--cube"] {
  transition-delay: .05s;
}

[class*="u-block-hover__additional--cube"] {
  opacity: 0;
  transition-delay: 0s;
}

.u-block-hover--cube:hover [class*="u-block-hover__main--cube"] {
  opacity: 0;
  transition-delay: 0s;
}

.u-block-hover--cube:hover [class*="u-block-hover__additional--cube"] {
  opacity: 1;
  transform: translateY(0%) rotateX(0deg);
  transition-delay: .05s;
}

.u-block-hover__additional--cube-up {
  transform: translateY(50%) rotateX(-90deg);
}

.u-block-hover--cube:hover .u-block-hover__main--cube-up {
  transform: translateY(-50%) rotateX(90deg);
}

.u-block-hover__additional--cube-down {
  transform: translateY(-50%) rotateX(90deg);
}

.u-block-hover--cube:hover .u-block-hover__main--cube-down {
  transform: translateY(50%) rotateX(-90deg);
}

.u-block-hover__additional--cube-left {
  transform: translateX(-50%) rotateY(-90deg);
}

.u-block-hover--cube:hover .u-block-hover__main--cube-left {
  transform: translateX(50%) rotateY(90deg);
}

.u-block-hover__additional--cube-right {
  transform: translateX(50%) rotateY(90deg);
}

.u-block-hover--cube:hover .u-block-hover__main--cube-right {
  transform: translateX(-50%) rotateY(-90deg);
}

.u-block-hover--border-reveal::before,
.u-block-hover--border-reveal::after,
.u-block-hover__additional--border-reveal::before,
.u-block-hover__additional--border-reveal::after {
  content: "";
  position: absolute;
  background-color: #fff;
  transform-origin: 0 0;
}

.u-block-hover--border-reveal::before,
.u-block-hover--border-reveal::after {
  left: 5px;
  right: 5px;
  height: 4px;
  z-index: 1;
  transform: scaleX(0);
}

.u-block-hover--border-reveal::before {
  top: 5px;
  transition-delay: .28s;
}

.u-block-hover--border-reveal:hover::before {
  transition-delay: 0s;
}

.u-block-hover--border-reveal::after {
  bottom: 5px;
  transition-delay: 0s;
}

.u-block-hover--border-reveal:hover::after {
  transition-delay: .28s;
}

.u-block-hover__additional--border-reveal {
  background-color: transparent;
  z-index: 3;
}

.u-block-hover__additional--border-reveal__inner {
  opacity: 0;
}

.u-block-hover__additional--border-reveal::before,
.u-block-hover__additional--border-reveal::after {
  top: 5px;
  bottom: 5px;
  width: 4px;
  z-index: -1;
  transform: scaleY(0);
}

.u-block-hover__additional--border-reveal::before {
  left: 5px;
  transition-delay: .28s;
}

.u-block-hover--border-reveal:hover .u-block-hover__additional--border-reveal::before {
  transition-delay: 0s;
}

.u-block-hover__additional--border-reveal::after {
  right: 5px;
  transition-delay: 0s;
}

.u-block-hover--border-reveal:hover .u-block-hover__additional--border-reveal::after {
  transition-delay: .28s;
}

.u-block-hover--border-reveal:hover .u-block-hover__main--border-reveal {
  opacity: 0;
}

.u-block-hover--border-reveal:hover::before,
.u-block-hover--border-reveal:hover::after,
.u-block-hover--border-reveal:hover .u-block-hover__additional--border-reveal::before,
.u-block-hover--border-reveal:hover .u-block-hover__additional--border-reveal::after {
  transform: scale(1);
}

.u-block-hover--border-reveal:hover .u-block-hover__additional--border-reveal__inner {
  opacity: 1;
  transition-delay: .35s;
}

.u-block-hover:hover img[class*="u-block-hover__main--mover-"],
.u-block-hover:hover [class*="u-block-hover__additional--mover-"] {
  opacity: 1;
  transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
}

img[class*="u-block-hover__main--mover-"] {
  max-width: initial;
  width: calc(100% + 60px);
  transition-duration: 0.5s;
  transform-origin: 50% 50%;
}

[class*="u-block-hover__additional--mover-"] {
  opacity: 0;
  transition-duration: 0.5s;
}

img.u-block-hover__main--mover-left {
  transform: translate3d(-60px, 0, 0);
}

.u-block-hover__additional--mover-left {
  transform: translate3d(10px, 0, 0);
}

img.u-block-hover__main--mover-right {
  transform: translate3d(-60px, 0, 0);
}

.u-block-hover__additional--mover-right {
  transform: translate3d(-10px, 0, 0);
}

img.u-block-hover__main--mover-up {
  transform: translate3d(0, 20px, 0) scale3d(1.1, 1.1, 1.1);
}

.u-block-hover__additional--mover-up {
  transform: translate3d(0, 10px, 0);
}

img.u-block-hover__main--mover-down {
  transform: translate3d(0, -20px, 0) scale3d(1.1, 1.1, 1.1);
}

.u-block-hover__additional--mover-down {
  transform: translate3d(0, -10px, 0);
}

.u-block-hover__additional--focuser-element {
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  opacity: .3;
  box-shadow: 0 0 0 31px rgba(0, 0, 0, 0.5);
  transform: scale3d(1.4, 1.4, 1.4);
  transition-duration: .5s;
}

.u-block-hover__additional--focuser-target {
  position: static;
  opacity: 0;
  transform: scale3d(1.4, 1.4, 1.4);
  transition-duration: .5s;
}

.u-block-hover:hover .u-block-hover__additional--focuser-target,
.u-block-hover:hover .u-block-hover__additional--focuser-element {
  opacity: 1;
  transform: scale3d(1, 1, 1);
  transition-duration: .3s;
}

img[class*="u-block-hover__main--magnifier"] {
  max-width: initial;
  width: calc(100% + 10px);
  margin: -10px 0;
}

[class*="u-block-hover__additional--magnifier-element"] {
  width: 400px;
  height: 400px;
  border-radius: 50%;
  box-shadow: 0 0 0 4000px rgba(255, 255, 255, 0.3);
  z-index: 2;
  opacity: 0;
  transform: scale3d(0.7, 0.7, 0.7);
  transform-origin: 50% 50%;
}

.u-block-hover:hover [class*="u-block-hover__additional--magnifier-element"] {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}

[class*="u-block-hover__additional--magnifier-description"] {
  max-width: 115px;
  z-index: 3;
  opacity: 0;
}

.u-block-hover:hover [class*="u-block-hover__additional--magnifier-description"] {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.u-block-hover__additional--magnifier-element-bottom-right {
  top: auto;
  left: auto;
  bottom: -180px;
  right: -180px;
}

.u-block-hover__additional--magnifier-description-bottom-right {
  top: auto;
  right: 30px;
  bottom: 30px;
  left: auto;
  transform: translate3d(20px, 20px, 0);
}

.u-block-hover:hover img.u-block-hover__main--magnifier-bottom-right {
  transform: translate3d(-10px, -10px, 0);
}

.u-block-hover__additional--magnifier-element-top-right {
  top: -180px;
  right: -180px;
  bottom: auto;
  left: auto;
}

.u-block-hover__additional--magnifier-description-top-right {
  top: 30px;
  right: 30px;
  bottom: auto;
  left: auto;
  transform: translate3d(20px, -20px, 0);
}

.u-block-hover:hover img.u-block-hover__main--magnifier-top-right {
  transform: translate3d(-10px, 10px, 0);
}

img.u-block-hover__main--magnifier-bottom-left {
  transform: translate3d(-10px, 0, 0);
}

.u-block-hover__additional--magnifier-element-bottom-left {
  top: auto;
  left: -180px;
  bottom: -180px;
  right: auto;
}

.u-block-hover__additional--magnifier-description-bottom-left {
  top: auto;
  right: auto;
  bottom: 30px;
  left: 30px;
  transform: translate3d(-20px, 20px, 0);
}

.u-block-hover:hover img.u-block-hover__main--magnifier-bottom-left {
  transform: translate3d(0, -10px, 0);
}

img.u-block-hover__main--magnifier-top-left {
  transform: translate3d(-10px, 0, 0);
}

.u-block-hover__additional--magnifier-element-top-left {
  top: -180px;
  left: -180px;
  bottom: auto;
  right: auto;
}

.u-block-hover__additional--magnifier-description-top-left {
  top: 30px;
  right: auto;
  bottom: auto;
  left: 30px;
  transform: translate3d(-20px, -20px, 0);
}

.u-block-hover:hover img.u-block-hover__main--magnifier-top-left {
  transform: translate3d(0, 10px, 0);
}

[class*="u-block-hover__additional--pappercuter"] {
  transform-origin: 50% 50%;
}

.u-block-hover__additional--pappercuter-inner {
  width: 100%;
  transform: rotate3d(0, 0, 1, 5deg);
}

.u-block-hover__additional--pappercuter-front,
.u-block-hover__additional--pappercuter-back {
  max-width: initial;
  width: 120%;
  top: -60px;
  bottom: -60px;
  left: -10%;
  z-index: 2;
  background-position: center;
  background-size: cover;
  transform: rotate3d(0, 0, 1, -5deg);
}

.u-block-hover__additional--pappercuter-front {
  clip: rect(0px, auto, 246px, 0px);
}

.u-block-hover__additional--pappercuter-back {
  top: -61px;
  clip: rect(246px, auto, auto, 0px);
}

.u-block-hover:hover .u-block-hover__additional--pappercuter-front {
  transform: scale3d(1.3, 1.3, 1.3) rotate3d(0, 0, 1, -10deg) translate3d(0, -45%, 0);
}

.u-block-hover:hover .u-block-hover__additional--pappercuter-back {
  transform: scale3d(1.3, 1.3, 1.3) rotate3d(0, 0, 1, -10deg) translate3d(0, 45%, 0);
}

[class*="u-block-hover__additional--outside"] {
  opacity: 0;
  visibility: hidden;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(0.7, -1.2, 0.8, 1.2);
}

.u-block-hover:hover [class*="u-block-hover__additional--outside"] {
  opacity: 1;
  visibility: visible;
  transform: translate3d(0, 0, 0);
  transition-timing-function: cubic-bezier(0.25, 1.8, 0.8, 1);
}

.u-block-hover__additional--outside-down {
  transform: translate3d(0, -100%, 0);
}

.u-block-hover__additional--outside-up {
  transform: translate3d(0, 100%, 0);
}

.u-block-hover__additional--outside-left {
  transform: translate3d(100%, 0, 0);
}

.u-block-hover__additional--outside-right {
  transform: translate3d(-100%, 0, 0);
}

[class*="u-block-hover__additional--lightspeed"] {
  visibility: hidden;
  opacity: 0;
  transition-duration: .7s;
  transition-timing-function: cubic-bezier(0.75, -1.2, 0.8, 2);
}

.u-block-hover:hover [class*="u-block-hover__additional--lightspeed"] {
  opacity: 1;
  visibility: visible;
  transform: translate3d(0, 0, 0) skew(0deg, 0deg);
  transition-timing-function: cubic-bezier(0.25, 2, 0.75, 1);
}

.u-block-hover__additional--lightspeed-left {
  transform-origin: 50% 0%;
  transform: translate3d(150%, 0, 0) skew(-35deg, 0deg);
}

.u-block-hover__additional--lightspeed-right {
  transform-origin: 50% 100%;
  transform: translate3d(-150%, 0, 0) skew(35deg, 0deg);
}

.u-block-hover__additional--lightspeed-down {
  transform-origin: 50% 50%;
  transform: translate3d(0, -150%, 0) skew(0deg, -35deg);
}

.u-block-hover__additional--lightspeed-up {
  transform-origin: 100% 50%;
  transform: translate3d(0, 150%, 0) skew(0deg, -35deg);
}

[class*="u-block-hover__additional--rotate"] {
  opacity: 0;
  visibility: hidden;
}

.u-block-hover:hover [class*="u-block-hover__additional--rotate"] {
  opacity: 1;
  visibility: visible;
  transform: rotate3d(0, 0, 0, 0deg) scale3d(1, 1, 1);
}

.u-block-hover__additional--rotate-in {
  opacity: 1;
  transition-property: transform, opacity, visibility;
  transition-duration: .4s;
  transform: rotate3d(0, 0, 1, 720deg) scale3d(0, 0, 0);
}

.u-block-hover__additional--rotate-down-left {
  transform-origin: 0 100%;
  transform: rotate3d(0, 0, 1, -45deg);
}

.u-block-hover__additional--rotate-down-right {
  transform-origin: 100% 100%;
  transform: rotate3d(0, 0, 1, 45deg);
}

.u-block-hover__additional--rotate-up-left {
  transform-origin: 0 100%;
  transform: rotate3d(0, 0, 1, 45deg);
}

.u-block-hover__additional--rotate-up-right {
  transform-origin: 100% 100%;
  transform: rotate3d(0, 0, 1, -45deg);
}

.u-block-hover__additional--jump {
  position: static;
}

.u-block-hover:hover .u-block-hover__additional--jump,
.u-block-hover.u-block-hover__additional--jump:hover {
  transform: translate3d(0, -10px, 0);
}

/*------------------------------------
  Dedicated Properties
------------------------------------*/
.u-block-hover:hover, .u-block-hover:focus {
  /* Opacity */
  /* Colors */
  /* Background-colors */
}

.u-block-hover:hover .u-block-hover__prop-opacity-1, .u-block-hover:focus .u-block-hover__prop-opacity-1 {
  opacity: 1;
}

.u-block-hover:hover .u-block-hover__prop-color-white, .u-block-hover:focus .u-block-hover__prop-color-white {
  color: #fff;
}

.u-block-hover:hover .u-block-hover__prop-bg-primary, .u-block-hover:focus .u-block-hover__prop-bg-primary {
  background-color: #2cb8f3;
}

.u-block-hover:hover .u-block-hover__prop-bg-primary-opacity-0_9, .u-block-hover:focus .u-block-hover__prop-bg-primary-opacity-0_9 {
  background-color: rgba(44, 184, 243, 0.9);
}

/*------------------------------------
  Blockquotes
------------------------------------*/
.u-blockquote-v1 {
  position: relative;
  background-color: #fff;
  box-shadow: 5px 6px 9px -6px rgba(0, 0, 0, 0.15);
}

.u-blockquote-v1::before {
  content: "\201C";
  position: absolute;
  width: 60px;
  color: #2cb8f3;
  font-size: 60px;
  margin: -25px 0 0 -40px;
}

.u-blockquote-v1::after {
  content: "";
  position: absolute;
  bottom: -30px;
  left: 80px;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 30px 30px 0 0;
  border-color: #fff transparent transparent transparent;
  -webkit-filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.1));
  filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.1));
}

.u-blockquote-v2::before, .u-blockquote-v2::after {
  position: relative;
  top: 5px;
  font-size: 22px;
  line-height: 10px;
}

.u-blockquote-v2::before {
  content: "\“";
  padding-right: 5px;
}

.u-blockquote-v2::after {
  content: "\”";
  padding-left: 5px;
}

.u-blockquote-v3 {
  position: relative;
}

.u-blockquote-v3::before {
  content: "\201C";
  position: absolute;
  font-size: 60px;
  color: #2cb8f3;
  margin: -30px 0 0 -40px;
}

.u-blockquote-v4::before, .u-blockquote-v4::after {
  content: "\201C";
  position: absolute;
  font-size: 50px;
  margin-top: -12px;
}

.u-blockquote-v4::before {
  margin-left: -30px;
}

.u-blockquote-v4::after {
  margin-left: 13px;
}

.u-blockquote-v5::before {
  content: "\201C";
  position: absolute;
  width: 60px;
  color: rgba(255, 255, 255, 0.2);
  font-size: 70px;
  margin: -25px 0 0 -40px;
}

.u-blockquote-v6::before, .u-blockquote-v6::after {
  content: "\0022";
  font-family: inherit;
  color: inherit;
}

.u-blockquote-v7 {
  position: relative;
  padding-top: 55px;
}

.u-blockquote-v7::before {
  content: "\f10d";
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 35px;
  height: 35px;
  font-size: 12px;
  font-family: "FontAwesome";
  color: #fff;
  line-height: 35px;
  background: #2cb8f3;
  border-radius: 50%;
  margin-left: -18px;
}

.u-blockquote-v8 {
  position: relative;
  background-color: #fff;
  box-shadow: 5px 6px 9px -6px rgba(85, 85, 85, 0.08);
}

.u-blockquote-v8::after {
  content: "";
  position: absolute;
  left: 50px;
  bottom: -15px;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 15px 0 0;
  border-color: #fff transparent transparent transparent;
  -webkit-filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.05));
  filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.05));
}

/*------------------------------------
  Accordions
------------------------------------*/
.u-accordion__header {
  padding: 0.71429rem 1.07143rem;
}

.u-accordion__body {
  padding: 1.07143rem;
}

.u-accordion__control-icon i:nth-child(1) {
  display: none;
}

.collapsed .u-accordion__control-icon i:nth-child(1) {
  display: inline;
}

.u-accordion__control-icon i:nth-child(2) {
  display: inline;
}

.collapsed .u-accordion__control-icon i:nth-child(2) {
  display: none;
}

[aria-expanded="false"] .u-accordion__control-icon i:nth-child(1) {
  display: inline;
}

[aria-expanded="false"] .u-accordion__control-icon i:nth-child(2) {
  display: none;
}

[aria-expanded="true"] .u-accordion__control-icon i:nth-child(1) {
  display: none;
}

[aria-expanded="true"] .u-accordion__control-icon i:nth-child(2) {
  display: inline;
}

.u-accordion [class*="et-icon-"], .u-accordion-line-icon-pro {
  position: relative;
  top: 3px;
}

.u-accordion-color-primary .u-accordion__header [aria-expanded="true"] {
  color: #2cb8f3 !important;
}

.u-accordion-color-white .u-accordion__header [aria-expanded="true"] {
  color: #fff !important;
}

.u-accordion-bg-primary .u-accordion__header [aria-expanded="true"] {
  background-color: #2cb8f3 !important;
  border-color: #2cb8f3 !important;
}

.u-accordion-bg-white .u-accordion__header [aria-expanded="true"] {
  background-color: #fff !important;
  border-color: #fff !important;
}

.u-accordion-brd-primary .u-accordion__header [aria-expanded="true"] {
  border-color: #2cb8f3 !important;
}

.u-accordion-brd-white .u-accordion__header [aria-expanded="true"] {
  border-color: #fff !important;
}

/*------------------------------------
  Carousels
------------------------------------*/
.u-carousel-v1 .js-prev,
.u-carousel-v1 .js-next {
  opacity: 0;
  transition-property: opacity;
  transition-duration: .2s;
  transition-timing-function: ease-in;
}

.u-carousel-v1:hover .js-prev,
.u-carousel-v1:hover .js-next {
  opacity: 1;
}

.u-carousel-v2 .slick-slide {
  padding-top: 4px;
  padding-bottom: 4px;
  opacity: .5;
  transform: scale(1);
  transition-property: all;
  transition-duration: .4s;
  transition-timing-function: ease-in;
}

.u-carousel-v2 .slick-center {
  padding-top: 0;
  padding-bottom: 0;
  opacity: 1;
  transform: scale(1.1);
}

.u-carousel-v3 .slick-slide {
  opacity: .5;
  transition-property: opacity;
  transition-duration: .4s;
  transition-timing-function: ease-in;
}

.u-carousel-v3 .slick-center {
  opacity: 1;
}

.u-carousel-v4 .js-pagination {
  opacity: 0;
  transition-property: opacity;
  transition-duration: .2s;
  transition-timing-function: ease-in;
}

.u-carousel-v4:hover .js-pagination {
  opacity: 1;
}

.u-carousel-v5 .slick-list {
  height: 100%;
}

.u-carousel-v5 .slick-track {
  display: flex;
  height: 100%;
}

.u-carousel-v5 .slick-track .slick-slide {
  display: flex;
  height: auto;
}

.u-carousel-v6 .slick-list.draggable {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.u-carousel-v11 .slick-slide {
  opacity: .5;
}

.u-carousel-v11 .slick-slide.slick-current {
  opacity: 1;
}

/*------------------------------------
  Slick Carousel v12
------------------------------------*/
.u-carousel-v12 .slick-current .g-opacity-1--active {
  opacity: 1 !important;
}

.u-carousel-v12 .slick-current .u-block-hover__main--grayscale {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(0%);
}

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item,
.swiper-wrapper,
.swiper-slide {
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}

.single-slide {
  opacity: 0 !important;
}

.single-slide.set-position {
  opacity: 1 !important;
}

.js-origin {
  perspective: 1200px;
  perspective-origin: 50% 50%;
}

/* backSlide */
.js-back-slide-out {
  -webkit-animation: backSlideOut 1s both ease;
  -moz-animation: backSlideOut 1s both ease;
  animation: backSlideOut 1s both ease;
}

.js-back-slide-in {
  -webkit-animation: backSlideIn 1s both ease;
  -moz-animation: backSlideIn 1s both ease;
  animation: backSlideIn 1s both ease;
}

.swiper-carousel {
  overflow: hidden;
}

@-webkit-keyframes backSlideOut {
  25% {
    opacity: .5;
    -webkit-transform: translateZ(-400px);
  }
  75% {
    opacity: .5;
    -webkit-transform: translateZ(-400px) translateX(-200%);
  }
  100% {
    opacity: .5;
    -webkit-transform: translateZ(-400px) translateX(-200%);
  }
}

@-moz-keyframes backSlideOut {
  25% {
    opacity: .5;
    -moz-transform: translateZ(-400px);
  }
  75% {
    opacity: .5;
    -moz-transform: translateZ(-400px) translateX(-200%);
  }
  100% {
    opacity: .5;
    -moz-transform: translateZ(-400px) translateX(-200%);
  }
}

@keyframes backSlideOut {
  25% {
    opacity: .5;
    transform: translateZ(-400px);
  }
  75% {
    opacity: .5;
    transform: translateZ(-400px) translateX(-200%);
  }
  100% {
    opacity: .5;
    transform: translateZ(-400px) translateX(-200%);
  }
}

@-webkit-keyframes backSlideIn {
  0%, 25% {
    opacity: .5;
    -webkit-transform: translateZ(-400px) translateX(200%);
  }
  75% {
    opacity: .5;
    -webkit-transform: translateZ(-400px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateZ(0) translateX(0);
  }
}

@-moz-keyframes backSlideIn {
  0%, 25% {
    opacity: .5;
    -moz-transform: translateZ(-400px) translateX(200%);
  }
  75% {
    opacity: .5;
    -moz-transform: translateZ(-400px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateZ(0) translateX(0);
  }
}

@keyframes backSlideIn {
  0%, 25% {
    opacity: .5;
    transform: translateZ(-400px) translateX(200%);
  }
  75% {
    opacity: .5;
    transform: translateZ(-400px);
  }
  100% {
    opacity: 1;
    transform: translateZ(0) translateX(0);
  }
}

/*--------------------------------------------------
  Chart Pie v1
----------------------------------------------------*/
.u-chart-pie-v1 {
  position: relative;
  line-height: 1em;
}

.u-chart-pie-v1::after {
  content: "";
  display: block;
  position: absolute;
  top: 9px;
  right: 10px;
  bottom: 11px;
  left: 10px;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  border-color: inherit;
}

/*------------------------------------
  Charts
------------------------------------*/
/* Sparkline chart's tooltip */
.jqstooltip {
  width: auto !important;
  height: auto !important;
}

/*--------------------------------------------------
  Counter v1
----------------------------------------------------*/
.u-counter-v3 {
  position: relative;
}

.u-counter-v3::after {
  content: "";
  position: absolute;
  left: -9px;
  right: -9px;
  bottom: -9px;
  top: -9px;
  display: block;
  border-radius: inherit;
  border-width: 1px;
  border-style: solid;
  border-color: inherit;
}

/*--------------------------------------------------
  Counter v6
----------------------------------------------------*/
.u-counter-v6 {
  position: relative;
  width: 120px;
  height: 120px;
}

.u-counter-v6::after {
  content: "";
  position: absolute;
  top: -11px;
  right: -11px;
  bottom: -11px;
  left: -11px;
  display: block;
  border-radius: inherit;
  border-width: 2px;
  border-style: inherit;
  border-color: inherit;
  clip: rect(auto, 71px, 142px, auto);
}

/*------------------------------------
  Credit cards
------------------------------------*/
.u-card--v1 {
  position: relative;
}

.u-card--v1-front {
  position: relative;
  margin-bottom: 220px;
  z-index: 2;
}

.u-card--v1-back {
  position: absolute;
  bottom: -70%;
  right: 0;
  z-index: 1;
  width: 100%;
  height: 80%;
  padding-left: 0;
}

@media (min-width: 768px) {
  .u-card--v1-front {
    margin-bottom: 30px;
  }
  .u-card--v1-back {
    bottom: auto;
    top: 30px;
    right: -30%;
    height: 100%;
    padding-left: 70%;
  }
}

/*------------------------------------
  Dropdowns v1
------------------------------------*/
.u-dropdown-v1 {
  position: relative;
  line-height: normal;
  font-size: 16px;
  color: #777;
  background-color: #fff;
  border-width: 1px;
  border-style: solid;
  border-color: #ccc;
  padding: 11px 40px 11px 14px;
  transition-property: background-color, border-color, box-shadow;
  transition-duration: .2s;
  transition-timing-function: ease;
}

.u-dropdown-v1::after {
  display: none;
}

.u-dropdown-v1-menu {
  padding: 0;
  border-color: #ccc;
  border-radius: 3px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
}

.u-dropdown-v1-menu__item {
  line-height: normal;
  font-size: 16px;
  color: #777;
  padding: 9px 20px;
}

.u-dropdown-v1-menu__item:hover {
  color: #777;
}

.u-dropdown-v1__icon {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 40px;
  height: 100%;
}

.u-dropdown-v1__icon-open, .u-dropdown-v1__icon-close {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.u-dropdown-v1__icon-open {
  opacity: 1;
}

.u-dropdown-v1__icon-close {
  opacity: 0;
}

.u-dropdown-v1[aria-expanded="true"] {
  background-color: rgba(204, 204, 204, 0.1);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
}

.u-dropdown-v1[aria-expanded="true"] [class*="__icon-open"] {
  opacity: 0;
}

.u-dropdown-v1[aria-expanded="true"] [class*="__icon-close"] {
  opacity: 1;
}

.u-dropdown-v1:focus {
  outline: 0 none;
}

/*------------------------------------
  Covers
------------------------------------*/
.u-bg-overlay {
  position: relative;
}

.u-bg-overlay::before, .u-bg-overlay::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.u-bg-overlay--v1::after {
  position: absolute;
  top: 0.35714rem;
  right: 0.35714rem;
  bottom: 0.35714rem;
  left: 0.35714rem;
}

.u-bg-overlay__inner {
  z-index: 3;
  position: relative;
}

.u-bg-overlay--reverse::before {
  z-index: 2;
}

.u-bg-overlay--reverse::after {
  z-index: 1;
}

/*------------------------------------
  Material: Waves
------------------------------------*/
.u-waves-effect {
  position: relative;
  overflow: hidden;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  z-index: 1;
  transition: .3s ease-out;
}

.u-waves-effect .u-waves-ripple {
  position: absolute;
  border-radius: 50%;
  width: 1.42857rem;
  height: 1.42857rem;
  margin-top: -0.71429rem;
  margin-left: -0.71429rem;
  opacity: 0;
  background: rgba(0, 0, 0, 0.2);
  transition: all .7s ease-out;
  transition-property: transform, opacity;
  transform: scale(0);
  pointer-events: none;
}

.u-waves-effect.u-waves-light .u-waves-ripple {
  background-color: rgba(255, 255, 255, 0.45);
}

.u-waves-effect.u-waves-dark .u-waves-ripple {
  background-color: rgba(0, 0, 0, 0.15);
}

.u-waves-effect input[type="button"], .u-waves-effect input[type="reset"], .u-waves-effect input[type="submit"] {
  border: 0;
  font-style: normal;
  font-size: inherit;
  text-transform: inherit;
  background: none;
}

.u-waves-effect img {
  position: relative;
  z-index: -1;
}

.u-waves-notransition {
  transition: none !important;
}

.u-waves-circle {
  transform: translateZ(0);
  -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
}

.u-waves-input-wrapper {
  border-radius: .2em;
  vertical-align: bottom;
}

.u-waves-input-wrapper .u-waves-button-input {
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
}

.u-waves-circle {
  text-align: center;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  border-radius: 50%;
  -webkit-mask-image: none;
}

.u-waves-block {
  display: block;
}

/* Firefox Bug: link not triggered */
.u-waves-effect .u-waves-ripple {
  z-index: -1;
}

/*------------------------------------
  Material: FAB
------------------------------------*/
.u-fixed-action-btn {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 997;
}

.u-fixed-action-btn ul {
  left: 0;
  right: 0;
  text-align: center;
  position: absolute;
  bottom: 60px;
  margin: 0;
  visibility: hidden;
  list-style-type: none;
  padding-left: 0;
}

.u-fixed-action-btn ul li {
  margin-bottom: 1.07143rem;
}

.u-fixed-action-btn ul a.u-btn-floating {
  opacity: 0;
}

.u-fixed-action-btn.active ul {
  visibility: visible;
}

.u-fixed-action-btn-horizontal {
  padding: 0 0 0 1.07143rem;
}

.u-fixed-action-btn-horizontal ul {
  text-align: right;
  right: 4.57143rem;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  left: auto;
  width: 500px;
  /*width 100% only goes to width of button container */
}

.u-fixed-action-btn-horizontal ul li {
  display: inline-block;
  margin: 0.71429rem 1.07143rem 0 0;
}

.u-fixed-action-btn-toolbar {
  padding: 0;
  height: 4rem;
}

.u-fixed-action-btn-toolbar.active > a i {
  opacity: 0;
}

.u-fixed-action-btn-toolbar ul {
  display: flex;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.u-fixed-action-btn-toolbar ul li {
  flex: 1;
  display: inline-block;
  margin: 0;
  height: 100%;
  transition: none;
}

.u-fixed-action-btn-toolbar ul li a {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: transparent;
  box-shadow: none;
  color: #fff;
  line-height: 4rem;
  z-index: 1;
}

.u-fixed-action-btn-toolbar ul li a i {
  line-height: inherit;
}

.u-fixed-action-btn .u-fab-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 2.85714rem;
  height: 2.85714rem;
  border-radius: 50%;
  transform: scale(0);
}

/*------------------------------------
  Chips
------------------------------------*/
.u-chip {
  display: inline-block;
  height: 2.28571rem;
  margin: 0.14286rem 0;
  padding: 0 0.85714rem;
  font-size: 0;
  white-space: nowrap;
  line-height: 2.28571rem;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 1.14286rem;
  background-color: #eee;
}

.u-chip:focus {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.u-chip--deletable {
  padding-right: 0.28571rem;
}

.u-chip--contact {
  padding-left: 0;
}

.u-chip__contact {
  display: inline-block;
  width: 2.28571rem;
  height: 2.28571rem;
  margin-right: 0.57143rem;
  overflow: hidden;
  vertical-align: middle;
  border-radius: 1.14286rem;
  text-align: center;
  font-size: 1.28571rem;
  line-height: 2.28571rem;
}

.u-chip__text {
  display: inline-block;
  vertical-align: middle;
  font-size: 0.92857rem;
}

.u-chip__action {
  display: inline-block;
  width: 1.71429rem;
  height: 1.71429rem;
  margin: 0 0 0 0.28571rem;
  padding: 0;
  text-align: center;
  vertical-align: middle;
  border: none;
  font-size: 0.92857rem;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.3);
  background: transparent;
  opacity: .54;
  cursor: pointer;
  outline: none;
  overflow: hidden;
}

.u-outer-spaces-helper {
  position: fixed;
  bottom: 0;
  left: 0;
  display: none;
  width: 100%;
  overflow: hidden;
}

.u-outer-spaces-helper::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100vh;
  background-color: #f7f7f7;
  background-position: center;
  background-repeat: repeat;
}

.g-dark-theme .u-outer-spaces-helper::after {
  background-color: #000;
}

[class*="g-bgi-v"] .u-outer-spaces-helper::after {
  background-color: transparent;
}

.u-outer-space-v1 .u-outer-spaces-helper,
.u-outer-space-v2 .u-outer-spaces-helper {
  display: block;
}

.u-outer-space-v1 .u-outer-spaces-helper {
  height: 20px;
}

.u-outer-space-v2 .u-outer-spaces-helper {
  height: 40px;
}

/*------------------------------------
  Info Block v1-1
------------------------------------*/
.u-info-v1-1 {
  overflow: hidden;
  position: relative;
}

.u-info-v1-1::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 40px;
}

/*--------------------------------------------------
  Info Block v1-2
----------------------------------------------------*/
.u-info-v1-2__item {
  position: relative;
  z-index: 1;
  padding-bottom: 0.78571rem;
}

.u-info-v1-2__item::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  width: 40px;
  height: 1px;
  background: #bbb;
  transition: all 0.4s ease-in-out;
}

.u-info-v1-2:hover .u-info-v1-2__item::after {
  width: 100%;
  background: #2cb8f3;
}

/*------------------------------------
  Info Block v1-3
------------------------------------*/
.u-info-v1-3__item {
  position: relative;
  z-index: 1;
  padding-bottom: 0.92857rem;
}

.u-info-v1-3__item::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 2.14286rem;
  height: 1px;
  margin-left: -1.07143rem;
  background: #777;
}

/*------------------------------------
  Info Block v1-4
------------------------------------*/
.u-info-v1-4__item-hidden {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 61px;
  overflow: hidden;
  transform: translate3d(0, 61px, 0);
}

.u-info-v1-4:hover .u-info-v1-4__item-hidden {
  transform: translate3d(0, 0, 0);
}

.u-info-v1-4:hover .u-info-v1-4__item-regular {
  transform: translate3d(0, -61px, 0);
}

/*------------------------------------
  Info Block v1-5
------------------------------------*/
.u-info-v1-5__item {
  position: relative;
  padding-bottom: 19px;
}

.u-info-v1-5__item::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 1px;
  display: block;
  width: 28px;
  border-bottom: 1px solid #2cb8f3;
}

/*------------------------------------
  Info Block v2-1
------------------------------------*/
.u-info-v2-1__item {
  height: 65px;
  transition: .3s ease-out;
}

.u-info-v2-1__item:hover {
  height: 80px;
  margin-top: -15px;
}

/*------------------------------------
  Info Block v2-2
------------------------------------*/
.u-info-v2-2__item {
  position: relative;
  border-left: solid 1px;
  border-right: solid 1px;
  border-bottom: solid 1px;
}

.u-info-v2-2__item::after, .u-info-v2-2__item::before {
  content: "";
  position: absolute;
  top: 0;
  width: 30%;
  border-top: solid 1px;
  z-index: 1;
}

.u-info-v2-2__item::before {
  left: 0;
}

.u-info-v2-2__item::after {
  right: 0;
}

.u-info-v2-2__item.g-brd-white-dark-v3 {
  border-color: rgba(255, 255, 255, 0.3);
}

.u-info-v2-2__item.g-brd-white-dark-v3::before, .u-info-v2-2__item.g-brd-white-dark-v3::after {
  border-color: rgba(255, 255, 255, 0.3);
}

.u-info-v2-2__item.g-brd-black-dark-v3 {
  border-color: rgba(0, 0, 0, 0.3);
}

.u-info-v2-2__item.g-brd-black-dark-v3::before, .u-info-v2-2__item.g-brd-black-dark-v3::after {
  border-color: rgba(0, 0, 0, 0.3);
}

/*------------------------------------
  Info Block v2-3
------------------------------------*/
.u-info-v2-3:hover .u-info-v2-3__title {
  color: #000;
}

/*------------------------------------
  Info Block v3-1
------------------------------------*/
/* Title */
.info-v3-1__title {
  position: relative;
  display: inline-block;
  margin-left: 4.28571rem;
  margin-right: 4.28571rem;
}

.info-v3-1__title::before, .info-v3-1__title::after {
  content: "";
  position: absolute;
  top: 50%;
  display: block;
  width: 3.57143rem;
  margin-top: -1px;
  height: 2px;
  background-color: #fff;
}

.info-v3-1__title::before {
  right: 100%;
  margin-right: 10px;
}

.info-v3-1__title::after {
  left: 100%;
  margin-left: 10px;
}

/*------------------------------------
  Info Block v3-2
------------------------------------*/
.info-v3-2 {
  position: relative;
  background-position: center;
}

.info-v3-2::before {
  content: "";
  position: absolute;
  top: 1.42857rem;
  right: 1.42857rem;
  bottom: 1.42857rem;
  left: 1.42857rem;
  display: block;
  border: 1px solid rgba(255, 255, 255, 0.2);
  z-index: 1;
}

.info-v3-2:hover {
  background-position: center 0;
}

/*------------------------------------
  Info Block v3-3
------------------------------------*/
.info-v3-3 {
  overflow: hidden;
}

.info-v3-3:hover .info-v3-3__title {
  color: #fff;
}

.info-v3-3:hover .info-v3-3__img,
.info-v3-3:hover .info-v3-3__description {
  transform: translate3d(0, 0, 0);
}

.info-v3-3:hover .info-v3-3__content {
  display: block;
}

.info-v3-3:hover .info-v3-3__category {
  display: none;
}

.info-v3-3:hover::after {
  opacity: 1;
}

.info-v3-3::after {
  opacity: 0;
  transition: opacity .5s;
}

/* Image */
.info-v3-3__img {
  transform: translate3d(70%, 0, 0);
  transition: transform .5s;
}

/* Content */
.info-v3-3__content {
  display: none;
}

/* Description */
.info-v3-3__description {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translate3d(-20%, 0, 0);
  transition: transform .5s;
  z-index: 1;
}

/*------------------------------------
  Info Block v3-4
------------------------------------*/
.info-v3-4 {
  position: relative;
}

.info-v3-4::before {
  content: "";
  position: absolute;
  top: 0.71429rem;
  right: 0.71429rem;
  bottom: 0.71429rem;
  left: 0.71429rem;
  display: block;
  border: 1px solid rgba(255, 255, 255, 0.4);
  z-index: 1;
}

/*------------------------------------
  Info Block v5-1
------------------------------------*/
/* Title */
.info-v5-1__title {
  position: relative;
  display: inline-block;
}

.info-v5-1__title::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  display: block;
  width: 3000%;
  margin-left: 15px;
  border-bottom: 1px solid #eee;
}

/*------------------------------------
  Info Block v5-2
------------------------------------*/
/* Product Image */
.info-v5-2__image {
  width: calc(100% + 1px);
}

/*------------------------------------
  Info Block v5-3
------------------------------------*/
.info-v5-3__info-price {
  opacity: 0;
}

.info-v5-3__info-title {
  position: absolute;
  left: 0;
  bottom: 100%;
  width: 100%;
  margin-bottom: 4.64286rem;
  transform: translate3d(0, 70px, 0);
}

.info-v5-3:hover .info-v5-3__info {
  color: rgba(255, 255, 255, 0.8);
  background-color: rgba(44, 184, 243, 0.9);
}

.info-v5-3:hover .info-v5-3__info-price {
  opacity: 1;
}

.info-v5-3:hover .info-v5-3__info-list {
  color: #fff;
}

.info-v5-3:hover .info-v5-3__info-list .fa {
  color: #fff;
}

.info-v5-3:hover .info-v5-3__info-title {
  transform: translate3d(0, 0, 0);
  margin-bottom: 3.21429rem;
}

/*------------------------------------
  Info Block v5-4
------------------------------------*/
/* Actions */
.info-v5-4__action .fa {
  opacity: 0.75;
}

.info-v5-4__action:hover .fa {
  opacity: 1;
}

/*------------------------------------
  Info Block v5-5
------------------------------------*/
.info-v5-5__header::after,
.info-v5-5__content::after {
  transition: inherit;
}

.info-v5-5__content::after {
  opacity: 0;
}

.info-v5-5:hover .info-v5-5__header::after,
.info-v5-5:hover .info-v5-5__content::after {
  opacity: .8;
}

/*------------------------------------
  Info Block v5-6
------------------------------------*/
.info-v5-6:hover .info-v5-6__rating {
  color: #333;
}

.info-v5-6:hover .info-v5-6__price {
  background-color: #333;
}

.info-v5-6 .g-color-gray-light-v5--hover .g-rating,
.info-v5-6 .g-bg-white--hover,
.info-v5-6 .g-color-primary--hover {
  transition-property: all;
  transition-timing-function: ease;
  transition-delay: 0s;
  transition-duration: .3s;
}

.info-v5-6:hover .g-color-gray-light-v5--hover .g-rating {
  color: #f7f7f7;
}

.info-v5-6:hover .g-bg-white--hover {
  background: #fff;
}

.info-v5-6:hover .g-color-primary--hover {
  color: #2cb8f3;
}

/*--------------------------------------------------
  Info Block v6-1
----------------------------------------------------*/
.u-info-v6-1__item {
  position: relative;
  z-index: 1;
  padding-bottom: 0.78571rem;
}

.u-info-v6-1__item::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  width: 40px;
  height: 1px;
  background-image: linear-gradient(to right, #2cb8f3 0%, transparent 100%);
  background-repeat: repeat-x;
  transition: all 0.4s ease-in-out;
}

.u-info-v6-1:hover .u-info-v6-1__item::after {
  width: 100%;
  background-image: linear-gradient(to right, #2cb8f3 0%, transparent 100%);
  background-repeat: repeat-x;
}

/*--------------------------------------------------
  Info Block v7-1
----------------------------------------------------*/
.u-info-v7-1__item {
  border: 2px solid transparent;
  transition: all .2s ease-in-out;
}

.u-info-v7-1__item-child-v1 {
  border: 5px solid transparent;
  transition: all .2s ease-in-out;
}

.u-info-v7-1__item-child-v2 {
  transition: all .2s ease-in-out;
}

.u-info-v7-1:hover {
  border-color: #eee;
}

.u-info-v7-1:hover .u-info-v7-1__item {
  border-color: #ddd;
}

.u-info-v7-1:hover .u-info-v7-1__item-child-v1 {
  border-color: #ddd;
}

.u-info-v7-1:hover .u-info-v7-1__item-child-v2 {
  color: #fff;
  background: #2cb8f3;
}

/*--------------------------------------------------
  Info Block v8-1
----------------------------------------------------*/
.u-hs-filter {
  padding: 20px;
  margin-bottom: 0;
}

.u-hs-filter a {
  padding: 0.5rem 1.07143rem;
  color: rgba(255, 255, 255, 0.8);
  border: solid 1px rgba(255, 255, 255, 0.2);
  text-transform: uppercase;
  border-radius: 3px;
  transition: all .3s;
}

.u-hs-filter a:hover, .u-hs-filter a:focus {
  text-decoration: none;
}

.u-hs-filter a:hover {
  color: #585f69;
  background-color: white;
}

.u-hs-filter .active a {
  color: #585f69;
  background-color: white;
}

/*--------------------------------------------------
  Info Block v9-1
----------------------------------------------------*/
@media (min-width: 768px) {
  .u-info-v9-1 {
    position: relative;
  }
  .u-info-v9-1::before {
    position: absolute;
    top: 111px;
    left: 17%;
    width: 66%;
    border-top: 1px dotted #ddd;
    content: " ";
  }
}

/*--------------------------------------------------
  Info Block v10-1
----------------------------------------------------*/
.u-info-v10-1 {
  position: relative;
  display: inline-block;
}

.u-info-v10-1::before, .u-info-v10-1::after {
  display: block;
  position: absolute;
  top: 50%;
  width: 1000px;
  height: 1px;
  background: #f7f7f7;
  content: " ";
  margin-top: 0.5px;
}

.u-info-v10-1::before {
  right: 100%;
  margin-right: 25px;
}

.u-info-v10-1::after {
  left: 100%;
  margin-left: 25px;
}

/*------------------------------------
  Info v11-1
------------------------------------*/
.u-info-v11-1-img {
  border: 0 solid #eee;
  transform: scale(1);
  transition: all .3s ease;
}

.u-info-v11-1:hover .u-info-v11-1-img {
  border-width: 5px;
  transform: scale(0.95);
}

/*------------------------------------
  Custom Bootstrap
------------------------------------*/
.container-semiboxed {
  box-sizing: border-box;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 576px) {
  .container-semiboxed {
    width: 540px;
  }
}

@media (min-width: 768px) {
  .container-semiboxed {
    width: 720px;
  }
}

@media (min-width: 992px) {
  .container-semiboxed {
    width: 960px;
  }
}

@media (min-width: 1200px) {
  .container-semiboxed {
    width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container-semiboxed {
    width: 1340px;
  }
}

.text-muted {
  color: #999 !important;
}

.list-group-item {
  border-color: #eee;
}

.list-group-item-action:hover {
  background-color: #f7f7f7;
}

.list-group-item.active {
  background-color: #2cb8f3;
  border-color: #2cb8f3;
}

.list-group-border-0 .list-group-item:first-child,
.list-group-border-0 .list-group-item:last-child {
  border-radius: 0;
}

/* Paginations */
.page-link {
  color: #2cb8f3;
}

.page-link:focus, .page-link:hover {
  color: #2cb8f3;
}

.page-item.active .page-link,
.page-item.active .page-link:focus,
.page-item.active .page-link:hover {
  border-color: #2cb8f3;
  background-color: #2cb8f3;
}

.nav-pills .nav-item.show .nav-link,
.nav-pills .nav-link.active {
  background-color: #2cb8f3;
}

.nav-tabs {
  border-bottom: 1px solid #eee;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #555;
  border-color: #eee #eee #fff;
}

.progress {
  font-size: 1rem;
  line-height: inherit;
  height: inherit;
  background-color: #eee;
}

.progress-bar {
  height: auto;
  min-height: 12px;
  background-color: #2cb8f3;
}

.btn-primary {
  background-color: #2cb8f3;
  border-color: #2cb8f3;
}

.btn-primary:hover {
  color: #fff;
  background-color: #22b5f2;
  border-color: #22b5f2;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 2px rgba(44, 184, 243, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  background-color: #2cb8f3;
  border-color: #2cb8f3;
}

.btn-primary:active, .btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  background-color: #22b5f2;
  border-color: #22b5f2;
}

.btn-outline-primary {
  color: #2cb8f3;
  border-color: #2cb8f3;
}

.btn-outline-primary:hover {
  background-color: #22b5f2;
  border-color: #22b5f2;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 2px rgba(44, 184, 243, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #2cb8f3;
}

.btn-outline-primary:active, .btn-outline-primary.active,
.show > .btn-outline-primary.dropdown-toggle {
  background-color: #22b5f2;
  border-color: #22b5f2;
}

.card,
.card-header {
  border-color: #eee;
}

.card-header {
  padding: 0.71429rem 1.07143rem;
}

.card-block {
  padding: 1.07143rem;
}

.form-control {
  border-color: #ccc;
  height: auto;
}

.form-control-md {
  padding: .8rem 1rem .6rem;
}

.input-group-lg > .form-control, .input-group-lg > .input-group-prepend > .input-group-text, .input-group-lg > .input-group-append > .input-group-text, .input-group-lg > .input-group-prepend > .btn, .input-group-lg > .input-group-append > .btn {
  height: calc(3.75rem + 2px);
}

.form-control,
.form-control:focus {
  color: #555;
}

.form-control:focus,
.custom-select:focus {
  border-color: #2cb8f3;
  box-shadow: none;
}

.form-control::placeholder {
  color: inherit;
  opacity: .5;
}

.has-success .col-form-label,
.has-success .custom-control,
.has-success .form-check-label,
.has-success .form-control-feedback,
.has-success .form-control-label {
  color: #5cb85c;
}

.has-success .form-control {
  border-color: #5cb85c;
}

.has-warning .col-form-label,
.has-warning .custom-control,
.has-warning .form-check-label,
.has-warning .form-control-feedback,
.has-warning .form-control-label {
  color: #f0ad4e;
}

.has-warning .form-control {
  border-color: #f0ad4e;
}

.has-danger .col-form-label,
.has-danger .custom-control,
.has-danger .form-check-label,
.has-danger .form-control-feedback,
.has-danger .form-control-label {
  color: #d9534f;
}

.has-danger .form-control {
  border-color: #d9534f;
}

[class*="input-group-"] {
  min-width: 38px;
  background-color: transparent;
  border-color: #ccc;
  transition: border-color .15s ease-in-out 0s;
}

[class*="input-group-"] > * {
  white-space: normal;
}

[class*="input-group-"] i {
  margin: 0 auto;
}

.input-group .form-control:active,
.input-group .form-control:focus,
.input-group .form-control:hover {
  z-index: auto;
}

.btn-group {
  display: block;
}

.input-group-addon + .input-group-addon {
  border-left: solid 1px #ccc;
}

.table-striped tbody tr:nth-of-type(2n+1) {
  background-color: #f7f7f7;
}

.table-striped tbody td {
  border-top: none;
}

@media (min-width: 768px) {
  .btn-group {
    display: inline-flex;
  }
  .justified-content {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  .justified-content > * {
    display: table-cell;
    width: 1%;
    float: none;
  }
}

.dropdown-menu {
  min-width: 15rem;
  padding: 0;
}

.dropdown-toggle::before {
  display: none;
}

.dropdown-toggle::after {
  content: "\e900";
  position: relative;
  top: 0.21429rem;
  font-family: "hs-icons" !important;
  font-size: 10px;
  display: inline;
  border: none;
  margin-left: 0.5rem;
}

.dropdown-item {
  padding: 0.21429rem 1.07143rem;
}

.dropdown-item:focus, .dropdown-item:hover {
  background: rgba(0, 0, 0, 0.03);
}

.dropdown-item.active, .dropdown-item:active {
  background: rgba(0, 0, 0, 0.05);
  color: inherit !important;
}

.dropdown-item.active > a, .dropdown-item:active > a {
  color: inherit !important;
}

.dropdown-item.active > a::before, .dropdown-item.active > a::after, .dropdown-item:active > a::before, .dropdown-item:active > a::after {
  background-color: #fff;
}

.u-header__section--dark .dropdown-item.active > a, .u-header__section--dark .dropdown-item:active > a {
  color: #fff !important;
}

.chosen-container-single .chosen-single div b {
  position: relative;
}

.chosen-container-single .chosen-search input[type="text"] {
  padding: .8rem 1rem .6rem;
  border-color: #ccc;
}

.chosen-container-single.u-dropdown-sm .chosen-search input[type="text"] {
  padding: .25rem .5rem .1rem;
}

.chosen-container-single.u-dropdown-lg .chosen-search input[type="text"] {
  padding: .75rem 1.5rem .55rem;
}

.chosen-container-single.u-select-above .chosen-drop {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: -1px;
}

[data-animation].cbp {
  visibility: visible;
}

.cbp-slider-next::after,
.cbp-slider-prev::after {
  display: none;
}

.cbp-filter-item {
  cursor: pointer;
}

.cbp-item-off {
  opacity: 0;
}

/*------------------------------------
  Border Colors
------------------------------------*/
/* Primary Colors */
.g-brd-primary--active.cbp-filter-item-active {
  border-color: #2cb8f3 !important;
}

.g-brd-primary-opacity-0_3--active.cbp-filter-item-active {
  border-color: rgba(44, 184, 243, 0.3) !important;
}

.g-brd-primary-dark-dark-v1--active.cbp-filter-item-active {
  border-color: #22b5f2 !important;
}

.g-brd-primary-dark-dark-v2--active.cbp-filter-item-active {
  border-color: #14b0f2 !important;
}

.g-brd-primary-dark-dark-v3--active.cbp-filter-item-active {
  border-color: #0da1df !important;
}

/* Black Colors */
.g-brd-black--active.cbp-filter-item-active {
  border-color: #000 !important;
}

/* White Colors */
.g-brd-white--active.cbp-filter-item-active {
  border-color: #fff !important;
}

.g-brd-white-opacity-0_1--active.cbp-filter-item-active {
  border-color: rgba(255, 255, 255, 0.1) !important;
}

.g-brd-white-opacity-0_2--active.cbp-filter-item-active {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

.g-brd-white-opacity-0_3--active.cbp-filter-item-active {
  border-color: rgba(255, 255, 255, 0.3) !important;
}

.g-brd-white-opacity-0_4--active.cbp-filter-item-active {
  border-color: rgba(255, 255, 255, 0.4) !important;
}

.g-brd-white-opacity-0_5--active.cbp-filter-item-active {
  border-color: rgba(255, 255, 255, 0.5) !important;
}

.g-brd-white-opacity-0_6--active.cbp-filter-item-active {
  border-color: rgba(255, 255, 255, 0.6) !important;
}

/* Gray Colors */
.g-brd-gray-dark-v1--active.cbp-filter-item-active {
  border-color: #111 !important;
}

.g-brd-gray-dark-v2--active.cbp-filter-item-active {
  border-color: #333 !important;
}

.g-brd-gray-dark-v3--active.cbp-filter-item-active {
  border-color: #555 !important;
}

.g-brd-gray-dark-v4--active.cbp-filter-item-active {
  border-color: #777 !important;
}

.g-brd-gray-dark-v5--active.cbp-filter-item-active {
  border-color: #999 !important;
}

.g-brd-gray-light-v1--active.cbp-filter-item-active {
  border-color: #bbb !important;
}

.g-brd-gray-light-v2--active.cbp-filter-item-active {
  border-color: #ccc !important;
}

.g-brd-gray-light-v3--active.cbp-filter-item-active {
  border-color: #ddd !important;
}

.g-brd-gray-light-v4--active.cbp-filter-item-active {
  border-color: #eee !important;
}

.g-brd-gray-light-v5--active.cbp-filter-item-active {
  border-color: #f7f7f7 !important;
}

/* Transparent */
.g-brd-transparent--active.cbp-filter-item-active {
  border-color: transparent !important;
}

/*------------------------------------
  Background Colors
------------------------------------*/
/* Primary Colors */
.g-bg-primary--active.cbp-filter-item-active {
  background-color: #2cb8f3 !important;
}

/*------------------------------------
  Colors
------------------------------------*/
/* Primary Colors */
.g-color-primary--active.cbp-filter-item-active {
  color: #2cb8f3 !important;
}

/* Black Colors */
.g-color-black--active.cbp-filter-item-active {
  color: #000 !important;
}

/* White Colors */
.g-color-white--active.cbp-filter-item-active {
  color: #fff !important;
}

/* Gray Colors */
.g-color-gray-light-v1--active.cbp-filter-item-active {
  color: #bbb !important;
}

.g-color-gray-light-v2--active.cbp-filter-item-active {
  color: #ccc !important;
}

.g-color-gray-light-v3--active.cbp-filter-item-active {
  color: #ddd !important;
}

.g-color-gray-light-v4--active.cbp-filter-item-active {
  color: #eee !important;
}

.g-color-gray-light-v5--active.cbp-filter-item-active {
  color: #f7f7f7 !important;
}

.g-color-gray-dark-v1--active.cbp-filter-item-active {
  color: #111 !important;
}

.g-color-gray-dark-v2--active.cbp-filter-item-active {
  color: #333 !important;
}

.g-color-gray-dark-v3--active.cbp-filter-item-active {
  color: #555 !important;
}

.g-color-gray-dark-v4--active.cbp-filter-item-active {
  color: #777 !important;
}

.g-color-gray-dark-v5--active.cbp-filter-item-active {
  color: #999 !important;
}

/*--------------------------------------------------
  Fancybox
----------------------------------------------------*/
/* Blur bg container */
/*--------------------------------------------------
     Fancybox
     ----------------------------------------------------*/
.u-fancybox-theme .fancybox-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.u-fancybox-theme .fancybox-slide--iframe .fancybox-content {
  position: static;
}

.u-fancybox-theme.fancybox-show-thumbs .fancybox-content {
  right: 220px;
}

.u-fancybox-theme .fancybox-button,
.u-fancybox-theme .fancybox-arrow {
  font-size: 1.42857rem;
  line-height: 2.92857rem;
  width: 3.14286rem;
  height: 3.14286rem;
  text-align: center;
  background-color: transparent;
  border-width: 0.07143rem;
  border-style: solid;
  border-color: #fff;
  color: #fff;
  outline: none;
  border-radius: 50%;
  opacity: .8;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
}

.u-fancybox-theme .fancybox-button::before,
.u-fancybox-theme .fancybox-arrow::before {
  font-family: "hs-icons";
  position: static;
  opacity: 1;
  background-color: transparent;
  border: none;
  box-shadow: none;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  width: auto;
  height: auto;
}

.u-fancybox-theme .fancybox-button::after,
.u-fancybox-theme .fancybox-arrow::after {
  display: none;
}

.u-fancybox-theme .fancybox-button:hover, .u-fancybox-theme .fancybox-button:focus,
.u-fancybox-theme .fancybox-arrow:hover,
.u-fancybox-theme .fancybox-arrow:focus {
  opacity: 1;
}

.u-fancybox-theme .fancybox-button {
  display: inline-block;
}

.u-fancybox-theme .fancybox-button--close::before {
  content: "\e904";
}

.u-fancybox-theme .fancybox-button--play::before {
  content: "\e90c";
}

.u-fancybox-theme .fancybox-button--fullscreen::before {
  content: "\e909";
}

.u-fancybox-theme .fancybox-button--thumbs::before {
  content: "\e906";
}

.u-fancybox-theme .fancybox-arrow--left, .u-fancybox-theme .fancybox-arrow--right {
  margin-top: -1.57143rem;
  position: absolute;
  top: 50%;
  z-index: 100000;
  display: none;
}

.u-fancybox-theme.fancybox-show-nav .fancybox-arrow--left, .u-fancybox-theme.fancybox-show-nav .fancybox-arrow--right {
  display: block;
}

.u-fancybox-theme .fancybox-arrow--left::before, .u-fancybox-theme .fancybox-arrow--right::before {
  content: "\e902";
}

.u-fancybox-theme .fancybox-arrow--left {
  left: 1.42857rem;
}

.u-fancybox-theme .fancybox-arrow--right {
  right: 1.42857rem;
}

.u-fancybox-theme .fancybox-toolbar {
  top: 1.42857rem;
  right: 1.42857rem;
  margin-left: -0.35714rem;
  margin-right: -0.35714rem;
}

.u-fancybox-theme .fancybox-toolbar .fancybox-button {
  margin-left: 0.35714rem;
  margin-right: 0.35714rem;
}

.u-fancybox-theme .fancybox-thumbs {
  background-color: #000;
}

.u-fancybox-theme .fancybox-thumbs > ul > li {
  border-color: #000;
}

.u-fancybox-theme .fancybox-thumbs > ul > li::before {
  border-color: #72c02c;
}

.fancybox-controls--canzoomIn .fancybox-placeholder,
.fancybox-controls--canzoomOut .fancybox-placeholder {
  cursor: inherit;
}

.fancybox-slide.has-animation {
  display: block;
}

.fancybox-is-sliding .fancybox-slide.has-animation,
.fancybox-slide--current.has-animation,
.fancybox-slide--next.has-animation,
.fancybox-slide--previous.has-animation {
  display: none;
}

.fancybox-is-sliding .fancybox-slide.has-animation.animated,
.fancybox-slide--current.has-animation.animated,
.fancybox-slide--next.has-animation.animated,
.fancybox-slide--previous.has-animation.animated {
  display: block;
}

/*--------------------------------------------------
  Hamburgers
----------------------------------------------------*/
.hamburger {
  padding: 10px;
}

.hamburger-box {
  width: 37px;
  height: 25px;
}

.hamburger-inner {
  margin-top: 1px;
}

.hamburger-inner, .hamburger-inner::after, .hamburger-inner::before {
  width: 100%;
  height: 1px;
  border-radius: 0;
}

.u-header__section--light .hamburger-inner,
.u-header__section--light .hamburger-inner::after,
.u-header__section--light .hamburger-inner::before {
  background: #555;
}

.u-header__section--dark .hamburger-inner,
.u-header__section--dark .hamburger-inner::after,
.u-header__section--dark .hamburger-inner::before {
  background: #fff;
}

.hs-has-mega-menu:not(.hs-mega-menu-opened) *,
.hs-has-sub-menu:not(.hs-sub-menu-opened) * {
  transition: none !important;
}

.hs-has-mega-menu:not(.hs-mega-menu-opened) *::before, .hs-has-mega-menu:not(.hs-mega-menu-opened) *::after,
.hs-has-sub-menu:not(.hs-sub-menu-opened) *::before,
.hs-has-sub-menu:not(.hs-sub-menu-opened) *::after {
  transition: none !important;
}

[class^="et-"] {
  line-height: 1.1;
}

.material-icons {
  position: relative;
  top: 0.14286rem;
}

.u-btn-floating .material-icons,
.u-chip .material-icons {
  top: 0;
}

.jFiler-jProgressBar {
  height: auto;
  margin-top: 0;
}

.jFiler-item-thumb-image img {
  max-width: 100%;
}

/*------------------------------------
  jQuery UI
------------------------------------*/
.ui-autocomplete {
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 1px;
  z-index: 991;
}

.ui-autocomplete .left {
  float: left;
}

.ui-autocomplete .right {
  float: right;
}

.ui-autocomplete .ui-menu-item {
  padding: 0;
}

.ui-autocomplete .ui-menu-item-wrapper {
  display: block;
  color: inherit;
  background-color: transparent;
  border-color: transparent;
  padding: 7px 15px;
}

.ui-autocomplete .ui-menu-item-wrapper.ui-state-active, .ui-autocomplete .ui-menu-item-wrapper:hover {
  color: #fff;
  background-color: #2cb8f3;
  text-decoration: none;
}

.ui-autocomplete .ui-menu-item:hover {
  color: #fff;
  background-color: #2cb8f3;
}

.ui-autocomplete .ui-menu-item:hover .ui-menu-item-wrapper {
  background-color: transparent;
  border-color: transparent;
}

.ui-autocomplete-category {
  padding: 5px 15px;
  margin: 0;
  font-weight: bold;
}

.jvectormap-container {
  width: 100%;
  height: 100%;
}

/*------------------------------------
  Pin Map
------------------------------------*/
.point {
  width: 21px;
  height: 21px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
}

.point::before, .point::after {
  content: "";
  display: block;
  border-radius: 50%;
  position: absolute;
}

.point::before {
  top: 5px;
  left: 5px;
  width: 11px;
  height: 11px;
  background: #2cb8f3;
}

.point::after {
  top: 8px;
  left: 8px;
  width: 5px;
  height: 5px;
  background: rgba(0, 0, 0, 0.5);
}

.mwp-wrap {
  box-shadow: 0 1px 9px -1px;
  left: -82px;
  bottom: 35px !important;
}

.mwp-wrap::after {
  content: "";
  position: absolute;
  top: 99%;
  left: 50%;
  margin-left: -7px;
  width: 0;
  height: 0;
  border-width: 14px 14px 0 14px;
  border-style: solid;
  border-color: #111 transparent transparent transparent;
}

.embed-responsive .plyr--video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

pre[class*="language-"] {
  position: relative;
  margin: 0;
}

pre[class*="language-"] .toolbar {
  position: absolute;
  top: -1px;
  right: -1px;
}

pre[class*="language-"] .toolbar-item a {
  display: block;
  z-index: 10;
  padding: 5px 8px;
  font-size: 12px;
  color: #111;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #bbb;
  border-radius: 0 0 0 4px;
}

.select2-container .select2-selection--single {
  height: 2.5rem;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: normal;
  padding: .5rem .75rem;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 50%;
  line-height: 26px;
  text-align: center;
  transform: translateY(-50%);
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  position: static;
  top: auto;
  left: auto;
  width: auto;
  height: auto;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  vertical-align: middle;
  border: none;
  margin-top: 0;
  margin-left: 0;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b::before {
  content: "\f0dc";
}

.g-rounded-0 + .select2-container--default .select2-selection--single {
  border-radius: 0;
}

.select2-results__option {
  position: relative;
}

.select2-results__option img:first-child {
  margin-right: 10px;
}

.select2-results__option img:last-child {
  margin-left: 10px;
}

.select2-results__option:hover .u-option-icons--v1 {
  opacity: 1;
}

.u-select-v1 {
  position: relative;
}

.select2-container--default .u-select-v1 {
  height: auto;
  border-radius: 0;
}

.select2-container--default .u-select-v1 .select2-selection__rendered {
  padding: 0;
}

.select2-container--default .u-select-v1 .select2-selection__arrow {
  line-height: 22px;
}

.select2-container--default .u-select-v1:focus {
  outline: 0 none;
}

.slick-transform-off .slick-track {
  transform: none !important;
}

.slick-slide:focus {
  outline: 0 none;
}

[data-lazy] {
  opacity: 0;
  transition-property: opacity;
  transition-duration: .4s;
  transition-timing-function: ease-in;
}

.js-carousel {
  background: url(../../assets/img/preloaders/preloader-black.svg) no-repeat 50% 50%;
}

.js-carousel .js-next,
.js-carousel .js-prev {
  opacity: 0;
}

.js-carousel.slick-initialized {
  background: none;
}

.js-carousel.slick-initialized .js-slide,
.js-carousel.slick-initialized .js-thumb {
  visibility: visible;
  height: auto;
  overflow: visible;
}

.js-carousel.slick-initialized .js-next,
.js-carousel.slick-initialized .js-prev {
  opacity: 1;
}

.js-slide, .js-thumb {
  height: 0;
  visibility: hidden;
  overflow: hidden;
}

.js-slide:first-child, .js-thumb:first-child {
  height: auto;
}

.slick-vertical .slick-slide {
  border: none;
}

/*------------------------------------
  Border Colors
------------------------------------*/
/* Primary Colors */
.slick-active .g-brd-primary--before--active::before {
  border-color: #2cb8f3 !important;
}

.slick-active .g-brd-primary--active {
  border-color: #2cb8f3 !important;
}

.js-pagination .g-brd-primary--before--hover:hover::before {
  border-color: #2cb8f3 !important;
}

/*------------------------------------
  Border Width
------------------------------------*/
.slick-active .g-brd-3--before--active::before {
  border-width: 3px !important;
}

/*------------------------------------
  Box shadows
------------------------------------*/
.g-parent.slick-current .u-shadow-v24--active {
  box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.06);
}

/*------------------------------------
  Dot line v1
------------------------------------*/
.slick-active .u-dot-line-v1__inner::before, .slick-active
.u-dot-line-v1-2__inner::before {
  width: 100%;
  height: 100%;
}

/*------------------------------------
  Dot line v2
------------------------------------*/
.slick-active .u-dot-line-v2__inner::before, .slick-active
.u-dot-line-v2-2__inner::before {
  width: 100%;
  height: 100%;
}

.u-has-sidebar-navigation .custombox-content,
.u-has-sidebar-navigation .custombox-overlay {
  left: 0;
  right: 0;
}

/*------------------------------------
  JS
------------------------------------*/
.js-carousel_single-item {
  max-width: 100%;
  position: relative;
}

.js-next,
.js-prev,
.js-thumb {
  cursor: pointer;
}

.js-next,
.js-prev {
  z-index: 10;
}

.js-thumb img {
  display: inline-block;
}

.js-carousel_single-item-thumbs5__thumbs .js-thumb {
  opacity: .3;
  transition-property: opacity;
  transition-duration: .2s;
  transition-timing-function: ease-in;
}

.js-carousel_single-item-thumbs5__thumbs .slick-center {
  opacity: 1;
}

.js-carousel_single-item-thumbs5--v2__thumbs .js-thumb {
  opacity: .4;
  transition-property: opacity;
  transition-duration: .2s;
  transition-timing-function: ease-in;
}

.js-carousel_single-item-thumbs5--v2__thumbs .slick-center {
  opacity: 1;
}

.js-carousel_single-item-thumbs5--v3__thumbs .js-thumb {
  opacity: .4;
  transition-property: opacity;
  transition-duration: .2s;
  transition-timing-function: ease-in;
}

.js-carousel_single-item-thumbs5--v3__thumbs .slick-center {
  opacity: 1;
}

.u-pagi-control--v2 {
  display: block;
  width: 12px;
  height: 12px;
  margin: 0 3px;
  border-radius: 50%;
  background-color: #ddd;
  opacity: .3;
  cursor: pointer;
}

.slick-active .u-pagi-control-red {
  background-color: #f00;
}

.slick-active .u-pagi-control-grey {
  opacity: 1;
}

.u-carousel--v3 .js-prev,
.u-carousel--v3 .js-next {
  top: 50%;
  opacity: 0;
  transition-property: opacity;
  transition-duration: .2s;
  transition-timing-function: ease-in;
}

.u-carousel--v3 .g-height-40.js-prev, .u-carousel--v3 .g-height-40.js-next {
  margin-top: -20px;
  left: 50%;
}

.u-carousel--v3 .g-height-40.js-prev {
  margin-left: -99px;
}

.u-carousel--v3 .g-height-40.js-next {
  margin-left: 65px;
}

.u-carousel--v3:hover .js-prev,
.u-carousel--v3:hover .js-next {
  opacity: 1;
}

.u-carousel--v4 {
  margin-left: -15px;
  margin-right: -15px;
}

.u-carousel--v4 .js-prev,
.u-carousel--v4 .js-next {
  top: 50%;
  opacity: 0;
  transition-property: opacity;
  transition-duration: .4s;
  transition-timing-function: ease-in;
}

.u-carousel--v4 .g-height-40.js-prev, .u-carousel--v4 .g-height-40.js-next {
  margin-top: -20px;
  left: 50%;
}

.u-carousel--v4 .g-height-40.js-prev {
  margin-left: -160px;
}

.u-carousel--v4 .g-height-40.js-next {
  margin-left: 125px;
}

.u-carousel--v4:hover .js-prev,
.u-carousel--v4:hover .js-next {
  opacity: 1;
}

/*--------------------------------------------------
  Filter v1
----------------------------------------------------*/
.u-filter-v1 > li:not(:last-child)::after {
  content: "|";
  font-size: 0.71429rem;
  margin: 0 7px 0 14px;
  position: relative;
  top: -2px;
  line-height: inherit;
  display: inline-block;
  vertical-align: middle;
  color: #ddd;
}

.u-filter-v1 > li:hover,
.u-filter-v1 [class*="active"] {
  color: #2cb8f3;
}

/*--------------------------------------------------
  Carousel v10
----------------------------------------------------*/
.u-carousel-v10 .slick-dots {
  position: absolute;
  bottom: 30px;
  left: 0;
  width: auto;
  visibility: hidden;
  margin: 0;
  padding: 0;
}

.u-carousel-v10 .slick-dots li {
  display: block;
  margin-bottom: 5px;
}

.u-carousel-v10 .slick-dots button {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  color: transparent;
  background: #ccc;
  border: none;
  outline: none;
}

.u-carousel-v10 .slick-dots .slick-active button {
  background: #2cb8f3;
}

.u-carousel-v10-nav {
  margin-top: -135px;
}

.u-carousel-v10-nav .js-slide {
  position: relative;
  cursor: pointer;
  height: 100%;
}

.u-carousel-v10-nav .js-slide:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s ease-in-out;
}

.u-carousel-v10-nav .js-slide.slick-current::after {
  opacity: 0;
}

@media only screen and (max-width: 991px) {
  .u-carousel-v10 .slick-dots {
    visibility: visible;
  }
  .u-carousel-v10-nav {
    display: none;
  }
}

/*------------------------------------
  Internet Explorer (IE)
------------------------------------*/
/* Text Gradients */
.IE .g-color-cyan-gradient-opacity-v1,
.IE .g-color-blue-gradient-opacity-v1 {
  background: transparent;
  -webkit-text-fill-color: initial;
}

.IE .g-color-cyan-gradient-opacity-v1 {
  color: #00bed6;
}

.IE .g-color-blue-gradient-opacity-v1 {
  color: #8654da;
}

.IE .form-control:-ms-input-placeholder {
  opacity: 1;
}

/*------------------------------------
  Demo of Icons
------------------------------------*/
.u-icons-demo__item {
  border: solid 1px #eee;
  margin: 0 -1px -1px 0;
  padding: 20px 15px;
}

.u-icons-demo-item__value, .u-icons-demo-item__value:focus {
  width: 100%;
  text-align: center;
  border: none;
  outline: none;
}

/* Add here all your css styles (customizations) */
.g-bg-cover {
  overflow: hidden;
}

.g-bg-cover::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(44, 184, 243, 0.95) !important;
}

.banner-image {
  position: relative;
  margin-top: 150px;
  z-index: 1;
}

.banner-image .server-image .img-fluid {
  display: inline-block;
}

.gb-image {
  position: absolute;
  bottom: 0;
  z-index: -1;
}

.gb-image.gb-image.circle1 {
  right: 20px;
  bottom: 30px;
  animation: circle1 8s linear infinite;
}

.gb-image.circle2 {
  animation: circle2 8s linear infinite;
}

.gb-image.gb-image.cloud1 {
  left: 40px;
  animation: cloud1 3s linear;
  bottom: inherit;
  top: -150px;
}

.gb-image.gb-image.cloud2 {
  bottom: 45px;
  -webkit-animation: cloud2 3s linear;
  -moz-animation: cloud2 3s linear;
  animation: cloud2 3s linear;
  left: -30px;
}

.gb-image.gb-image.cloud3 {
  right: -30px;
  bottom: 75px;
  -webkit-animation: cloud3 3s linear;
  -moz-animation: cloud3 3s linear;
  animation: cloud3 3s linear;
}

@keyframes circle1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1);
  }
}

@keyframes circle2 {
  0% {
    opacity: 0;
    -webkit-transform: scale(1);
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.6);
  }
}

@keyframes cloud1 {
  0% {
    transform: translateY(-40%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes cloud2 {
  0% {
    transform: translateX(-40%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes cloud3 {
  0% {
    transform: translateX(40%);
  }
  100% {
    transform: translateX(0%);
  }
}

.gb-cloud {
  position: absolute;
  top: 100px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.gb-cloud ul li {
  background: #fff;
  background: linear-gradient(top, #fff 5%, #f1f1f1 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fff', endColorstr='#f1f1f1',GradientType=0 );
  border-radius: 100px;
  box-shadow: 0 8px 5px rgba(0, 0, 0, 0.1);
  height: 120px;
  position: relative;
  width: 350px;
  display: block;
}

.gb-cloud ul li:after,
.gb-cloud ul li:before {
  background: #fff;
  content: '';
  position: absolute;
}

.gb-cloud ul li:after {
  left: 50px;
  top: -50px;
  width: 100px;
  height: 100px;
  border-radius: 100px;
}

.gb-cloud ul li:before {
  width: 180px;
  height: 180px;
  right: 50px;
  top: -90px;
  border-radius: 200px;
}

.gb-cloud ul li:nth-child(1) {
  animation: animatecloud 45s linear infinite;
  transform: scale(0.5);
}

.gb-cloud ul li:nth-child(2) {
  animation: animatecloud 35s linear infinite;
  transform: scale(0.3);
}

.gb-cloud ul li:nth-child(3) {
  animation: animatecloud 50s linear infinite;
  transform: scale(0.5);
}

.gb-cloud ul li:nth-child(4) {
  animation: animatecloud 28s linear infinite;
  transform: scale(0.4);
}

.gb-cloud ul li:nth-child(5) {
  animation: animatecloud 35s linear infinite;
  transform: scale(0.5);
}

@keyframes animatecloud {
  0% {
    margin-left: -30%;
  }
  100% {
    margin-left: 100%;
  }
}
