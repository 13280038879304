/* Add here all your css styles (customizations) */

// Variables
@import "custom.variables";

// Mixins
@import "custom.mixins";

.g-bg-cover {
	overflow: hidden;
	&::after {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: rgba(44, 184, 243, .95) !important;
	}
}

.banner-image {
    position: relative;
    margin-top: 150px;
	z-index: 1;
	.server-image .img-fluid {
		display: inline-block;
	}
}

.gb-image {
    position: absolute;
    bottom: 0;
	z-index: -1;
	&.gb-image.circle1 {
		right: 20px;
		bottom: 30px;
		animation: circle1 8s linear infinite;
	}
	&.circle2 {
		animation: circle2 8s linear infinite;
	}
	&.gb-image.cloud1 {
		left: 40px;
		animation: cloud1 3s linear;
		bottom: inherit;
		top: -150px;
	}
	&.gb-image.cloud2 {
		bottom: 45px;
		-webkit-animation: cloud2 3s linear;
		-moz-animation: cloud2 3s linear;
		animation: cloud2 3s linear;
		left: -30px;
	}
	&.gb-image.cloud3 {
		right: -30px;
		bottom: 75px;
		-webkit-animation: cloud3 3s linear;
		-moz-animation: cloud3 3s linear;
		animation: cloud3 3s linear;
	}
}



@keyframes circle1 {
    0% {
    	opacity: 0;
        -webkit-transform: scale(0.5);
    }
    10% {
    	opacity: 1;
    }
    90% {
    	opacity: 1;
    }
    100% {
    	opacity: 0;
        -webkit-transform: scale(1);
    }
}

@keyframes circle2 {
    0% {
    	opacity: 0;
        -webkit-transform: scale(1);
    }
    10% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
    	opacity: 0;
        -webkit-transform: scale(0.6);
    }
}

@keyframes cloud1 {
    0% {
        transform: translateY(-40%);
    }
    100% {
        transform: translateY(-0%);
    }
}

@keyframes cloud2 {
    0% {                 
        transform: translateX(-40%);
    }
    100% {
        transform: translateX(0%);
    }
}

@keyframes cloud3 {
    0% {
        transform: translateX(40%);
    }
    100% {
        transform: translateX(0%);
    }
}



.gb-cloud {
	position: absolute;
	top: 100px;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
	// overflow: hidden;
}

.gb-cloud ul li {
	background: #fff;
	background: linear-gradient(top,  #fff 5%,#f1f1f1 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fff', endColorstr='#f1f1f1',GradientType=0 );
	border-radius: 100px;
	box-shadow: 0 8px 5px rgba(0, 0, 0, 0.1);
	height: 120px;
	position: relative;
	width: 350px;
	display: block;
}

.gb-cloud ul li:after, 
.gb-cloud ul li:before {
    background: #fff;
	content: '';
	position: absolute;
}

.gb-cloud ul li:after {
	left: 50px;
	top: -50px;
	width: 100px;
	height: 100px;
	border-radius: 100px;
}

.gb-cloud ul li:before {
	width: 180px;
	height: 180px;
	right: 50px;
	top: -90px;
	border-radius: 200px;
}

.gb-cloud ul li:nth-child(1) {
	animation: animatecloud 45s linear infinite;
	transform: scale(0.50);
}

.gb-cloud ul li:nth-child(2) {
	animation: animatecloud 35s linear infinite;
	transform: scale(0.3);
}

.gb-cloud ul li:nth-child(3) {
	animation: animatecloud 50s linear infinite;
	transform: scale(0.5);
}

.gb-cloud ul li:nth-child(4) {
	animation: animatecloud 28s linear infinite;
	transform: scale(0.4);
}

.gb-cloud ul li:nth-child(5) {
	animation: animatecloud 35s linear infinite;
	transform: scale(0.50);
}

@keyframes animatecloud {
    0% {
        margin-left: -30%;
    }
    100% {
        margin-left: 100%;
    }
}

